import * as React from "react"
// import { Link } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"

import FormInput from "components/Form/FormInput"
import FormTextarea from "components/Form/FormTextarea"
import { ContentBlockAction, ContentBlockSubmitButton } from "components/ContentBlock"
import { ICompany } from "types/ICompany"
import { MutationResult } from "react-query"
import { emailValidationPattern } from "utils/formHelpers"

interface Props {
	company: ICompany
	onUpdate: Function
	updateCompanyInfo: MutationResult<any>
}

interface FormValues {
	displayName: ICompany["displayName"]
	phone: ICompany["phone"]
	email: ICompany["email"]
	website: ICompany["website"]
	address: ICompany["address"]
}

const CompanyForm: React.FC<Props> = ({ company, onUpdate, updateCompanyInfo }) => {
	const { handleSubmit, register, errors, setValue, control } = useForm<FormValues>({})

	React.useEffect(() => {
		if (company) {
			setValue("displayName", company.displayName || "")
			setValue("phone", company.phone || "")
			setValue("email", company.email || "")
			setValue("address", company.address || "")
			setValue("website", company.website || "")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company])

	const onSave = (values: FormValues) => {
		onUpdate(values)
	}

	const isLoading = updateCompanyInfo.status === "loading" ? true : false

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<FlexGrid flexGridColumnCount={[1, 1, 1, 2]} flexGridColumnGap="scale800" flexGridRowGap={["scale200", "scale200", "scale600"]}>
				<FlexGridItem>
					<FormInput label="Företagsnamn" id="companyName" name="companyName" value={company.name} disabled />
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="Organisationsnummer"
						id="corporateIdentity"
						name="corporateIdentity"
						value={company.corporateIdentity}
						disabled
					/>
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="Förenklat företagsnamn"
						fieldError={errors.displayName}
						placeholder="Namn"
						id="displayName"
						name="displayName"
						inputRef={register}
					/>
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="E-post"
						fieldError={errors.email}
						placeholder="Namn"
						id="email"
						name="email"
						inputRef={register({
							pattern: emailValidationPattern,
						})}
					/>
				</FlexGridItem>
				<FlexGridItem>
					<FormInput label="Telefon" fieldError={errors.phone} placeholder="Telefon" id="phone" name="phone" inputRef={register} />
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="Hemsida"
						fieldError={errors.website}
						placeholder="www.foretag.se"
						id="website"
						name="website"
						startEnhancer="https://"
						inputRef={register}
					/>
				</FlexGridItem>
				<FlexGridItem>
					<Controller
						render={props => (
							<FormTextarea
								{...props}
								fieldError={errors.address}
								label="Adress"
								placeholder="..."
								rows={3}
								id="address"
								name="address"
								onChange={(e: React.BaseSyntheticEvent) => setValue("address", e.target.value, { shouldValidate: true })}
							/>
						)}
						control={control}
						defaultValue=""
						name="address"
						// rules={{ required: "Fältet är obligatoriskt" }}
					/>
				</FlexGridItem>
			</FlexGrid>
			<ContentBlockAction>
				<ContentBlockSubmitButton disabled={isLoading} isLoading={isLoading} type="submit">
					Spara
				</ContentBlockSubmitButton>
			</ContentBlockAction>
		</form>
	)
}

export default CompanyForm
