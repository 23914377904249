import * as React from "react"
import { Link } from "react-router-dom"
import { Card } from "baseui/card"
import { Block } from "baseui/block"
import { ParagraphMedium, Label3, ParagraphSmall } from "baseui/typography"
import { format } from "format-phone-swedish"

// import { UserPositionsArray } from "utils/enums"

// import Tile from "components/Tile"
// import Editable from "components/Editable"
import UserPositionSelect from "components/UserPositionSelect"
import { IProfile } from "types/IProfile"
// import Can from "components/Can"

export interface Props {
	userProfile: IProfile
	currentProfile: IProfile
	onUpdate: any
}

const UserCard: React.FC<Props> = ({ currentProfile, userProfile, onUpdate }) => {
	const arr: any = []
	const positions: any = arr.concat(currentProfile.position)

	// console.log("UPDATES", currentProfile, userProfile)

	return (
		<Card title={<ParagraphMedium margin="0">Kontakt</ParagraphMedium>}>
			<ParagraphMedium>{currentProfile.email}</ParagraphMedium>
			{currentProfile.phone && (
				<ParagraphMedium>
					<a href={`tel:${currentProfile.phone}`}>{format(currentProfile.phone, "–")}</a>
				</ParagraphMedium>
			)}

			<div>
				Roll:{" "}
				{currentProfile._id === userProfile._id ? (
					<UserPositionSelect profile={userProfile} onProfileUpdate={onUpdate} />
				) : (
					<Label3>
						{positions.map((p: any, i: number) => {
							return i + 1 === positions.length ? p : p + ", "
						})}
					</Label3>
				)}
				{/* <Can do="update" on={currentProfile} type="Profile" passThrough>
					{
						allowed =>
							allowed ? (
								<UserPositionSelect
									position={currentProfile.position}
									placeholder="Välj roll"
									onChange={params =>
										onUpdate(currentProfile.position, params.value, currentProfile)
									}
								/>
							) : (
								<Label3>
									{positions.map((p, i) => {
										return i + 1 === positions.length ? p : p + ", "
									})}
								</Label3>
							)
						// return <Label3>{UserPositions[currentProfile.position]}</Label3>
					}
				</Can> */}
				{/* Roll: {currentProfile.position} */}
				{/* <UserPositionSelect position={currentProfile.position} onChange={onUpdate} /> */}
				{/* Roll: <Editable onUpdate={val => onUpdate(val)} value={currentProfile.position} /> */}
			</div>
			{currentProfile.company && (
				<Block marginTop="scale200">
					<Link to={`../../company/${currentProfile.company._id}`}>{currentProfile.company.name}</Link>
				</Block>
			)}
			{currentProfile.personalContacts && (
				<div>
					<ParagraphSmall>Anhöriga:</ParagraphSmall>
					<ParagraphSmall>{currentProfile.personalContacts}</ParagraphSmall>
				</div>
			)}
		</Card>
	)
}

export default UserCard
