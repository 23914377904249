import { Bell, Check } from "react-feather"
import { Button, KIND, SIZE, SHAPE } from "baseui/button"
import { StatefulPopover, PLACEMENT } from "baseui/popover"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { ParagraphXSmall, ParagraphMedium } from "baseui/typography"
import { StatefulTooltip } from "baseui/tooltip"

import { INotification } from "types/INotification"
import useNotifications from "hooks/useNotifications"

import DropdownContent from "components/DropdownContent"
import Notification from "./Notification"

const Notifications: React.FC = () => {
	const [css, theme] = useStyletron()
	const { notifications, markAllAsRead, markAsRead } = useNotifications()
	const unread: INotification[] = (notifications && notifications.filter((n: INotification) => n.isRead === false)) || []

	return (
		<StatefulPopover
			placement={PLACEMENT.bottomRight}
			overrides={{
				Body: {
					style: () => ({
						width: "400px",
						maxWidth: "100%",
					}),
				},
			}}
			content={({ close }) => (
				<DropdownContent padding="0">
					<Block
						className={css({
							padding: `${theme.sizing.scale600} ${theme.sizing.scale800}`,
							backgroundColor: theme.colors.primary100,
						})}
						display="flex"
						justifyContent="space-between"
						alignItems="center">
						<ParagraphMedium margin="0">Notifikationer</ParagraphMedium>
						{unread && unread.length > 0 && (
							<StatefulTooltip content={() => <Block>Markera som lästa</Block>} returnFocus showArrow autoFocus>
								<Button size={SIZE.mini} kind={KIND.tertiary} shape={SHAPE.round} onClick={() => markAllAsRead()}>
									<Check size="16" />
								</Button>
							</StatefulTooltip>
						)}
					</Block>
					<Block backgroundColor="white" height="380px" maxHeight="100%" overflow="scroll">
						{notifications && notifications.length > 0 ? (
							notifications.map((notification: INotification, i: number) => (
								<Notification key={i} notification={notification} markAsRead={markAsRead} closePopup={close} />
							))
						) : (
							<ParagraphXSmall
								className={css({
									margin: "0",
									padding: theme.sizing.scale800,
									textAlign: "center",
								})}>
								Inga nya notifikationer
							</ParagraphXSmall>
						)}
					</Block>
				</DropdownContent>
			)}
			returnFocus
			showArrow
			autoFocus>
			<Button
				kind={KIND.minimal}
				size={SIZE.compact}
				shape={SHAPE.round}
				overrides={{
					BaseButton: {
						style: ({ $theme }) => ({
							backgroundColor: unread.length > 0 ? $theme.colors.negative100 : "transparent",
						}),
					},
				}}>
				<Bell size="18" />
			</Button>
		</StatefulPopover>
	)
}

export default Notifications
