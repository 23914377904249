import { useQuery } from "react-query"
import { getTasks } from "services/tasks"
import { ITask } from "types/ITask"

export default function useTasks(checkId: string) {
	const { data: tasks, isLoading, status, error } = useQuery<ITask[], Error>(["tasks", { checkId }], getTasks, {
		enabled: checkId,
	})

	return {
		tasks,
		isLoading,
		status,
		error,
	}
}
