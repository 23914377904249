import * as React from "react"
import { useParams } from "react-router"
import { useAbility } from "@casl/react"

import useCompany from "hooks/useCompany"

import { ICompany } from "types/ICompany"
import RoutesBuilder from "components/RoutesBuilder"
import PageHeader from "components/layout/PageHeader"
import SubNavigation from "components/layout/SubNavigation"
import Can, { AbilityContext } from "components/Can"
import { setType } from "config/abilities"

import CompanyProfile from "./CompanyProfile"
import CompanyDocuments from "./CompanyDocuments"
import CompanyMembers from "./CompanyMembers"
import CompanySettings from "./CompanySettings"
import AddCompanyMember from "./AddCompanyMember"

const Company = () => {
	const ability = useAbility(AbilityContext)
	const { companyId, projectId } = useParams()
	const { company, updateCompany, updateCompanyInfo } = useCompany(companyId)

	const onUpdate = (updatedData: ICompany) => {
		updateCompany({ companyId, updatedData })
	}

	// console.log("Render <Company />", company)

	const routes = React.useMemo(
		() => [
			{
				text: "Allmänt",
				url: "",
				path: "",
				element: <CompanyProfile company={company} />,
			},
			{
				text: "Medlemmar",
				url: "members",
				path: "members",
				element: <CompanyMembers company={company} />,
			},
			// {
			// 	text: "KMA Dokument",
			// 	url: "documents",
			// 	path: "documents",
			// 	element: <CompanyDocuments company={company} />,
			// },
			// {
			// 	text: "Företagsinställningar",
			// 	url: "settings",
			// 	path: "settings",
			// 	element: (
			// 		<CompanySettings
			// 			company={company}
			// 			onUpdate={onUpdate}
			// 			updateCompanyInfo={updateCompanyInfo}
			// 		/>
			// 	),
			// },
			// {
			// 	text: "Dagbok",
			// 	url: "diary",
			// 	path: "diary",
			// 	element: <CompanyDiary />,
			// },
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[company, updateCompanyInfo],
	)

	if (!company) return null

	const currentCompanyDocuments = setType("CompanyDocuments", company)
	if (ability.can("view", currentCompanyDocuments)) {
		routes.push({
			text: "KMA Dokument",
			url: "documents",
			path: "documents",
			element: <CompanyDocuments company={company} />,
		})
	}

	const currentCompanySettings = setType("CompanySettings", company)
	if (ability.can("view", currentCompanySettings)) {
		routes.push({
			text: "Företagsinställningar",
			url: "settings",
			path: "settings",
			element: <CompanySettings company={company} onUpdate={onUpdate} updateCompanyInfo={updateCompanyInfo} />,
		})
	}

	return (
		<React.Fragment>
			<PageHeader title={company.name}>
				<Can do="create" on={company} type="Member">
					{() => (
						<RoutesBuilder
							routes={[
								{
									path: "members",
									element: <AddCompanyMember companyId={companyId} projectId={projectId} />,
								},
							]}
						/>
					)}
				</Can>
			</PageHeader>
			<SubNavigation links={routes} />
			<RoutesBuilder routes={routes} />
		</React.Fragment>
	)
}

export default Company
