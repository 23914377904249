import * as React from "react"
import { AspectRatioBox, AspectRatioBoxBody } from "baseui/aspect-ratio-box"
import { Button, SIZE, SHAPE, KIND } from "baseui/button"
import { useStyletron } from "baseui"

import GlobalContext from "context/GlobalContext"

import { buildFilePath } from "utils/misc"
import useHover from "hooks/helpers/useHover"

import Can from "components/Can"
import { ITask, ITaskAttachment } from "types/ITask"
import { MutationResult } from "react-query"
import { ModalEnum } from "containers/ModalContainer"
// import TaskDocument from "./TaskDocument"

interface Props {
	attachment: ITaskAttachment
	task: ITask
	removeAttachmentInfo: MutationResult<any>
	taskIsFreezed: boolean
	onDelete: Function
}

const TaskImage: React.FC<Props> = ({ attachment, task, onDelete, removeAttachmentInfo, taskIsFreezed }) => {
	const [hoverRef, hovered] = useHover<HTMLDivElement>()
	const { openModal } = React.useContext(GlobalContext)
	const [css, theme] = useStyletron()

	const isLoading = removeAttachmentInfo.status === "loading"

	return (
		<div
			ref={hoverRef as React.RefObject<HTMLDivElement>}
			className={css({
				textAlign: "center",
				opacity: isLoading ? "0.5" : "1",
				transitionTimingFunction: theme.animation.linearCurve,
				transitionDuration: theme.animation.timing300,
				transitionProperty: "opacity",
			})}>
			<AspectRatioBox>
				<AspectRatioBoxBody
					className={css({
						cursor: "pointer",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: theme.colors.mono200,
						":hover": {
							opacity: "0.5",
							transitionTimingFunction: theme.animation.linearCurve,
							transitionDuration: theme.animation.timing300,
							transitionProperty: "opacity",
						},
					})}>
					<span
						onClick={() =>
							openModal(ModalEnum.ViewDocument, {
								currentDocument: attachment,
							})
						}>
						<img
							alt=""
							src={buildFilePath(attachment)}
							className={css({
								maxHeight: "100%",
								maxWidth: "100%",
								width: "auto",
								height: "auto",
							})}
						/>
					</span>
				</AspectRatioBoxBody>
			</AspectRatioBox>
			<Can do="update" on={task} type="Task">
				{() =>
					!taskIsFreezed && (
						<Button
							size={SIZE.mini}
							shape={SHAPE.pill}
							kind={KIND.tertiary}
							overrides={{
								BaseButton: {
									style: () => ({
										marginTop: theme.sizing.scale100,
										opacity: hovered ? "1.0" : "0",
									}),
								},
							}}
							disabled={isLoading}
							onClick={e => onDelete(attachment._id)}>
							Ta bort
						</Button>
					)
				}
			</Can>
		</div>
	)
}

export default TaskImage
