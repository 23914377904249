import { createDarkTheme, createLightTheme, createThemedStyled } from "baseui"
import { Theme } from "baseui/theme"


const breakpoints: any = {
	small: 480,
	medium: 769,
	large: 1024,
	xLarge: 1216,
}
const primitives: any = Object.keys(breakpoints).reduce(
	(acc, key) => {
		acc.mediaQuery[key] = `@media screen and (min-width: ${breakpoints[key]}px)`
		return acc
	},
	{
		breakpoints,
		mediaQuery: {},
	},
)

primitives.primaryFontFamily = "'basier_circle','Times New Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"


interface CustomStyles {
	custom: {
		sidebarWidth: string
		sidebarBackgroundColor: string
		bodyBackgroundColor: string
		headerHeight: string
	}
}


// Custom variables
const globalOverrides = {
	sidebarWidth: "220px",
	headerHeight: "56px"
}

const lightOverrides: CustomStyles = {
	custom: {
		...globalOverrides,
		bodyBackgroundColor: "white",
		sidebarBackgroundColor: "#f2f2f2"
	}
}
const darkOverrides: CustomStyles = {
	custom: {
		...globalOverrides,
		bodyBackgroundColor: "#2E2E30",
		sidebarBackgroundColor: "black"
	}
}

interface CustomTheme extends CustomStyles, Theme { }

const lightTheme: CustomTheme = createLightTheme(primitives, lightOverrides)
const darkTheme: CustomTheme = createDarkTheme(primitives, darkOverrides)

const customStyled = createThemedStyled<CustomTheme>()

export { lightTheme, darkTheme, customStyled }


// const CustomThemeT = ThemeT & { customColor: string };
// const theme = createTheme(/*primtives, overrides*/);
// const customTheme: CustomThemeT = {
// 	...theme,
// 	customColor: 'pink',
// };