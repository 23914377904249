import JSZip from "jszip"

import { instance } from "config/axios"
import { IDocument } from "types/IDocument"
import { buildFilePath } from "./misc"


export const fetchFile = async (file: any): Promise<Blob> => {
    return new Promise(async (resolve, reject) => {
        const path = buildFilePath(file)
        const res = await instance({
            url: `${path}?download=true`,
            method: "GET",
            responseType: "blob",
        })

        if (res.status > 399) {
            reject("Error")
        }
        resolve(res.data)
    })
}

export const bundleZip = async (documents: IDocument[], zipName: string): Promise<Blob> => {
    return new Promise(async (resolve, reject) => {
        var zip = new JSZip()
        // Add an top-level, arbitrary text file with contents
        console.log("Creating folder...")
        const folder = zip.folder(zipName)
        if (!folder) {
            return null
        }

        console.log("Started looping...")
        for (let index = 0; index < documents.length; index++) {
            const document = documents[index]
            const data = await fetchFile(document)
            console.log("Fetched one file")
            folder.file(document.name, data)
        }

        // Generate the zip file asynchronously
        console.log("Generating zip...")
        zip.generateAsync({ type: "blob" }).then(function (content) {
            console.log("Generated zip!")
            resolve(content)
        })
    })
}