import * as React from "react"

import { ContentBlock } from "components/ContentBlock"
import SettingsForm from "./Form"
import { IProject } from "types/IProject"

interface Props {
	currentProject: IProject
	updateProjectInfo: any
	onUpdate: Function
}

const General: React.FC<Props> = ({ currentProject, updateProjectInfo, onUpdate }) => {
	// console.log('General page', onUpdate)
	if (!currentProject) {
		return null
	}

	return (
		<ContentBlock>
			<SettingsForm currentProject={currentProject} onUpdate={onUpdate} updateProjectInfo={updateProjectInfo} onCancel={false} />
		</ContentBlock>
	)
}

export default General
