// stitches.config.ts
import { createStyled } from '@stitches/react';

export const { styled, css } = createStyled({
    prefix: '',
    tokens: {
        colors: {
            $background: '#efefef',
            $primary: '#420D9A',
            $gray: '#7e7e7e '
        }
    },
    breakpoints: {
        bp1: (rule) => `@media (min-width: 640px) { ${rule} }`,
        bp2: (rule) => `@media (min-width: 768px) { ${rule} }`,
        bp3: (rule) => `@media (min-width: 1024px) { ${rule} }`,
        bp4: (rule) => `@media (min-width: 1280px) { ${rule} }`,
    },
    utils: {},
});