import * as React from "react"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { ParagraphXSmall } from "baseui/typography"

// Test
export interface Props {
	label?: string | JSX.Element
	narrow?: boolean
	border?: boolean
}

const ProjectplanColumn: React.FC<Props> = ({ children, label, narrow = false, border = true, ...props }) => {
	const [css, theme] = useStyletron()
	return (
		<Block
			className={css({
				width: narrow ? "50px" : "115px",
				padding: narrow ? "0 20px" : "0px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderLeft: border ? `1px solid ${theme.colors.mono300}` : "1px solid transparent",
				// borderRight: label ? "" : `1px solid ${theme.colors.mono300}`,
			})}
			{...props}>
			{label && <ParagraphXSmall>{label}</ParagraphXSmall>}
			{children}
		</Block>
	)
}

export default ProjectplanColumn
