import * as React from "react"
import { Button, SHAPE, SIZE } from "baseui/button"
import { Block } from "baseui/block"
import { ParagraphSmall } from "baseui/typography"

import useActivities from "hooks/useActivities"
import { IActivity } from "types/IActivity"

import { ContentBlock } from "components/ContentBlock"
import ActivityBlock from "./ActivityBlock"
import ActivitySteps from "./ActivitySteps"
import { ActivityEntities } from "types/shared"

export interface Props {
	entity: ActivityEntities
	id: string
	blockMode?: boolean
}

const Activities: React.FC<Props> = ({ entity, id, blockMode = false }) => {
	const { activities, isFetchingMore, fetchMore, canFetchMore } = useActivities(entity, id)

	if (!activities || !activities.length) {
		return (
			<div className="empty">
				<div className="empty-icon">
					<i className="icon icon-people"></i>
				</div>
				<p className="empty-title h5">Det finns ingen aktivitet</p>
			</div>
		)
	}

	return (
		<React.Fragment>
			{blockMode ? (
				<div>{activities.map(group => group.map((activity: IActivity) => <ActivityBlock key={activity._id} activity={activity} />))}</div>
			) : (
				<ContentBlock>
					<ActivitySteps activities={activities} />
				</ContentBlock>
			)}

			<Block margin="scale600" display="flex" justifyContent="center">
				{canFetchMore ? (
					<Button
						onClick={() => fetchMore()}
						isLoading={isFetchingMore === false ? false : true}
						shape={SHAPE.pill}
						size={SIZE.compact}
						disabled={isFetchingMore === false ? false : true}>
						Hämta mer
					</Button>
				) : (
					<ParagraphSmall>Det finns ingen mer aktivitet</ParagraphSmall>
				)}
			</Block>
		</React.Fragment>
	)
}

export default Activities
