import * as React from "react"
import { Block } from "baseui/block"
// import { ParagraphSmall } from "baseui/typography"
import { Button, SIZE, KIND } from "baseui/button"
import { Row } from "react-table"

interface Props {
	onSelect: Function | undefined
	selectedFlatRows: Row<any>[]
	onDelete: Function | undefined
	isDeleteLoading: boolean | undefined
	onDownload: Function | undefined
	isDownloadLoading: boolean | undefined
}

const SelectColumnOptions: React.FC<Props> = ({
	onSelect,
	selectedFlatRows,
	onDelete,
	isDeleteLoading = false,
	onDownload,
	isDownloadLoading = false,
}) => (
	<React.Fragment>
		{/* {selectable && selectedFlatRows.length > 0 && (
			<Block marginRight="scale200" marginLeft="scale200">
				<ParagraphSmall>({selectedFlatRows.length})</ParagraphSmall>
			</Block>
		)} */}
		{onSelect && selectedFlatRows.length > 0 && (
			<Block marginTop="scale400">
				{onDelete && (
					<Button
						size={SIZE.mini}
						kind={KIND.primary}
						onClick={e => onDelete(selectedFlatRows.map(row => row.original))}
						isLoading={isDeleteLoading}>
						Ta bort ({selectedFlatRows.length})
					</Button>
				)}
				{onDownload && (
					<Button
						size={SIZE.mini}
						kind={KIND.primary}
						onClick={e => onDownload(selectedFlatRows.map(row => row.original))}
						isLoading={isDownloadLoading}>
						Ladda ner ({selectedFlatRows.length})
					</Button>
				)}
			</Block>
		)}
	</React.Fragment>
)

export default SelectColumnOptions
