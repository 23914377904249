import * as React from "react"

import { ModalTypes, ModalPayload } from "containers/ModalContainer"
import { IToast } from "containers/ToastContainer"

export interface GlobalContextProps {
	isSidebarOpen: boolean
	toggleSidebar: () => void
	currentModal: ModalTypes
	openModal: (modal: ModalTypes, payload?: ModalPayload) => void
	closeModal: () => void
	modalPayload: ModalPayload
	showToast: (toast: IToast) => void
	activeToast: IToast | null
}

const initialContext: GlobalContextProps = {
	isSidebarOpen: true,
	modalPayload: {},
	toggleSidebar: () => {},
	currentModal: null,
	openModal: () => {},
	closeModal: () => {},
	showToast: () => {},
	activeToast: null,
}

const GlobalContext = React.createContext<GlobalContextProps>(initialContext)

export const GlobalProvider: React.FC<{}> = ({ children }) => {
	const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(initialContext.isSidebarOpen)
	const [currentModal, setCurrentModal] = React.useState<ModalTypes>(initialContext.currentModal)
	const [modalPayload, setPayload] = React.useState<ModalPayload>(initialContext.modalPayload)
	const [activeToast, setActiveToast] = React.useState<IToast | null>(initialContext.activeToast)

	const openModal = (modal: ModalTypes, payload?: ModalPayload) => {
		if (payload) {
			setPayload(payload)
		}
		setCurrentModal(modal)
	}
	const closeModal = () => {
		setCurrentModal(null)
	}
	const showToast = (toast: IToast) => {
		setActiveToast(toast)
	}

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen)
	}

	return (
		<GlobalContext.Provider
			value={{
				isSidebarOpen: isSidebarOpen,
				toggleSidebar: toggleSidebar,
				currentModal: currentModal,
				openModal: openModal,
				closeModal: closeModal,
				modalPayload: modalPayload,
				showToast: showToast,
				activeToast: activeToast,
			}}>
			{children}
		</GlobalContext.Provider>
	)
}

export const GlobalConsumer = GlobalContext.Consumer

export default GlobalContext
