import React from "react"
import { Hooks } from "react-table"

import IndeterminateCheckbox from "../actions/IndeterminateCheckbox"

export const selectionHook = (hooks: Hooks<any>) => {
	hooks.visibleColumns.push(columns => [
		{
			id: "selection",
			width: 40,
			// @ts-ignore
			Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />,
			// @ts-ignore
			Cell: ({ row }: CellProps<any>) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
		},

		...columns,
	])
}
