import * as React from "react"

import { EntityStatus } from "types/enums"
import { ICheck } from "types/ICheck"
import { IControl } from "types/IControl"

export default function useControlStatus(control: IControl, checks?: ICheck[]) {
	const [controlIsApprovable, setControlIsApprovable] = React.useState<boolean>(true)
	const [controlIsFreezed, setControlIsFreezed] = React.useState<boolean>(true)

	React.useEffect(() => {
		if (!checks) return
		if (checks.length && checks.filter(check => check.status !== EntityStatus.approved).length === 0) {
			setControlIsApprovable(true)
		} else {
			setControlIsApprovable(false)
		}
	}, [checks])

	React.useEffect(() => {
		if (!control) return

		if (control.status === EntityStatus.approved || control.status === EntityStatus.pending) {
			setControlIsFreezed(true)
		} else {
			setControlIsFreezed(false)
		}
	}, [control])

	return {
		controlIsFreezed,
		controlIsApprovable,
	}
}
