import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { MutationResult } from "react-query"
import { Button, KIND } from "baseui/button"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { FormControl } from "baseui/form-control"
import { OnChangeParams } from "baseui/select"

import { getKeysFromArray } from "utils/misc"
import { ICompany } from "types/ICompany"

import FormInput from "components/Form/FormInput"
import FormCorporateIdentity from "components/Form/FormCorporateIdentity"
import UserPositionSelect from "components/UserPositionSelect"

interface Props {
	goBack: Function
	createProject: Function
	createProjectInfo: MutationResult<any>
}

interface FormValues {
	position: any
	companyName: ICompany["name"]
}

const PersonalForm: React.FC<Props> = ({ goBack, createProject, createProjectInfo }) => {
	const [, theme] = useStyletron()
	const { handleSubmit, register, errors, setValue, control } = useForm<FormValues>({})

	const onSave = (values: FormValues) => {
		if (values.position) {
			values.position = getKeysFromArray(values.position)
		}

		createProject(values)
	}

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<React.Fragment>
				<FlexGrid flexGridColumnCount={[1, 1, 1, 1]} flexGridColumnGap="scale800" flexGridRowGap="scale600">
					<React.Fragment>
						<FlexGridItem>
							<FormInput
								label="Företagsnamn"
								fieldError={errors.companyName}
								placeholder="Namn"
								id="companyName"
								name="companyName"
								inputRef={register({
									required: "Fältet är obligatoriskt.",
								})}
							/>
						</FlexGridItem>
						<FlexGridItem>
							<FormCorporateIdentity errors={errors} register={register} setValue={setValue} />
						</FlexGridItem>
						<FlexGridItem>
							<Controller
								render={props => (
									<FormControl label="Roll i projektet" error={errors.position && errors.position.message}>
										<UserPositionSelect
											placeholder="Välj roll"
											name="position"
											size="default"
											value={props.value}
											// position={props.value}
											maxDropdownHeight="300px"
											// defaultValue={[]}
											onChange={(params: OnChangeParams) =>
												setValue("position", params.value, {
													shouldValidate: true,
												})
											}
										/>
									</FormControl>
								)}
								control={control}
								rules={{
									required: true,
									validate: val => val.length > 0 || "Du måste välja ett alternativ",
								}}
								defaultValue={[]}
								name="position"
							/>
						</FlexGridItem>
					</React.Fragment>
				</FlexGrid>
			</React.Fragment>
			{/* {isNewProject ? ( */}
			<Block display="flex" flexDirection="column" alignItems="center" marginTop="scale1200">
				<React.Fragment>
					<Button
						overrides={{
							BaseButton: {
								style: () => ({
									width: "100%",
									margin: "0 auto",
									display: "block",
									[theme.mediaQuery.medium]: {
										maxWidth: "300px",
									},
								}),
							},
						}}
						disabled={createProjectInfo && createProjectInfo.status === "loading"}
						isLoading={createProjectInfo && createProjectInfo.status === "loading"}>
						Skapa projekt
					</Button>
					<Button kind={KIND.tertiary} onClick={() => goBack()}>
						Tillbaka
					</Button>
				</React.Fragment>
			</Block>
		</form>
	)
}

export default PersonalForm
