import * as React from "react"
import { OnChangeParams, Select, SelectProps, SIZE } from "baseui/select"

import { UserPositionsArray } from "utils/enums"
import { getKeysFromArray } from "utils/misc"
import GlobalContext from "context/GlobalContext"
import { IProfile } from "types/IProfile"

export interface InlineSelect extends SelectProps {
	onProfileUpdate?: any
	onChange?: any
	profile?: IProfile
}
export interface FormSelect extends SelectProps {
	onProfileUpdate?: any
	onChange?: any
	profile?: IProfile
	name?: string
}

const UserPositionSelect: React.FC<InlineSelect | FormSelect> = ({
	size = SIZE.default,
	onProfileUpdate = null,
	profile = null,
	onChange = null,
	value,
	// onUpdate,
	...props
}) => {
	const { showToast } = React.useContext(GlobalContext)
	let currentValue: any = value ?? []
	if (profile) {
		// Find positions if exists in position array
		currentValue = UserPositionsArray.filter((pos: any) => profile.position.indexOf(pos.key) > -1)
	}

	const _onChange = (params: OnChangeParams) => {
		if (onChange) {
			onChange(params)
			return
		}

		if (!profile || !onProfileUpdate || profile.position === params.value) return

		if (!params.value.length) {
			showToast({ type: "negative", message: "Användaren måste ha minst en roll" })
			return
		}

		const updatedData = { position: getKeysFromArray(params.value) }
		onProfileUpdate({
			companyId: profile.company._id,
			memberId: profile._id,
			updatedData,
		})
	}

	return (
		<Select
			valueKey="key"
			labelKey="label"
			placeholder={currentValue.length ? "" : "Välj roll"}
			options={UserPositionsArray}
			value={currentValue}
			clearable={false}
			filterOutSelected={true}
			multi={true}
			size={size}
			getOptionLabel={getLabel}
			getValueLabel={getLabelExtended}
			maxDropdownHeight="300px"
			onChange={_onChange}
			{...props}
		/>
	)
}

export interface LabelProps {
	option?: any
}

const getLabelExtended: React.FC<LabelProps> = ({ option }) => {
	return <React.Fragment>{option.key}</React.Fragment>
}

const getLabel: React.FC<LabelProps> = ({ option }) => {
	return (
		<React.Fragment>
			{option.key} - {option.label}
		</React.Fragment>
	)
}

export default UserPositionSelect
