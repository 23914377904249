import { FormControl } from "baseui/form-control"
import { Datepicker, DatepickerProps } from "baseui/datepicker"
import sv from "date-fns/locale/sv"
import { FieldError } from "react-hook-form"

export interface Props extends DatepickerProps {
	fieldError?: FieldError
	label: string
	name: string
}

const FormDate = (props: Props) => {
	const { fieldError } = props

	return (
		<FormControl label={props.label} error={fieldError && fieldError.message}>
			<Datepicker locale={sv} placeholder="YYYY/MM/DD" {...props} />
		</FormControl>
	)
}

export default FormDate
