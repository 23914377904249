import * as React from "react"
import { ParagraphMedium, ParagraphXSmall } from "baseui/typography"
import { Block } from "baseui/block"

const NoSections: React.FC = () => (
	<Block>
		<ParagraphMedium marginBottom="0">Projektplanen är tom</ParagraphMedium>
		<ParagraphXSmall>Börja med att lägga till sektioner och kontrollpunkter.</ParagraphXSmall>
	</Block>
)

export default NoSections
