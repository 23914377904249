import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { Block } from "baseui/block"
import { ListItem } from "baseui/list"
import { useStyletron } from "baseui"
import { X } from "react-feather"
import { useSelector } from "react-unistore"
import { MutationResult } from "react-query"
import { OnChangeParams } from "baseui/select"

import { getKeysFromArray } from "utils/misc"
import { ControlPermissions } from "utils/enums"

import Can from "components/Can"
import ProfileTile from "components/ProfileTile"
import ControlRoles from "components/ControlRoles"
import { IControl } from "types/IControl"
import { IState } from "store"
import { IEmbeddedMember } from "types/shared"

interface Props {
	members: IEmbeddedMember[]
	onRemoveMember: Function
	removeControlMemberInfo: MutationResult<any>
	onUpdate: Function
	closeModal: Function
	controlIsFreezed: boolean
	projectId: string
	control: IControl
}

const MemberList: React.FC<Props> = ({
	members,
	onRemoveMember,
	removeControlMemberInfo,
	onUpdate,
	closeModal,
	controlIsFreezed,
	projectId,
	control,
}) => {
	const [, theme] = useStyletron()
	const currentProfile = useSelector((state: IState) => state.profileAbilities)

	if (!members) return null

	if (!members.length) {
		return <div>Det finns inga ansvariga</div>
	}

	const onChange = (newValue: any, member: IEmbeddedMember) => {
		onUpdate(member._id, { permissions: getKeysFromArray(newValue)[0] })
	}

	return (
		<>
			{members &&
				members.map((member, index) => (
					<ListItem
						key={index + member._id}
						endEnhancer={() => (
							<Can do="update" on={control} type="Member" passThrough>
								{(allowed: boolean) =>
									!allowed || controlIsFreezed || currentProfile?._id === member._id ? (
										<Block marginRight="scale400">{ControlPermissions[member.permissions]}</Block>
									) : (
										<>
											<ControlRoles
												searchable={false}
												onChange={(params: OnChangeParams) => onChange(params.value, member)}
												currentRole={member.permissions}
											/>
											<Button
												overrides={{
													BaseButton: {
														style: () => ({
															marginLeft: theme.sizing.scale400,
														}),
													},
												}}
												shape={SHAPE.round}
												size={SIZE.compact}
												kind={KIND.tertiary}
												onClick={e => onRemoveMember(member)}>
												<X size={14} />
											</Button>
										</>
									)
								}
							</Can>
						)}
						sublist
						overrides={{
							Content: {
								style: ({ $theme }) => ({
									paddingRight: "0",
									marginLeft: "0",
									borderRightColor: "none",
									borderBottomColor: "none",
									borderTopWidth: "0",
									borderRightWidth: "0",
									borderBottomWidth: "0",
									borderLeftWidth: "0",
								}),
							},
						}}>
						<Block margin={`${theme.sizing.scale200} ${theme.sizing.scale0}`}>
							<ProfileTile profile={member} projectId={projectId} onClick={closeModal} />
						</Block>
					</ListItem>
				))}
		</>
	)
}

export default MemberList
