import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { OnChangeParams } from "baseui/select"
import { KIND } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Plus } from "react-feather"
import { queryCache } from "react-query"

import GlobalContext from "context/GlobalContext"
import useProjectMembers from "hooks/useProjectMembers"
import useCompanies from "hooks/useCompanies"
import { getKeysFromArray } from "utils/misc"

import FormInput from "components/Form/FormInput"
import FormSelect from "components/Form/FormSelect"
import UserPositionSelect from "components/UserPositionSelect"
import ProjectRoles from "components/ProjectRoles"
import { IProfile } from "types/IProfile"
import { emailValidationPattern } from "utils/formHelpers"
import { ModalProps } from "containers/ModalContainer"

const AddProjectMemberModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const { handleSubmit, register, errors, setError, control, setValue } = useForm()
	const { modalPayload } = React.useContext(GlobalContext)
	const projectId = modalPayload?.projectId
	// const { projectId } = modalPayload
	const { companies } = useCompanies(projectId)
	const { addProjectMember, addProjectMemberInfo } = useProjectMembers(projectId)

	const onSave = async (updatedData: any) => {
		console.log(updatedData)
		if (updatedData.companyId) {
			updatedData.companyId = getKeysFromArray(updatedData.companyId)[0]._id
		}

		if (updatedData.permissions) {
			updatedData.permissions = getKeysFromArray(updatedData.permissions)[0]
		}

		if (updatedData.position) {
			updatedData.position = getKeysFromArray(updatedData.position)
		}

		// Check if added user already exists
		const projectMembers: IProfile[] | undefined = queryCache.getQueryData(["projectMembers", { projectId, query: null }])
		console.log(projectMembers)
		const members = projectMembers?.filter(member => member.email === updatedData.email)
		if (members && members.length > 0) {
			// Set error, User is already a member
			setError("email", {
				type: "manual",
				message: "Användaren är redan projektmedlem",
			})
			return
		}

		const response = await addProjectMember({
			projectId: projectId || "",
			companyId: updatedData.companyId,
			updatedData,
		})
		if (!response) {
			return
		}
		closeModal()
	}

	return (
		<Modal onClose={closeModal} closeable isOpen={isOpen} animate autoFocus unstable_ModalBackdropScroll size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>Ny deltagare</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit(onSave)}>
					<FormInput
						label="E-post"
						fieldError={errors.email}
						placeholder="namn@företag.se"
						id="email"
						name="email"
						inputRef={register({
							required: "Du måste skriva en e-post adress.",
							pattern: emailValidationPattern,
						})}
					/>

					<Controller
						render={props => (
							<FormControl label="Behörighet" error={errors.permissions && errors.permissions.message}>
								<ProjectRoles
									// label="Behörighet"
									error={errors.permissions}
									value={props.value}
									clearable={false}
									searchable={false}
									size="default"
									placeholder="Välj ett alternativ"
									onChange={(params: OnChangeParams) =>
										setValue("permissions", params.value, {
											shouldValidate: true,
										})
									}
								/>
							</FormControl>
						)}
						control={control}
						defaultValue={[]}
						rules={{ required: "Du måste välja ett alternativ" }}
						name="permissions"
					/>
					<Controller
						render={props => (
							<FormSelect
								label="Företag"
								options={companies}
								fieldError={errors.companyId}
								value={props.value}
								clearable={false}
								placeholder="Välj ett alternativ"
								onChange={params => setValue("companyId", params.value, { shouldValidate: true })}
								valueKey="_id"
								labelKey="name"
							/>
						)}
						control={control}
						rules={{ required: "Du måste välja ett alternativ" }}
						defaultValue={[]}
						name="companyId"
					/>
					<Controller
						render={props => (
							<FormControl label="Roll" error={errors.position && errors.position.message}>
								<UserPositionSelect
									value={props.value}
									onChange={(params: OnChangeParams) => {
										console.log("Logging params from lasfojhodsf", params)
										setValue("position", params.value, { shouldValidate: true })
									}}
								/>
								{/* <UserPositionSelect
									placeholder="Välj roll"
									size="default"
									// position={props.value}
									// maxDropdownHeight="300px"
									// value={props.value}
									// defaultValue={[]}
									onUpdate={(params: OnChangeParams) => setValue("position", params.value, { shouldValidate: true })}
								/> */}
							</FormControl>
						)}
						control={control}
						rules={{
							required: true,
							validate: val => val.length > 0 || "Du måste välja ett alternativ",
						}}
						defaultValue={[]}
						name="position"
					/>
				</form>
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Avbryt
				</ModalButton>

				<ModalButton
					type="submit"
					disabled={addProjectMemberInfo.status === "loading"}
					onClick={handleSubmit(onSave)}
					isLoading={addProjectMemberInfo.status === "loading"}
					endEnhancer={() => <Plus size={18} />}>
					Lägg till
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default AddProjectMemberModal
