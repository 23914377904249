import * as React from "react"
import { useStyletron } from "baseui"
import { Button, SIZE, SHAPE, KIND } from "baseui/button"
import { StatefulMenu } from "baseui/menu"
import { StatefulPopover } from "baseui/popover"
import { Block } from "baseui/block"
import { ParagraphXSmall, Paragraph3 } from "baseui/typography"
import { ListItem } from "baseui/list"
import { Paperclip, MoreVertical, Image } from "react-feather"
import { useAbility } from "@casl/react"

import { dateTimeFormatter } from "utils/format"
import { getClosest } from "utils/misc"
import useTaskStatus from "hooks/useTaskStatus"

import DragHandle from "components/DragHandle"
import Can, { AbilityContext } from "components/Can"
import Editable from "components/Editable"

import TaskFiles from "./TaskFiles"
import TaskNote from "./TaskNote"
import { setType } from "config/abilities"
import { ITask } from "types/ITask"
import { ICheck } from "types/ICheck"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { EntityStatus } from "types/enums"

interface Props {
	task: ITask
	check: ICheck
	checkWithType: ICheck
	checkIsFreezed: boolean
	onUpdate: Function
	onRemove: Function
	onApprove: Function
	dragHandleProps?: DraggableProvidedDragHandleProps
}

const Task: React.FC<Props> = ({ task, check, checkWithType, checkIsFreezed, onUpdate, onRemove, onApprove, dragHandleProps }) => {
	const [css, theme] = useStyletron()

	const ability = useAbility(AbilityContext)
	const [isHovered, setIsHover] = React.useState(false)
	const [isOpen, setIsOpen] = React.useState(false)
	const { taskIsFreezed, taskIsApprovable } = useTaskStatus(task)

	const updateTask = (keyValue: string, val: string) => {
		const updatedData = { [keyValue]: val }
		onUpdate(task._id, updatedData)
	}

	const removeTask = () => {
		onRemove(task._id)
	}

	// const checkIfApprovable = () => {
	// 	const isApprovable = false

	// 	if (task.type === "files") {
	// 		isApprovable = task.documents.filter(doc => doc.status !== EntityStatus.approved).length === 0
	// 	} else {
	// 	}

	// 	return isApprovable
	// }

	// let contentApproved = false

	// if (task.type === "files") {
	// 	contentApproved = task.documents.filter(doc => doc.status !== EntityStatus.approved).length === 0
	// } else if (task.type === "notes") {
	// 	contentApproved = task.description && task.description !== ""
	// }

	const isApproved = task.status === EntityStatus.approved
	const isFreezed = taskIsFreezed || checkIsFreezed

	const content =
		task.type === "files" ? (
			<TaskFiles task={task} taskIsFreezed={taskIsFreezed} updateTask={updateTask} />
		) : (
			<TaskNote task={task} taskIsFreezed={taskIsFreezed} updateTask={updateTask} />
		)

	return (
		<Block marginBottom="scale200">
			<div
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
				onClick={(e: React.BaseSyntheticEvent) => {
					const ignoreClosest = getClosest(e.target, "[ignoreclick]")
					const ignore = e.target.hasAttribute("ignoreclick")

					if (ignore || ignoreClosest) {
						return
					}
					setIsOpen(val => !val)
				}}
				className={css({ cursor: "pointer" })}>
				<ListItem
					overrides={{
						Root: {
							style: ({ $theme }) => ({
								backgroundColor: task.status === EntityStatus.approved ? $theme.colors.positive50 : $theme.colors.mono200,
								transitionTimingFunction: theme.animation.linearCurve,
								transitionDuration: theme.animation.timing200,
								transitionProperty: "all",

								":hover": {
									backgroundColor: task.status === EntityStatus.approved ? $theme.colors.positive100 : $theme.colors.mono300,
									transitionTimingFunction: theme.animation.linearCurve,
									transitionDuration: theme.animation.timing200,
									transitionProperty: "all",
								},
							}),
						},
						Content: {
							style: ({ $theme }) => ({
								borderBottomStyle: "none",
								marginLeft: $theme.sizing.scale300,
								// marginLeft: $theme.sizing.scale700,
							}),
						},
					}}
					endEnhancer={() => (
						<div
							className={css({
								marginRight: theme.sizing.scale200,
								opacity: isHovered ? "1" : "0.1",
								display: "flex",
								alignItems: "center",
								transitionTimingFunction: theme.animation.linearCurve,
								transitionDuration: theme.animation.timing200,
								transitionProperty: "all",
							})}>
							{task.type === "files" && task.documents.length > 0 && (
								// <ListItemLabel>
								<div>
									<Paragraph3 color="mono600" display="flex" alignItems="center">
										<span
											className={css({
												display: "inline-block",
												marginRight: theme.sizing.scale200,
											})}>
											<Paperclip size="12" />
										</span>
										{task.documents.length}
									</Paragraph3>
								</div>
								// </ListItemLabel>
							)}
							{task.type === "notes" && task.attachments.length > 0 && (
								// <ListItemLabel>
								<div>
									<Paragraph3 color="mono600" display="flex" alignItems="center">
										<span
											className={css({
												display: "inline-block",
												marginRight: theme.sizing.scale200,
											})}>
											<Image size="12" />
										</span>
										{task.attachments.length}
									</Paragraph3>
								</div>
								// </ListItemLabel>
							)}
							{/* <Button
								size={SIZE.mini}
								kind={KIND.tertiary}
								shape={SHAPE.round}
								onClick={e => setIsOpen(val => !val)}
								className={css({
									marginLeft: theme.sizing.scale600,
									marginRight: theme.sizing.scale200,
								})}>
								{isOpen ? <Minus size="16" /> : <Plus size="16" />}
							</Button> */}
							<Can do="delete" on={task} type="Task">
								{() =>
									!checkIsFreezed && (
										<StatefulPopover
											content={() => {
												const items = []
												const taskWithType = setType("Task", task)

												if (ability.can("approve", taskWithType)) {
													items.push({
														label: taskIsApprovable ? (
															<span onClick={e => (checkIsFreezed ? null : onApprove(task))}>
																{isApproved ? "Godkänn ej" : "Godkänn"}
															</span>
														) : (
															<span className={css({ opacity: "0.5" })}>{isApproved ? "Godkänn ej" : "Godkänn"}</span>
														),
													})
												}

												// if (ability.can("delete", task)) {
												items.push({
													label: isApproved ? (
														<span className={css({ opacity: "0.5" })}>Ta bort</span>
													) : (
														<span onClick={removeTask}>Ta bort</span>
													),
												})
												// }

												return (
													<Block {...{ ignoreclick: "" }}>
														<StatefulMenu items={items} />
													</Block>
												)
											}}>
											<Button
												size={SIZE.mini}
												kind={KIND.tertiary}
												shape={SHAPE.round}
												overrides={{
													BaseButton: {
														style: () => ({
															marginLeft: theme.sizing.scale600,
														}),
													},
												}}
												{...{ ignoreclick: "" }}>
												<MoreVertical size="16" />
											</Button>
										</StatefulPopover>
									)
								}
							</Can>
						</div>
					)}
					sublist>
					{/* <ListeItemLabel> */}
					<div>
						<span className={css({ display: "flex", alignItems: "center" })}>
							<Can do="update" on={check} type="Check">
								{() => (
									<Button
										{...dragHandleProps}
										size={SIZE.compact}
										kind={KIND.tertiary}
										shape={SHAPE.round}
										overrides={{
											BaseButton: {
												style: () => ({
													marginRight: theme.sizing.scale200,
													opacity: isHovered || isOpen ? "1" : "0.1",
													cursor: "grab",
													transitionTimingFunction: theme.animation.linearCurve,
													transitionDuration: theme.animation.timing200,
													transitionProperty: "all",
												}),
											},
										}}
										{...{ ignoreclick: "" }}>
										<DragHandle size="18" />
									</Button>
								)}
							</Can>
							<Can do="update" on={task} type="Task" passThrough>
								{(allowed: boolean) =>
									!allowed || isFreezed ? (
										task.title
									) : (
										<Editable
											value={task.title}
											editOnViewClick={false}
											onUpdate={(val: string) => updateTask("title", val)}
											// showButtonsOnHover
											showButton={true}
										/>
									)
								}
							</Can>
						</span>
					</div>
					{/* </ListeItemLabel> */}
				</ListItem>
			</div>
			<Block
				backgroundColor={theme.colors.mono200}
				padding={theme.sizing.scale600}
				display={isOpen ? "block" : "none"}
				className={css({
					borderBottom: `3px solid ${theme.colors.mono200}`,
					borderLeft: `3px solid ${theme.colors.mono200}`,
					borderRight: `3px solid ${theme.colors.mono200}`,
				})}>
				<div>{content}</div>
				<ParagraphXSmall color="mono600">
					Skapad {dateTimeFormatter(task.createdAt)} av {task.createdBy.fullName}
				</ParagraphXSmall>
			</Block>
		</Block>
	)
}

export default Task
