import * as React from "react"
import { useNavigate } from "react-router-dom"
import { H2 } from "baseui/typography"
import { useStyletron } from "baseui"

import useProject from "hooks/useProject"

import { ContentBlock } from "components/ContentBlock"
import SettingsForm from "pages/Settings/Form"

import PersonalForm from "./PersonalForm"
import { IProject } from "types/IProject"

const AddProject = () => {
	const navigate = useNavigate()
	const [css, theme] = useStyletron()
	const { createProject, createProjectInfo } = useProject()
	const [currentStep, setCurrentStep] = React.useState(1)
	const [newProject, setNewProject] = React.useState<IProject | null>(null)

	const submitProject = async (values: IProject) => {
		setNewProject(values)
		console.log("asdföljasklf")
		setCurrentStep(2)
		// const createdProject = await createProject(values)
		// // TODO: Error handling
		// if (createdProject) {
		// 	navigate(`../p/${createdProject._id}`)
		// }
	}
	// const onCreate = async values => {
	// 	const createdProject = await createProject(values)

	// 	// TODO: Error handling
	// 	if (createdProject) {
	// 		navigate(`../p/${createdProject._id}`)
	// 	}
	// }

	const onCreate = async (values: IProject) => {
		const data = Object.assign(newProject, values)
		const createdProject = await createProject({ updatedData: data })

		if (createdProject) {
			navigate(`../p/${createdProject._id}`)
		}
	}

	return (
		<div
			className={css({
				transitionTimingFunction: theme.animation.linearCurve,
				transitionDuration: theme.animation.timing300,
				transitionProperty: "all",
				opacity: createProjectInfo.status === "loading" ? ".5" : "1",
			})}>
			<H2
				className={css({
					textAlign: "center",
				})}>
				{currentStep === 1 ? "Skapa projekt" : "Personliga uppgifter"}
			</H2>
			{currentStep === 1 && (
				<ContentBlock maxWidth="700px" margin="0 auto">
					<SettingsForm
						createProject={submitProject}
						// createProject={onCreate}
						currentProject={newProject}
						isNewProject={true}
						createProjectInfo={createProjectInfo}
					/>
				</ContentBlock>
			)}
			{currentStep === 2 && (
				<ContentBlock maxWidth="500px" margin="0 auto">
					<PersonalForm createProject={onCreate} createProjectInfo={createProjectInfo} goBack={() => setCurrentStep(1)} />
				</ContentBlock>
			)}
		</div>
	)
}

export default AddProject
