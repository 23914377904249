import * as React from "react"
import { useAsyncDebounce, UseGlobalFiltersInstanceProps } from "react-table"
import { Search } from "react-feather"
import { Input, SIZE } from "baseui/input"

export interface Props<T extends object> extends UseGlobalFiltersInstanceProps<T> {
	globalFilter: any
}

const GlobalFilter = <T extends object>({ globalFilter, setGlobalFilter }: Props<T>) => {
	// const count = preGlobalFilteredRows.length
	const [value, setValue] = React.useState(globalFilter)
	const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined)
	}, 200)

	return (
		<Input
			type="text"
			startEnhancer={<Search size="16px" />}
			clearable
			size={SIZE.compact}
			value={value || ""}
			onChange={(e: any) => {
				setValue(e.target.value)
				onChange(e.target.value)
			}}
			// placeholder={`${count} rader...`}
			placeholder="Sök..."
			// overrides={{
			// 	Root: {
			// 		style: ({ $theme }) => {
			// 			return {
			// 				width: "300px",
			// 			}
			// 		},
			// 	},
			// }}
		/>
	)
}

export default GlobalFilter
