import * as React from "react"
import { Link } from "react-router-dom"

import { dateTimeFormatter } from "utils/format"

import DataTable from "components/DataTable"
import Status from "components/Status"
import { Cell, Column } from "react-table"
import { ICheck } from "types/ICheck"

interface Props {
	rows: any[]
}

const CheckList: React.FC<Props> = ({ rows }) => {
	const cols: Column<any>[] = React.useMemo(
		() => [
			{
				Header: "Rubrik",
				accessor: "title",
				primary: true,
				Cell: ({ row, value }: Cell<ICheck>) => <Link to={`check/${row.original._id}`}>{value}</Link>,
			},
			{
				Header: "Ansvarig",
				accessor: "createdBy.fullName",
				Cell: ({ row, value }: Cell<ICheck>) => <Link to={`../../profile/${row.original.createdBy._id}`}>{value}</Link>,
			},
			{
				Header: "Skapad",
				accessor: "createdAt",
				Cell: ({ value }: Cell<ICheck>) => dateTimeFormatter(value),
			},
			// {
			// 	Header: "Kategori",
			// 	accessor: "category",
			// 	Cell: ({ row }) => {
			// 		const category = projectCategories.filter(
			// 			cat => cat._id === row.original.category,
			// 		)[0]

			// 		return <div>{category && category.title}</div>
			// 	},
			// },
			{
				Header: "Status",
				accessor: (row: ICheck) => row.status,
				// accessor: row =>
				// 	row.status ? statusColor[row.status].label : statusColor["empty"].label,
				Cell: ({ value }: Cell<ICheck>) => {
					console.log("Check value", value)
					return <Status status={value} />
				},
			},
		],
		[],
		// [projectCategories],
	)

	if (!rows || !rows.length) {
		return <div>Det finns inga redovisningar...</div>
	}

	// if (!projectCategories || !projectCategories.length) {
	// 	return (
	// 		<div className="empty mt-2">
	// 			<p className="empty-title h5">Inga kategorier</p>
	// 		</div>
	// 	)
	// }

	// const onSearch = filters => {
	// 	console.log(filters)
	// }

	// console.log(rows)

	return (
		<div>
			{/* {projectCategories.map((category) => {
				if (!rows) return <h4>{category.title}</h4>;

				const filteredRows = rows.filter(
					r => r.category === category._id
				);

				if (!filteredRows.length) {
					return null;
				}

				return (
					<div className="block px-0" key={category._id}>
						<h4>{category.title}</h4>

						<DataTable
							columns={cols}
							data={filteredRows}
							onSearch={onSearch}
							sortable
						/>
					</div>
				);
			})} */}
			<div>
				<DataTable
					name="checklist"
					columns={cols}
					data={rows}
					// setActive={true}
					// onSearch={onSearch}
					// sortable
				/>
			</div>
		</div>
	)
}

export default CheckList
