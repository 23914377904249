import * as React from "react"
import { Button, KIND, SHAPE } from "baseui/button"

import GlobalContext from "context/GlobalContext"
import { ModalEnum } from "containers/ModalContainer"

interface Props {
	projectId: string
	companyId: string
}

const AddCompanyMember: React.FC<Props> = ({ projectId, companyId }) => {
	const { openModal } = React.useContext(GlobalContext)
	return (
		<div>
			<Button kind={KIND.primary} shape={SHAPE.pill} onClick={() => openModal(ModalEnum.AddCompanyMember, { projectId, companyId })}>
				Lägg till deltagare
			</Button>
		</div>
	)
}

export default AddCompanyMember
