import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { Button, KIND, SIZE as BUTTONSIZE } from "baseui/button"
import { Checkbox, STYLE_TYPE } from "baseui/checkbox"
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography"
import { Block } from "baseui/block"

import { dateTimeFormatter } from "utils/format"
import { reverseArray } from "utils/misc"
import GlobalContext from "context/GlobalContext"
import useControl from "hooks/useControl"

import FormTextarea from "components/Form/FormTextarea"
import ProfileTile from "components/ProfileTile"
import { useStyletron } from "baseui"
import { ModalProps, ModalEnum } from "containers/ModalContainer"
import { IDocument } from "types/IDocument"
import { EntityStatus } from "types/enums"
import { IControl } from "types/IControl"

interface FormValues {
	isApproved: boolean
	description: string
	attachment: any
}

const ControlApproval: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const [css, theme] = useStyletron()
	const { handleSubmit, register, errors, setValue, control } = useForm<FormValues>()
	const { approveControl, approveControlInfo } = useControl()
	const { modalPayload, openModal } = React.useContext(GlobalContext)
	const currentControl: IControl | undefined = modalPayload.currentControl as any

	const [filename, setFilename] = React.useState<string | null>(null)
	const [showForm, setShowForm] = React.useState<boolean>(false)

	const readFile = (e: React.BaseSyntheticEvent) => {
		console.log(e.target)
		var file = e.target.files[0]
		if (file) {
			setFilename(file.name)
			console.log(file.name)
		}
	}

	const onApproval = async (values: FormValues) => {
		const status = values.isApproved ? EntityStatus.approved : EntityStatus.disapproved
		// Return if same status
		if (currentControl?.status === status) {
			return
		}

		const formData = new FormData()
		if (values.attachment && values.attachment[0]) {
			formData.append("attachment", values.attachment[0], values.attachment[0].name)
		}
		if (values.description) {
			formData.append("comment", values.description)
		}
		formData.append("status", values.isApproved ? EntityStatus.approved : EntityStatus.disapproved)

		console.log(status)

		const response = await approveControl({
			controlId: currentControl?._id || "",
			updatedData: formData,
		})
		if (!response) {
			return
		}
		closeModal()
	}

	const viewDocument = (document: IDocument) => {
		const currentDocument = Object.assign(document, { project: currentControl?.project })
		openModal(ModalEnum.ViewDocument, { currentDocument })
	}

	return (
		<Modal
			onClose={closeModal}
			closeable
			isOpen={isOpen}
			animate
			autoFocus
			unstable_ModalBackdropScroll
			size={SIZE.auto}
			role={ROLE.dialog}
			overrides={{
				Root: {
					style: () => ({
						zIndex: 100,
					}),
				},
				Dialog: {
					style: () => ({
						width: "100%",
						maxWidth: "600px",
					}),
				},
			}}>
			<ModalHeader>Status</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit(onApproval)} encType="multipart/form-data">
					<Block
						className={css({
							marginBottom: theme.sizing.scale400,
						})}>
						<Controller
							render={props => {
								setShowForm(!props.value)
								return (
									<Checkbox
										checkmarkType={STYLE_TYPE.toggle_round}
										checked={props.value}
										name="isApproved"
										onChange={(e: React.BaseSyntheticEvent) => setValue("isApproved", e.target.checked)}>
										Godkänn
									</Checkbox>
								)
							}}
							control={control}
							defaultValue={currentControl?.status === EntityStatus.approved ? true : false}
							name="isApproved"
						/>
					</Block>
					{showForm && (
						<React.Fragment>
							<Controller
								render={props => (
									<FormTextarea
										{...props}
										fieldError={errors.description}
										label="Kommentar"
										placeholder="Skriv din kommentar här..."
										rows={3}
										id="description"
										name="description"
										onChange={(e: React.BaseSyntheticEvent) => setValue("description", e.target.value, { shouldValidate: true })}
									/>
								)}
								control={control}
								name="description"
								// rules={{ required: "Fältet är obligatoriskt" }}
								defaultValue=""
							/>
							<label htmlFor="attachment" className={css({ display: "flex", alignItems: "center" })}>
								<span
									className={css({
										backgroundColor: "black",
										padding: "8px 12px",
										color: "white",
										cursor: "pointer",
									})}>
									Ladda upp en fil
								</span>
								<input
									onChange={event => {
										readFile(event)
									}}
									onClick={(event: React.BaseSyntheticEvent) => {
										event.target.value = null
										setFilename(null)
									}}
									id="attachment"
									name="attachment"
									type="file"
									ref={register}
									hidden
								/>
								<ParagraphXSmall marginLeft="scale200">{filename}</ParagraphXSmall>
							</label>
						</React.Fragment>
					)}
				</form>
				<Block className={css({ textAlign: "right" })}>
					<ModalButton kind={KIND.tertiary} onClick={closeModal}>
						Avbryt
					</ModalButton>

					<ModalButton
						type="submit"
						onClick={handleSubmit(onApproval)}
						disabled={approveControlInfo.status === "loading"}
						isLoading={approveControlInfo.status === "loading"}
						// endEnhancer={() => <Plus size={24} />}
					>
						Uppdatera status
					</ModalButton>
				</Block>
			</ModalBody>
			<ModalFooter>
				<ParagraphSmall
					className={css({
						textAlign: "left",
					})}>
					Aktivitet
				</ParagraphSmall>

				{!currentControl?.approvalHistory.length && <div>Ingen aktivitet</div>}
				{reverseArray(currentControl?.approvalHistory).map(approval => (
					<Block>
						<Block display="flex" alignItems="center" justifyContent="space-between">
							<Block display="flex">
								<ProfileTile avatarSize="scale400" profile={approval.approvedBy} projectId={currentControl?.project} />
								<ParagraphSmall marginLeft="scale200">
									{approval.status === EntityStatus.approved ? "godkände" : "underkände"} kontrollpunkten
								</ParagraphSmall>
							</Block>
							<div>
								<ParagraphXSmall color="mono600">{dateTimeFormatter(approval.approvedAt)}</ParagraphXSmall>
							</div>
						</Block>
						{(approval.comment || approval.attachment) && (
							<Block
								padding="scale400"
								backgroundColor="mono200"
								marginBottom="scale400"
								className={css({
									textAlign: "left",
								})}>
								<div>{approval.comment}</div>
								{approval.attachment && (
									<Block marginTop="scale200">
										Bilaga:{" "}
										<Button size={BUTTONSIZE.mini} kind={KIND.tertiary} onClick={e => viewDocument(approval.attachment)}>
											<span
												className={css({
													wordBreak: "break-word",
													textAlign: "left",
												})}>
												{approval.attachment.name}
											</span>
										</Button>
									</Block>
								)}
							</Block>
						)}
					</Block>
				))}
			</ModalFooter>
		</Modal>
	)
}

export default ControlApproval
