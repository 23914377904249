import * as React from "react"
import { Select, SIZE, SelectProps } from "baseui/select"
import { ControlPermissionsArray } from "utils/enums"

export interface Props extends SelectProps {
	currentRole: string
	placeholder?: string
}

const ControlRoles: React.FC<Props> = ({ currentRole, placeholder, size = SIZE.mini, ...props }) => {
	const currentOptions = ControlPermissionsArray
	const role = currentOptions.filter(p => p.key === currentRole)

	return (
		<Select
			valueKey="key"
			labelKey="label"
			placeholder={role ? "" : placeholder}
			clearable={false}
			size={size}
			options={currentOptions}
			value={role || []}
			{...props}
		/>
	)
}

export default ControlRoles

// import * as React from "react";

// const ControlRoles = ({currentRole, onChange}) => {
//     if(currentRole === 'owner') {
//         return <span>Ägare</span>
//     }

//     return (
//         <select value={currentRole} className="form-select" onChange={onChange}>
//             <option value="limited">Begränsad</option>
//             <option value="normal">Medlem</option>
//             <option value="advanced">Ansvarig</option>
//         </select>
//     )
// }

// export default ControlRoles;
