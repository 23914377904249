import * as React from "react"
import { useAbility } from "@casl/react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { useSelector } from "react-unistore"

import { setType } from "config/abilities"
import GlobalContext from "context/GlobalContext"
import { statusColor } from "utils/enums"
import { IProjectPlanControl, IProjectPlanGroup } from "types/IProjectPlan"

import { AbilityContext } from "components/Can"
import ControlBody from "./ControlBody"
import { IState } from "store"
import { EntityStatus } from "types/enums"
import { ModalEnum } from "containers/ModalContainer"

export interface Props {
	canEdit: boolean
	control: IProjectPlanControl
	group: IProjectPlanGroup

	removeControl: Function
	updateControl: Function
	dragHandleProps?: DraggableProvidedDragHandleProps
	isDragging?: boolean
}

const Control: React.FC<Props> = ({
	canEdit,
	control,
	group,

	removeControl,
	updateControl,
	dragHandleProps,
	isDragging,
}) => {
	const { openModal } = React.useContext(GlobalContext)
	const index = `${group.index}.${control.index}`
	const ability = useAbility(AbilityContext)
	const currentProject = useSelector((state: IState) => state.currentProject)

	if (!control) return null

	const setPermissions = () => {
		openModal(ModalEnum.ControlPermissions, {
			controlId: control._id,
			projectId: control.project,
			control: control,
		})
		// openModal(ModalEnum.ControlPermissions, { controlId: control._id, projectId: control.project })
	}

	const _removeControl = async (controlId: string) => {
		if (!window.confirm("Är du säker på att du vill ta bort kontrollpunkten?")) return

		removeControl({ projectId: control.project, group, controlId })
	}

	const _onControlUpdate = (obj: any) => {
		updateControl({ controlId: control._id, updatedData: obj })
	}

	const controlWithType = setType("Control", control)
	// console.log("Can update control", ability.can("update", controlWithType))
	const canUpdateControl =
		ability.can("update", controlWithType) && control.status !== EntityStatus.approved && control.status !== EntityStatus.pending

	if (!control) return null

	return (
		<ControlBody
			index={index}
			currentProject={currentProject}
			canEdit={canEdit}
			canUpdateControl={canUpdateControl}
			setPermissions={setPermissions}
			control={control}
			controlWithType={controlWithType}
			onRemove={_removeControl}
			onUpdate={_onControlUpdate}
			statusColor={statusColor[control.status === "" ? EntityStatus.empty : control.status]}
			dragHandleProps={dragHandleProps}
			isDragging={isDragging}
		/>
	)
}

export default Control
