import * as React from "react"
import { Input } from "baseui/input"
import { useForm } from "react-hook-form"
import { useStyletron } from "baseui"
import { Paragraph2, HeadingSmall } from "baseui/typography"
import { SIZE } from "baseui/button"

import { ContentBlock, ContentBlockSubmitButton } from "components/ContentBlock"
import { MutationResult } from "react-query"

interface Props {
	onDelete: Function
	deleteProjectInfo: MutationResult<any>
}

const Advanced: React.FC<Props> = ({ onDelete, deleteProjectInfo }) => {
	const { handleSubmit, register, errors } = useForm()
	const [, theme] = useStyletron()

	const onSubmit = () => {
		if (window.confirm("Är du säker på att du vill ta bort projektet? Detta går inte att ångra.")) {
			onDelete()
		}
	}

	const isLoading = deleteProjectInfo.status === "loading" ? true : false

	return (
		<React.Fragment>
			<ContentBlock marginBottom="scale600">
				<HeadingSmall marginTop="0">Betalning</HeadingSmall>
				<Paragraph2>*Betalningsuppgifter*</Paragraph2>
			</ContentBlock>

			<ContentBlock>
				<HeadingSmall marginTop="0">Ta bort projekt</HeadingSmall>
				<Paragraph2>Skriv "RADERA" för att kunna ta bort projektet.</Paragraph2>
				{/* Detta går inte att ångra. */}
				<form onSubmit={handleSubmit(onSubmit)}>
					<Input
						inputRef={register({
							required: 'Skriv "RADERA" för att ta bort projektet',
							pattern: {
								value: /RADERA/,
								message: 'Skriv "RADERA" för att ta bort projektet',
							},
						})}
						error={errors.confirmDelete}
						id="confirmDelete"
						name="confirmDelete"
						overrides={{
							Root: {
								style: {
									// width: ["100%", "50%"],
									marginBottom: theme.sizing.scale400,
								},
							},
						}}
					/>

					<ContentBlockSubmitButton disabled={isLoading} isLoading={isLoading} size={SIZE.compact}>
						Radera Projekt
					</ContentBlockSubmitButton>
				</form>
			</ContentBlock>
		</React.Fragment>
	)
}

export default Advanced
