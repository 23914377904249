import { IPublicClientApplication, Logger, stubbedPublicClientApplication } from '@azure/msal-browser';
import * as React from 'react';

import { AccountIdentifiers } from './types/AccountIdentifiers';
import { InteractionStatus } from './utils/Constants';

export interface IMsalContext {
    instance: IPublicClientApplication;
    inProgress: InteractionStatus;
    accounts: AccountIdentifiers[];
    logger: Logger;

    isAuthenticated?: any;
    user?: any;
    token?: any;
    loading?: any;
    loginError?: any;
    login?: any;
    logout?: any;
    signup?: any;
    resetPassword?: any;
    getAccessToken?: any;
}

/*
 * Stubbed context implementation
 * Only used when there is no provider, which is an unsupported scenario
 */
const defaultMsalContext: IMsalContext = {
    instance: stubbedPublicClientApplication,
    inProgress: InteractionStatus.None,
    accounts: [],
    logger: new Logger({})
};

export const MsalContext = React.createContext(defaultMsalContext)
export const MsalConsumer = MsalContext.Consumer;