import React from "react"
import { RadioGroup, Radio, ALIGN } from "baseui/radio"
import { StatefulPopover } from "baseui/popover"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { ParagraphSmall } from "baseui/typography"

import { SortingOption } from "types/shared"

import StyledLink from "components/StyledLink"
import DropdownContent from "components/DropdownContent"

export interface Props {
	sortingOptions: SortingOption[] | undefined
	setSortBy: any
	current: any
}

const SortingOptions = ({ sortingOptions, setSortBy, current }: Props) => {
	// Set current default option
	let currentOption = ""
	if (current && current.length) {
		currentOption = `${current[0].value}:${current[0].desc}`
	}

	const [value, setValue] = React.useState(currentOption)

	React.useEffect(() => {
		if (!value) return
		const option = value.split(":")
		// Overwriting sortBy, only 1 option is allowed
		setSortBy([{ id: option[0], desc: option[1] }])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const clearSort = () => {
		setValue("")
		setSortBy([])
	}

	if (!sortingOptions || !sortingOptions.length || !setSortBy) return null

	return (
		<StatefulPopover
			content={
				<DropdownContent>
					<RadioGroup value={value} onChange={e => setValue(e.target.value)} name="sortingOptions" align={ALIGN.vertical}>
						{sortingOptions.map((option: any, i: number) => (
							<Radio key={`${option.value}-${i}`} value={`${option.value}:${option.desc}`}>
								{option.label}
							</Radio>
						))}
					</RadioGroup>
					{value && (
						<StyledLink onClick={clearSort}>
							<ParagraphSmall marginBottom="0">Standard sortering</ParagraphSmall>
						</StyledLink>
					)}
				</DropdownContent>
			}>
			<Button size={SIZE.mini} shape={SHAPE.pill} kind={current.length ? KIND.primary : KIND.secondary}>
				Sortera
			</Button>
		</StatefulPopover>
	)
}

export default SortingOptions
