import * as React from "react"
import EdiText, { EdiTextType } from "react-editext"
import { Check, X, Edit2 } from "react-feather"
import { useStyletron } from "baseui"
import { Button, SIZE, KIND, SHAPE } from "baseui/button"
import { Block } from "baseui/block"

import ConditionalWrapper from "components/ConditionalWrapper"

export interface Props {
	value: string
	component?: JSX.Element
	type?: EdiTextType
	onUpdate: Function
	showButton?: boolean
	canEdit?: boolean
	ignoreclick?: string
	editOnViewClick?: boolean
}

const Editable: React.FC<Props> = ({
	value,
	component,
	type = "text",
	onUpdate,
	showButton = true,
	canEdit = true,
	ignoreclick,
	editOnViewClick = false,
	...props
}) => {
	const [css, theme] = useStyletron()
	const [currentValue, setCurrentValue] = React.useState(value)
	const [isEditing, setIsEditing] = React.useState(false)

	const buttonStyle = {
		borderRadius: "50%",
		fontSize: "14px",
		margin: "0px",
		webkitAppearance: "none",
		backgroundColor: theme.colors.primary100,
		padding: `${theme.sizing.scale300} !important`,
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transitionTimingFunction: theme.animation.linearCurve,
		transitionDuration: theme.animation.timing200,
		transitionProperty: "opacity",
		border: "none",
		":hover": {
			backgroundColor: theme.colors.primary200,
			transitionTimingFunction: theme.animation.linearCurve,
			transitionDuration: theme.animation.timing300,
			transitionProperty: "background",
		},
	}

	React.useEffect(() => {
		setCurrentValue(value)
	}, [value])

	const onCancel = () => {
		setIsEditing(false)
	}

	const onSave = (val: string) => {
		setCurrentValue(val)
		setIsEditing(false)
		onUpdate(val)
	}

	if (!canEdit) {
		return <span>{value}</span>
	}

	return (
		<Block display="flex" alignItems="center">
			<ConditionalWrapper condition={isEditing} wrapper={(children: React.ReactElement) => <span {...{ ignoreclick: "" }}>{children}</span>}>
				{component && !isEditing ? (
					component
				) : (
					<EdiText
						type={type}
						value={currentValue}
						viewProps={{}}
						onCancel={onCancel}
						onSave={onSave}
						cancelButtonClassName={css({
							...buttonStyle,
							backgroundColor: "#FFEFED",
							color: "#AB1300",
							":hover": {
								backgroundColor: "#FED7D2",
							},
						})}
						cancelButtonContent={<X size="16" />}
						saveButtonClassName={css({
							...buttonStyle,
							margin: `0px ${theme.sizing.scale100} 0px 0px`,
							backgroundColor: "#E6F2ED",
							color: "#09864A",
							":hover": {
								backgroundColor: "#ADDEC9",
							},
						})}
						saveButtonContent={<Check size="16" />}
						editButtonClassName={css({
							// ...buttonStyle,
							display: "none",
						})}
						inputProps={{
							style: {
								outline: "none",
								minWidth: "auto",
								padding: "2px 4px",
							},
						}}
						editButtonContent={<Edit2 size="16" />}
						hideIcons={true}
						// showButtonsOnHover
						// className="editable"
						submitOnEnter
						editOnViewClick={true}
						editing={isEditing}
						{...props}
					/>
				)}
			</ConditionalWrapper>
			{!isEditing && (
				<Button
					size={SIZE.mini}
					kind={KIND.secondary}
					shape={SHAPE.round}
					onClick={e => setIsEditing(true)}
					overrides={{
						BaseButton: {
							style: () => ({
								opacity: showButton ? "1" : "0",
								transitionTimingFunction: theme.animation.linearCurve,
								transitionDuration: theme.animation.timing300,
								transitionProperty: "all",
							}),
						},
					}}
					{...{ ignoreclick: "" }}>
					<Edit2 size="16" />
				</Button>
			)}
		</Block>
	)
}

export default Editable
