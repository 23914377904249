import { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { Block } from "baseui/block"
import { customStyled } from "config/theme"

enum LabelString {
	section = "+ Lägg till sektion",
	group = "+ Lägg till grupp",
	control = "+ Lägg till rad",
}

export interface Props {
	onSubmit: Function
	type: keyof typeof LabelString
	wide?: boolean
	customStyle: any
}

const AddItemForm: React.FC<Props> = ({ onSubmit, type, wide = true, customStyle }) => {
	const { control, handleSubmit, setValue } = useForm()
	const [isAdding, setIsAdding] = useState(false)

	const _onSubmit = (values: any, e: any) => {
		if (!values.title) return

		console.log("ADDING THIS", values)

		onSubmit(values, e)
		setIsAdding(false)
	}

	const _onBlur = () => {
		setIsAdding(false)
		setValue("title", null)
	}

	const handleKeyPress = (e: any) => {
		if (e.keyCode === 27) {
			e.preventDefault()
			setIsAdding(false)
		}
	}

	return (
		<Block marginTop="0" marginBottom="scale600" width={wide ? "100%" : "96%"}>
			{!isAdding && (
				<TriggerButton style={customStyle} onClick={() => setIsAdding(true)}>
					{LabelString[type]}
				</TriggerButton>
			)}
			{isAdding && (
				<form onSubmit={handleSubmit(_onSubmit)} onBlur={_onBlur}>
					<Controller
						control={control}
						name="title"
						id="title"
						render={() => (
							<BlockInput
								type="text"
								placeholder={LabelString[type]}
								onKeyDown={handleKeyPress}
								onChange={(e: any) => setValue("title", e.target.value)}
								$style={customStyle}
								autoFocus
							/>
						)}
					/>
				</form>
			)}
		</Block>
	)
}

export default AddItemForm

const TriggerButton = customStyled("button", ({ $theme }) => ({
	border: "none",
	background: "none",
	cursor: "text",
	transitionDuration: $theme.animation.timing300,
	transitionProperty: "opacity",
	opacity: ".4",

	// ":hover": {
	// 	opacity: "1",
	// 	transitionDuration: $theme.animation.timing300,
	// 	transitionProperty: "opacity",
	// },
	// ...props.style,
}))

const BlockInput = customStyled("input", ({ $theme }) => ({
	height: "39px",
	backgroundColor: $theme.custom.bodyBackgroundColor,
	width: "100%",
	borderRadius: "none",

	":focus": {
		backgroundColor: "rgba(66, 13, 154, 0.05)",
		// backgroundColor: theme.colors.mono300,
		opacity: "1",
		border: `1px dashed ${$theme.colors.mono700}`,
	},
}))
