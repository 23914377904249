import { instance as axios } from "config/axios"
import { DocumentTypesEnum } from "types/enums"

export type GetTaskDocumentsVariables = { taskId: string }
export type RemoveTaskDocumentVariables = { taskId: string, documentId: string }
export type CreateTaskDocumentVariables = { taskId: string, files: File[], documentType: DocumentTypesEnum }
export type ApproveTaskDocumentVariables = { documentId: string, updatedData: any }

export async function getTaskDocuments(key: string, { taskId }: GetTaskDocumentsVariables) {
	console.log("GET TASK DOCS")

	const { data } = await axios.get(`/tasks/${taskId}/documents`)
	return data
}

export async function removeTaskDocument({ taskId, documentId }: RemoveTaskDocumentVariables) {
	const { data } = await axios.delete(`/tasks/${taskId}/documents/${documentId}`)
	return data
}

export async function createTaskDocument({ taskId, files, documentType }: CreateTaskDocumentVariables) {
	console.log("Started uploading from service", taskId, files)

	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	}

	const res = []

	for (let index = 0; index < files.length; index++) {
		const file = files[index]
		const formData = new FormData()
		formData.append("file", file)
		const { data } = await axios.post(
			`/tasks/${taskId}/documents?documentType=${documentType}`,
			formData,
			config,
		)
		res.push(data)
	}

	return res
}

export async function approveTaskDocument({ documentId, updatedData }: ApproveTaskDocumentVariables) {
	const { data } = await axios.post(`/documents/${documentId}/approval/`, updatedData)
	return data
}
