import { useEffect, useState } from "react"
import { useStyletron } from "baseui"
import { ParagraphLarge, ParagraphSmall } from "baseui/typography"
import { Block } from "baseui/block"
import { StatefulPopover } from "baseui/popover"
import { Button, SIZE, SHAPE, KIND } from "baseui/button"

import { IDocument } from "types/IDocument"
import { ICompany } from "types/ICompany"

import Can from "components/Can"
import DropdownContent from "components/DropdownContent"
import Uploader from "components/Uploader"

import DocumentTile from "./DocumentTile"

interface Props {
	title: string
	category: any
	documents: IDocument[] | undefined
	onAddedFiles: Function
	viewDocument: Function
	onRemove: Function
	company: ICompany
}

const DocumentCategory: React.FC<Props> = ({ title, category, documents, onAddedFiles, viewDocument, onRemove, company }) => {
	const [css] = useStyletron()
	const [currentDocuments, setDocuments] = useState<IDocument[]>([])
	const _onAddedFiles = (files: IDocument) => {
		onAddedFiles(files, category)
	}

	useEffect(() => {
		if (documents) {
			const filteredDocs = documents.filter(doc => doc.category === category)
			setDocuments(filteredDocs)
		}
	}, [documents, category])

	if (!documents) return null

	return (
		<Block marginBottom="scale800" backgroundColor="white" padding="scale800">
			<Can do="create" on={company} type="CompanyFiles">
				<Block display="flex" alignItems="center" justifyContent="space-between" marginBottom="scale800">
					<ParagraphLarge margin="0">{title}</ParagraphLarge>
					<StatefulPopover
						content={() => (
							<DropdownContent>
								<Uploader onAddedFiles={_onAddedFiles} />
							</DropdownContent>
						)}>
						<Button size={SIZE.mini} shape={SHAPE.pill} kind={KIND.secondary}>
							Ladda upp filer
						</Button>
					</StatefulPopover>
				</Block>
			</Can>

			{currentDocuments && currentDocuments.length > 0 ? (
				<ul
					className={css({
						padding: "0",
					})}>
					{currentDocuments.map(file => (
						<DocumentTile key={file._id} file={file} viewDocument={viewDocument} onRemove={() => onRemove(file._id)} company={company} />
					))}
				</ul>
			) : (
				<ParagraphSmall marginBottom="0">Inga filer</ParagraphSmall>
			)}
		</Block>
	)
}

export default DocumentCategory
