import * as React from "react"
import { Link } from "react-router-dom"
import { format } from "format-phone-swedish"
import { X } from "react-feather"
import { Button, SHAPE, KIND, SIZE } from "baseui/button"
import { Checkbox } from "baseui/checkbox"
import { StatefulTooltip } from "baseui/tooltip"
import { Block } from "baseui/block"
import { Tag, VARIANT } from "baseui/tag"
import { Label3, ParagraphMedium } from "baseui/typography"
import { useAbility } from "@casl/react"
import { Check } from "react-feather"
// import { useStyletron } from "baseui"

// import { getKeysFromArray } from "utils/misc"
import useCompanyMembers from "hooks/useCompanyMembers"
import useProjectMembers from "hooks/useProjectMembers"
import { setType } from "config/abilities"

import Can, { AbilityContext } from "components/Can"
import DataTable from "components/DataTable"
import Loader from "components/Loader"
import UserPositionSelect from "components/UserPositionSelect"
import { ContentBlock } from "components/ContentBlock"
import PageLayout from "components/layout/PageLayout"
// import GlobalContext from "context/GlobalContext"
import { useSelector } from "react-unistore"
import { ICompany } from "types/ICompany"
import { IProfile } from "types/IProfile"
import { Cell } from "react-table"
import { IState } from "store"
// import Editable from "components/Editable"

interface Props {
	company: ICompany | undefined
}

const CompanyMembers: React.FC<Props> = ({ company }) => {
	// const [css] = useStyletron()
	// const { showToast } = React.useContext(GlobalContext)
	const ability = useAbility(AbilityContext)
	const { companyMembers, updateCompanyMember, updateCompanyMemberInfo } = useCompanyMembers(company?._id)
	const { removeProjectMember, removeProjectMemberInfo } = useProjectMembers(company?.project)
	const currentProfile = useSelector((state: IState) => state.profileAbilities)

	const onUpdate = (response: any) => {
		updateCompanyMember(response)
	}
	// const onUpdate = (oldValue, newValue, userProfile) => {
	// 	if (oldValue === newValue) return

	// 	if (!newValue.length) {
	// 		showToast({ type: "negative", message: "Användaren måste ha minst en roll" })
	// 		return
	// 	}

	// 	const updatedData = { position: getKeysFromArray(newValue) }
	// 	updateCompanyMember({
	// 		companyId: userProfile.company._id,
	// 		memberId: userProfile._id,
	// 		updatedData,
	// 	})
	// }

	const onRemove = (member: IProfile) => {
		if (window.confirm(`Är du säker på att du vill ta bort ${member.firstName} ${member.lastName}?`)) {
			removeProjectMember({
				projectId: company?.project || "",
				companyId: member.company._id,
				memberId: member._id,
			})
		}
	}

	const onPermissionsChange = (isChecked: boolean, userProfile: IProfile) => {
		const updatedData = { companyPermissions: isChecked ? "companyManager" : "companyMember" }
		updateCompanyMember({
			companyId: userProfile.company._id,
			memberId: userProfile._id,
			updatedData,
		})
	}

	if (!companyMembers) {
		return <Loader />
	}

	const columns = [
		{
			Header: "Namn",
			accessor: "fullName",
			primary: true,
			Cell: ({ row }: Cell<IProfile>) => {
				const userProfile = row.original

				if (row.original.isPending) {
					return <ParagraphMedium>-</ParagraphMedium>
				}

				return <Link to={`/p/${userProfile.project}/profile/${userProfile._id}`}>{userProfile.fullName}</Link>
			},
			search: true,
		},
		{
			Header: "Kontakt",
			accessor: "contact",
			Cell: ({ row }: Cell<IProfile>) => {
				const userProfile = row.original
				return (
					<div>
						{userProfile.email && (
							<a target="_blank" rel="noopener noreferrer" href={"mailto:" + userProfile.email}>
								{userProfile.email}
							</a>
						)}
						{userProfile.email && userProfile.phone && <br />}

						{userProfile.isPending && (
							<Tag closeable={false} variant={VARIANT.light} kind="neutral">
								Skickat inbjudan
							</Tag>
						)}
						{userProfile.phone && <a href={`tel:${userProfile.phone}`}>{format(userProfile.phone, "–")}</a>}
					</div>
				)
			},
		},
		{
			Header: "Roll",
			accessor: "position",
			Cell: ({ row, value }: Cell<IProfile>) => {
				const userProfileWithType = setType("Profile", row.original)

				if (ability.can("update", userProfileWithType)) {
					return <UserPositionSelect placeholder="Välj roll" profile={userProfileWithType} onProfileUpdate={onUpdate} />
				} else {
					const positions = [].concat(value)
					return (
						<Label3>
							{positions.map((p, i) => {
								return i + 1 === positions.length ? p : p + ", "
							})}
						</Label3>
					)
					// return <Label3>{UserPositions[value]}</Label3>
				}
			},
		},
		{
			Header: "Ansvarig",
			accessor: "companyPermissions",
			Cell: ({ row }: Cell<IProfile>) => {
				const userProfileWithType = setType("Profile", row.original)
				const checked = userProfileWithType.companyPermissions === "companyManager" ? true : false
				if (
					(userProfileWithType.permissions === "owner" ||
						userProfileWithType.permissions === "admin" ||
						userProfileWithType._id !== currentProfile?._id) &&
					ability.can("update", userProfileWithType, "companyPermissions")
				) {
					return (
						<Checkbox
							checked={checked}
							onChange={(e: React.BaseSyntheticEvent) => onPermissionsChange(e.target.checked, userProfileWithType)}></Checkbox>
					)
				}

				return checked ? <Check size="16" /> : ""
			},
		},
	]

	const companyWithType = setType("Member", company)
	if (ability.can("delete", companyWithType)) {
		columns.push({
			Header: "",
			accessor: "actionColumn",
			width: 50,
			Cell: ({ row }: Cell<IProfile>) => {
				const userProfile = row.original
				return (
					<Can do="delete" on={userProfile} type="Profile" passThrough>
						{(allowed: boolean) =>
							allowed ? (
								<StatefulTooltip
									content={() => <Block>Ta bort</Block>}
									showArrow
									// returnFocus
									// autoFocus
								>
									<Button shape={SHAPE.round} kind={KIND.secondary} size={SIZE.compact} onClick={e => onRemove(userProfile)}>
										<X size={16} />
									</Button>
								</StatefulTooltip>
							) : (
								<StatefulTooltip content={() => <Block>Ta bort</Block>} showArrow>
									<Button shape={SHAPE.round} kind={KIND.secondary} size={SIZE.compact} disabled={true}>
										<X size={16} />
									</Button>
								</StatefulTooltip>
							)
						}
					</Can>
				)
			},
		} as any)
	}

	const isLoading = removeProjectMemberInfo.status === "loading" || updateCompanyMemberInfo.status === "loading" ? true : false

	return (
		<PageLayout full>
			<ContentBlock isLoading={isLoading}>
				<DataTable
					name="companyMembers"
					columns={columns as any}
					data={companyMembers}
					// searchable={["fullName"]} sortable
				/>
			</ContentBlock>
		</PageLayout>
	)
}

export default CompanyMembers
