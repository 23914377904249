import * as React from "react"
// import shortid from "shortid"
// import dayjs from "dayjs"
// import { StatefulCalendar } from "baseui/datepicker"
// import { StatefulPopover } from "baseui/popover"
// import { Button, KIND, SIZE, SHAPE } from "baseui/button"
import { Block } from "baseui/block"
import { Checkbox, STYLE_TYPE } from "baseui/checkbox"
import { ParagraphMedium } from "baseui/typography"
import { Calendar, ExternalLink } from "react-feather"
import { useAbility } from "@casl/react"
import shortid from "shortid"

import { dateTimeFormatter } from "utils/format"
import { dateOrNull } from "utils/misc"

import { AbilityContext } from "components/Can"
import Editable from "components/Editable"
import DateDropdown from "components/DateDropdown"
import Requirements from "./Requirements"
import { setType } from "config/abilities"
import { IControl } from "types/IControl"

interface Props {
	currentControl: IControl
	onUpdate: Function
	controlIsFreezed: boolean
}

const Blocks: React.FC<Props> = ({ currentControl, controlIsFreezed, onUpdate }) => {
	const [startDate, setStartDate] = React.useState<Date | null>(null)
	const [endDate, setEndDate] = React.useState<Date | null>(null)
	const [regulatoryRequirements, setRegulatoryRequirements] = React.useState(false)
	const ability = useAbility(AbilityContext)

	React.useEffect(() => {
		setStartDate(dateOrNull(currentControl.startDate))
		setEndDate(dateOrNull(currentControl.endDate))
		setRegulatoryRequirements(currentControl.regulatoryRequirements || false)
	}, [currentControl])

	// const _onUpdate = updatedData => {
	// 	onUpdate(updatedData)
	// }

	const onChange = () => {
		setRegulatoryRequirements(val => !val)
		onUpdate({ regulatoryRequirements: !regulatoryRequirements })
	}

	const handleChangeStart = ({ date }: { date: Date | Date[] }) => {
		const startDate = Array.isArray(date) ? date[0] : date
		setStartDate(startDate)
		onUpdate({ startDate: dateOrNull(startDate) })
	}

	const handleChangeEnd = ({ date }: { date: Date | Date[] }) => {
		const endDate = Array.isArray(date) ? date[0] : date
		setEndDate(endDate)
		onUpdate({ endDate: dateOrNull(endDate) })
	}

	if (!currentControl) return null

	const controlWithType = setType("Control", currentControl)
	const canEdit = ability.can("update", controlWithType)

	return (
		<div>
			<Block backgroundColor="white" padding="scale800" marginBottom="scale600">
				{/* <H5 marginTop="0">Info</H5> */}
				<ParagraphMedium marginTop="0">Info</ParagraphMedium>
				{canEdit && !controlIsFreezed ? (
					<div>
						<DateDropdown
							label={<React.Fragment>Startdatum: </React.Fragment>}
							date={startDate}
							maxDate={endDate || undefined}
							onChange={handleChangeStart}
							clearable
						/>
					</div>
				) : (
					<ParagraphMedium margin="0">
						<Calendar size="16" /> Startdatum: {dateTimeFormatter(currentControl.startDate, true)}
					</ParagraphMedium>
				)}

				{canEdit && !controlIsFreezed ? (
					<DateDropdown
						label={<React.Fragment>Slutdatum: </React.Fragment>}
						date={endDate}
						minDate={startDate || undefined}
						onChange={handleChangeEnd}
						clearable
					/>
				) : (
					<ParagraphMedium margin="0">
						<Calendar size="16" /> Slutdatum: {dateTimeFormatter(currentControl.endDate, true)}
					</ParagraphMedium>
				)}

				{canEdit && !controlIsFreezed ? (
					<Checkbox
						checkmarkType={STYLE_TYPE.toggle_round}
						name="regulatoryRequirements"
						checked={regulatoryRequirements}
						onChange={onChange}>
						<ParagraphMedium margin="0">Myndighetskrav</ParagraphMedium>
					</Checkbox>
				) : (
					regulatoryRequirements && <ParagraphMedium margin="0">Myndighetskrav</ParagraphMedium>
				)}
			</Block>

			<Block backgroundColor="white" padding="scale800" marginBottom="scale600">
				{/* <H5 marginTop="0">Föreskrifter</H5> */}
				<ParagraphMedium marginTop="0">Föreskrifter</ParagraphMedium>
				{canEdit && !controlIsFreezed ? (
					<Requirements onUpdate={onUpdate} currentControl={currentControl} />
				) : (
					<ul>
						{currentControl.regulations.map((regulation, index) => (
							<li key={shortid.generate()}>
								<a target="_blank" rel="noopener noreferrer" href={regulation.link}>
									{regulation.title} <ExternalLink className="icon" />
								</a>
							</li>
						))}
					</ul>
				)}
			</Block>

			{!controlIsFreezed && (
				<Block backgroundColor="white" padding="scale800" marginBottom="scale600">
					{/* <H5 marginTop="0">Kontrollmetoder</H5> */}
					<ParagraphMedium marginTop="0">Kontrollmetoder</ParagraphMedium>
					{canEdit && !controlIsFreezed ? (
						<Editable
							value={currentControl.methods}
							type="textarea"
							onUpdate={(val: string) => onUpdate({ methods: val })}
							canEdit={true}
						/>
					) : (
						<ParagraphMedium>{currentControl.methods}</ParagraphMedium>
					)}
				</Block>
			)}
		</div>
	)
}
export default Blocks
