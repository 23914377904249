import dayjs from "dayjs"
import filesize from "filesize"
import { format as formatTimeago } from "timeago.js"

export const formatBytes = (bytes: number): string => {
    return filesize(bytes, { unix: true })
}

export const dateTimeFormatter = (dateToFormat: Date | string, excludeTime?: boolean): string | null => {
    if (!dateToFormat) return null

    const date = dayjs(dateToFormat).format("DD MMM")
    const time = dayjs(dateToFormat).format("HH:mm")
    return excludeTime ? `${date}` : `${date} ${time}`
}

export const timeagoFormatter = (date: Date): string => {
    // var hour = 60 * 60 * 1000
    var minute = 60 * 1000

    let datePlaceholder = formatTimeago(date, "sv_SE")
    const now: any = new Date()
    const currentDate: any = new Date(date)

    if (now - currentDate < minute) {
        datePlaceholder = "<1 min"
    }

    return `${datePlaceholder}`
}