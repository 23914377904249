import * as React from "react"

import Activities from "components/Activities"
import PageLayout from "components/layout/PageLayout"
import Can from "components/Can"

import CompanyCard from "./CompanyCard"
import { ICompany } from "types/ICompany"

interface Props {
	company: ICompany | undefined
}

const CompanyProfile: React.FC<Props> = ({ company }) => {
	if (!company) {
		return <div>Loading...</div>
	}

	return (
		<PageLayout leftContent={<CompanyCard company={company} />}>
			<Can do="read" on={company} type="Activity">
				<Activities entity="company" id={company._id} blockMode={true} />
			</Can>
		</PageLayout>
	)
}

export default CompanyProfile
