import * as React from "react"
import { Avatar } from "baseui/avatar"
import { StatefulTooltip } from "baseui/tooltip"
import { Block } from "baseui/block"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { useStyletron } from "baseui"
import { ParagraphXSmall } from "baseui/typography"
import { IProfile } from "types/IProfile"
import { IEmbeddedMember } from "types/shared"

export interface Props {
	users: IProfile[] | IEmbeddedMember[]
	onClick: Function
	size?: keyof SIZE | "scale400" | "scale600" | "scale800"
}

const AvatarGroup: React.FC<Props> = ({ users, onClick, size }) => {
	const [css, theme] = useStyletron()
	if (!users || !users.length) return null

	if (users.length === 1) {
		const user = users[0]
		const fullName = user.fullName
		return (
			<Button kind={KIND.minimal} shape={SHAPE.round} size={SIZE.mini} onClick={() => onClick()}>
				<Avatar name={fullName} size={size || "scale900"} src={user.photoUrl} />
			</Button>
		)
	}

	const counter = users.length > 3 ? `+${users.length - 3}` : ""
	const groupedUsers: IProfile[] | IEmbeddedMember[] = users.length > 3 ? [users[0], users[1], users[2]] : users

	return (
		<Block
			className={css({
				margin: `${theme.sizing.scale0} ${theme.sizing.scale500}`,
				display: "flex",
				alignItems: "center",
			})}>
			{groupedUsers &&
				groupedUsers.map((user, index) => {
					const fullName = user.fullName
					return (
						<Button
							key={index + user._id}
							kind={KIND.minimal}
							shape={SHAPE.round}
							size={SIZE.mini}
							overrides={{
								BaseButton: {
									style: () => ({
										marginTop: `${theme.sizing.scale0}`,
										marginBottom: `${theme.sizing.scale0}`,
										marginRight: `-${theme.sizing.scale500}`,
										marginLeft: `-${theme.sizing.scale500}`,
									}),
								},
							}}
							onClick={() => onClick()}>
							<StatefulTooltip content={() => <Block>{fullName}</Block>} returnFocus autoFocus>
								<Avatar name={fullName} size={size || "scale900"} src={user.photoUrl} />
							</StatefulTooltip>
						</Button>
					)
				})}

			{/* {users.map(user => (
				<Avatar
					size="scale1000"
					name={`${user.firstName} ${user.lastName}`}
					src={user.photoUrl}
				/>
			))} */}
			<ParagraphXSmall className={css({ marginLeft: theme.sizing.scale500 })}>{counter}</ParagraphXSmall>
		</Block>
	)
}

export default AvatarGroup
