import * as React from "react"
import { Button, KIND, SHAPE } from "baseui/button"

import GlobalContext from "context/GlobalContext"
import { ModalEnum } from "containers/ModalContainer"

const AddCompany = () => {
	const { openModal } = React.useContext(GlobalContext)

	return (
		<div>
			<Button kind={KIND.primary} shape={SHAPE.pill} onClick={e => openModal(ModalEnum.AddCompany)}>
				Lägg till företag
			</Button>
		</div>
	)
}

export default AddCompany
