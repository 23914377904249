import * as React from "react"
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd"
import { Button } from "baseui/button"
import { Menu, X } from "react-feather"
import ObjectId from "objectid-random"
import { useForm } from "react-hook-form"

import { deepCopy, getChanges, reorder } from "utils/misc"
import PageLayout from "components/layout/PageLayout"
import { IControl } from "types/IControl"
import { EntityStatus } from "types/enums"

interface Props {
	currentControl: IControl
	onUpdate: Function
}

interface ListItem {
	_id: string
	title: string
	link: string
}

const Requirements: React.FC<Props> = ({ currentControl, onUpdate }) => {
	const { handleSubmit, register } = useForm()
	const [list, setList] = React.useState<ListItem[]>([])
	const [, setChanges] = React.useState<any>([])

	React.useEffect(() => {
		if (currentControl) {
			const newList = deepCopy(currentControl.regulations)
			setList(newList)
		}
	}, [currentControl])

	if (!currentControl) return null

	const onSave = () => {
		onUpdate({ regulations: list })
	}

	const updateValue = (val: string, item: ListItem, isTitle: boolean) => {
		if (isTitle) {
			item.title = val
		} else {
			item.link = val
		}

		const newList = list.map(l => {
			if (l._id === item._id) {
				return item
			}
			return l
		})

		_onUpdate([...newList])
		// checkUpdates(currentControl.regulations, newList);
	}

	const _onUpdate = (updatedList: ListItem[]) => {
		setList(updatedList)
		checkUpdates(currentControl.regulations, updatedList)
	}

	const checkUpdates = (oldList: IControl["regulations"], newList: ListItem[]) => {
		const changed = getChanges(oldList, newList)
		setChanges(changed)
	}

	const onAdd = (value: ListItem, e: any) => {
		const newItem = {
			_id: ObjectId(),
			title: value.title,
			link: value.link,
		}

		_onUpdate([...list, newItem])
		e.target.reset()
	}

	const onRemove = (item: ListItem, e: React.BaseSyntheticEvent) => {
		if (!window.confirm(`Är du säker på att du vill ta bort "${item.title}"?`)) return
		const newList = list.filter(listItem => listItem._id !== item._id)
		_onUpdate(newList)
	}

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result

		if (!destination) {
			return
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}

		const updatedItems = reorder(list, source.index, destination.index)

		setList(updatedItems)
		return
	}

	const isFreezed = currentControl.status === EntityStatus.pending || currentControl.status === EntityStatus.approved

	return (
		<PageLayout full>
			{list && list.length < 1 ? (
				<div>
					<em>Det finns inga redovisningskategorier</em>
				</div>
			) : (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable-requirements">
						{(provided, snapshot) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{list.map((item, index) => (
									<Draggable key={item._id} draggableId={item._id} index={index}>
										{(provided, snapshot) => (
											<div ref={provided.innerRef} {...provided.draggableProps}>
												<span {...provided.dragHandleProps}>
													<Menu />
												</span>
												<input
													value={item.title}
													placeholder="Skriv här..."
													onChange={e => updateValue(e.target.value, item, true)}
												/>
												<input
													value={item.link}
													className="form-input form-input-editable"
													placeholder="https://www.exempel.se"
													onChange={e => updateValue(e.target.value, item, false)}
												/>
												{!isFreezed && (
													<Button onClick={e => onRemove(item, e)}>
														<X />
													</Button>
												)}
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}

			{!isFreezed && (
				<form onSubmit={handleSubmit(onAdd)}>
					<div>
						<input
							name="title"
							id="title"
							type="text"
							placeholder="Föreskrift"
							ref={register({
								required: true,
								minLength: 2,
							})}
						/>
						<input
							name="link"
							id="link"
							type="text"
							placeholder="https://www.exempel.se"
							ref={register({
								required: true,
								minLength: 2,
							})}
						/>
						<Button>Lägg till</Button>
					</div>
				</form>
			)}
			<Button type="submit" disabled={isFreezed} onClick={onSave}>
				Spara ändringar
			</Button>
		</PageLayout>
	)
}

export default Requirements
