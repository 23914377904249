import * as React from "react"
// import { Link } from "react-router-dom"
import { Button, SHAPE, SIZE, KIND } from "baseui/button"
import { File, MoreVertical, Check } from "react-feather"
import { ListItem, ListItemLabel, ARTWORK_SIZES } from "baseui/list"
import { StatefulPopover } from "baseui/popover"
import { StatefulMenu } from "baseui/menu"
import { useStyletron } from "baseui"
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography"

import ability, { setType } from "config/abilities"

import { ITask, ITaskDocument } from "types/ITask"
import { dateTimeFormatter, formatBytes } from "utils/format"
import useTaskDocuments from "hooks/useTaskDocuments"
import GlobalContext from "context/GlobalContext"

import Can from "components/Can"
import { EntityStatus } from "types/enums"
import { ModalEnum } from "containers/ModalContainer"

interface Props {
	taskIsFreezed: boolean
	document: ITaskDocument
	task: ITask
}

const TaskDocument: React.FC<Props> = ({ taskIsFreezed, document, task }) => {
	const [css, theme] = useStyletron()
	const { openModal } = React.useContext(GlobalContext)
	const { approveDocument, removeDocument, removeDocumentInfo } = useTaskDocuments(task._id)
	const taskWithType = setType("Task", task)

	const viewDocument = () => {
		// Document must contain projectId.
		const currentDocument: ITaskDocument = Object.assign(document, { project: task.project })
		openModal(ModalEnum.ViewDocument, { currentDocument })
	}

	const onApproval = () => {
		const status = document.status === EntityStatus.approved ? EntityStatus.disapproved : EntityStatus.approved
		approveDocument({ documentId: document._id, updatedData: { status } })
	}

	const onDelete = () => {
		removeDocument({ taskId: task._id, documentId: document._id })
	}

	const isApproved = document.status === EntityStatus.approved ? true : false
	const isLoading = removeDocumentInfo.status === "loading"

	return (
		<ListItem
			overrides={{
				Root: {
					style: ({ $theme }) => ({
						// backgroundColor: $theme.colors.positive50,
						opacity: isLoading ? "0.5" : "1",
					}),
				},
				Content: {
					style: ({ $theme }) => ({
						marginLeft: "0",
						paddingLeft: $theme.sizing.scale200,
					}),
				},
				ArtworkContainer: {
					style: ({ $theme }) => ({
						width: $theme.sizing.scale1000,
					}),
				},
			}}
			artwork={(props: any) =>
				document.status === EntityStatus.approved ? (
					<Check className={css({ color: theme.colors.positive })} {...props} />
				) : (
					<File {...props} />
				)
			}
			artworkSize={ARTWORK_SIZES.LARGE}
			sublist
			endEnhancer={() => (
				<Can do="delete" on={task} type="Task">
					{() =>
						!taskIsFreezed && (
							<React.Fragment>
								<StatefulPopover
									content={() => {
										const items = []
										console.log("CAN APPROVE FILE ", ability.can("approve", taskWithType))

										if (ability.can("approve", taskWithType)) {
											items.push({
												label: <span onClick={onApproval}>{isApproved ? "Inte godkänd" : "Godkänn"}</span>,
											})
										}

										items.push({
											label: !isApproved ? (
												<span onClick={onDelete}>Ta bort</span>
											) : (
												<span
													className={css({
														color: theme.colors.mono400,
													})}>
													Ta bort
												</span>
											),
										})

										return <StatefulMenu items={items} />
									}}
									returnFocus
									autoFocus>
									<Button
										size={SIZE.mini}
										kind={KIND.tertiary}
										shape={SHAPE.round}
										overrides={{
											BaseButton: {
												style: () => ({
													marginLeft: theme.sizing.scale200,
												}),
											},
										}}>
										<MoreVertical size="16" />
									</Button>
								</StatefulPopover>
							</React.Fragment>
						)
					}
				</Can>
			)}>
			<ListItemLabel>
				<ParagraphSmall
					margin="0"
					className={css({
						cursor: "pointer",
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "block",
						width: "400px",
					})}>
					<span onClick={() => viewDocument()}>{document.name}</span>
				</ParagraphSmall>
				<ParagraphXSmall color="mono600" margin="0">
					{document.size && formatBytes(document.size)} · {dateTimeFormatter(document.createdAt)} •
					{/* {DocumentTypes[document.documentType]} */}
				</ParagraphXSmall>
			</ListItemLabel>
		</ListItem>
	)
}

export default TaskDocument
