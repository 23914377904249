import * as React from "react"
import { Link } from "react-router-dom"
// import { useSelector } from "react-unistore"
import { Block } from "baseui/block"

import { dateTimeFormatter } from "utils/format"
import { ICheck } from "types/ICheck"

// import FormSelect from "components/Form/FormSelect"
import { ParagraphXSmall } from "baseui/typography"
import Editable from "components/Editable"
import Can from "components/Can"
import { EntityStatus } from "types/enums"

interface Props {
	currentCheck: ICheck
	onUpdate: Function
	checkIsFreezed: boolean
}

const CheckInfo: React.FC<Props> = ({ currentCheck, onUpdate, checkIsFreezed }) => {
	// // const currentProject = useSelector((state: IState) => state.currentProject)

	if (!currentCheck) return null

	const onSave = (val: string) => {
		if (val === currentCheck.description) return
		onUpdate({ description: val })
	}

	// const onChange = params => {
	// 	const val = getKeysFromArray(params.value)[0]._id
	// 	if (val === currentCheck.category) return

	// 	onUpdate({ category: val })
	// }

	return (
		<div>
			<Block marginTop="scale800" marginBottom="scale800">
				<Can do="update" on={currentCheck} type="Check" passThrough>
					{(allowed: boolean) =>
						!allowed || checkIsFreezed ? (
							<p>{currentCheck.description}</p>
						) : (
							<Editable value={currentCheck.description} type="textarea" onUpdate={onSave} />
						)
					}
				</Can>
			</Block>

			{/* {checkIsFreezed ? (
				<div>
					Typ:{" "}
					{
						// currentProject.categories.filter(c => c._id === currentCheck.category)[0]
							.title
					}
				</div>
			) : (
				<div style={{ maxWidth: "200px" }}>
					// {currentProject && (
						<FormSelect
							// options={currentProject.categories}
							// value={currentProject.categories.filter(
								c => c._id === currentCheck.category,
							)}
							size="compact"
							clearable={false}
							searchable={false}
							valueKey="_id"
							labelKey="title"
							placeholder={currentCheck.category ? "" : "Välj ett alternativ"}
							onChange={onChange}
						/>
					)}
				</div>
			)} */}

			<div>
				{currentCheck.location && <div>Plats: {currentCheck.location}</div>}
				{currentCheck.status && currentCheck.requestedBy && (
					<div>
						Skickat för godkännande:{" "}
						<Link to={`/p/${currentCheck.project}/profile/${currentCheck.requestedBy._id}`}>{currentCheck.requestedBy.fullName}</Link>
					</div>
				)}

				{currentCheck.status && (currentCheck.status === EntityStatus.approved || currentCheck.status === EntityStatus.disapproved) && currentCheck.approvedBy && (
					<ParagraphXSmall margin="0">
						Kontrollerad av:{" "}
						<Link to={`/p/${currentCheck.project}/profile/${currentCheck.approvedBy._id}`}>{currentCheck.approvedBy.fullName}</Link>
					</ParagraphXSmall>
				)}
				<ParagraphXSmall margin="0">
					Skapad av: <Link to={`/p/${currentCheck.project}/profile/${currentCheck.createdBy._id}`}>{currentCheck.createdBy.fullName}</Link>
				</ParagraphXSmall>
				<ParagraphXSmall margin="0">Skapades: {dateTimeFormatter(currentCheck.createdAt)}</ParagraphXSmall>
				<ParagraphXSmall margin="0">Senaste uppdatering: {dateTimeFormatter(currentCheck.updatedAt)}</ParagraphXSmall>
			</div>
		</div>
	)
}

export default CheckInfo
