import { Row } from "react-table"
import { getBy } from "utils/filterHelpers"

export const text = (rows: Row<any>[], ids: string[], filterValue: any) => {
	rows = rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
		})
	})
	return rows
}

text.autoRemove = (val: any) => !val

export const exactText = (rows: Row<any>[], ids: string[], filterValue: any) => {
	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return rowValue !== undefined
				? String(rowValue).toLowerCase() === String(filterValue).toLowerCase()
				: true
		})
	})
}

exactText.autoRemove = (val: any) => !val

export const exactTextCase = (rows: Row<any>[], ids: string[], filterValue: any) => {
	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return rowValue !== undefined ? String(rowValue) === String(filterValue) : true
		})
	})
}

exactTextCase.autoRemove = (val: any) => !val

export const includes = (rows: Row<any>[], ids: string[], filterValue: any) => {
	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return rowValue.includes(filterValue)
		})
	})
}

includes.autoRemove = (val: any) => !val || !val.length

export const includesAll = (rows: Row<any>[], ids: string[], filterValue: any) => {
	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return rowValue && rowValue.length && filterValue.every((val: any) => rowValue.includes(val))
		})
	})
}

includesAll.autoRemove = (val: any) => !val || !val.length

export const includesSome = (rows: Row<any>[], ids: string[], filterValue: any) => {

	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return rowValue && rowValue.length && filterValue.some((val: any) => rowValue.includes(val))
		})
	})
}

includesSome.autoRemove = (val: any) => !val || !val.length

export const includesValue = (rows: Row<any>[], ids: string[], filterValue: any) => {
	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return filterValue.includes(rowValue)
		})
	})
}

includesValue.autoRemove = (val: any) => !val || !val.length

export const exact = (rows: Row<any>[], ids: string[], filterValue: any) => {
	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return rowValue === filterValue
		})
	})
}

exact.autoRemove = (val: any) => typeof val === "undefined"

export const equals = (rows: Row<any>[], ids: string[], filterValue: any) => {
	return rows.filter(row => {
		return ids.some(id => {
			let rowValue = row[id]

			if (typeof id === "string") {
				const accessorPath = id.split(".")
				rowValue = getBy(row, accessorPath)
			}
			console.log(rowValue, filterValue)
			// const rowValue = row[id]
			// eslint-disable-next-line eqeqeq
			return rowValue == filterValue
		})
	})
}

equals.autoRemove = (val: any) => val == null

export const between = (rows: Row<any>[], ids: string[], filterValue: any) => {
	let [min, max] = filterValue || []

	min = typeof min === "number" ? min : -Infinity
	max = typeof max === "number" ? max : Infinity

	if (min > max) {
		const temp = min
		min = max
		max = temp
	}

	return rows.filter(row => {
		return ids.some(id => {
			const rowValue = row[id]
			return rowValue >= min && rowValue <= max
		})
	})
}

between.autoRemove = (val: any) => !val || (typeof val[0] !== "number" && typeof val[1] !== "number")
