import { Block } from "baseui/block"

interface Props {
	padding?: string
}

const DropdownContent: React.FC<Props> = ({ children, padding = "20px", ...props }) => {
	return (
		<Block backgroundColor="white" padding={padding} {...props}>
			{children}
		</Block>
	)
}

export default DropdownContent
