import * as React from "react"
import { HeaderNavigation, ALIGN, StyledNavigationList, StyledNavigationItem } from "baseui/header-navigation"
import { Button } from "baseui/button"
import { Menu } from "react-feather"
import { useStyletron } from "baseui"

import Notifications from "./Navigation/Notifications"
import MainBreadcrumbs from "./MainBreadcrumbs"

export interface Props {
	sidebarIsOpen: boolean
	setSidebarIsOpen: any
}

const Header: React.FC<Props> = ({ sidebarIsOpen, setSidebarIsOpen }) => {
	const [css, theme] = useStyletron()
	return (
		<HeaderNavigation
			overrides={{
				Root: {
					style: ({ $theme }) => ({
						position: "relative",
						width: "100%",
						zIndex: 0,
						paddingTop: `${$theme.sizing.scale200}`,
						paddingBottom: `${$theme.sizing.scale200}`,
						paddingRight: `${$theme.sizing.scale400}`,
						paddingLeft: `${$theme.sizing.scale400}`,
						height: "56px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						borderBottom: `1px solid ${$theme.colors.mono300}`,
						// boxShadow:

						[$theme.mediaQuery.medium]: {
							paddingRight: `${$theme.sizing.scale1000}`,
							paddingLeft: `${$theme.sizing.scale1000}`,
						},
					}),
				},
			}}>
			<StyledNavigationList $align={ALIGN.left}>
				{!sidebarIsOpen && (
					<StyledNavigationItem className={css({ marginRight: theme.sizing.scale400, paddingLeft: "0px" })}>
						<Button kind="minimal" size="mini" onClick={() => setSidebarIsOpen(true)}>
							<Menu size="16" />
						</Button>
					</StyledNavigationItem>
				)}
				<StyledNavigationItem>
					<MainBreadcrumbs />
				</StyledNavigationItem>
			</StyledNavigationList>
			<StyledNavigationList $align={ALIGN.center} />
			<StyledNavigationList $align={ALIGN.right}>
				<StyledNavigationItem>
					<Notifications />
				</StyledNavigationItem>
				{/* <StyledNavigationItem>
					<Account />
				</StyledNavigationItem> */}
			</StyledNavigationList>
		</HeaderNavigation>
	)
}

export default Header
