import * as React from "react"
import { useQuery, useMutation, queryCache } from "react-query"
import { getCurrentUser, updateUser, uploadPhoto, removePhoto, createUser, deleteUser, CreateUserVariables, UploadPhotoVariables } from "services/users"
import { useAction } from "react-unistore"

import GlobalContext from "context/GlobalContext"
import { IState } from "store"


export default function useUser(userId?: string | undefined) {
	const { showToast } = React.useContext(GlobalContext)

	const { data: currentUser, isLoading, isFetching, status, error } = useQuery(["currentUser", { userId }], getCurrentUser, { enabled: userId })

	const [_updateUser, updateUserInfo] = useMutation(updateUser, {
		onSuccess: ({ userId }) => {
			showToast({ type: "positive", message: "Kontot har uppdaterats" })
			queryCache.invalidateQueries(["currentUser", { userId }])
		},
	})
	const [_createUser, createUserInfo] = useMutation<any, Error, CreateUserVariables>(createUser, {
		onSuccess: ({ userId }) => {
			showToast({ type: "positive", message: "Ditt konto har skapats" })
			queryCache.invalidateQueries(["currentUser", { userId }])
		},
		throwOnError: true,
	})

	const [_removeUser, removeUserInfo] = useMutation<any, Error, string>(deleteUser, {
		onSuccess: ({ userId }) => {
			queryCache.invalidateQueries(["currentUser", { userId }])
		},
	})

	const [_updatePhoto, updatePhotoInfo] = useMutation<any, Error, UploadPhotoVariables>(uploadPhoto)
	const [_removePhoto, removePhotoInfo] = useMutation<any, Error, string>(removePhoto)

	const setUser = useAction((state: IState, currentUser: IState["currentUser"]) => ({ ...state.currentUser, currentUser }))

	React.useEffect(() => {
		if (currentUser) {
			setUser(currentUser)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser])

	return {
		currentUser,
		isLoading,
		isFetching,
		status,
		error,

		updateUser: _updateUser,
		updateUserInfo,

		createUser: _createUser,
		createUserInfo,

		removeUser: _removeUser,
		removeUserInfo,

		updatePhoto: _updatePhoto,
		updatePhotoInfo,

		removePhoto: _removePhoto,
		removePhotoInfo,
	}
}
