import * as React from "react"
import { Block, BlockProps } from "baseui/block"
import { useStyletron } from "baseui"
import { ParagraphXSmall } from "baseui/typography"

export interface Props extends BlockProps {
	author?: string | JSX.Element
	footer?: string | JSX.Element
	action?: string | JSX.Element
}

const Tile: React.FC<Props> = props => {
	const [css, theme] = useStyletron()

	return (
		<Block
			display="flex"
			alignItems="flex-start"
			marginBottom="scale200"
			position="relative"
			backgroundColor="white"
			padding="scale600"
			{...props}>
			{props.author && <Block marginRight="scale600">{props.author}</Block>}
			<Block className={css({ flexGrow: 1 })}>
				<Block display="flex" justifyContent="space-between">
					<div className={css({ margin: "0" })}>{props.children}</div>
					<div
						className={css({
							margin: "0",
							display: "flex",
							alignItems: "flex-start",
							color: theme.colors.primary400,
						})}>
						{props.action}
					</div>
				</Block>
				{props.footer && <ParagraphXSmall margin="0">{props.footer}</ParagraphXSmall>}
			</Block>
		</Block>
	)
}

export default Tile
