import * as React from "react"
import { instance as axios } from "config/axios"
import { Routes, Route, useParams, useNavigate } from "react-router-dom"
import { _resetCurrentProject, _getMyProjectProfile, _resetMyProjectProfile } from "actions/project"
import { useSelector, useAction } from "react-unistore"

import useProject from "hooks/useProject"
import { prepareTreeData } from "utils/tree"
import useProjectPlan from "hooks/useProjectPlan"
import { IState } from "store"

import ProjectPlan from "pages/ProjectPlan"
import Members from "pages/Members"
import Documents from "pages/Documents"
import Settings from "pages/Settings"
import UserProfile from "pages/UserProfile"
import CompanyProfile from "pages/CompanyProfile"
import Control from "pages/Control"
import { IProject } from "types/IProject"

const ProjectContainer = () => {
	const { projectId } = useParams()
	const navigate = useNavigate()
	const currentProject = useSelector((state: IState) => state.currentProject)
	const resetCurrentProject = useAction(_resetCurrentProject)
	const getMyProjectProfile = useAction(_getMyProjectProfile)
	const resetMyProjectProfile = useAction(_resetMyProjectProfile)

	const { getProject, updateProject, updateProjectInfo, deleteProject, deleteProjectInfo } = useProject(projectId)
	const { getProjectPlan } = useProjectPlan(projectId)

	React.useEffect(() => {
		if (!currentProject || currentProject._id !== projectId) {
			axios.defaults.headers.common["Path"] = projectId
			getProject()
			getMyProjectProfile(projectId)
			getProjectPlan()
			// axios.defaults.headers.common["Reference"] = projectId
			// axios.headers = {"RERERE":"poasdijfsdiofjdsiof"}
		}

		// ! Assure that this is working correctly when working with multiple different projects
		return () => {
			axios.defaults.headers.common["Path"] = ""
			resetCurrentProject()
			resetMyProjectProfile()
			// axios.defaults.headers.common["Reference"] = null
			console.info("Resetting project data")
			// Invalidate all project related cache
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onUpdate = (data: IProject) => {
		const updatedData = prepareTreeData(data)
		updateProject({ projectId: currentProject?._id || "", updatedData })
		// updateProject(currentProject._id, data)
		// console.info("ProjectContainer: onUpdate", data)
	}

	const onDelete = async () => {
		await deleteProject({ projectId: currentProject?._id || "" })
		// await _deleteProject(currentProject._id)
		navigate("/")
	}

	if (!currentProject) return null

	return (
		<Routes>
			<Route path="" element={<ProjectPlan projectId={projectId} />} />
			<Route path="members/*" element={<Members projectId={projectId} />} />
			<Route path="profile/:userId/*" element={<UserProfile />} />
			<Route path="company/:companyId/*" element={<CompanyProfile />} />
			<Route path="documents/*" element={<Documents />} />
			<Route
				path="settings/*"
				element={
					<Settings
						currentProject={currentProject}
						onUpdate={onUpdate}
						updateProjectInfo={updateProjectInfo}
						onDelete={onDelete}
						deleteProjectInfo={deleteProjectInfo}
					/>
				}
			/>
			<Route path="control/:controlId/*" element={<Control currentProject={currentProject} />} />
		</Routes>
	)
}

export default ProjectContainer
