import * as React from "react"
import { useStyletron } from "baseui"
import { Label3, ParagraphXSmall } from "baseui/typography"
import moment from "moment"

import Timeline, {
	TimelineMarkers,
	// TodayMarker,
	TimelineHeaders,
	SidebarHeader,
	DateHeader,
	TimelineItemBase,
} from "react-calendar-timeline"
import "react-calendar-timeline/lib/Timeline.css"
import "./timeline.css"
import "moment/locale/sv"

import { statusColor } from "utils/enums"
import { buildTree } from "utils/tree"
import useControl from "hooks/useControl"

import NoSections from "pages/ProjectPlan/Sections/NoSections"
import { IProjectPlan, IProjectPlanControl, IProjectPlanSection } from "types/IProjectPlan"
import { EntityStatus } from "types/enums"

export interface Props {
	projectPlan: IProjectPlan
	controls: IProjectPlanControl[]
	canEdit: boolean
}

const ProjectTimeline: React.FC<Props> = ({ projectPlan, controls, canEdit }) => {
	const [, setProject] = React.useState<IProjectPlanSection[] | null>(null)
	const { updateControl } = useControl()
	const [timelineGroups, setTimelineGroups] = React.useState([])

	const [timelineItems, setTimelineItems] = React.useState<any>([])
	const build = React.useCallback(buildTree, [])
	const [css, theme] = useStyletron()

	React.useMemo(() => {
		if (!projectPlan || !controls) return
		let data

		data = build(projectPlan, controls, false)
		if (!data) return
		console.log(data)
		setProject(data)

		let g: any = []
		let i: any = []

		data.forEach(section => {
			g.push({
				id: section._id,
				title: (
					<Label3
						className={css({
							margin: 0,
							textTransform: "uppercase",
							height: "100%",
							display: "flex",
							paddingLeft: theme.sizing.scale400,
							alignItems: "center",
							backgroundColor: "#DDDDDD",
							border: "0",
						})}>
						{section.title}
					</Label3>
				),
				height: 25,
			})
			section.groups.forEach(group => {
				// Loop controls, add group and item for each
				g.push({
					id: group._id,
					title: (
						<Label3
							className={css({
								margin: 0,
								height: "100%",
								display: "flex",
								paddingLeft: theme.sizing.scale400,
								alignItems: "center",
								border: "0",
							})}>
							{group.title}
						</Label3>
					),
					height: 50,
				})

				group.controls.forEach(control => {
					const currentStatus = statusColor[control.status === "" ? EntityStatus.empty : control.status]

					const dot = (
						<span
							className={css({
								backgroundColor: theme.colors[currentStatus.color],
								height: "6px",
								width: "6px",
								borderRadius: "50%",
								marginRight: "5px",
								display: "inline-block",
							})}></span>
					)
					g.push({
						id: control._id,
						title: (
							<ParagraphXSmall
								className={css({
									margin: 0,
									height: "100%",
									paddingLeft: theme.sizing.scale800,
									display: "flex",
									alignItems: "center",
								})}>
								{dot} {control.title}
							</ParagraphXSmall>
						),
						height: 35,
					})

					// console.log(moment(control.startDate), moment())
					console.log(control.title, control.status === EntityStatus.approved || control.status === EntityStatus.pending)

					i.push({
						id: control._id,
						group: control._id,
						title: `${moment(control.startDate).format("D MMM")} - ${moment(control.endDate).format("D MMM")}`,
						start_time: control.startDate ? moment(control.startDate) : null,
						end_time: control.endDate ? moment(control.endDate) : null,
						className: `control status-${control?.status?.toString()}`,
						canMove: control.status !== EntityStatus.approved && control.status !== EntityStatus.pending,
						canResize: control.status !== EntityStatus.approved && control.status !== EntityStatus.pending,
						style: {
							backgroundColor: "red",
						},
					})
				})
			})
		})

		setTimelineGroups(g)
		setTimelineItems(i)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [controls, projectPlan])

	const _updateControl = async (controlId: string, data: any) => {
		const updatedData = {
			startDate: new Date(data.start_time),
			endDate: new Date(data.end_time),
		}
		console.log("DATA TO SEND", updatedData)
		return await updateControl({ controlId: controlId.toString(), updatedData })
	}
	// console.log(timelineGroups, timelineItems)

	const onItemMove = (itemId: string | number, dragTime: number, newGroupOrder: number): void => {
		const group: any = timelineGroups[newGroupOrder]
		const updatedItems = timelineItems.map(async (item: any) => {
			if (item.id === itemId) {
				const updatedTime = Object.assign({}, item, {
					start_time: dragTime,
					end_time: dragTime + (item.end_time - item.start_time),
					group: group.id,
				})
				const response = await _updateControl(itemId.toString(), updatedTime)
				console.log("RESPONSE ", response)
				if (!response) return false

				return updatedTime
			} else {
				return item
			}
		})
		console.log("Updated items via move: ", updatedItems)
		setTimelineItems(updatedItems)

		console.log("Moved", itemId, dragTime, newGroupOrder)
	}

	const onItemResize = (itemId: string | number, endTimeOrStartTime: number, edge: "left" | "right"): void => {
		const updatedItems = timelineItems.map(async (item: TimelineItemBase<number>) => {
			if (item.id === itemId) {
				const updatedTime = Object.assign({}, item, {
					start_time: edge === "left" ? endTimeOrStartTime : item.start_time,
					end_time: edge === "left" ? item.end_time : endTimeOrStartTime,
				})

				const response = await _updateControl(itemId.toString(), updatedTime)
				if (!response) return false

				return updatedTime
			} else {
				return item
			}
		})

		console.log("Updated items via resize: ", updatedItems)
		setTimelineItems(updatedItems)
		console.log("Resized", itemId, endTimeOrStartTime, edge)
	}

	const moveResizeValidator = (action: "resize" | "move", item: string | number, time: number, resizeEdge: "left" | "right"): number => {
		console.log(action, item, time, resizeEdge)
		const currentTime = moment(time)
		if (action === "resize") {
			if (resizeEdge === "right" && currentTime < moment(item).add(1, "day")) {
				return parseInt(moment(item).add(1, "day").format("X"))
			} else if (resizeEdge === "left" && currentTime > moment(item).add(-1, "day")) {
				return parseInt(moment(item).add(-1, "day").format("X"))
			}
			// if (resizeEdge === "right" && currentTime < moment(item.start_time).add(1, "day")) {
			// 	return moment(item.start_time).add(1, "day")
			// } else if (resizeEdge === "left" && currentTime > moment(item.end_time).add(-1, "day")) {
			// 	return moment(item.end_time).add(-1, "day")
			// }
			// } else {
			// 	if (time < new Date().getTime()) {
			// 		var newTime = Math.ceil(new Date().getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)
			// 		return newTime
			// 	}
		}

		return time
	}

	const onItemSelect = (itemId: React.ReactText, e: any, time: number): void => {
		console.log(itemId, time)
		e.target.classList.add("active")
	}

	const onItemDeselect = (e: React.SyntheticEvent<Element, Event>): void => {
		const active = document.querySelector(".control.active")
		if (!active) return

		active.classList.remove("active")
	}

	if (!timelineGroups.length || !timelineItems.length) {
		return <NoSections />
	}

	return (
		<Timeline
			moveResizeValidator={moveResizeValidator}
			groups={timelineGroups}
			items={timelineItems}
			defaultTimeStart={moment().add(-20, "days")}
			defaultTimeEnd={moment().add(20, "days")}
			canChangeGroup={false}
			onItemMove={onItemMove}
			onItemResize={onItemResize}
			dragSnap={24 * 60 * 60 * 1000}
			minZoom={10 * 24 * 60 * 60 * 1000}
			maxZoom={1 * 365.24 * 86400 * 1000}
			canResize={true}
			useResizeHandle={true}
			itemHeightRatio={1}
			sidebarWidth={200}
			traditionalZoom={true}
			// fullUpdate
			clickTolerance={3}
			onItemSelect={onItemSelect}
			onItemDeselect={onItemDeselect}
			stackItems={true}
			// showCursorLine={true}
		>
			<TimelineHeaders
				calendarHeaderStyle={{
					height: "60px",
				}}>
				<SidebarHeader>
					{({ getRootProps }) => {
						return (
							<div
								className={css({
									background: "white",
								})}
								{...getRootProps()}></div>
						)
					}}
				</SidebarHeader>
				<DateHeader
					unit="primaryHeader"
					style={{
						backgroundColor: "black",
						color: "white",
						border: "none",
						fontFamily: "Inter",
						textTransform: "uppercase",
					}}
				/>
				<DateHeader />
			</TimelineHeaders>
			<TimelineMarkers>{/* <TodayMarker /> */}</TimelineMarkers>
		</Timeline>
	)
}

export default ProjectTimeline
