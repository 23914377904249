import * as React from "react"
import { Block } from "baseui/block"
import { Button, SIZE, SHAPE } from "baseui/button"
import { useStyletron } from "baseui"
import { Plus } from "react-feather"

import GlobalContext from "context/GlobalContext"
import { ITask, ITaskDocument } from "types/ITask"

import Editable from "components/Editable"
import Can from "components/Can"
import TaskDocument from "./TaskDocument"
import { ModalEnum } from "containers/ModalContainer"

interface Props {
	task: ITask
	taskIsFreezed: boolean
	updateTask: Function
}

const TaskFiles: React.FC<Props> = ({ task, taskIsFreezed, updateTask }) => {
	const { openModal } = React.useContext(GlobalContext)
	const [css, theme] = useStyletron()

	return (
		<React.Fragment>
			<Block marginBottom="scale1000">
				<Can do="update" on={task} type="Task" passThrough>
					{(allowed: boolean) =>
						!allowed || taskIsFreezed ? (
							task.description
						) : (
							<Editable type="textarea" value={task.description} onUpdate={(val: string) => updateTask("description", val)} />
						)
					}
				</Can>
			</Block>
			<Block marginBottom="scale800">
				<Can do="update" on={task} type="Task">
					{() =>
						!taskIsFreezed && (
							<Button
								overrides={{
									BaseButton: {
										style: () => ({
											margin: `0px ${theme.sizing.scale600} ${theme.sizing.scale600}`,
											float: "right",
										}),
									},
								}}
								size={SIZE.mini}
								// kind={KIND.secondary}
								shape={SHAPE.pill}
								endEnhancer={() => <Plus size="14" />}
								onClick={e => openModal(ModalEnum.UploadTaskDocument, { taskId: task._id })}>
								Ladda upp filer
							</Button>
						)
					}
				</Can>
				<ul className={css({ padding: "0px", margin: "0px" })}>
					{task.documents &&
						task.documents.map((doc: ITaskDocument, index: number) => (
							<TaskDocument key={index} taskIsFreezed={taskIsFreezed} task={task} document={doc} />
						))}
				</ul>
			</Block>
		</React.Fragment>
	)
}

export default TaskFiles
