import { useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Block } from "baseui/block"
import { Button, SIZE } from "baseui/button"
import { useStyletron } from "baseui"
import { MutationResult } from "react-query"

import { getKeysFromArray } from "utils/misc"
import { ControlPermissionsArray } from "utils/enums"
import useProjectMembers from "hooks/useProjectMembers"
import { IControl } from "types/IControl"
import { IProfile } from "types/IProfile"

import { getPermissionsLabel, getProfileLabel } from "./helpers"
import FormSelect from "components/Form/FormSelect"

interface Props {
	projectId: string
	onSubmit: Function
	controlMembers: IControl["members"]
	addControlMemberInfo: MutationResult<any>
}

interface FormValues {
	userId: IProfile["_id"] | []
	permissions: IProfile["permissions"] | []
}

const Form: React.FC<Props> = ({ projectId, onSubmit, controlMembers, addControlMemberInfo }) => {
	const { handleSubmit, errors, setValue, control, reset } = useForm<FormValues>()
	const [css, theme] = useStyletron()
	const { members: projectMembers, isLoading: isLoadingProject } = useProjectMembers(projectId)

	useEffect(() => {
		if (addControlMemberInfo.status === "success") {
			reset({
				userId: [],
				permissions: [],
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addControlMemberInfo])

	const onSave = (values: FormValues) => {
		console.log("THE VALUES", values)
		let updatedData: any = { ...values }
		if (updatedData.permissions) {
			updatedData.permissions = getKeysFromArray(updatedData.permissions)[0]
		}
		if (updatedData.userId) {
			updatedData.userId = getKeysFromArray(updatedData.userId)[0]._id
		}
		const userId = updatedData.userId
		delete updatedData.userId

		onSubmit(userId, updatedData)
	}

	const adminRoles = ["owner", "admin", "manager"]
	const currentMembersId = (controlMembers && controlMembers.map(m => m._id)) || []
	const addableMembers = projectMembers?.filter((member: IProfile) => {
		return !member.isPending && adminRoles.indexOf(member.permissions) === -1 && currentMembersId.indexOf(member._id) === -1
	})
	const sortedMembers = addableMembers?.sort((a, b) => {
		let fa = a.fullName.toLowerCase(),
			fb = b.fullName.toLowerCase()

		if (fa < fb) {
			return -1
		}
		if (fa > fb) {
			return 1
		}
		return 0
	})
	const isLoading = addControlMemberInfo.status === "loading"

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<Block display={["block", "block", "flex"]}>
				<Block
					marginRight={[theme.sizing.scale0, theme.sizing.scale0, theme.sizing.scale400]}
					marginBottom={[theme.sizing.scale400, theme.sizing.scale400, theme.sizing.scale0]}
					className={css({ flexGrow: 1 })}>
					<Controller
						render={props => (
							<FormSelect
								isLoading={isLoadingProject}
								options={sortedMembers}
								fieldError={errors.userId}
								value={props.value}
								clearable={false}
								searchable={true}
								size="compact"
								valueKey="_id"
								labelKey="fullName"
								placeholder="Välj användare"
								getOptionLabel={getProfileLabel}
								getValueLabel={({ option }) => option.fullName}
								onChange={params => setValue("userId", params.value, { shouldValidate: true })}
							/>
						)}
						control={control}
						// defaultValue={}
						rules={{ required: "Du måste välja en användare" }}
						name="userId"
					/>
				</Block>
				<Block marginRight={[theme.sizing.scale0, theme.sizing.scale0, theme.sizing.scale400]} className={css({ flexGrow: 1 })}>
					<Controller
						render={props => (
							<FormSelect
								// label="Behörighet"
								options={ControlPermissionsArray}
								fieldError={errors.permissions}
								value={props.value}
								clearable={false}
								searchable={false}
								size="compact"
								placeholder="Välj behörighet"
								getOptionLabel={getPermissionsLabel}
								onChange={params => setValue("permissions", params.value, { shouldValidate: true })}
							/>
						)}
						control={control}
						rules={{ required: "Du måste välja behörighet" }}
						name="permissions"
					/>
				</Block>
				<Block>
					<Button
						overrides={{
							BaseButton: {
								style: () => ({
									width: "100%",
								}),
							},
						}}
						type="submit"
						isLoading={isLoading}
						disabled={isLoading}
						size={SIZE.compact}
						onClick={handleSubmit(onSave)}>
						Lägg till
					</Button>
				</Block>
			</Block>
		</form>
	)
}

export default Form
