import { Routes, Route } from "react-router-dom"

import useChecks from "hooks/useChecks"
import useControlStatus from "hooks/useControlStatus"
import useControlHelpers from "hooks/useControlHelpers"
import { IProject } from "types/IProject"
import { IControl } from "types/IControl"
import { ICheck } from "types/ICheck"

import CheckSidebar from "pages/Check"
import PageHeader from "components/layout/PageHeader"
import SubNavigation from "components/layout/SubNavigation"
import Loader from "components/Loader"

import ControlActivities from "./ControlActivities"
import Options from "./Options"
import Checks from "./Checks"
import Title from "./Title"

interface Props {
	currentProject: IProject
	currentControl: IControl
	updateControl: Function
	sendControlForApproval: Function
}

const Control: React.FC<Props> = ({
	currentProject,
	currentControl,
	updateControl,
	// deleteControl,
	sendControlForApproval,
}) => {
	const controlId = currentControl && currentControl._id
	const { checks } = useChecks(controlId)
	const { controlIndex } = useControlHelpers(controlId, currentControl?.project)
	const { controlIsApprovable, controlIsFreezed } = useControlStatus(currentControl, checks)

	const sendForApproval = () => {
		if (!controlIsApprovable) {
			return null
		}

		// if (!window.confirm("Är du säker?")) return
		// openModal(ModalEnum.ControlApproval);
		// console.log('Sent control for approval');
		sendControlForApproval({ controlId: currentControl._id })
	}

	const onUpdate = (updatedData: {}) => {
		updateControl({
			controlId: currentControl._id,
			updatedData,
		})
	}

	// const onDelete = async () => {
	// 	await deleteControl({
	// 		controlId: currentControl._id,
	// 		updateProject: true,
	// 	})
	// 	await refetchProject(currentProject._id)
	// 	// console.log("NAVIGATEIJKNFD")
	// 	navigate("../..")
	// }

	const subRoutes = [
		{
			text: "Redovisningar",
			url: "",
		},
		{
			text: "Aktivitet",
			url: "activity",
		},
	]

	if (!currentControl && !checks) {
		return <Loader />
	}

	return (
		<>
			<PageHeader
				title={
					<Title
						controlIndex={controlIndex}
						currentControl={currentControl}
						controlIsFreezed={controlIsFreezed}
						controlIsApprovable={controlIsApprovable}
						// checksCount={checks && checks.length}
						sendForApproval={sendForApproval}
						onUpdate={onUpdate}
					/>
				}>
				<Options
					projectId={currentProject && currentProject._id}
					controlId={controlId}
					currentControl={currentControl}
					controlIsFreezed={controlIsFreezed}
					// onDelete={onDelete}
				/>
			</PageHeader>

			<SubNavigation links={subRoutes} />

			<Routes>
				<Route
					path="*"
					element={
						<Checks
							// projectCategories={currentProject.categories}
							currentControl={currentControl}
							onUpdate={onUpdate}
							rows={checks as ICheck[]}
							controlIsFreezed={controlIsFreezed}
						/>
					}
				/>
				<Route path="activity" element={<ControlActivities />} />
			</Routes>

			{/* <Can do="create" on="Control">
				{() => ( */}
			<Routes>
				<Route path="/check/:checkId" element={<CheckSidebar currentControl={currentControl} currentProject={currentProject} />} />
			</Routes>
			{/* )}
			</Can> */}
		</>
	)
}

export default Control
