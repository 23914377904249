import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { useSelector } from "react-unistore"
import { Block } from "baseui/block"
import { Button, KIND } from "baseui/button"
import { H3 } from "baseui/typography"
import { useStyletron } from "baseui"

import { getKeysFromArray } from "utils/misc"

import FormInput from "components/Form/FormInput"
import FormSelect from "components/Form/FormSelect"
import FormTextarea from "components/Form/FormTextarea"
import { IState } from "store"
import { ICheck } from "types/ICheck"

interface Props {
	onCreate: Function
	onClose: Function
}

const CheckForm: React.FC<Props> = ({ onCreate, onClose }) => {
	const [css] = useStyletron()
	const { handleSubmit, register, errors, control, setValue } = useForm()
	const currentProject = useSelector((state: IState) => state.currentProject)

	const onSubmit = (values: ICheck) => {
		if (values.sector) {
			values.sector = getKeysFromArray(values.sector)[0]._id
		}
		// if (values.category) {
		// 	values.category = getKeysFromArray(values.category)[0]._id
		// }

		onCreate(values)
	}

	if (!currentProject) return null

	return (
		<Block padding={["scale700", "scale700", "scale900"]}>
			<H3>Ny redovisning</H3>
			{/* <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormInput
					label="Rubrik"
					fieldError={errors.title}
					placeholder="Rubrik"
					// cge="checkTitle"
					name="title"
					inputRef={register({
						required: "Fältet är obligatoriskt.",
					})}
					autoFocus
				/>
				<Controller
					render={props => (
						<FormTextarea
							{...props}
							fieldError={errors.description}
							label="Beskrivning"
							placeholder="Skriv din beskrivning här..."
							rows={3}
							id="description"
							name="description"
							onChange={(e: React.BaseSyntheticEvent) => setValue("description", e.target.value, { shouldValidate: true })}
						/>
					)}
					control={control}
					name="description"
					rules={{ required: "Fältet är obligatoriskt" }}
					defaultValue=""
				/>
				{/* <FormSelect
											options={[
												{
													key: "5e9c2a2a91ebfc8efe39473d",
													label: "Sektor 1",
												},
												{
													key: "5e9c2a2cfc28c15494d172a1",
													label: "Sektor 2",
												},
											]}
											// value={[]}
											// clearable={false}
											// searchable={false}
											// size="compact"
											placeholder="Välj behörighet"
											onChange={params => console.log(params)}
										/> */}

				<Controller
					render={props => (
						<FormSelect
							options={currentProject.sectors}
							fieldError={errors.sector}
							value={props.value}
							label="Sektor/plats"
							clearable={false}
							searchable={false}
							valueKey="title"
							labelKey="title"
							placeholder={props.value ? "" : "Välj ett alternativ"}
							onChange={params => setValue("sector", params.value, { shouldValidate: true })}
						/>
					)}
					control={control}
					rules={{ required: "Du måste välja ett alternativ" }}
					name="sector"
					defaultValue={[]}
				/>
				{/* <Controller
					render={props => (
						<FormSelect
							options={currentProject.categories}
							fieldError={errors.category}
							value={props.value}
							label="Kategori"
							clearable={false}
							searchable={false}
							valueKey="_id"
							labelKey="title"
							placeholder={props.value ? "" : "Välj ett alternativ"}
							onChange={params => setValue("category", params.value, { shouldValidate: true })}
						/>
					)}
					control={control}
					rules={{ required: "Du måste välja ett alternativ" }}
					name="category"
				/>*/}

				<Block
					marginTop="scale800"
					className={css({
						textAlign: "right",
					})}>
					<Button kind={KIND.tertiary} onClick={() => onClose()}>
						Avbryt
					</Button>
					<Button type="submit">Skapa redovisning</Button>
				</Block>
			</form>
		</Block>
	)
}

export default CheckForm
