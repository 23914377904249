import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-unistore"
import { useAbility } from "@casl/react"
import { Navigation, Item } from "baseui/side-navigation"
import { StatefulTooltip } from "baseui/tooltip"
import { Block } from "baseui/block"
import { BookOpen, Briefcase, FileText, Settings, User, Users } from "react-feather"

import { IState } from "store"
import { customStyled } from "config/theme"

import { AbilityContext } from "components/Can"

import UserAccount from "../UserAccount"
import SidebarHeader from "./SidebarHeader"
import BottomLinks from "./BottomLinks"
import { useStyletron } from "baseui"
import { CustomRoute } from "types/shared"

export interface Props {
	sidebarIsOpen: boolean
	setSidebarIsOpen: any
	// onToggle: any
	projectId: string
}

const Sidebar: React.FC<Props> = ({ sidebarIsOpen, setSidebarIsOpen, projectId }) => {
	const [css] = useStyletron()
	const navigate = useNavigate()
	const params = useParams()
	const ability = useAbility(AbilityContext)
	const [currentLinks, setCurrentLinks] = useState<Item[]>([])
	const profileAbilities = useSelector((state: IState) => state.profileAbilities)

	const links = useMemo(
		() => [
			{
				text: "Projektplan",
				icon: <BookOpen size="16" className={css({ marginRight: "12px", opacity: ".8" })} />,
				path: ".",
			},
			{
				text: "Projektdeltagare",
				icon: <Users size="16" className={css({ marginRight: "12px", opacity: ".8" })} />,
				path: "members",
				conditions: { do: "view", on: "Members" },
			},
			{
				text: "Projekthandlingar",
				icon: <FileText size="16" className={css({ marginRight: "12px", opacity: ".8" })} />,
				path: "documents",
				conditions: { do: "view", on: "Documents" },
			},
			{
				text: "Min profil",
				icon: <User size="16" className={css({ marginRight: "12px", opacity: ".8" })} />,
				path: profileAbilities && `profile/${profileAbilities._id}`,
			},
			{
				text: "Mitt företag",
				icon: <Briefcase size="16" className={css({ marginRight: "12px", opacity: ".8" })} />,
				path: profileAbilities && `company/${profileAbilities.company._id}`,
			},
			{
				text: "Inställningar",
				icon: <Settings size="16" className={css({ marginRight: "12px", opacity: ".8" })} />,
				path: "settings",
				conditions: { do: "view", on: "Settings" },
			},
		],

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[profileAbilities],
	)

	const prepareLink = (link: CustomRoute): Item => {
		const title = (
			<Block display="flex" alignItems="center" height="scale800">
				{sidebarIsOpen ? (
					<>
						<span style={{ opacity: 0.4, height: "20px" }}>{link.icon}</span>
						<span>{link.text}</span>
					</>
				) : (
					<StatefulTooltip content={() => <Block>{link.text}</Block>} showArrow>
						<>{link.icon}</>
					</StatefulTooltip>
				)}
			</Block>
		)

		return {
			title: title,
			itemId: link.path,
		}
	}

	useEffect(() => {
		if (!links) return

		let mappedLinks: Item[] = []

		for (let i = 0; i < links.length; i++) {
			const link: any = links[i]

			if (link.conditions) {
				if (ability.can(link.conditions.do, link.conditions.on)) {
					mappedLinks.push(prepareLink(link))
				}
			} else {
				mappedLinks.push(prepareLink(link))
			}
		}

		setCurrentLinks(mappedLinks)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sidebarIsOpen, profileAbilities, links])

	return (
		<Aside id="sidebar" sidebarIsOpen={sidebarIsOpen}>
			<SidebarHeader setSidebarIsOpen={setSidebarIsOpen} />

			{projectId && (
				<Navigation
					overrides={{
						Root: {
							style: ({ $theme }) => ({
								width: "100%",
								paddingTop: $theme.sizing.scale400,
							}),
						},
						NavItem: {
							style: ({ $active }) => {
								if ($active) {
									return {
										fontWeight: 500,
										borderLeftColor: "transparent",
										background: "white",
										// textDecoration: "underline",
									}
								}
								return {
									fontWeight: 400,
								}
							},
						},
					}}
					items={currentLinks}
					activeItemId={params["*"] === "" ? "." : params["*"]}
					onChange={({ event, item }) => {
						event.preventDefault()
						navigate(item.itemId)
					}}
				/>
			)}

			<BottomLinks />
			<UserAccount />
		</Aside>
	)
}

export default Sidebar

const Aside = customStyled<"div", { sidebarIsOpen: boolean }>("div", ({ $theme, sidebarIsOpen }) => ({
	position: "fixed",
	display: "flex",
	width: $theme.custom.sidebarWidth,
	flexDirection: "column",
	alignItems: "center",
	top: 0,
	left: 0,
	height: "100%",
	backgroundColor: $theme.custom.sidebarBackgroundColor,
	paddingBottom: $theme.sizing.scale800,
	transform: sidebarIsOpen ? "translateX(0)" : "translateX(-100%)",
	transition: "all 0.3s",
	zIndex: 100,
	boxShadow: "rgb(0 0 0 / 16%) 0px 4px 16px",

	[$theme.mediaQuery.medium]: {
		boxShadow: "none",
	},
}))
