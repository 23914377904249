// *** Validation ***
export const emailValidation = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
export const ID06Validation = /^[a-zA-Z]{2}-?\d{4}-?\d{6}$/
export const corporateIdentityValidation = /^\d{6}(?:\d{2})?-?\d{4}$/
export const swedishPhoneValidation = /0([-\s]?\d){6,10}/

export const emailValidationPattern = {
	value: emailValidation,
	message: "E-postadressen har fel format",
}

// *** Helpers ***
export const maskID06 = (e: React.FocusEvent<HTMLInputElement>, setValue: Function): void => {
	let val = e.target.value

	if (val === "" || val === "--") {
		return
	}

	if (val.indexOf("-") === -1) {
		let first = e.target.value.slice(0, 2) //?
		let second = e.target.value.slice(2, 6) //?
		let third = e.target.value.slice(6, val.length) //?
		setValue("ID06", `${first.toUpperCase()}-${second}-${third}`)
	}
}


export const maskCorporateIdentity = (e: React.FocusEvent<HTMLInputElement>, setValue: Function): void => {
	let val = e.target.value

	if (val.match(corporateIdentityValidation) && val.indexOf("-") === -1) {
		let start = e.target.value.slice(0, val.length - 4)
		let end = e.target.value.slice(val.length - 4, val.length)
		setValue("corporateIdentity", `${start}-${end}`)
	}
}



// export const maskCorporateIdentity = (e, setValue) => {
// 	let val = e.target.value

// 	if (val.match(corporateIdentityValidation) && val.indexOf("-") === -1) {
// 		let start = e.target.value.slice(0, val.length - 4)
// 		let end = e.target.value.slice(val.length - 4, val.length)
// 		setValue("corporateIdentity", `${start}-${end}`)
// 	}
// }
