import { useQuery, useMutation, queryCache } from "react-query"
import { getNotifications, markAllAsRead, markAsRead, MarkAsReadVariables } from "services/notifications"
import { INotification } from "types/INotification"

export default function useNotifications() {
	const { data: notifications, status, error, isFetching } = useQuery<INotification[], Error>("notifications", getNotifications)

	const [_markAllAsRead] = useMutation(markAllAsRead, {
		onSettled: () => {
			queryCache.invalidateQueries("notifications")
		},
	})
	const [_markAsRead] = useMutation<any, Error, MarkAsReadVariables>(markAsRead, {
		onSettled: () => {
			queryCache.invalidateQueries("notifications")
		},
	})

	return {
		notifications,
		status,
		error,
		isFetching,

		markAllAsRead: _markAllAsRead,
		markAsRead: _markAsRead,
	}
}
