import { Link } from "react-router-dom"
import { useStyletron } from "baseui"
import { customStyled } from "config/theme"

const Logo: React.FC = () => {
	const [css] = useStyletron()
	return (
		<Link to="/" className={css({ display: "block" })}>
			<LogoWrapper>
				<LogoImage src="/assets/img/logo.svg" alt="" />
			</LogoWrapper>
		</Link>
	)
}

export default Logo

const LogoImage = customStyled("img", () => ({
	height: "24px",
	width: "auto",
	maxWidth: "100%",
	maxHeight: "100%",
}))

const LogoWrapper = customStyled("span", () => ({
	position: "relative",
	display: "flex",
	alignItems: "center",
	maxWidth: "100%",
	height: "56px",
}))
