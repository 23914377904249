import * as React from "react"
import { useParams } from "react-router-dom"

import Activities from "components/Activities"

const ControlActivities = () => {
	const { controlId } = useParams()

	return (
		<React.Fragment>
			<Activities entity="control" id={controlId} />
		</React.Fragment>
	)
}

export default ControlActivities
