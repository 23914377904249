import { instance as axios } from "config/axios"

export type GetTasksVariables = { checkId: string }
export type GetTaskVariables = { taskId: string }
export type CreateTaskVariables = { checkId: string, updatedData: any }
export type UpdateTaskVariables = { taskId: string, updatedData: any }
export type DeleteTaskVariables = { taskId: string }
export type SendTaskVariables = { taskId: string }
export type ApproveTaskVariables = { taskId: string, updatedData: any }

export async function getTasks(key: string, { checkId }: GetTasksVariables) {
	const { data } = await axios.get(`/checks/${checkId}/tasks`)
	return data
}

export async function getTask(key: string, { taskId }: GetTaskVariables) {
	const { data } = await axios.get(`/tasks/${taskId}`)
	return data
}

export async function createTask({ checkId, updatedData }: CreateTaskVariables) {
	const { data } = await axios.post(`/checks/${checkId}/tasks/`, updatedData)
	return data
}

export async function updateTask({ taskId, updatedData }: UpdateTaskVariables) {
	// console.log("!!!!!!!!",taskId, updatedData)
	// console.log("%cUpdating", "color: green;", taskId, updatedData)
	const { data } = await axios.patch(`/tasks/${taskId}`, updatedData)
	return data
}

export async function deleteTask({ taskId }: DeleteTaskVariables) {
	const { data } = await axios.delete(`/tasks/${taskId}`)
	return data
}

export async function sendTaskForApproval({ taskId }: SendTaskVariables) {
	const { data } = await axios.post(`/tasks/${taskId}/sendForApproval/`)
	return data
}

export async function approveTask({ taskId, updatedData }: ApproveTaskVariables) {
	const { data } = await axios.post(`/tasks/${taskId}/approval/`, updatedData)
	return data
}
