import * as React from "react"
import { Modal, ModalHeader, ModalBody, SIZE, ROLE } from "baseui/modal"
import { CheckCircle, Download } from "react-feather"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Button, KIND, SIZE as BUTTONSIZE } from "baseui/button"
import { saveAs } from "file-saver"

import GlobalContext from "context/GlobalContext"
import { buildFilePath } from "utils/misc"
import { instance } from "config/axios"

import Loader from "components/Loader"
import { EntityStatus } from "types/enums"
import { ModalProps } from "containers/ModalContainer"
import { IDocument } from "types/IDocument"

const ViewDocument: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const [css, theme] = useStyletron()
	const { modalPayload } = React.useContext(GlobalContext)
	const currentDocument: IDocument = modalPayload.currentDocument as any
	const [isLoading, setIsLoading] = React.useState(false)

	if (!currentDocument) {
		return <Loader />
	}

	const onDownload = async () => {
		const path = buildFilePath(currentDocument)
		setIsLoading(true)
		const { data } = await instance({
			url: `${path}?download=true`,
			method: "GET",
			responseType: "blob",
		})
		setIsLoading(false)
		saveAs(data, currentDocument.name)
	}

	return (
		<Modal
			onClose={closeModal}
			closeable
			isOpen={isOpen}
			animate
			autoFocus
			unstable_ModalBackdropScroll
			size={SIZE.full}
			role={ROLE.dialog}
			overrides={{
				Dialog: {
					style: {
						width: "80vw",
						// height: "80vh",
						display: "flex",
						flexDirection: "column",
						// height: "100%",
					},
				},
				Root: {
					style: {
						zIndex: 100,
					},
				},
			}}>
			<ModalHeader>
				<span
					className={css({
						// whiteSpace: "nowrap",
						wordWrap: "break-word",
						// overflow: "hidden",
						// textOverflow: "ellipsis",
						display: "block",
					})}>
					{currentDocument.status && currentDocument.status === EntityStatus.approved && <CheckCircle size="16" />}
					{currentDocument && currentDocument.name}
				</span>
				<Button size={BUTTONSIZE.mini} kind={KIND.secondary} isLoading={isLoading} endEnhancer={<Download size="16" />} onClick={onDownload}>
					Ladda ner
				</Button>
			</ModalHeader>
			<ModalBody
				className={css({
					height: "100%",
				})}>
				{!currentDocument ? (
					<Loader />
				) : (
					<Block
						className={css({
							backgroundColor: theme.colors.mono200,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100%",
						})}>
						{currentDocument.mimeType === "application/pdf" ? (
							<embed
								src={buildFilePath(currentDocument)}
								className={css({
									maxHeight: "100%",
									maxWidth: "100%",
									width: "100%",
									height: "100%",
								})}
								type="application/pdf"></embed>
						) : (
							<img
								src={buildFilePath(currentDocument)}
								alt=""
								className={css({
									maxHeight: "100%",
									maxWidth: "100%",
									width: "auto",
									height: "auto",
								})}
							/>
						)}
					</Block>
				)}
			</ModalBody>
			{/* <ModalFooter></ModalFooter> */}
		</Modal>
	)
}

export default ViewDocument
