import * as React from "react"


export default function useHover<T extends HTMLElement>() {
	const [value, setValue] = React.useState(false)
	const ref = React.useRef<T>(null)

	const handleMouseOver = () => setValue(true)
	const handleMouseOut = () => setValue(false)

	React.useEffect(
		() => {
			const node = ref.current
			if (node) {
				// node.addEventListener("mouseenter", handleMouseOver)
				// node.addEventListener("mouseleave", handleMouseOut)

				// return () => {
				// 	node.removeEventListener("mouseenter", handleMouseOver)
				// 	node.removeEventListener("mouseleave", handleMouseOut)
				// }
				node.addEventListener("mouseover", handleMouseOver)
				node.addEventListener("mouseout", handleMouseOut)

				return () => {
					node.removeEventListener("mouseover", handleMouseOver)
					node.removeEventListener("mouseout", handleMouseOut)
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ref.current], // Recall only if ref changes
	)

	return [ref, value]
}
