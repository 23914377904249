import { styled } from "stitches.config"

export const Button = styled("button", {
	backgroundColor: "$primary",
	color: "white",
	fontSize: 16,
	lineHeight: "1",
	letterSpacing: 1,
	fontWeight: 500,
	padding: "15px 22px",
	cursor: "pointer",
	border: "0",
	transition: ".3s all ease-in-out",
	margin: "1rem auto",

	":hover, :focus": {
		transition: ".3s all ease-in-out",
		backgroundColor: "#000"
	},

	bp3: {
		padding: "20px 32px",
		fontSize: 20,
		margin: "2rem auto",
	}
})



// interface Props {
// 	isLoading: boolean
// 	login: Function
// }

// const Button: React.FC<Props> = ({ isLoading, login, children }) => {
// 	return (
// 		<button
// 			style={{
// 				background: "black",
// 				padding: "16px 22px",
// 				outline: "none",
// 				color: "white",
// 				borderRadius: "32px",
// 				fontFamily: "sans-serif",
// 				border: "none",
// 				cursor: "pointer",
// 				opacity: isLoading ? "0.5" : "1",
// 			}}
// 			onClick={() => login()}>
// 			{children}
// 		</button>
// 	)
// }

// export default Button
