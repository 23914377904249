import { Actions } from "config/abilities";
import { ColumnInstance } from "react-table"
import { UserPositions } from "utils/enums"
import { AbilityPermissions, ObjectTypesArray } from "./enums"
import { IProfile } from "./IProfile"



export interface Object {
	hasOwnProperty<T>(this: T, v: any): v is keyof T
}

export enum NodeEnum {
	section = "section",
	group = "group",
	control = "control",
};
export type NodeType = keyof typeof NodeEnum;
export type ActivityEntities = "user" | "profile" | "company" | "control" | "check";

export interface CreatedBy extends Object {
	_id: string
	fullName: string
	photoUrl: string
	user: string
	position: keyof typeof UserPositions[]
	company: string
}

export interface ApprovedBy extends CreatedBy { }
export interface RequestedBy extends CreatedBy { }

export interface StrippedMember extends Object {
	_id: IProfile["_id"]
	firstName: IProfile["firstName"]
	lastName: IProfile["lastName"]
	fullName: IProfile["fullName"]
}

export interface IEmbeddedMember extends Object {
	_id: IProfile["_id"]
	fullName: IProfile["fullName"]
	photoUrl: IProfile["photoUrl"]
	user: IProfile["user"]
	permissions: typeof AbilityPermissions[number]
	// position: CreatedBy["position"],
}

export interface IActor extends Object {
	_id: string
	path: string // /p/{projectId}/profile/actorId
	displayName: string // "Hector Romo"
	objectType: string // "profile",
	photoUrl: string
	company: string
}

// // profile|project|control|check|task|document|photo|company|comment?
export interface IObject extends Object {
	_id: string
	title: string
	path: string // ,
	photoUrl: string
	objectType: typeof ObjectTypesArray[number] // "photo"
}

export interface ITarget extends Object {
	_id: string
	title: string // "Martin's Photo Album",
	path: string
	image?: {
		url: string // https...
	}
	objectType: typeof ObjectTypesArray[number]
}

// export interface FileObject extends Object {
// 	file: Formidable.File
// 	data: Buffer
// }

export interface IDragData {
	oldIndex: number
	newIndex: number
	oldParent?: string
	newParent?: string
}

export interface IStatusColor {
	color: String
	label: string
}

// REACT TABLE TYPES
export interface CustomColumnInstance<T extends object> extends ColumnInstance<T> {
	getFilterLabel?: (value: string) => string
}

export interface ArrayOption {
	value: string | boolean
	label: string
}

export interface SortingOption {
	label: string
	value: string
	desc: boolean
	// order: "asc" | "desc"
}

export interface RouteCondition {
	do: Actions
	on: any
}

export interface CustomRoute {
	path: string
	element?: any
	icon?: JSX.Element
	text?: string | JSX.Element
	url?: string
	conditions?: RouteCondition
}

// export interface MenuLink {
// 	title: string | JSX.Element
// 	itemId: string
// 	icon?: JSX.Element
// 	conditions?: ICondition
// }