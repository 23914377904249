import * as React from "react"
import { MutationResult } from "react-query"

// import { ContentBlockAction, ContentBlockSubmitButton } from "components/ContentBlock"
import List from "./List"

interface Props {
	items: ListItem[]
	onSave: Function
	updateProjectInfo: MutationResult<any>
}

export interface ListItem {
	_id: string
	title: string
}

const DndList: React.FC<Props> = ({ items, onSave, updateProjectInfo }) => {
	const onUpdate = (updatedList: any[], removed: boolean) => {
		onSave(updatedList, removed)
	}

	return (
		<React.Fragment>
			<List items={items} onUpdate={onUpdate} />
		</React.Fragment>
	)
}

export default DndList
