import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"
import { Button, KIND, SIZE } from "baseui/button"
import { MutationResult } from "react-query"

import { maskID06, ID06Validation } from "utils/formHelpers"
import { useMsal } from "auth/hooks/useMsal"
import useUser from "hooks/useUser"

import FormInput from "components/Form/FormInput"
import FormTextarea from "components/Form/FormTextarea"
import { ContentBlockAction, ContentBlockSubmitButton } from "components/ContentBlock"
import FormSwedishPhone from "components/Form/FormSwedishPhone"
import { IUser } from "types/IUser"

interface Props {
	currentUser: IUser
	onUpdate: Function
	updateUserInfo: MutationResult<any>
}

const AccountForm: React.FC<Props> = ({ currentUser, onUpdate, updateUserInfo }) => {
	const { handleSubmit, register, errors, setValue, control } = useForm({})
	const { removeUser, removeUserInfo } = useUser()
	const auth = useMsal()

	React.useEffect(() => {
		if (currentUser) {
			setValue("firstName", currentUser.firstName)
			setValue("lastName", currentUser.lastName)
			setValue("ID06", currentUser.ID06)
			setValue("phone", currentUser.phone)
			setValue("personalContacts", currentUser.personalContacts)
		}
	}, [currentUser, setValue])

	const onSave = (values: IUser) => {
		onUpdate(values)
	}

	const removeAccount = async () => {
		if (window.confirm(`Är du säker på att du vill ta bort ditt konto?`)) {
			if (window.confirm(`Ditt användarkonto, profiler samt projekt kommer att raderas. Vill du fortsätta?`)) {
				await removeUser()
				alert("Ditt användarkonto har raderats! Du loggas ut.")
				auth.logout()
			}
			// const response = await removeControlMember({ controlId, memberId: member._id })
			// console.log("REDSPONSDFOSJdf", response)
			// if (!response) return
			// setCurrentControl(response)
		}
	}

	const isLoading = updateUserInfo.status === "loading" ? true : false

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<FlexGrid
				flexGridColumnCount={[1]}
				// flexGridColumnCount={[1, 1, 1, 2]}
				flexGridColumnGap="scale800"
				flexGridRowGap={["scale200"]}
				// flexGridRowGap={["scale200", "scale200", "scale600"]}
			>
				<FlexGridItem>
					<FormInput label="E-post" id="email" name="email" value={currentUser.email} disabled />
				</FlexGridItem>
				<FlexGridItem>
					<Button
						size={SIZE.mini}
						type="button"
						onClick={() => auth.resetPassword()}
						overrides={{
							BaseButton: {
								style: ({$theme}) => ({
									marginBottom: $theme.sizing.scale400,
								}),
							},
						}}
						kind={KIND.tertiary}>
						Återställa lösenord
					</Button>
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="Förnamn"
						fieldError={errors.firstName}
						placeholder="Förnamn"
						id="firstName"
						name="firstName"
						inputRef={register({
							required: "Fältet är obligatoriskt.",
						})}
					/>
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="Efternamn"
						fieldError={errors.lastName}
						placeholder="Efternamn"
						id="lastName"
						name="lastName"
						inputRef={register({
							required: "Fältet är obligatoriskt.",
						})}
					/>
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="ID06"
						fieldError={errors && errors.ID06}
						placeholder="XX-1234-123456"
						id="ID06"
						name="ID06"
						clearable={true}
						max={14}
						inputRef={register({
							pattern: {
								value: ID06Validation,
								message: "Fel format på ID06. XX-1234-123456",
							},
							maxLength: 14,
						})}
						onBlur={e => maskID06(e, setValue)}
					/>
					{/* <FormInput
						label="ID06"
						error={errors.ID06}
						placeholder="XX-1234-123456"
						id="ID06"
						name="ID06"
						inputRef={register({ pattern: /[a-zA-Z]{2}-?\d{4}-?\d{6}/g })}
					/> */}
				</FlexGridItem>
				<FlexGridItem>
					{/* <FormSwedishPhone /> */}
					<FormSwedishPhone errors={errors} register={register} setValue={setValue} />
					{/* <FormInput
						label="Telefonnummer"
						fieldError={errors.lastName}
						placeholder="070-1234578"
						id="phone"
						name="phone"
						inputRef={register}
					/> */}
				</FlexGridItem>
				<FlexGridItem>
					<Controller
						render={props => (
							<FormTextarea
								{...props}
								fieldError={errors.personalContacts}
								label="Anhöriglista"
								placeholder="..."
								id="personalContacts"
								name="personalContacts"
								onChange={(e: React.BaseSyntheticEvent) => setValue("personalContacts", e.target.value, { shouldValidate: true })}
							/>
						)}
						control={control}
						// rules={{ required: "Fältet är obligatoriskt" }}
						name="personalContacts"
						defaultValue=""
					/>
				</FlexGridItem>
			</FlexGrid>
			<ContentBlockAction>
				<ContentBlockSubmitButton disabled={isLoading} isLoading={isLoading} type="submit">
					Spara
				</ContentBlockSubmitButton>
				<ContentBlockSubmitButton
					disabled={removeUserInfo.isLoading}
					isLoading={removeUserInfo.isLoading}
					kind="minimal"
					type="button"
					style={{ marginTop: "20px" }}
					onClick={() => removeAccount()}>
					Radera konto
				</ContentBlockSubmitButton>
			</ContentBlockAction>
		</form>
	)
}

export default AccountForm
