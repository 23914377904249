import { Button } from "baseui/button"
import { customStyled } from "config/theme"
import { Menu } from "react-feather"
import Logo from "../Logo"

interface Props {
	setSidebarIsOpen: any
}

const SidebarHeader: React.FC<Props> = ({ setSidebarIsOpen }) => {
	return (
		<StyledSidebarHeader>
			<Logo />
			<Button
				overrides={{
					BaseButton: {
						style: () => ({
							opacity: ".5",
							transition: "0.3s all",
							":hover": {
								transition: "0.3s all",
								opacity: 1,
							},
						}),
					},
				}}
				kind="minimal"
				size="mini"
				onClick={() => setSidebarIsOpen(false)}>
				<Menu size="16" />
			</Button>
		</StyledSidebarHeader>
	)
}

export default SidebarHeader

const StyledSidebarHeader = customStyled("div", ({ $theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	paddingLeft: $theme.sizing.scale850,
	paddingRight: $theme.sizing.scale300,
}))
