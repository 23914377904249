import * as React from "react"

import { IProfile } from "types/IProfile"

import Activities from "components/Activities"

const UserProfileFeed: React.FC<{ userProfile: IProfile }> = ({ userProfile }) => {
	if (!userProfile) return null

	// if (isLoading) {
	// 	return (
	// 		<div>
	// 			<Skeleton count="10" />
	// 		</div>
	// 	)
	// }

	return (
		<div>
			<Activities entity="profile" id={userProfile._id} blockMode={true} />
		</div>
	)
}

export default UserProfileFeed
