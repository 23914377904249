import * as React from "react"
// import { queryCache } from "react-query"
import { ColumnInstance } from "react-table"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Tag, KIND, VARIANT } from "baseui/tag"
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography"

import { IProjectPlan, IProjectPlanSection, IProjectPlanGroup, IProjectPlanControl } from "types/IProjectPlan"
import { buildTree } from "utils/tree"
import StyledLink from "components/StyledLink"
// import useControls from "hooks/useControls"

export interface Props {
	column: ColumnInstance<IProjectPlanControl>
	controls: IProjectPlanControl[]
	projectPlan: IProjectPlan | undefined
}

const Plan = ({ column, projectPlan, controls }: Props) => {
	const { filterValue, setFilter } = column
	const [css, theme] = useStyletron()
	const [plan, setPlan] = React.useState<IProjectPlanSection[]>()

	// Fetch projectplan
	const build = React.useCallback(buildTree, [projectPlan, controls])

	React.useEffect(() => {
		if (!controls || !projectPlan) return

		const updatedPlan: IProjectPlan = Object.assign(projectPlan)
		const data: IProjectPlanSection[] | null = build(updatedPlan, controls, false)
		if (data) {
			setPlan(data)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [controls, projectPlan])

	// // Render a multi-select box
	const onChange = (control: IProjectPlanControl, isAdding: boolean) => {
		let newValue: any[] = filterValue || []

		if (isAdding) {
			newValue.push(control._id)
		} else {
			// Remove value from filterValue
			newValue = newValue.filter((v: string) => v !== control._id)
		}

		if (newValue.length) {
			setFilter(newValue)
		} else {
			setFilter(undefined)
		}
	}

	const clearFilter = () => {
		setFilter(undefined)
	}

	if (!projectPlan || !controls || !plan) return null

	return (
		<Block padding="scale600" backgroundColor="white" width="400px">
			{plan.map((section: IProjectPlanSection, i: number) => (
				<Block key={i}>
					<ParagraphXSmall
						className={css({
							padding: 0,
							marginTop: 0,
							marginBottom: theme.sizing.scale200,
							fontWeight: 500,
						})}>
						{section.title}
					</ParagraphXSmall>
					{section.groups.map((group: IProjectPlanGroup, j: number) => {
						return (
							<div key={j}>
								<Block
									backgroundColor="mono400"
									paddingTop="scale100"
									paddingBottom="scale100"
									paddingLeft="scale400"
									paddingRight="scale200"
									marginTop="scale200">
									<ParagraphXSmall padding="0" margin="0">
										{group.index}. {group.title}
									</ParagraphXSmall>
								</Block>
								{group.controls.map((control: IProjectPlanControl, k: number) => {
									const isActive = filterValue && filterValue.some((el: any) => el === control._id)

									return (
										<Block marginLeft="scale800" key={k}>
											{isActive ? (
												<Tag
													kind={KIND.accent}
													variant={VARIANT.solid}
													overrides={{
														Root: {
															style: () => ({
																marginTop: "0",
																marginBottom: "0",
																outline: "none",
															}),
														},
														Text: {
															style: () => ({
																fontSize: "12px",
															}),
														},
													}}
													onActionClick={e => onChange(control, false)}>
													{group.index}.{control.index} {control.title}
												</Tag>
											) : (
												<span onClick={() => onChange(control, true)}>
													<ParagraphXSmall
														className={css({
															marginTop: theme.sizing.scale100,
															marginBottom: theme.sizing.scale100,
															cursor: "pointer",
														})}>
														{group.index}.{control.index} {control.title}
													</ParagraphXSmall>
												</span>
											)}
										</Block>
									)
								})}
							</div>
						)
					})}
					{filterValue && (
						<StyledLink onClick={clearFilter}>
							<ParagraphSmall marginBottom="0">Rensa filter</ParagraphSmall>
						</StyledLink>
					)}
				</Block>
			))}
		</Block>
	)
}

export default Plan
