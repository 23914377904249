import * as React from "react"
import { Provider as StyletronProvider } from "styletron-react"
import { Client as Styletron } from "styletron-engine-atomic"
import { BaseProvider } from "baseui"
import { Provider } from "react-unistore"

import store from "store"
import { lightTheme, darkTheme } from "./config/theme"
import { GlobalProvider } from "context/GlobalContext"
import { ViewportProvider } from "context/ViewportContext"
import { useMsal } from "auth/hooks/useMsal"

import AppAuthenticated from "./AppAuthenticated"
import AppUnauthenticated from "./AppUnauthenticated"

const engine = new Styletron()

const THEME = {
	light: "light",
	dark: "dark",
}

const App = () => {
	const [theme] = React.useState(THEME.light)
	const { isAuthenticated, inProgress } = useMsal()

	// console.log("The progress: ", inProgress)

	if (inProgress !== "none") {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
				<h1>
					<img src="/assets/img/icon.svg" alt="" />
				</h1>
			</div>
		)
	}

	// console.log("RENDERING")

	return (
		<StyletronProvider value={engine}>
			<BaseProvider theme={theme === THEME.light ? lightTheme : darkTheme}>
				{isAuthenticated ? (
					<Provider value={store}>
						<GlobalProvider>
							<ViewportProvider>
								{/* Add toggler for light/dark theme */}
								{/* <button
								style={{ position: "absolute", right: "0", zIndex: 1000 }}
								onClick={() => setTheme(theme === THEME.light ? THEME.dark : THEME.light)}>
								Toggle light/dark theme!
							</button> */}
								<AppAuthenticated />
							</ViewportProvider>
						</GlobalProvider>
					</Provider>
				) : (
					<AppUnauthenticated />
				)}
			</BaseProvider>
		</StyletronProvider>
	)
}

export default App
