import { useContext, useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE as MODALSIZE, ROLE } from "baseui/modal"
import { KIND } from "baseui/button"
import { useStyletron } from "baseui"
import { ParagraphSmall } from "baseui/typography"

import GlobalContext from "context/GlobalContext"
import useControlMembers from "hooks/useControlMembers"
import useControlStatus from "hooks/useControlStatus"
import useControlHelpers from "hooks/useControlHelpers"

import Can from "components/Can"
import Form from "./Form"
import MemberList from "./MemberList"
import { ModalProps } from "containers/ModalContainer"
import { IProfile } from "types/IProfile"
import { IControl } from "types/IControl"

const ControlPermissions: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const [css, theme] = useStyletron()
	const { modalPayload } = useContext(GlobalContext)
	const { controlId, projectId } = modalPayload

	const control: IControl = modalPayload.control as any
	const [currentControl, setCurrentControl] = useState<IControl>(control)
	const { controlIsFreezed } = useControlStatus(currentControl)
	const {
		// members,
		addControlMember,
		addControlMemberInfo,
		updateControlMember,
		updateControlMemberInfo,
		removeControlMember,
		removeControlMemberInfo,
	} = useControlMembers()
	const { controlIndex } = useControlHelpers(controlId || "", projectId || "")
	const members = currentControl && currentControl.members

	// React.useEffect(() => {
	// 	setCurrentControl(control)
	// }, [control])

	const onRemoveMember = async (member: IProfile) => {
		if (window.confirm(`Är du säker på att du vill ta bort ${member.fullName}?`)) {
			const response = await removeControlMember({ controlId: controlId || "", memberId: member._id })
			if (!response) return
			setCurrentControl(response)
		}
	}

	const onUpdate = async (userId: string, updatedData: IProfile) => {
		const response = await updateControlMember({ controlId: controlId || "", userId, updatedData })
		if (!response) return
		setCurrentControl(response)
	}

	const onSubmit = async (userId: string, updatedData: IProfile) => {
		const response = await addControlMember({ controlId: controlId || "", userId, updatedData })
		if (!response) return
		setCurrentControl(response)
	}

	const isLoading = updateControlMemberInfo.status === "loading" || removeControlMemberInfo.status === "loading"

	return (
		<Modal
			onClose={closeModal}
			closeable
			isOpen={isOpen}
			animate
			autoFocus
			unstable_ModalBackdropScroll
			size={MODALSIZE.default}
			role={ROLE.dialog}
			overrides={{
				Dialog: {
					style: {
						width: "100%",
						maxWidth: "600px",
					},
				},
			}}>
			<ModalHeader>
				Hantera ansvariga
				<ParagraphSmall color="mono600" marginTop="0" marginBottom="scale900">
					{currentControl ? `${controlIndex} ${currentControl.title}` : ""}
				</ParagraphSmall>
			</ModalHeader>
			<ModalBody>
				<Can do="create" on={currentControl} type="Member">
					{() =>
						!controlIsFreezed && (
							<Form
								projectId={projectId || ""}
								onSubmit={onSubmit}
								controlMembers={members}
								addControlMemberInfo={addControlMemberInfo}
							/>
						)
					}
				</Can>
				<div
					className={css({
						marginTop: theme.sizing.scale800,
						opacity: isLoading ? "0.5" : "1",
					})}>
					<MemberList
						members={members}
						projectId={projectId || ""}
						onRemoveMember={onRemoveMember}
						removeControlMemberInfo={removeControlMemberInfo}
						onUpdate={onUpdate}
						control={control}
						closeModal={closeModal}
						controlIsFreezed={controlIsFreezed}
					/>
				</div>

				{/* <div
					className={css({
						opacity: isLoading ? "0.5" : "1",
					})}>
					<MemberList
						members={members}
						projectId={projectId || ""}
						onRemoveMember={onRemoveMember}
						removeControlMemberInfo={removeControlMemberInfo}
						onUpdate={onUpdate}
						control={control}
						closeModal={closeModal}
						controlIsFreezed={controlIsFreezed}
					/>
				</div> */}
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Stäng
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default ControlPermissions
