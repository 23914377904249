export const ControlPermissions = {
	limited: "Begränsad",
	normal: "Normal",
	advanced: "Avancerad",
}

export const ControlPermissionsArray = Object.keys(ControlPermissions).map(function (key) {
	return { key: key, label: ControlPermissions[key] }
})

export const Permissions = {
	owner: "Projektägare",
	admin: "Projektadmin",
	manager: "Projektansvarig",
	member: "Projektmedlem",
	readOnly: "Läsbehörig",
}
export const PermissionsArray = Object.keys(Permissions).map(function (key) {
	return { key: key, label: Permissions[key] }
})

export const DocumentTypes = {
	constructionDocument: "Bygghandling",
	drawing: "Ritning",
	selfControl: "Egenkontroll",
}

export const DocumentTypesArray = Object.keys(DocumentTypes).map(function (key) {
	return { key: key, label: DocumentTypes[key] }
})

export const UserPositions = {
	KA: "Kontrollansvarig",
	// Projektörer
	A: "Arkitekt",
	E: "Elprojektör",
	F: "Förvaltare",
	G: "Geotekniker",
	I: "Inredningsarkitekt",
	K: "Byggnadskonstruktör",
	L: "Landskapsarkitekt",
	M: "Markprojektör",
	P: "Projekt- och entreprenadgemensamt",
	R: "Va-projektör",
	S: "Styr- och övervakningsprojektör",
	V: "V-projektör (ventilation/luftbehandling) eller VVS-projektör när och VS inte är åtskilda",
	W: "VS-projektör, när både V och VS finns.",

	// Entreprenörer
	BE: "Byggentreprenör",
	EE: "Elentreprenör",
	GE: "Generalentreprenör",
	HE: "Hissentreprenör",
	KE: "Kylentreprenör",
	ME: "Markentreprenör",
	RE: "Rörentreprenör",
	SE: "Styrentreprenör",
	TE: "Totalentreprenör",
	VE: "Ventilationsentreprenör",
}
export const UserPositionsArray = Object.keys(UserPositions && UserPositions).map(function (key) {
	return { key: key, label: UserPositions[key] }
})

export const ContractOptions = {
	// subcontractor: "Underentreprenör",
	turnkeyContract: "Totalentreprenad",
	controlledTurnkeyContract: "Styrd totalentreprenad",
	executionGeneralContract: "Uförande-/ Generalentreprenad",
	executionSharedContract: "Uförande-/ Delad entreprenad",
}
export const ContractOptionsArray = Object.keys(ContractOptions && ContractOptions).map(function (key) {
	return { key: key, label: ContractOptions[key] }
})

export const RegulatoryRequirements = {
	false: "Utan myndighetskrav",
	true: "Med myndighetskrav",
}

export const SettingsPrefix = {
	none: "Ingen",
	numeric: "Numrerad",
}
export const SettingsPrefixArray = Object.keys(SettingsPrefix && SettingsPrefix).map(function (key) {
	return { key: key, label: SettingsPrefix[key] }
})

export const statusColor = {
	approved: { color: "positive400", label: "Klar" },
	pending: { color: "warning300", label: "Inväntar godkännande" },
	disapproved: { color: "negative400", label: "Behöver åtgärdas" },
	ongoing: { color: "accent300", label: "Pågående" },
	empty: { color: "mono500", label: "Ej påbörjad" },
}

export const colorState = {
	done: "bg-success",
	started: "bg-primary",
	not_started: "",
	needs_approval: "bg-warning",
}
