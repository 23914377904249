import { instance as axios } from "config/axios"

export type GetControlDocumentsVariables = { projectId: string, controlId: string }
export type CreateProjectDocumentVariables = { projectId: string, files: File[] }
export type RemoveProjectDocumentVariables = { projectId: string, documentId: string }
export type RemoveProjectDocumentsVariables = { projectId: string, documentIds: string[] }

export async function getControlDocuments(key: string, { projectId, controlId }: GetControlDocumentsVariables) {
	const { data } = await axios.get(`/projects/${projectId}/documents?control=${controlId}`)
	return data
}

export async function getProjectDocuments<T>(key: string, id: string, query: any): Promise<T> {
	const { data } = await axios.get(`/projects/${id}/documents?${query}`)
	return data
}

export async function createProjectDocument<T>({ projectId, files }: CreateProjectDocumentVariables): Promise<T> {
	// export async function createProjectDocument(key, projectId, document) {
	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	}

	const result: any = []
	for (let index = 0; index < files.length; index++) {
		const file = files[index]
		const formData = new FormData()
		formData.append("file", file)
		const { data } = await axios.post(`/projects/${projectId}/documents`, formData, config)
		result.push(data)
	}

	return result
}

export async function removeProjectDocument({ projectId, documentId }: RemoveProjectDocumentVariables) {
	if (documentId) {
		const { data } = await axios.delete(`/projects/${projectId}/documents/${documentId}`)
		return data
	}
}

export async function removeProjectDocuments({ projectId, documentIds }: RemoveProjectDocumentsVariables) {
	if (!documentIds) {
		throw new Error("No document ids where provided")
	}

	const res = []
	for (let index = 0; index < documentIds.length; index++) {
		const documentId = documentIds[index]
		const { data } = await axios.delete(`/projects/${projectId}/documents/${documentId}`)
		res.push(data)
	}
	return res
}
