import * as React from "react"
import { Button, SHAPE, SIZE, KIND } from "baseui/button"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { StatefulTooltip } from "baseui/tooltip"
// import { Check as CheckIcon } from "react-feather"
import { MoreVertical, ChevronDown } from "react-feather"
import { StatefulPopover, PLACEMENT } from "baseui/popover"
import { StatefulMenu } from "baseui/menu"

import { ICheck } from "types/ICheck"
import { statusColor } from "utils/enums"

import PageHeader from "components/layout/PageHeader"
import Editable from "components/Editable"
import Can from "components/Can"
import { EntityStatus } from "types/enums"
// import { useAbility } from "@casl/react"
// import Status from "components/Status"

interface Props {
	checkIsFreezed: boolean
	checkIsApprovable: boolean
	controlIsFreezed: boolean
	currentCheck: ICheck
	// currentControl: IControl

	onUpdate: Function
	onDelete: Function
	sendForApproval: Function
	approve: Function
}

const CheckHeader: React.FC<Props> = ({
	checkIsFreezed,
	checkIsApprovable,
	controlIsFreezed,
	currentCheck,
	// currentControl,

	onUpdate,
	onDelete,
	sendForApproval,
	approve,
}) => {
	// const ability = useAbility(AbilityContext)
	// console.log('IS FREEZED',checkIsFreezed)

	const [css, theme] = useStyletron()
	if (!currentCheck) return null

	const onSave = (val: string) => {
		if (val === currentCheck.title) return
		onUpdate({ title: val })
	}

	// const approved = currentCheck.documents.filter(doc => doc.status === EntityStatus.approved)
	// const isApprovable = approved.length === currentCheck.documents.length

	const currentStatusColor = currentCheck.status ? statusColor[currentCheck.status] : statusColor["empty"]

	const title = (
		<Block display="flex" alignItems="center">
			<StatefulTooltip showArrow content={() => <Block>{currentStatusColor.label}</Block>} returnFocus autoFocus>
				<div
					className={css({
						width: "16px",
						height: "16px",
						borderRadius: "100%",
						backgroundColor: theme.colors[currentStatusColor.color],
						marginRight: theme.sizing.scale400,
					})}></div>
			</StatefulTooltip>
			{/* <Status status={currentCheck.status} />{" "} */}
			{/* {console.log(ability.can("update", currentCheck), checkIsFreezed)} */}
			<Can do="update" on={currentCheck} type="Check" passThrough>
				{(allowed: boolean) =>
					!allowed || checkIsFreezed ? (
						<div>
							{/* {console.log("Can not edit", allowed, checkIsFreezed)} */}
							{currentCheck.title}
						</div>
					) : (
						<div>
							{/* {console.log("Can edit", allowed, checkIsFreezed)} */}
							<Editable value={currentCheck.title} type="text" onUpdate={onSave} />
						</div>
					)
				}
			</Can>
		</Block>
	)

	return (
		<PageHeader title={title}>
			<div>
				<Can do="approve" on={currentCheck} type="Check" passThrough>
					{(allowed: boolean) =>
						allowed && checkIsApprovable && !controlIsFreezed ? (
							<StatefulPopover
								focusLock
								placement={PLACEMENT.bottomLeft}
								content={({ close }) => (
									<StatefulMenu
										items={[
											{
												label: "Godkänn",
												value: EntityStatus.approved,
											},
											{
												label: "Godkänn ej",
												value: EntityStatus.disapproved,
											},
										]}
										onItemSelect={response => {
											console.log(response)
											approve(response.item.value)
											close()
										}}
									/>
								)}>
								<Button shape={SHAPE.pill} size={SIZE.compact} kind={KIND.secondary} endEnhancer={() => <ChevronDown size={18} />}>
									Hantera status
								</Button>
							</StatefulPopover>
						) : (
							// <Button
							// 	shape={SHAPE.pill}
							// 	size={SIZE.mini}
							// 	kind={checkIsFreezed ? KIND.secondary : KIND.primary}
							// 	success={currentCheck.status === EntityStatus.approved ? "true" : "false"}
							// 	endEnhancer={() => <ChevronDown size={18} />}
							// 	onClick={approve}>
							// 	Hantera status
							// 	{/* {currentCheck.status === EntityStatus.approved ? "Godkänd" : "Godkänn"} */}
							// </Button>
							<Can do="sendForApproval" on={currentCheck} type="Check">
								{() =>
									checkIsApprovable && (
										<Button
											shape={SHAPE.pill}
											size={SIZE.compact}
											kind={checkIsFreezed ? KIND.secondary : KIND.primary}
											onClick={() => sendForApproval()}>
											Skicka för granskning
										</Button>
									)
								}
							</Can>
						)
					}
				</Can>
			</div>
			<Can do="delete" on={currentCheck} type="Check">
				{() => (
					<StatefulPopover
						content={() => (
							<StatefulMenu
								items={[
									{
										label: !checkIsFreezed ? (
											<span onClick={() => onDelete()}>Ta bort</span>
										) : (
											<span
												className={css({
													color: theme.colors.mono400,
												})}>
												Ta bort
											</span>
										),
									},
								]}
							/>
						)}
						returnFocus
						autoFocus>
						<Button
							overrides={{
								BaseButton: {
									style: () => ({
										marginLeft: theme.sizing.scale200,
									}),
								},
							}}
							shape={SHAPE.round}
							size={SIZE.compact}
							kind={KIND.secondary}
							disabled={checkIsFreezed}>
							<MoreVertical size="16" />
						</Button>
					</StatefulPopover>
				)}
			</Can>
		</PageHeader>
	)
}

export default CheckHeader
