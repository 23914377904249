import * as React from "react"

import GlobalContext from "context/GlobalContext"
import * as Modals from "components/Modals"
import { IDocument } from "types/IDocument"
import { IObject } from "types/shared"
import { ITaskAttachment, ITaskDocument } from "types/ITask"
import { IControl } from "types/IControl"
import { IProfile } from "types/IProfile"
import { IProjectPlanControl } from "types/IProjectPlan"

export enum ModalEnum {
	ControlPermissions = "ControlPermissions",
	ControlApproval = "ControlApproval",
	AddProjectMember = "AddProjectMember",
	AddCompany = "AddCompany",
	AddCompanyMember = "AddCompanyMember",
	BindUserProfileToCompany = "BindUserProfileToCompany",
	ViewDocument = "ViewDocument",
	UploadGlobalDocument = "UploadGlobalDocument",
	UploadTaskDocument = "UploadTaskDocument",
	UploadTaskAttachment = "UploadTaskAttachment",
	AddSection = "AddSection",
}

export type ModalTypes = keyof typeof ModalEnum | null

export interface ModalProps {
	closeModal: () => void
	isOpen: boolean
}

export type ModalPayload = {
	projectId?: string
	companyId?: string
	controlId?: string
	checkId?: string
	taskId?: string
	currentDocument?: IDocument | IObject | ITaskDocument | ITaskAttachment
	control?: IControl | IProjectPlanControl
	currentControl?: IControl | IProjectPlanControl
	userProfile?: IProfile
}

// const MODAL_TYPES = {
// 	// Control Modals:
// 	ControlPermissions: Modals.ControlPermissions,
// 	ControlApproval: Modals.ControlApproval,

// 	// Member Modals:
// 	AddProjectMember: Modals.AddProjectMember,
// 	AddCompany: Modals.AddCompany,
// 	AddCompanyMember: Modals.AddCompanyMember,
// 	BindUserProfileToCompany: Modals.BindUserProfileToCompany,

// 	// Document Modals:
// 	ViewDocument: Modals.ViewDocument,
// 	UploadGlobalDocument: Modals.UploadGlobalDocument,
// 	// UploadCheckDocument: Modals.UploadCheckDocument,
// 	UploadTaskDocument: Modals.UploadTaskDocument,
// 	UploadTaskAttachment: Modals.UploadTaskAttachment,

// 	AddSection: Modals.AddSection,
// }

const ModalContainer = () => {
	const [isOpen, setIsOpen] = React.useState(true)
	const { currentModal, closeModal } = React.useContext(GlobalContext)

	React.useEffect(() => {
		if (currentModal) {
			setIsOpen(true)
		}
	}, [currentModal])

	if (!currentModal) return null
	// const ActiveModal = MODAL_TYPES[currentModal]
	const ActiveModal = Modals[currentModal]

	// Use onClose to respect baseui modal close animation.
	const onClose = () => {
		setIsOpen(false)
		setTimeout(closeModal, 500)
	}

	return <ActiveModal closeModal={onClose} isOpen={isOpen} />
}

export default ModalContainer
