import { useQuery } from "react-query"
import { getProjectMember } from "services/projectMembers"
import { IProfile } from "types/IProfile"

// const defaultCallbacks = {
// 	onSuccess: ({ userId }) => {
// 		queryCache.invalidateQueries(["projectMember", { userId }])
// 	},
// }

export default function useProjectMember(projectId: string, userId: string) {
	const { data: member, isLoading, isFetching, status, error } = useQuery<IProfile, Error>(
		// ["projectMember", { userId }],
		["projectMember", { projectId, userId }],
		getProjectMember,
		{ enabled: projectId },
	)

	// const [removeMember, removeInfo] = useMutation(async ({ memberId }) => {
	// 	await removeProjectMember("removeProjectMember", {
	// 		projectId,
	// 		memberId,
	// 	})
	// }, defaultCallbacks)

	// const [addMember, addInfo] = useMutation(async ({ updatedData }) => {
	// 	await addProjectMember("addProjectMember", { projectId, updatedData })
	// }, defaultCallbacks)

	return {
		member,
		isLoading,
		isFetching,
		status,
		error,

		// removeMember,
		// removeInfo,

		// addMember,
		// addInfo,
	}
}
