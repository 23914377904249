import * as React from "react"
import { Routes, Route } from "react-router-dom"
import { StatefulTooltip } from "baseui/tooltip"
import { Info } from "react-feather"
import { Block } from "baseui/block"
import { ParagraphXSmall } from "baseui/typography"

import { IProject } from "types/IProject"

import PageHeader from "components/layout/PageHeader"
import SubNavigation from "components/layout/SubNavigation"
import PageLayout from "components/layout/PageLayout"
import Can from "components/Can"

import AddMember from "./AddMember"
import AddCompany from "./AddCompany"
import Members from "./Members"
import Companies from "./Companies"

interface Props {
	projectId: string
}

const MembersPage: React.FC<Props> = ({ projectId }) => {
	const subRoutes = [
		{
			text: "Personer",
			url: "",
		},
		{
			text: "Företag",
			url: "companies",
		},
	]

	// console.log("%c<Members />", "color: blue;")

	return (
		<React.Fragment>
			<PageHeader title="Projektdeltagare">
				<Can do="create" on={{ _id: projectId } as IProject} type="Member">
					{() => (
						<Routes>
							<Route path="" element={<AddMember projectId={projectId} />} />
							<Route path="companies" element={<AddCompany />} />
						</Routes>
					)}
				</Can>
			</PageHeader>

			<StatefulTooltip
				content={() => (
					<Block>
						<ParagraphXSmall>
							<strong>Ägare:</strong> Admin + Betalningsinställningar. Kan även radera projekt <br />
							<strong>Admin:</strong> Ansvarig + Kan se allt och redigera allt, inkl. projektinställningar. <br />
							<strong>Ansvarig:</strong> Hantera kontrollpunkter (även inställningar och godkännande) <br />
							<strong>Medlem:</strong> Kommer endast åt kontrollpunkter som han/hon är med kopplad till. <br />
							<strong>Begränsad:</strong> Har läsrättigheter till allt i projektet utom fakturering.
						</ParagraphXSmall>
					</Block>
				)}
				overrides={{
					Inner: {
						style: ({ $theme }) => ({
							backgroundColor: $theme.colors.white,
						}),
					},
				}}>
				<Info size="16" />
			</StatefulTooltip>

			<SubNavigation links={subRoutes} />

			<PageLayout full>
				<Routes>
					<Route path="" element={<Members />} />
					<Route path="companies" element={<Companies projectId={projectId} />} />
				</Routes>
			</PageLayout>
		</React.Fragment>
	)
}

export default MembersPage
