import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Plus } from "react-feather"
import { queryCache } from "react-query"

import GlobalContext from "context/GlobalContext"
// import useCompanyMembers from "hooks/useCompanyMembers"
import useProjectMembers from "hooks/useProjectMembers"
import { getKeysFromArray } from "utils/misc"

import UserPositionSelect from "components/UserPositionSelect"
import FormInput from "components/Form/FormInput"
import { emailValidationPattern } from "utils/formHelpers"
import { ModalProps } from "containers/ModalContainer"
import { IProfile } from "types/IProfile"
import { OnChangeParams } from "baseui/select"

const AddCompanyMemberModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const { handleSubmit, register, errors, control, setValue, setError } = useForm()
	const { modalPayload } = React.useContext(GlobalContext)
	const { projectId, companyId } = modalPayload

	// const { addCompanyMember, addCompanyMemberInfo } = useCompanyMembers(companyId)
	const { addProjectMember, addProjectMemberInfo } = useProjectMembers(projectId)

	const checkIfExists = (email: string) => {
		const projectMembers = queryCache.getQueryData<IProfile[]>(["projectMembers", { projectId, query: null }])
		if (!projectMembers) {
			console.log("CURRENT MEMBERS", projectMembers)
			return false
		}

		const members = projectMembers.filter((member: IProfile) => member.email === email)
		return members.length > 0
	}

	const onSave = async (updatedData: IProfile & { companyId: string }) => {
		console.log("On save company member")
		if (checkIfExists(updatedData.email)) {
			setError("email", {
				type: "manual",
				message: "Användaren är redan projektmedlem",
			})
			return
		}

		// updatedData.companyPermissions = "companyMember"
		updatedData.permissions = "member"
		updatedData.companyId = companyId ? companyId : ""

		if (updatedData.position) {
			updatedData.position = getKeysFromArray(updatedData.position)
		}

		// await addCompanyMember({ projectId, companyId, updatedData })
		await addProjectMember({
			projectId: projectId || "",
			companyId: companyId || "",
			updatedData,
		})
		closeModal()
	}

	return (
		<Modal onClose={closeModal} closeable isOpen={isOpen} animate autoFocus unstable_ModalBackdropScroll size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>Ny företagsmedlem</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit(onSave)}>
					<FormInput
						label="E-post"
						fieldError={errors.email}
						placeholder="namn@företag.se"
						id="email"
						name="email"
						inputRef={register({
							required: "Du måste skriva en e-post adress.",
							pattern: emailValidationPattern,
						})}
					/>
					<Controller
						render={props => (
							<FormControl label="Roll" error={errors.position && errors.position.message}>
								<UserPositionSelect
									placeholder="Välj roll"
									value={props.value}
									maxDropdownHeight="300px"
									// defaultValue={[]}
									onChange={(params: OnChangeParams) => {
										console.log("Logging params from lasfojhodsf", params)
										setValue("position", params.value, { shouldValidate: true })
									}}
								/>
							</FormControl>
						)}
						control={control}
						rules={{
							required: true,
							validate: val => val.length > 0 || "Du måste välja ett alternativ",
						}}
						defaultValue={[]}
						name="position"
					/>
				</form>
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Avbryt
				</ModalButton>

				<ModalButton
					type="submit"
					disabled={addProjectMemberInfo.status === "loading"}
					onClick={handleSubmit(onSave)}
					isLoading={addProjectMemberInfo.status === "loading"}
					endEnhancer={() => <Plus size={18} />}>
					Lägg till
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default AddCompanyMemberModal
