import * as React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND } from "baseui/button"
import { UploadCloud } from "react-feather"

import GlobalContext from "context/GlobalContext"
import useTaskAttachments from "hooks/useTaskAttachments"

import Uploader from "components/Uploader"
import { ModalProps } from "containers/ModalContainer"

const UploadTaskAttachmentModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const { modalPayload } = React.useContext(GlobalContext)
	const { taskId } = modalPayload
	const { addAttachment, addAttachmentInfo } = useTaskAttachments()
	const [files, setFiles] = React.useState<File[]>([])

	const addFiles = (files: File[]) => {
		setFiles(files)
	}

	const onClose = () => {
		closeModal()
	}

	const upload = async () => {
		console.log(files)
		if (!files) return null
		// console.log(values)

		try {
			await addAttachment({ taskId: taskId || "", files })
			onClose()
		} catch (err) {}
	}

	return (
		<Modal onClose={closeModal} closeable isOpen={isOpen} animate autoFocus unstable_ModalBackdropScroll size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>Ladda upp bilder</ModalHeader>
			<ModalBody>
				<form onSubmit={upload} encType="multipart/form-data">
					<Uploader onAddedFiles={addFiles} showFileList={true} allowImagesOnly={true} />
				</form>
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Avbryt
				</ModalButton>

				<ModalButton
					type="submit"
					disabled={!files || !files.length || addAttachmentInfo.status === "loading"}
					onClick={upload}
					isLoading={addAttachmentInfo.status === "loading"}
					endEnhancer={() => <UploadCloud size={18} />}>
					Ladda upp
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default UploadTaskAttachmentModal
