import * as React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND } from "baseui/button"
import { UploadCloud } from "react-feather"

import GlobalContext from "context/GlobalContext"
import useGlobalDocuments from "hooks/useGlobalDocuments"

import Uploader from "components/Uploader"
import { ModalProps } from "containers/ModalContainer"

const UploadGlobalDocumentModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const [files, setFiles] = React.useState<File[]>()
	const { modalPayload } = React.useContext(GlobalContext)
	const { projectId } = modalPayload
	const {
		addDocument,
		addDocumentInfo: { status },
	} = useGlobalDocuments(projectId ? projectId : "")

	const addFiles = (files: File[]) => {
		setFiles(files)
	}

	const upload = async () => {
		if (!files) return

		await addDocument({ projectId: projectId || "", files })
		closeModal()
	}

	return (
		<Modal onClose={closeModal} closeable isOpen={isOpen} animate autoFocus unstable_ModalBackdropScroll size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>Ladda upp dokument</ModalHeader>
			<ModalBody>
				<form onSubmit={upload} encType="multipart/form-data">
					<Uploader showFileList={true} onAddedFiles={addFiles} />{" "}
					{/* <FormGroup>
							<Label htmlFor="description">Beskrivning</Label>
							<textarea
								placeholder="Skriv din beskrivning här..."
								rows={3}
								id="description"
							/>
						</FormGroup> */}{" "}
				</form>
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Avbryt
				</ModalButton>
				<ModalButton
					type="submit"
					disabled={!files || !files.length || status === "loading"}
					onClick={upload}
					isLoading={status === "loading"}
					endEnhancer={() => <UploadCloud size={18} />}>
					{/* endEnhancer={() => <Upload size={24} />}> */}
					Ladda upp
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default UploadGlobalDocumentModal
