import * as React from "react"
import { FieldErrors, FieldName, SetFieldValue, SetValueConfig } from "react-hook-form"

import { corporateIdentityValidation, maskCorporateIdentity } from "utils/formHelpers"

import FormInput from "./FormInput"

export interface Props {
	register: any
	setValue: (name: FieldName<any>, value: SetFieldValue<any>, config?: SetValueConfig) => void
	errors?: FieldErrors
}

const FormCorporateIdentity: React.FC<Props> = ({ register, setValue, errors }) => (
	<FormInput
		label="Organisationsnummer"
		fieldError={errors && errors.corporateIdentity}
		placeholder="00000000-XXXX"
		id="corporateIdentity"
		name="corporateIdentity"
		clearable={true}
		inputRef={register({
			required: "Fältet är obligatoriskt.",
			pattern: {
				// value: /^\d{10}(?:\d{2})?$/,
				value: corporateIdentityValidation,
				message: "Organisationsnummer ska skrivas med 10 eller 12 siffror",
			},
		})}
		onBlur={(e: any) => maskCorporateIdentity(e, setValue)}
	/>
)

export default FormCorporateIdentity
