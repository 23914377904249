import * as React from "react"
import { useStyletron } from "baseui"
import { ListItem, ListItemLabel } from "baseui/list"
import { Button } from "baseui/button"
import { X } from "react-feather"

import { formatBytes } from "utils/format"

interface Props {
	files: File[]
	removeFile: Function
}

const FileList: React.FC<Props> = ({ files, removeFile }) => {
	const [css, theme] = useStyletron()

	return (
		<div>
			{/* <h6>Filer att ladda upp</h6> */}
			<ul className={css({ paddingLeft: "0" })}>
				{files.map((file: any) => {
					return (
						<ListItem
							key={file.path}
							endEnhancer={() => (
								<Button shape="round" size="compact" kind="secondary" onClick={e => removeFile(file.path)}>
									<X size={18} />
								</Button>
							)}
							sublist>
							<ListItemLabel
								// className={css({
								// 	font: theme.typography.font200,
								// })}
								description={
									<span
										className={css({
											color: theme.colors.mono600,
											// font: theme.typography.font200,
											margin: "0",
										})}>
										{formatBytes(file.size)}
									</span>
								}>
								<span
									className={css({
										cursor: "pointer",
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "block",
										width: "400px",
									})}>
									{file.name}
								</span>
							</ListItemLabel>
						</ListItem>
					)
				})}
			</ul>
		</div>
	)
}

export default FileList
