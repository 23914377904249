import * as React from "react"
// import { useSelector, useAction } from "react-unistore"

// import { _getUser, _resetCurrentUser, _updatePhoto } from "actions/user"

import PageHeader from "components/layout/PageHeader"
import Loader from "components/Loader"
// import MemberCard from "pages/CompanyProfile/MemberCard"
// import FeedBlock from "components/FeedBlock"

import AccountForm from "./AccountForm"
import AccountPhoto from "./AccountPhoto"
import useUser from "hooks/useUser"
import { ContentBlock } from "components/ContentBlock"
import { IProfile } from "types/IProfile"

const UserAccount = () => {
	const { currentUser, updateUser, updateUserInfo, updatePhoto, updatePhotoInfo, removePhoto, removePhotoInfo } = useUser("me")

	console.log(currentUser)

	const onUpdate = (updatedData: IProfile) => {
		console.log("onUpdate:", updatedData)
		updateUser({ userId: "me", updatedData })
	}

	const onAddedFiles = async (files: File[]) => {
		await updatePhoto({files})
	}

	const onRemove = (files: File[]) => {
		removePhoto("")
	}

	if (!currentUser) {
		return <Loader />
	}

	return (
		<React.Fragment>
			<PageHeader title="Konto" center />
			<ContentBlock maxWidth="600px" margin="0 auto">
				<AccountPhoto
					onAddedFiles={onAddedFiles}
					onRemove={onRemove}
					currentUser={currentUser}
					removePhotoInfo={removePhotoInfo}
					updatePhotoInfo={updatePhotoInfo}
				/>
				<AccountForm currentUser={currentUser} onUpdate={onUpdate} updateUserInfo={updateUserInfo} />
			</ContentBlock>
		</React.Fragment>
	)
}

export default UserAccount
