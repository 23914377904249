import { instance as axios } from "config/axios"

export type GetControlsVariables = { projectId: string };
export type GetControlVariables = { controlId: string };
export type CreateControlVariables = { projectId: string, updatedData: any };
export type UpdateControlVariables = { controlId: string, updatedData: any };
export type DeleteControlVariables = { projectId: string, controlId: string };
export type SendControlVariables = { controlId: string };
export type ApproveControlVariables = { controlId: string, updatedData: any };


export async function getControls(key: string, { projectId }: GetControlsVariables) {
	// console.log("FETCHING CONTORLS ")
	const { data } = await axios.get(`/projects/${projectId}/controls`)
	return data
}

// function testTimeout(controlId) {
// 	return new Promise(resolve => {
// 		setTimeout(async () => {
// 			const { data } = await axios.get(`/controls/${controlId}`)
// 			resolve(data)
// 		}, 2000)
// 	})
// }

export async function getControl(key: string, { controlId }: GetControlVariables) {
	// const res= await testTimeout(controlId);
	// console.log('RESPSER', res);
	// return res
	const { data } = await axios.get(`/controls/${controlId}`)
	return data
}

export async function createControl({ projectId, updatedData }: CreateControlVariables) {
	const { data } = await axios.post(`/projects/${projectId}/controls`, updatedData)
	return data
}

export async function updateControl({ controlId, updatedData }: UpdateControlVariables) {
	const { data } = await axios.patch(`/controls/${controlId}`, updatedData)
	return data
}

export async function deleteControl({ controlId, projectId }: DeleteControlVariables) {
	const { data } = await axios.delete(`/controls/${controlId}`)
	return data
}

export async function sendControlForApproval({ controlId }: SendControlVariables) {
	const { data } = await axios.post(`/controls/${controlId}/sendForApproval/`)
	return data
}

export async function approveControl({ controlId, updatedData }: ApproveControlVariables) {
	const { data } = await axios.post(`/controls/${controlId}/approval/`, updatedData)
	return data
}
