import { useQuery } from "react-query"

import { getProjects } from "services/projects"
import { IProject } from "types/IProject"

export default function useProjects() {
	const { data: projects } = useQuery<IProject[], Error>("projects", getProjects)

	return {
		projects,
	}
}
