import * as React from "react"
import { Link } from "react-router-dom"

import { verbString } from "utils/activityStrings"
import { ObjectTypes, ActivityVerbs } from "types/constants"
import { IActivity } from "types/IActivity"

export const buildActivity = (activity: IActivity, withLinks: boolean, simple?: boolean) => {
	const verb =
		activity.object.objectType === ObjectTypes.photo ||
		(activity.object.objectType === ObjectTypes.document && activity.verb === ActivityVerbs.create)
			? "laddade upp"
			: verbString[activity.verb]

	if (!withLinks) {
		if (simple) {
			return (
				<React.Fragment>
					{activity.actor.displayName} {verb}
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					{activity.actor.displayName} {verb} {activity.object.title} {activity.target && activity.target.title}
				</React.Fragment>
			)
		}
	}

	if (simple) {
		return (
			<React.Fragment>
				{activity.actor.displayName}
				{verb}{" "}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			{/* <StyledLink href={activity.actor.path}>{activity.actor.displayName}</StyledLink> */}
			<Link to={activity.actor.path}>{activity.actor.displayName}</Link> {verb}{" "}
		</React.Fragment>
	)
}
