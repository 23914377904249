import * as React from "react"
import { Block, BlockProps } from "baseui/block"
import { Button, ButtonProps } from "baseui/button"
import { useStyletron } from "baseui"

export interface Props extends BlockProps {
	isLoading?: boolean
	maxWidth?: string
}
export const ContentBlock: React.FC<Props> = ({ isLoading, maxWidth = null, ...props }) => {
	const [css] = useStyletron()

	return (
		<Block
			padding={["scale400", "scale600", "scale700", "scale1000"]}
			className={css({
				opacity: isLoading ? "0.5" : "1",
				margin: "0 auto",
				maxWidth: maxWidth ? maxWidth : "100%",
				backgroundColor: "white",
			})}
			{...props}>
			{props.children}
		</Block>
	)
}

export const ContentBlockAction: React.FC = ({ children }) => {
	const [css, theme] = useStyletron()
	return (
		<Block
			padding={[
				`${theme.sizing.scale800} ${theme.sizing.scale0} ${theme.sizing.scale0}`,
				`${theme.sizing.scale800} ${theme.sizing.scale0} ${theme.sizing.scale0}`,
				`${theme.sizing.scale1000} ${theme.sizing.scale0} ${theme.sizing.scale0}`,
			]}
			maxWidth="1000px"
			// margin="0 auto"
			backgroundColor="white"
			className={css({
				textAlign: "right",
			})}>
			{children}
		</Block>
	)
}

interface SubmitButtonProps extends ButtonProps {
	disabled?: boolean
	isLoading?: boolean
	style?: any
}

export const ContentBlockSubmitButton: React.FC<SubmitButtonProps> = ({ children, ...props }) => {
	return (
		<Button
			overrides={{
				BaseButton: {
					style: ({ $theme }) => {
						return {
							display: "block",
							width: "100%",
							marginTop: 0,
							marginRight: "auto",
							marginLeft: "auto",
							marginBottom: 0,
							[$theme.mediaQuery.medium]: {
								width: "auto",
							},
						}
					},
				},
			}}
			{...props}>
			{children}
		</Button>
	)
}
