import * as React from "react"
import { Routes, Route } from "react-router-dom"
import shortid from "shortid"

import Can from "components/Can"
import { CustomRoute } from "types/shared"

export interface Props {
	routes: CustomRoute[]
}

const RoutesBuilder: React.FC<Props> = ({ routes }) => {
	const normalRoutes: CustomRoute[] = routes.filter((r: CustomRoute) => !r.conditions)
	const conditionalRoutes: CustomRoute[] = routes.filter((r: CustomRoute) => r.conditions)

	return (
		<React.Fragment>
			{normalRoutes && (
				<Routes>
					{normalRoutes.map((route, index) => (
						<Route path={route.path} element={route.element} key={index + "-" + shortid.generate()} />
					))}
				</Routes>
			)}
			{conditionalRoutes.map((route, index) => {
				if (!route.conditions) return null

				return (
					<Can do={route.conditions.do} on={route.conditions.on} key={index + "-" + shortid.generate()}>
						<Routes>
							<Route path={route.path} element={route.element} />
						</Routes>
					</Can>
				)
			})}
		</React.Fragment>
	)
}

export default RoutesBuilder
