import * as React from "react"
import { StyledProgressSteps, StyledStep, StyledContentTail, StyledContentTitle, StyledIcon, StyledContentDescription } from "baseui/progress-steps"
import { StyledContent } from "baseui/list"
import { useStyletron } from "baseui"

import { IActivity } from "types/IActivity"

import ActivityTitle from "./ActivityTitle"
import Activity from "./Activity"

const ActivitySteps: React.FC<{ activities: IActivity[] }> = ({ activities }) => {
	const [css] = useStyletron()
	return (
		<StyledProgressSteps
			{...{
				className: css({
					height: "auto",
					width: "100%",
				}),
			}}>
			{activities.map((group: any) =>
				group.map((activity: IActivity) => (
					<StyledStep key={activity._id}>
						<StyledIcon />
						<StyledContentTail />
						<StyledContent
							$mLeft={false}
							$sublist={false}
							{...{
								className: css({
									height: "auto !important",
									flexDirection: "column",
									alignItems: "flex-start",
									borderBottom: "0",
								}),
							}}>
							<StyledContentTitle
								{...{
									className: css({
										width: "100%",
									}),
								}}>
								<ActivityTitle activity={activity} />
							</StyledContentTitle>
							<StyledContentDescription>
								<Activity key={activity._id} activity={activity} />
							</StyledContentDescription>
						</StyledContent>
					</StyledStep>
				)),
			)}
		</StyledProgressSteps>
	)
}

export default ActivitySteps
