import * as React from "react"
// import { Tag, KIND, VARIANT } from "baseui/tag"
import { StatefulPopover } from "baseui/popover"
import { Button, KIND as BUTTONKIND, SIZE, SHAPE } from "baseui/button"
import { ColumnInstance, FilterProps } from "react-table"
import { Radio, RadioGroup, ALIGN } from "baseui/radio"
import { ParagraphSmall } from "baseui/typography"

import { ArrayOption } from "types/shared"

import StyledLink from "components/StyledLink"
import DropdownContent from "components/DropdownContent"

export interface Props<T extends object> extends FilterProps<T> {
	column: ColumnInstance<T>
}

const RadioFilter = <T extends object>({ column }: Props<T>) => {
	const { filterValue, setFilter, preFilteredRows, id, getFilterLabel, staticFilterOptions } = column
	const [value, setValue] = React.useState(filterValue)

	// console.log("Current filter value: ", filterValue)
	// console.log(staticFilterOptions)

	// Calculate the options for filtering
	const options = React.useMemo((): ArrayOption[] => {
		let options: ArrayOption[] = []

		if (staticFilterOptions) {
			options = options.concat(staticFilterOptions)
		} else {
			preFilteredRows.forEach(row => {
				const value = row.values[id]
				const option: ArrayOption = {
					label: getFilterLabel ? getFilterLabel(value) : value,
					value: value,
				}

				const found: boolean = options.some((el: ArrayOption) => el.value === value)

				if (!found) options.push(option)
			})
		}

		return options
	}, [id, preFilteredRows, getFilterLabel, staticFilterOptions])

	React.useEffect(() => {
		if (!filterValue) return

		// console.log("CHANGING FILTER VALUE", filterValue[0])

		setValue(filterValue[0].value)
		// const option = value.split(":")
		// // Overwriting sortBy, only 1 option is allowed.
		// setFilter([{ id: option[0], desc: option[1] }])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterValue])

	// Render a multi-select box
	// const onChange = (option: ArrayOption) => {
	const onChange = (option: string) => {
		// console.log("Options on change: ", options)
		// let newValue = filterValue || []
		let newValue = options.filter((v: ArrayOption) => v.value === option)
		// console.log("Value and found value object: ", option, newValue)
		if (newValue.length) {
			setFilter(newValue)
		}
	}

	const clearFilter = () => {
		setFilter(undefined)
		setValue("")
	}

	return (
		<StatefulPopover
			content={() => (
				<DropdownContent>
					<RadioGroup value={value} onChange={(e: any) => onChange(e.target.value)} name={`filter-${id}`} align={ALIGN.vertical}>
						{options.map((option: any, i: number) => (
							<Radio key={`${option.value}-${i}`} value={option.value}>
								{option.label}
							</Radio>
						))}
					</RadioGroup>
					{value && (
						<StyledLink onClick={clearFilter}>
							<ParagraphSmall marginBottom="0">Rensa filter</ParagraphSmall>
						</StyledLink>
					)}
					{/* <ul
						className={css({
							padding: "0",
							margin: "0",
						})}>
						{options.map((option: ArrayOption, i: number) => {
							const isActive = (filterValue && filterValue.includes(option.value)) || false
							return (
								<li key={`${i}-${option}`}>
									<Tag
										kind={isActive ? KIND.accent : KIND.primary}
										variant={isActive ? VARIANT.solid : VARIANT.outlined}
										onActionClick={() => (isActive ? onChange(option) : "")}
										onClick={() => (!isActive ? onChange(option) : "")}
										closeable={isActive}>
										{option.label}
									</Tag>
								</li>
							)
						})}
					</ul> */}
				</DropdownContent>
			)}
			placement="bottomLeft">
			<Button kind={filterValue ? BUTTONKIND.primary : BUTTONKIND.secondary} size={SIZE.mini} shape={SHAPE.pill}>
				{column.Header} {filterValue && `(${filterValue.length})`}
			</Button>
		</StatefulPopover>
	)
}

export default RadioFilter
