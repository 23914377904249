import * as React from "react"
import { useQuery, useMutation, queryCache } from "react-query"
import {
	getCheck,
	deleteCheck,
	createCheck,
	updateCheck,
	approveCheck,
	sendCheckForApproval,
	DeleteCheckVariables,
	ApproveCheckVariables,
	SendCheckVariables,
	UpdateCheckVariables,
	CreateCheckVariables,
} from "services/checks"
import GlobalContext from "context/GlobalContext"
import { ICheck } from "types/ICheck"


const defaultOptions = {
	onSettled: () => {
		queryCache.invalidateQueries("currentCheck")
		queryCache.invalidateQueries("checks")
	},
}


export function useCheck(checkId?: string | undefined) {
	const { showToast } = React.useContext(GlobalContext)

	// Get Check
	const { data: currentCheck, status, error } = useQuery<ICheck, Error>(
		["currentCheck", { checkId }],
		getCheck,
		{ enabled: checkId },
	)

	// Create Check
	const [_createCheck, createCheckInfo] = useMutation<any, Error, CreateCheckVariables>(createCheck, defaultOptions)

	// Update Check
	const [_updateCheck, updateCheckInfo] = useMutation<any, Error, UpdateCheckVariables>(updateCheck, {
		onMutate: ({ checkId, updatedData }) => {
			const oldCheck: ICheck | undefined = queryCache.getQueryData<ICheck>(["currentCheck", { checkId }])
			if (!oldCheck) {
				console.log(`Old check with id ${checkId} was not found`);
				return
			}

			const newCheck = {
				...oldCheck,
				...updatedData,
			}

			queryCache.setQueryData<ICheck>(["currentCheck", { checkId }], () => newCheck)
			queryCache.setQueryData<ICheck[] | undefined>(["checks", { controlId: newCheck.control }], checkList => {
				if (checkList) {
					return checkList.map((c: ICheck) => (c._id === newCheck._id ? newCheck : c))
				}
			})

			return () => queryCache.setQueryData<ICheck>(["currentCheck", { checkId }], oldCheck)
		},
		onSuccess: (data, updatedData) => {
			showToast({ type: "positive", message: "Redovisningen har uppdaterats" })
		},
		onError: (error, updatedData, rollback: any) => {
			showToast({ type: "negative", message: "Det gick inte att uppdatera redovisningen" })
			rollback()
		},
	})

	// Delete Check
	const [_deleteCheck, deleteCheckInfo] = useMutation<any, Error, DeleteCheckVariables>(deleteCheck, {
		onSuccess: (data, updatedData) => {
			showToast({ type: "positive", message: "Redovisningen har tagits bort" })
		},
		onError: (error: any, updatedData, rollback) => {
			let message = "Det gick inte att ta bort redovisningen"
			if (error.response && error.response.data.message === "Has freezed items") {
				message = "Redovisningen har godkända punkter under sig"
			}
			showToast({ type: "negative", message: message })
		},
		onSettled: (deletedCheck: any, error: any) => {
			// console.log("--------------Check from Delete", deletedCheck, error)
			queryCache.removeQueries(["currentCheck", { checkId: deletedCheck._id }], {
				exact: true,
			})
			// queryCache.removeQueries(["checkDocuments", { checkId: deletedCheck._id }])
			queryCache.invalidateQueries("checks")
		},
	})

	const [_approveCheck, approveCheckInfo] = useMutation<any, Error, ApproveCheckVariables>(approveCheck, {
		onMutate: ({ checkId, updatedData }: ApproveCheckVariables) => {
			const oldCheck = queryCache.getQueryData<ICheck>(["currentCheck", { checkId }])
			const newCheck = {
				...oldCheck,
				...updatedData,
			}

			queryCache.setQueryData<ICheck>(["currentCheck", { checkId }], () => newCheck)
			queryCache.setQueryData<ICheck[] | undefined>(["checks", { controlId: newCheck.control }], checkList => {
				if (checkList) {
					return checkList.map(c => (c._id === newCheck._id ? newCheck : c))
				}
			}
			)
		},
		...defaultOptions,
	})

	const [_sendCheckForApproval, sendCheckForApprovalInfo] = useMutation<any, Error, SendCheckVariables>(
		sendCheckForApproval,
		defaultOptions,
	)

	return {
		currentCheck,
		status,
		error,

		deleteCheck: _deleteCheck,
		deleteCheckInfo,

		createCheck: _createCheck,
		createCheckInfo,

		updateCheck: _updateCheck,
		updateCheckInfo,

		approveCheck: _approveCheck,
		approveCheckInfo,

		sendCheckForApproval: _sendCheckForApproval,
		sendCheckForApprovalInfo,
	}
}
