import * as React from "react"
import { Link } from "react-router-dom"
import { Block } from "baseui/block"
import { Avatar } from "baseui/avatar"
import { useStyletron } from "baseui"

export interface Props {
	profile: any
	projectId?: string
	onClick?: any
	useLink?: boolean
	avatarSize?: string
}

const ProfileTile: React.FC<Props> = ({ profile, projectId, onClick, useLink = false, avatarSize }) => {
	const [css, theme] = useStyletron()

	if (!profile) return null

	const fullName = profile.displayName || profile.fullName || `${profile.firstName} ${profile.lastName}`

	const handleOnClick = (e: any) => {
		if (onClick) {
			onClick(e)
		}
	}

	return (
		<Block display="flex" alignItems="center">
			<div className={css({ marginRight: theme.sizing.scale400, display: "flex" })}>
				<Avatar size={avatarSize || "scale700"} name={fullName} src={profile.photoUrl} />
			</div>
			<div>
				{useLink !== null && useLink === false ? (
					fullName
				) : (
					<Link to={profile.path ? profile.path : `/p/${projectId || profile.project}/profile/${profile._id}`} onClick={handleOnClick}>
						{fullName}
					</Link>
				)}
			</div>
		</Block>
	)
}

export default ProfileTile
