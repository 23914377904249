import * as React from "react"
import { useSelector } from "react-unistore"
import { Avatar } from "baseui/avatar"
import { StatefulPopover, PLACEMENT } from "baseui/popover"
import { Button, KIND, SIZE, SHAPE } from "baseui/button"
import { StatefulMenu } from "baseui/menu"
import { useStyletron } from "baseui"
import { useNavigate } from "react-router-dom"
import { Block } from "baseui/block"
import { ParagraphMedium, ParagraphSmall, ParagraphXSmall } from "baseui/typography"

import { useMsal } from "auth/hooks/useMsal"
import { IState } from "store"

import DropdownContent from "components/DropdownContent"

const HeaderAccount: React.FC = () => {
	const currentUser = useSelector((state: IState) => state.currentUser)
	const navigate = useNavigate()
	// const currentProfile = useSelector((state: IState) => state.profileAbilities)
	const [css, theme] = useStyletron()
	const { logout } = useMsal()

	if (!currentUser) return null

	return (
		<StatefulPopover
			placement={PLACEMENT.rightBottom}
			showArrow
			overrides={{
				Arrow: {
					style: () => ({
						borderColor: "white",
					}),
				},
				Body: {
					style: () => ({
						zIndex: 101,
					}),
				},
			}}
			content={({ close }) => (
				<DropdownContent padding="0" {...{ id: "accountPopover" }}>
					<Block
						paddingTop="scale600"
						paddingRight="scale600"
						paddingLeft="scale600"
						paddingBottom="scale600"
						backgroundColor="white"
						className={css({
							textAlign: "center",
							borderBottom: `1px solid ${theme.colors.mono200}`,
						})}>
						<Avatar
							size="scale1200"
							name={(currentUser && currentUser.firstName + " " + currentUser.lastName) || ""}
							src={currentUser && currentUser.photoUrl}
						/>
						<ParagraphMedium margin="0">{currentUser.firstName + "	 " + currentUser.lastName}</ParagraphMedium>
						<ParagraphXSmall color="mono500" margin="0">
							{currentUser.email}
						</ParagraphXSmall>
					</Block>
					<StatefulMenu
						overrides={{
							List: {
								style: () => ({
									boxShadow: "0",
								}),
							},
						}}
						onItemSelect={(response: any) => {
							console.log(response, typeof response.value)
							if (typeof response.item.value === "string") {
								navigate(response.item.value)
							} else {
								response.item.value()
							}
							close()
						}}
						items={[
							{ label: "Mitt konto", value: "/account" },
							{ label: "Logga ut", value: logout },
						]}
					/>
				</DropdownContent>
			)}
			returnFocus
			autoFocus>
			<Button
				{...{ id: "accountPopoverTrigger" }}
				kind={KIND.minimal}
				size={SIZE.compact}
				shape={SHAPE.default}
				overrides={{
					BaseButton: {
						style: () => ({
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							paddingLeft: theme.sizing.scale850,
							paddingRight: 0,
							paddingTop: 0,
							paddingBottom: 0,
							width: "100%",
						}),
					},
				}}>
				<Avatar
					size="scale900"
					name={(currentUser && currentUser.firstName + " " + currentUser.lastName) || ""}
					src={currentUser && currentUser.photoUrl}
				/>
				<ParagraphSmall
					className={css({
						textAlign: "left",
						marginLeft: theme.sizing.scale200,
					})}>
					{`${currentUser.firstName} ${currentUser.lastName.charAt(0)}.`}
				</ParagraphSmall>
			</Button>
		</StatefulPopover>
	)
}

export default HeaderAccount
