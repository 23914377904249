import * as React from "react"
import { useQuery, useMutation, queryCache } from "react-query"
import { getProjectDocuments, createProjectDocument, removeProjectDocument, removeProjectDocuments, CreateProjectDocumentVariables, RemoveProjectDocumentVariables, RemoveProjectDocumentsVariables } from "services/projectDocuments"
import GlobalContext from "context/GlobalContext"
import { IDocument } from "types/IDocument"

const pluralize = (arr: []) => {
	return arr.length > 1 ? "filerna" : "filen"
}

export default function useGlobalDocuments(projectId: string) {
	const { showToast } = React.useContext(GlobalContext)
	const { data: documents, isLoading, isFetching, error } = useQuery<IDocument[], Error>(["globalDocuments", projectId, "global=true"], getProjectDocuments, {
		enabled: projectId,
	})

	const defaultCallbacks = {
		onSuccess: (data: IDocument[], { files }: any) => {
			queryCache.invalidateQueries("globalDocuments")
			// showToast({ type: "positive", message: "Filen har tagits bort" })
		},
	}

	const [addDocument, addDocumentInfo] = useMutation<any, Error, CreateProjectDocumentVariables>(createProjectDocument, {
		...defaultCallbacks,
		onSuccess: (data: IDocument[], { files }: any) => {
			queryCache.invalidateQueries("globalDocuments")
			showToast({ type: "positive", message: `${pluralize(files)} har lagts till` })
		},
		onError: (err, { files }: any) => {
			showToast({
				type: "negative",
				message: `Det gick inte att lägga till ${pluralize(files)}`,
			})
		},
	})

	const [removeDocument, removeDocumentInfo] = useMutation<any, Error, RemoveProjectDocumentVariables>(removeProjectDocument, {
		...defaultCallbacks,
		onSuccess: (data, { documentId }) => {
			queryCache.invalidateQueries("globalDocuments")
			showToast({ type: "positive", message: `Filen har tagits bort` })
		},
		onError: (err, { documentId }) => {
			showToast({
				type: "negative",
				message: `Det gick inte att ta bort filen`,
			})
		},
	})
	const [removeDocuments, removeDocumentsInfo] = useMutation<any, Error, RemoveProjectDocumentsVariables>(removeProjectDocuments, {
		...defaultCallbacks,
		onSuccess: (data, { documentIds }: any) => {
			queryCache.invalidateQueries("globalDocuments")
			showToast({ type: "positive", message: `Filerna har tagits bort` })
		},
		onError: (err, { documentIds }: any) => {
			showToast({
				type: "negative",
				message: `Det gick inte att ta bort filerna`,
			})
		},
	})

	return {
		documents,
		isLoading,
		isFetching,
		error,

		addDocument,
		addDocumentInfo,

		removeDocument,
		removeDocumentInfo,

		removeDocuments,
		removeDocumentsInfo,
	}
}
