import * as React from "react"
import { ButtonGroup, MODE } from "baseui/button-group"
import { Button, SIZE } from "baseui/button"
import { useAbility } from "@casl/react"

import { setType } from "config/abilities"
import { IProjectPlan, IProjectPlanControl } from "types/IProjectPlan"

import useControls from "hooks/useControls"
import useProjectPlan from "hooks/useProjectPlan"

import { AbilityContext } from "components/Can"
import PageHeader from "components/layout/PageHeader"
import PageLayout from "components/layout/PageLayout"
import Timeline from "components/Timeline"
import Sections from "./Sections"

export interface Props {
	projectId: string
}

const ProjectPlan: React.FC<Props> = ({ projectId }) => {
	const [selected, setSelected] = React.useState<number>(0)
	const { projectPlan, getProjectPlan } = useProjectPlan(projectId)
	const { controls: controlResult } = useControls(projectId)
	const ability = useAbility(AbilityContext)

	// console.log("%c<ProjectPlan />", "color: blue;")
	React.useEffect(() => {
		getProjectPlan()
		// getProjectPlan(projectId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!controlResult || !projectPlan) return <div>Laddar...</div>

	const projectPlanWithType: IProjectPlan = setType("ProjectPlan", projectPlan)
	const canEdit = ability.can("update", projectPlanWithType)
	const controls = controlResult as IProjectPlanControl[]

	return (
		<React.Fragment>
			<PageHeader title="Projektplan">
				<ButtonGroup
					mode={MODE.radio}
					selected={selected}
					size={SIZE.mini}
					onClick={(event, index) => {
						setSelected(index)
					}}>
					<Button>Projektplan</Button>
					<Button>Tidslinje</Button>
				</ButtonGroup>
			</PageHeader>
			<PageLayout full>
				{/* <Sections projectPlan={projectPlan} controls={controls} canEdit={canEdit} /> */}
				{selected === 0 ? (
					<Sections projectPlan={projectPlan} controls={controls} canEdit={canEdit} />
				) : (
					// <TimelineComponent2 />
					<Timeline projectPlan={projectPlan} controls={controls} canEdit={canEdit} />
				)}
			</PageLayout>
		</React.Fragment>
	)
}

export default ProjectPlan
