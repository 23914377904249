import * as React from "react"
import { FormControl } from "baseui/form-control"
import { Textarea, TextareaProps } from "baseui/textarea"
import { FieldError } from "react-hook-form"

export interface Props extends TextareaProps {
	fieldError?: FieldError
	label: string
}

const FormTextarea: React.FC<Props> = props => {
	const { fieldError } = props

	return (
		<FormControl label={props.label} error={fieldError && fieldError.message}>
			<Textarea {...props} />
		</FormControl>
	)
}

export default FormTextarea
