import { EntityStatus } from "types/enums"
import { IProject, IProjectGroup, IProjectSection } from "types/IProject"
import { IProjectPlan, IProjectPlanControl, IProjectPlanGroup, IProjectPlanSection } from "types/IProjectPlan"
import { IDragData } from "types/shared"
import { deepCopy, reorderChildren, moveToList, reorder } from "./misc"

export function prepareTreeData(data: IProjectPlan | IProject): IProjectPlan {
	let updatedData: IProjectPlan = Object.assign({}, data as any)

	if (updatedData.hasOwnProperty("sections")) {
		const sections: IProjectSection[] = updatedData.sections.map((section: IProjectSection) => {
			section.groups = section.groups.map((group: IProjectPlanGroup | string) => (typeof group === "string" ? group : group._id))
			return section
		})
		updatedData = {
			...updatedData,
			...{ sections },
		}
	}
	if (updatedData.hasOwnProperty("groups")) {
		// console.log("updating groups")
		const groups: IProjectGroup[] = updatedData.groups.map((groups: IProjectGroup) => {
			groups.controls = groups.controls.map((control: IProjectPlanControl | string) => {
				// console.log(typeof control === "string" ? control : control._id)
				return typeof control === "string" ? control : control._id
			})
			return groups
		})

		updatedData = {
			...updatedData,
			...{ groups },
		}
	}

	return updatedData
}

export function buildTree(project: IProjectPlan, projectControls: IProjectPlanControl[], showAll: boolean = false): IProjectPlanSection[] | null {
	const projectSections = project.sections
	const projectGroups = project.groups

	if (!projectSections || !projectGroups || !projectControls) return null
	// console.log("BUILDING TREE", project, projectControls)

	const sections = deepCopy(projectSections)
	const groups = deepCopy(projectGroups)
	const controls = deepCopy(projectControls)

	// Filter all groups first
	if (showAll) {
		// Only populate, no filtering
		const builtGroups: IProjectGroup[] | null = populateChildren<IProjectGroup, IProjectPlanControl, IProjectGroup>(groups, controls, "controls")
		if (!builtGroups) {
			return null
		}

		const tree: IProjectPlanSection[] | null = populateChildren<IProjectSection, IProjectGroup, IProjectPlanSection>(
			sections,
			builtGroups,
			"groups",
		)
		// console.log("BUILT TREE", tree)
		return tree
	} else {
		// Populate and filter
		// const builtGroups = populateChildrenWithFilter(groups, controls, "controls")
		const builtGroups: IProjectGroup[] | null = populateChildrenWithFilter<IProjectGroup, IProjectPlanControl, IProjectGroup>(
			groups,
			controls,
			"controls",
		)
		if (!builtGroups) {
			return null
		}
		const tree: IProjectPlanSection[] | null = populateChildrenWithFilter<IProjectSection, IProjectGroup, IProjectPlanSection>(
			sections,
			builtGroups,
			"groups",
		)
		return tree
	}
}

export const populateChildren = <SourceType, TargetType, ReturnType>(
	sourceArr: SourceType[],
	targetArr: TargetType[],
	arrKey: string,
	onlyObjects?: boolean,
): ReturnType[] | null => {
	// console.log("populateChildren", sourceArr, targetArr)
	if (!sourceArr) return null

	let populatedArray: ReturnType[] = []

	for (let index = 0; index < sourceArr.length; index++) {
		let obj: any = sourceArr[index]

		let filteredChildren: (TargetType | string)[] = []

		obj[arrKey].forEach((keyId: string, j: number) => {
			const obj: TargetType = targetArr.filter((c: any) => c._id === keyId)[0]
			if (onlyObjects) {
				if (obj) {
					filteredChildren.push(Object.assign(obj, { index: j + 1 }))
				}
			} else {
				filteredChildren.push(obj ? Object.assign(obj, { index: j + 1 }) : keyId)
			}
		})
		obj[arrKey] = filteredChildren
		populatedArray.push(obj)
	}

	return populatedArray
}

export const populateChildrenWithFilter = <SourceType, TargetType, ReturnType>(
	sourceArr: SourceType[],
	targetArr: TargetType[],
	arrKey: string,
): ReturnType[] | null => {
	let populated: ReturnType[] | null = populateChildren<SourceType, TargetType, ReturnType>(sourceArr, targetArr, arrKey, true)
	if (!populated) return null

	const filtered: ReturnType[] = populated.filter((item: any) => item[arrKey].length > 0)
	return filtered
}

export const isSectionDeleteable = (section: IProjectPlanSection) => {
	if (!section || !section.groups || section.groups.length === 0) return true

	const deleteableGroups = section.groups.filter(group => {
		if (typeof group === "string") return false
		return isGroupDeleteable(group)
	})
	return deleteableGroups.length === section.groups.length
}

export const isGroupDeleteable = (group: IProjectPlanGroup) => {
	if (!group || !group.controls || group.controls.length === 0) return true

	const lockedControls = group.controls.filter(
		(control: IProjectPlanControl) => control.status === EntityStatus.approved || control.status === EntityStatus.pending,
	)

	return lockedControls.length === 0
}

export function moveNode(plan: IProjectPlan, type: "group" | "control" | "section", data: IDragData) {
	const { sections, groups } = plan
	const { oldIndex, newIndex, oldParent, newParent } = data

	if (type === "section") {
		const updatedList = reorder(sections, oldIndex, newIndex)
		return updatedList
	}

	const childKey = type === "group" ? "groups" : "controls"
	const parentList = type === "group" ? sections : groups

	if (oldParent === newParent) {
		const updatedList = reorderChildren(parentList, newParent, childKey, oldIndex, newIndex)
		return updatedList
	}

	const updatedList = moveToList(parentList, oldParent, newParent, childKey, oldIndex, newIndex)
	return updatedList
}

export function updateNode(project: any, type: "group" | "control" | "section", nodeId: string, data: any) {
	const childKey = type === "section" ? "sections" : "groups"
	return project[childKey].map((item: any) => (item._id === nodeId ? { ...item, data } : item))
}
