import * as React from "react"
import { matchSorter } from "match-sorter"

import * as filterTypes from "utils/filterTypes"

export interface IFilter {
	title: string
	key: string
	filterType: string
	element: any
}

export function useFilter(mainFilters: IFilter[], rows: any) {
	const [items, setItems] = React.useState([])
	const [activeFilters, setActiveFilter] = React.useState<{}>({})
	const [globalSearch, setGlobalSearch] = React.useState<string | null>(null)
	const [isActive, setIsActive] = React.useState<boolean>(false)
	const filterGlobally = React.useCallback(
		items => {
			if (!globalSearch || globalSearch === "") return items
			// console.log("On Filter globally", items)
			return matchSorter(
				items,
				globalSearch,
				{
					threshold: matchSorter.rankings.WORD_STARTS_WITH,
					keys: ["title"],
					// keys: ["title", "createdBy.fullName"],
					keepDiacritics: true,
				},
				// { keys: [row => row.values[id]] }
			)
		},
		[globalSearch],
	)

	// Loop through all filters
	const filterComponents: React.ReactElement[] = mainFilters.map((f: IFilter, i: number) => {
		const onSetFilter = (filter: any) => {
			let updated = Object.assign({}, activeFilters)
			updated[f.key] = filter
			setActiveFilter(updated)
		}
		const onClearFilter = () => {
			let updated = Object.assign({}, activeFilters)
			delete updated[f.key]
			setActiveFilter(updated)
		}

		// Create component for each filter
		return (
			<React.Fragment key={i}>
				{React.cloneElement(f.element, {
					filterValue: activeFilters[f.key],
					setActiveFilter: onSetFilter,
					clearFilter: onClearFilter,
					preFilteredRows: rows,
					id: f.key,
				})}
			</React.Fragment>
		)
	})

	const onSearch = (value: string) => {
		setGlobalSearch(value)
	}

	React.useEffect(() => {
		if (rows) {
			setItems(rows)
		}
	}, [rows])

	const clearFilters = () => {
		setActiveFilter({})
	}

	// Set filter to active if search or activeFilters has values
	React.useEffect(() => {
		if ((globalSearch && globalSearch !== "") || Object.keys(activeFilters).length > 0) {
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}, [globalSearch, activeFilters])

	React.useEffect(() => {
		let filteredItems =
			(Object.keys(activeFilters).length &&
				Object.keys(activeFilters).reduce((filteredRows, activeFilterKey) => {
					const currentFilter = mainFilters.filter((f: IFilter) => f.key === activeFilterKey)[0]
					return filterTypes[currentFilter.filterType](filteredRows, [currentFilter.key], activeFilters[activeFilterKey])
				}, rows)) ||
			rows
		filteredItems = filterGlobally(filteredItems)
		setItems(filteredItems)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, mainFilters, globalSearch, filterGlobally])

	return {
		FilterComponent: filterComponents,
		activeFilters,
		search: globalSearch,
		isActive,
		onSearch,
		clearFilters,
		items,
	}
}
