import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"

import FormTextarea from "components/Form/FormTextarea"
import FormInput from "components/Form/FormInput"
import { ContentBlockSubmitButton, ContentBlockAction } from "components/ContentBlock"

export interface Props {
	email: string
	onCreate: Function
	createUserInfo: any
}

const NewUserForm: React.FC<Props> = ({ email, onCreate, createUserInfo }) => {
	const { handleSubmit, register, errors, control, setValue } = useForm({})

	const onSave = (values: Object) => {
		onCreate(values)
	}

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<FlexGrid>
				<FlexGridItem>
					<FormInput label="E-post" id="email" name="email" value={email} disabled />
					{/* <FormGroup>
							<Label htmlFor="email">E-post</Label>
							<input
								className="form-input"
								type="text"
								name="email"
								id="email"
								value={email}
								disabled
							/>
						</FormGroup> */}
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="Förnamn"
						fieldError={errors.firstName}
						placeholder="Förnamn"
						id="firstName"
						name="firstName"
						inputRef={register({
							required: "Fältet är obligatoriskt.",
						})}
					/>
					{/* <FormGroup className={errors.firstName ? "has-error" : ""}>
							<Label htmlFor="firstName">Förnamn</Label>
							<input
								className="form-input"
								type="text"
								placeholder="Förnamn"
								name="firstName"
								id="firstName"
								ref={register({
									required: "Fältet är obligatoriskt.",
								})}
							/>
							<p className="form-input-hint">
								{errors.firstName && errors.firstName.message}
							</p>
						</FormGroup> */}
				</FlexGridItem>
				<FlexGridItem>
					<FormInput
						label="Efternamn"
						fieldError={errors.lastName}
						placeholder="Efternamn"
						id="lastName"
						name="lastName"
						inputRef={register({
							required: "Fältet är obligatoriskt.",
						})}
					/>
					{/* <FormGroup className={errors.lastName ? "has-error" : ""}>
							<Label htmlFor="lastName">Efternamn</Label>
							<input
								className="form-input"
								type="text"
								placeholder="Efternamn"
								name="lastName"
								id="lastName"
								ref={register({
									required: "Fältet är obligatoriskt.",
								})}
							/>
							<p className="form-input-hint">
								{errors.lastName && errors.lastName.message}
							</p>
						</FormGroup> */}
				</FlexGridItem>
				<FlexGridItem>
					<Controller
						render={props => (
							<FormTextarea
								// {...props}
								value={props.value}
								fieldError={errors.personalContacts}
								label="Anhöriglista"
								placeholder="..."
								rows={3}
								id="personalContacts"
								name="personalContacts"
								onChange={(e: any) => setValue("personalContacts", e.target.value, { shouldValidate: true })}
							/>
						)}
						control={control}
						name="personalContacts"
						// rules={{ required: "Fältet är obligatoriskt" }}
						defaultValue=""
					/>

					{/* <FormGroup className={errors.personalContacts ? "has-error" : ""}>
							<Label htmlFor="personalContacts">Anhöriglista</Label>
							<textarea
								cols={30}
								rows={3}
								className="form-input"
								type="text"
								placeholder="..."
								name="personalContacts"
								id="personalContacts"
								ref={register}></textarea>
							<p className="form-input-hint">
								{errors.personalContacts && errors.personalContacts.message}
							</p>
						</FormGroup> */}
				</FlexGridItem>
			</FlexGrid>
			<ContentBlockAction>
				<ContentBlockSubmitButton
					{...{
						disabled: createUserInfo.status === "loading",
						isLoading: createUserInfo.status === "loading",
						type: "submit",
					}}>
					Kom igång!
				</ContentBlockSubmitButton>
			</ContentBlockAction>
		</form>
	)
}

export default NewUserForm
