import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, SIZE, SHAPE, KIND } from "baseui/button"
import { useStyletron } from "baseui"
import { AlertCircle, MoreVertical, User } from "react-feather"
import { StatefulTooltip } from "baseui/tooltip"
import { Block } from "baseui/block"
import { StatefulMenu } from "baseui/menu"
import { StatefulPopover } from "baseui/popover"
import { Label3, ParagraphSmall, ParagraphXSmall } from "baseui/typography"
import dayjs from "dayjs"
import { useAbility } from "@casl/react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

import { dateOrNull } from "utils/misc"
import { IProjectPlanControl } from "types/IProjectPlan"
import { EntityStatus } from "types/enums"

import Editable from "components/Editable"
import DateDropdown from "components/DateDropdown"
import AvatarGroup from "components/AvatarGroup"
import DragHandle from "components/DragHandle"
import ProjectplanColumn from "components/ProjectplanColumn"
import { AbilityContext } from "components/Can"
import DropdownContent from "components/DropdownContent"

export interface Props {
	canEdit: boolean
	control: IProjectPlanControl
	controlWithType: IProjectPlanControl
	index: string

	dragHandleProps?: DraggableProvidedDragHandleProps
	isDragging?: boolean

	canUpdateControl: boolean

	currentProject: any
	onUpdate: any
	setPermissions: any
	onRemove: any
	statusColor: any
}

const ControlBody: React.FC<Props> = ({
	canEdit,
	canUpdateControl,
	currentProject,
	control,
	controlWithType,
	index,
	onUpdate,
	setPermissions,
	onRemove,
	statusColor,
	dragHandleProps,
	isDragging,
}) => {
	const [css, theme] = useStyletron()
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)
	const [isHovered, setIsHover] = useState(false)
	// const navigate = useNavigate()
	const ability = useAbility(AbilityContext)

	useEffect(() => {
		if (control) {
			setStartDate(dateOrNull(control.startDate))
			setEndDate(dateOrNull(control.endDate))
		}
	}, [control])

	const handleChangeStart = ({ date }: { date: any }) => {
		const startDate = dateOrNull(date)
		setStartDate(startDate)
		onUpdate({ startDate })
	}

	const handleChangeEnd = ({ date }: { date: any }) => {
		const endDate = dateOrNull(date)
		setEndDate(endDate)
		onUpdate({ endDate })
	}

	let checksCounter = ""
	if (control.checkStatus) {
		const approvedCount = control.checkStatus.checks.filter(c => c.status === EntityStatus.approved)[0]
		checksCounter += approvedCount ? `${approvedCount.count}/` : "0/"
		checksCounter += control.checkStatus ? control.checkStatus.count : "-"
		// const approvedCount: number = control.checkStatus.approvedChecks
		// checksCounter += approvedCount ? `${approvedCount}/` : "0/"
		// checksCounter += control.checkStatus ? control.checkStatus.count : "-"
	}

	return (
		<div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
			<Block
				width="100%"
				position="relative"
				display="flex"
				alignItems="center"
				height="40px"
				backgroundColor={isHovered ? "mono300" : "white"}
				className={css({
					transitionTimingFunction: theme.animation.linearCurve,
					transitionDuration: theme.animation.timing300,
					transitionProperty: "all",
					borderBottom: `1px solid ${theme.colors.mono300}`,
				})}>
				{canEdit && (
					<DragHandle
						size="20"
						className={css({
							opacity: isHovered || isDragging ? "1" : "0",
							transitionTimingFunction: theme.animation.linearCurve,
							transitionDuration: theme.animation.timing300,
							transitionProperty: "all",
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							left: "-26px",
						})}
						{...dragHandleProps}
					/>
				)}
				<Block
					className={css({
						width: "100%",
						// paddingLeft: "16px",
						height: "100%",
						display: "flex",
						alignItems: "center",
						padding: `0px 0px 0px ${theme.sizing.scale600}`,
						// cursor: "pointer",
					})}>
					{canUpdateControl ? (
						<Label3
							display="flex"
							alignItems="center"
							className={css({
								fontWeight: 400,
							})}>
							{currentProject?.settings && currentProject?.settings.prefix === "numeric" && (
								<Block marginRight="scale400">{index}</Block>
							)}{" "}
							<span
								className={css({
									cursor: "pointer",
									":hover": {
										textDecoration: "underline",
									},
								})}>
								<Editable
									onUpdate={(val: string) => onUpdate({ title: val })}
									value={control.title}
									component={<Link to={`control/${control._id}`}>{control.title}</Link>}
									type="text"
									{...{
										editOnViewClick: false,
										inputProps: {
											style: {
												backgroundColor: theme.colors.mono400,
												border: "0",
												outline: "none",
												minWidth: "auto",
												padding: "2px 4px",
											},
										},
									}}
									showButton={isHovered}
								/>
							</span>
						</Label3>
					) : (
						<Label3
							display="flex"
							alignItems="center"
							className={css({
								fontWeight: 400,
							})}>
							<Link to={`control/${control._id}`}>
								{currentProject.settings && currentProject.settings.prefix === "numeric" && index} {control.title}
							</Link>
						</Label3>
					)}
				</Block>
				<Block
					marginLeft="auto"
					display="flex"
					height="100%"
					className={css({
						opacity: isDragging ? "0" : "1",
					})}>
					<>
						{control.regulatoryRequirements && (
							<ProjectplanColumn narrow border={false}>
								<StatefulTooltip content={() => <Block>Myndighetskrav</Block>} returnFocus autoFocus showArrow>
									<AlertCircle size="16" />
								</StatefulTooltip>
								{/* <span
									className="tooltip tooltip-left"
									data-tooltip="Myndighetskrav"></span> */}
							</ProjectplanColumn>
						)}

						{!canUpdateControl ? (
							<ProjectplanColumn>
								<ParagraphXSmall>
									{startDate && dayjs(startDate).format("D MMM")} - {endDate && dayjs(endDate).format("D MMM")}
								</ParagraphXSmall>
							</ProjectplanColumn>
						) : (
							<ProjectplanColumn>
								<DateDropdown
									date={startDate}
									dateFormat="D MMM"
									buttonSize="mini"
									maxDate={endDate || undefined}
									onChange={handleChangeStart}
									clearable
								/>
								–
								<DateDropdown
									date={endDate}
									dateFormat="D MMM"
									buttonSize="mini"
									minDate={startDate || undefined}
									onChange={handleChangeEnd}
									clearable
								/>
							</ProjectplanColumn>
						)}
						<ProjectplanColumn>
							{control.members.length > 0 ? (
								<AvatarGroup onClick={setPermissions} users={control.members} size="scale800" />
							) : (
								<Button
									size={SIZE.compact}
									shape={SHAPE.round}
									kind={KIND.secondary}
									disabled={ability.cannot("update", controlWithType)}
									// disabled={!canUpdateControl}
									onClick={setPermissions}>
									<User size={14} />
								</Button>
							)}
						</ProjectplanColumn>

						<ProjectplanColumn>
							<StatefulTooltip showArrow content={() => <Block>{statusColor.label}</Block>} returnFocus autoFocus>
								<div
									className={css({
										width: "16px",
										height: "16px",
										borderRadius: "100%",
										backgroundColor: theme.colors[statusColor.color],
									})}></div>
							</StatefulTooltip>
						</ProjectplanColumn>

						<ProjectplanColumn>
							<ParagraphSmall>{checksCounter}</ParagraphSmall>
						</ProjectplanColumn>

						<ProjectplanColumn narrow>
							{canEdit && (
								<StatefulPopover
									content={() => (
										<DropdownContent padding="0">
											<StatefulMenu
												items={[
													{
														label: <span onClick={e => onRemove(control._id)}>Ta bort</span>,
													},
												]}
											/>
										</DropdownContent>
									)}
									overrides={{
										Inner: {
											style: ({ $theme }) => ({
												backgroundColor: $theme.colors.white,
											}),
										},
									}}
									returnFocus
									autoFocus>
									<Button
										shape={SHAPE.round}
										size={SIZE.mini}
										kind={KIND.tertiary}
										overrides={{
											BaseButton: {
												style: ({ $theme }) => ({
													opacity: isHovered ? "1" : "0",
													transitionDuration: theme.animation.timing300,
													transitionProperty: "opacity",
												}),
											},
										}}
										{...{ ignoreclick: "" }}>
										<MoreVertical size="16" />
									</Button>
								</StatefulPopover>
							)}
						</ProjectplanColumn>
					</>
				</Block>
			</Block>
		</div>
	)
}

export default ControlBody
