import React from "react"
import { TableInstance } from "react-table"
import { Button } from "baseui/button"

import { ChevronLeft, ChevronRight } from "react-feather"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { ParagraphMedium } from "baseui/typography"

export interface Props<T extends object> {
	instance: TableInstance<T>
}

const TablePagination = <T extends object>({ instance }: Props<T>) => {
	const [css] = useStyletron()
	return (
		<Block
			marginTop="scale800"
			className={css({
				textAlign: "center",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			})}>
			<Button size="compact" shape="square" onClick={() => instance.previousPage()} disabled={!instance.canPreviousPage}>
				<ChevronLeft />
			</Button>{" "}
			<ParagraphMedium marginLeft="scale600" marginRight="scale600">
				{instance.state.pageIndex + 1} av {instance.pageCount}
			</ParagraphMedium>
			<Button size="compact" shape="square" onClick={() => instance.nextPage()} disabled={!instance.canNextPage}>
				<ChevronRight />
			</Button>
		</Block>

		// <div className="pagination">
		//     <button onClick={() => instance.gotoPage(0)} disabled={!instance.canPreviousPage}>
		// 		{"<<"}
		// 	</button>{" "} */}
		// 	{/* <button onClick={() => instance.previousPage()} disabled={!instance.canPreviousPage}>
		// 		{"<"}
		// 	</button>{" "}
		// 	<button onClick={() => instance.nextPage()} disabled={!instance.canNextPage}>
		// 		{">"}
		// 	</button> */}
		// 	{/* <button onClick={() => instance.gotoPage(instance.pageCount - 1)} disabled={!instance.canNextPage}>
		// 		{">>"}
		// 	</button>{" "}
		// 	<span>
		// 		Page{" "}
		// 		<strong>
		// 			{instance.state.pageIndex + 1} of {instance.pageOptions.length}
		// 		</strong>{" "}
		// 	</span>
		// 	<span>
		// 		| Go to page:{" "}
		// 		<input
		// 			type="number"
		// 			defaultValue={instance.state.pageIndex + 1}
		// 			onChange={e => {
		// 				const page = e.target.value ? Number(e.target.value) - 1 : 0
		// 				instance.gotoPage(page)
		// 			}}
		// 			style={{ width: "100px" }}
		// 		/>
		// 	</span>{" "}
		// 	<select
		// 		value={instance.state.pageSize}
		// 		onChange={e => {
		// 			instance.setPageSize(Number(e.target.value))
		// 		}}>
		// 		{[10, 20, 30, 40, 50].map(pageSize => (
		// 			<option key={pageSize} value={pageSize}>
		// 				Show {pageSize}
		// 			</option>
		// 		))}
		// 	</select> */}
		// // </div>
	)
}

export default TablePagination
