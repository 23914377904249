import * as React from "react"
import ObjectId from "objectid-random"
import { useForm } from "react-hook-form"
import { Button, KIND } from "baseui/button"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { Input } from "baseui/input"

import { ICheck } from "types/ICheck"
import { TaskTypesEnum } from "types/enums"
import { MutationResult } from "react-query"

interface Props {
	onCancel: Function
	addTask: Function
	createTaskInfo: MutationResult<any, unknown>
	addingType: keyof typeof TaskTypesEnum | ""
	currentCheck: ICheck
}

const TaskForm: React.FC<Props> = ({ onCancel, addTask, createTaskInfo, addingType }) => {
	const [css, theme] = useStyletron()
	const { register, handleSubmit, errors } = useForm<{ title: string }>()

	const onSubmit = async (values: { title: string }) => {
		const newTask = {
			_id: ObjectId(),
			type: addingType,
			// check: currentCheck._id,
			title: values.title,
			description: "",
			status: "",
			documents: [],
		}

		addTask(newTask)
	}

	// const handleKeyPress = e => {
	// 	if (e.keyCode === 27) {
	// 		e.preventDefault()
	// 		onCancel()
	// 	}
	// }

	const isLoading = createTaskInfo.status === "loading"

	return (
		<Block width="100%" padding={["0px", `${theme.sizing.scale600}`]}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={css({
					width: "100%",
					[theme.mediaQuery.medium]: {
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					},
				})}>
				<Input
					type="text"
					placeholder="Rubrik"
					id="title"
					name="title"
					inputRef={register({
						required: "Fältet är obligatoriskt.",
					})}
					error={errors.title ? true : false}
					// size="compact"
					autoFocus
				/>
				<Button
					// size={SIZE.compact}
					onClick={handleSubmit(onSubmit)}
					isLoading={isLoading}
					disabled={isLoading}
					overrides={{
						BaseButton: {
							style: () => ({
								marginTop: theme.sizing.scale300,
								[theme.mediaQuery.medium]: {
									marginTop: "0px",
									width: "300px",
								},
							}),
						},
					}}>
					Lägg till {addingType === "notes" ? "anteckning" : "fillista"}
				</Button>
				<Button
					// size={SIZE.compact}
					kind={KIND.tertiary}
					onClick={() => onCancel()}>
					Avbryt
				</Button>
			</form>
		</Block>
	)
}

export default TaskForm
