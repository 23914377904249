import { FilterValue, IdType, Row } from "react-table"

export function textFilter<T extends object>(rows: Array<Row<T>>, id: IdType<T>, filterValue: FilterValue) {
	return rows.filter(row => {
		const rowValue = row.values[id]
		return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
	})
}

textFilter.autoRemove = (val: any) => !val
