import React from "react";
import { IDocument } from "types/IDocument"
import { bundleZip, fetchFile } from "utils/documents";

export const useDownloadDocument = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const downloadDocuments = React.useCallback(async (documents: IDocument | IDocument[]): Promise<void> => {
        let data: Blob
        let name: string = ""
        let arr: IDocument[] = []
        const documentsArray = arr.concat(documents)

        setIsLoading(true)

        if (Array.isArray(documentsArray) && documentsArray.length > 1) {
            console.log("Sending for bundle...")
            const folderName = "qubitum-files"
            data = await bundleZip(documentsArray, folderName)
            name = `${folderName}.zip`
        } else {
            data = await fetchFile(documentsArray[0])
            name = documentsArray[0].name
        }

        saveAs(data, name)
        setIsLoading(false)
    }, [])

    return {
        isLoading,
        downloadDocuments
    }
}