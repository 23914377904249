import * as React from "react"
import { useParams } from "react-router-dom"
import { Tag, VARIANT } from "baseui/tag"
import { useSelector } from "react-unistore"
import { Block } from "baseui/block"

import { Permissions } from "utils/enums"
import useProjectMember from "hooks/useProjectMember"
import useCompanyMembers from "hooks/useCompanyMembers"
// import { UserPositions } from "types/enums"

import PageHeader from "components/layout/PageHeader"
import PageLayout from "components/layout/PageLayout"
import Loader from "components/Loader"
import ProfileTile from "components/ProfileTile"

import Can from "components/Can"
import UserProfileFeed from "./UserProfileFeed"
import UserCard from "./UserCard"
import { IState } from "store"

const UserProfile: React.FC = () => {
	let { projectId, userId } = useParams()
	const currentProfile = useSelector((state: IState) => state.profileAbilities)
	const { member: userProfile } = useProjectMember(projectId, userId)
	const { updateCompanyMember } = useCompanyMembers()

	if (!userProfile || !currentProfile) {
		return <Loader />
	}

	const onUpdate = async (response: any) => {
		console.log("This is the response", response)
		await updateCompanyMember(response)
	}

	return (
		<React.Fragment>
			<PageHeader
				title={
					<Block display="flex" alignItems="center">
						<ProfileTile profile={userProfile} useLink={false} avatarSize="scale1200" />
						<Tag closeable={false} variant={VARIANT.solid} kind="accent">
							{Permissions[userProfile.permissions]}
						</Tag>
					</Block>
				}></PageHeader>

			<PageLayout
				leftContent={
					<UserCard
						userProfile={userProfile}
						onUpdate={onUpdate}
						// onUpdate={onPositionUpdate}
						currentProfile={currentProfile}
					/>
				}>
				<Can do="read" on={userProfile} type="Activity">
					<UserProfileFeed userProfile={userProfile} />
				</Can>
			</PageLayout>
		</React.Fragment>
	)
}

export default UserProfile
