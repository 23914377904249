import React from "react"
// import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { MoreVertical } from "react-feather"
import { useStyletron } from "baseui"

export interface Props {
	size?: string
	className?: any
}

const DragHandle: React.FC<Props> = ({ size, ...props }) => {
	const [css, theme] = useStyletron()
	const iconSize = size || "16"
	const iconMargin: string = parseInt(iconSize) > 18 ? "-.8em" : "-.7em"

	return (
		<span {...props}>
			<MoreVertical className={css({ color: theme.colors.mono600 })} size={iconSize} />
			<MoreVertical className={css({ color: theme.colors.mono600, marginLeft: iconMargin })} size={iconSize} />
		</span>
	)
}

export default DragHandle
