import * as React from "react"
import { TableToggleRowsSelectedProps } from "react-table"
import { Checkbox } from "baseui/checkbox"

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: TableToggleRowsSelectedProps, ref: any) => {
	const defaultRef = React.useRef()
	const resolvedRef = ref || defaultRef

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	}, [resolvedRef, indeterminate])

	return (
		<>
			<Checkbox type="checkbox" isIndeterminate={indeterminate} inputRef={resolvedRef as any} {...rest} />
		</>
	)
})

export default IndeterminateCheckbox
