import * as React from "react"

// import Playground from "pages/_Playground"
import { useMsal } from "auth/hooks/useMsal"

import { Wrapper } from "components/UnAuthenticated/Wrapper"
import { Button } from "components/UnAuthenticated/Button"
import { Logo } from "components/UnAuthenticated/Logo"
import { Title, SmallTitle } from "components/UnAuthenticated/Typography"
import { Polygon } from "components/UnAuthenticated/Polygon"

const AppUnauthenticated = () => {
	const { inProgress, signup, login } = useMsal()

	React.useEffect(() => {
		const path = window.location.pathname

		if (path === "/signup") {
			console.log("Triggering signup...")
			signup()
			return
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const _login = () => {
		login()
	}
	
	console.log(inProgress)

	return (
		<Wrapper>
			<Logo />
			<Polygon />
			<Polygon />
			<Polygon />
			<div style={{ position: "relative", zIndex: 1 }}>
				<Title>Välkommen till</Title>
				<Title>Qubitum</Title>
				<Button
					onClick={_login}
					// isLoading={isLoading}
				>
					Logga in
				</Button>
			</div>
			<SmallTitle css={{ marginBottom: "2rem" }}>Qubitum betaversion</SmallTitle>
		</Wrapper>
	)
}

export default AppUnauthenticated

/* <Playground /> */
