// import { instance as axios } from "config/axios"
import { instance as axios } from "config/axios"
import { NodeType } from "types/shared"



export type GetProjectVariables = { projectId: string }
export type GetProjectPlanVariables = { projectId: string }
export type CreateProjectPlanNodeVariables = { projectId: string, type: NodeType, updatedData: any }
export type UpdateProjectPlanNodeVariables = { projectId: string, type: NodeType, nodeId: string, updatedData: any }
export type MoveProjectPlanNodeVariables = { projectId: string, type: NodeType, nodeId: string, updatedData: any }
export type DeleteProjectPlanNodeVariables = { projectId: string, type: NodeType, nodeId: string }

export async function getMyProjectProfile(id: string) {
	const { data } = await axios.get(`/projects/${id}/members/me?all=true`)
	// console.log("PROFILES", data)
	return data
}

export async function getProjects() {
	const { data } = await axios.get("/projects")
	return data
}

// export async function getProject(id) {
export async function getProject(action: string, { projectId }: GetProjectVariables) {
	console.log("GET", projectId, action)
	const { data } = await axios.get(`/projects/${projectId}`)
	return data
}

// export async function getProject(id) {
export async function getProjectPlan(action: string, { projectId }: GetProjectPlanVariables) {
	const { data } = await axios.get(`/projects/${projectId}/plan`)
	return data
}

export async function createProjectPlanNode({ projectId, type, updatedData }: CreateProjectPlanNodeVariables) {
	// console.log("UPDATED DATA", updatedData)
	const { data } = await axios.post(`/projects/${projectId}/plan/${type}`, updatedData)
	return data
}

export async function updateProjectPlanNode({ projectId, type, nodeId, updatedData }: UpdateProjectPlanNodeVariables) {
	// console.log(type, nodeId, updatedData)
	const { data } = await axios.patch(`/projects/${projectId}/plan/${type}/${nodeId}`, updatedData)
	return data
}

export async function moveProjectPlanNode({ projectId, type, nodeId, updatedData }: MoveProjectPlanNodeVariables) {
	// console.log(type, nodeId, updatedData)
	const dataToUpdate = Object.assign(updatedData, { action: "move" })
	const { data } = await axios.patch(
		`/projects/${projectId}/plan/${type}/${nodeId}`,
		dataToUpdate,
	)
	return data
}

export async function deleteProjectPlanNode({ projectId, type, nodeId }: DeleteProjectPlanNodeVariables) {
	// console.log(projectId)
	const { data } = await axios.delete(`/projects/${projectId}/plan/${type}/${nodeId}`)
	return data
}
