export const verbString = {
	approve: "godkände",
	disapprove: "underkände",
	requestApproval: "begärde godkännande av",
	create: "skapade",
	update: "uppdaterade",
	remove: "tog bort",
	invite: "bjöd in",
}

export const objectString = {
	document: "ett dokument",
	photo: "en bild",
	comment: "en kommentar",
	profile: "en medlem",
	company: "",
	task: "",
	check: "",
	control: "",
	project: "",
}

export const targetString = {
	document: "",
	photo: "",
	comment: "",
	profile: "",
	company: "",
	task: "",
	check: "",
	control: "",
	project: "",
}
