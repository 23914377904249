import * as React from "react"
import GlobalContext from "context/GlobalContext"
import { useMutation, queryCache } from "react-query"
import { deleteTask, createTask, updateTask, approveTask, sendTaskForApproval, CreateTaskVariables, SendTaskVariables, ApproveTaskVariables, UpdateTaskVariables, DeleteTaskVariables } from "services/tasks"
import { ITask } from "types/ITask"
import { ICheck } from "types/ICheck"

const defaultOptions = {
	onSettled: () => {
		queryCache.invalidateQueries("currentCheck")
		// queryCache.invalidateQueries("currentTask")
		queryCache.invalidateQueries("tasks")
	},
}

const mutateOptions = {
	onMutate: ({ taskId, checkId, updatedData }: any) => {
		const oldTask = queryCache.getQueryData<ITask[] | undefined>(["tasks", { checkId }])
		if (!oldTask) return;

		const filteredTasks = oldTask.filter(tasks => tasks._id === taskId)[0]
		const newTask = {
			...filteredTasks,
			...updatedData,
		}

		// queryCache.setQueryData(["currentTask", { taskId }], () => newTask)
		queryCache.setQueryData<ITask[] | undefined>(["tasks", { checkId: newTask.check }], taskList => {
			if (taskList) {
				return taskList.map(t => (t._id === newTask._id ? newTask : t))
			}
		})
	},
	onSettled: (updatedTask: ITask, error: any) => {
		if (error || !updatedTask) return

		// queryCache.setQueryData(["currentTask", { taskId: updatedTask._id }], () => updatedTask)
		queryCache.setQueryData<ITask[] | undefined>(["tasks", { checkId: updatedTask.check }], taskList => {
			if (taskList) {
				return taskList.map(t => (t._id === updatedTask._id ? updatedTask : t))
			}
		})
	},
}

export function useTask() {
	const { showToast } = React.useContext(GlobalContext)

	const [_createTask, createTaskInfo] = useMutation<any, Error, CreateTaskVariables>(createTask, {
		onSettled: (createdTask, error) => {
			if (error || !createdTask) return

			const newTask = [].concat(createdTask)

			queryCache.setQueryData<ITask[] | undefined>(["tasks", { checkId: createdTask.check }], taskList => {
				if (taskList) {
					return [...taskList, ...newTask]
				}
			})
			queryCache.setQueryData<ICheck | undefined>(["currentCheck", { checkId: createdTask.check }], check => {
				if (!check) return;

				check.tasks.push(createdTask._id)
				return check
			})
			// queryCache.invalidateQueries("currentCheck")
		},
		onSuccess: updatedData => {
			showToast({ type: "positive", message: "Punkten har skapats" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Punkten gick inte att skapa" })
		},
	})
	const [_updateTask, updateTaskInfo] = useMutation<any, Error, UpdateTaskVariables>(updateTask, {
		...mutateOptions,
		// ...defaultOptions,
		onSuccess: updatedData => {
			showToast({ type: "positive", message: "Punkten har uppdaterats" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Punkten gick inte att uppdatera" })
		},
	})
	const [_deleteTask, deleteTaskInfo] = useMutation<any, Error, DeleteTaskVariables>(deleteTask, {
		onSettled: (deletedTask, error) => {
			if (error || !deletedTask) return

			queryCache.setQueryData<ITask[] | undefined>(["tasks", { checkId: deletedTask.check }], taskList => {
				if (taskList) {
					return taskList.filter(c => c._id !== deletedTask._id);
				}
			})
			queryCache.setQueryData<ICheck | undefined>(["currentCheck", { checkId: deletedTask.check }], check => {
				if (!check) return
				return {
					...check,
					...{ tasks: check.tasks.filter(taskId => taskId !== deletedTask._id) }
				}
			})

			// queryCache.removeQueries(["currentTask", { taskId: deletedTask._id }])
			queryCache.removeQueries(["taskDocuments", { taskId: deletedTask._id }])
			// queryCache.invalidateQueries(["currentCheck", { checkId: deletedTask.check }])
		},
		onSuccess: updatedData => {
			showToast({ type: "positive", message: "Punkten har tagits bort" })
		},
		onError: (error: any, updatedData, rollback) => {
			let message = "Det gick inte att ta bort punkten"
			if (error.response && error.response.data.message === "Has freezed items") {
				message = "Redovisningen har godkända filer under sig"
			}
			showToast({ type: "negative", message: message })
		},
		// onError: (err, updatedData, rollback) => {
		// 	showToast({ type: "negative", message: "Det gick inte att ta bort punkten" })
		// },
	})
	const [_approveTask, approveTaskInfo] = useMutation<any, Error, ApproveTaskVariables>(approveTask, {
		...mutateOptions,
		...defaultOptions,
		onSuccess: updatedData => {
			showToast({ type: "positive", message: "Punkten har ändrat status" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Det gick inte att ändra status" })
		},
	})
	const [_sendTaskForApproval, sendTaskForApprovalInfo] = useMutation<any, Error, SendTaskVariables>(sendTaskForApproval, {
		...defaultOptions,
		onSuccess: updatedData => {
			showToast({ type: "positive", message: "Punkten har skickats för granskning" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Punkten kunde inte skickas för granskning" })
		},
	})

	return {
		// currentTask,
		// isLoading,
		// status,
		// error,

		deleteTask: _deleteTask,
		deleteTaskInfo,

		createTask: _createTask,
		createTaskInfo,

		updateTask: _updateTask,
		updateTaskInfo,

		approveTask: _approveTask,
		approveTaskInfo,

		sendTaskForApproval: _sendTaskForApproval,
		sendTaskForApprovalInfo,
	}
}
