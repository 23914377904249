import * as React from "react"
import { Link } from "react-router-dom"
import { useStyletron } from "baseui"

export interface Props {
	to?: string
	onClick?: any
	title?: string
}

const StyledLink: React.FC<Props> = ({ to = "", onClick, children, title = "", ...props }) => {
	const [css] = useStyletron()
	const style = {
		cursor: "pointer",
		color: "inherit",
		":hover": {
			textDecoration: "underline",
		},
	}

	if (onClick) {
		return (
			<span onClick={onClick} className={css(style)} title={title} {...props}>
				{children}
			</span>
		)
	}

	return (
		<Link to={to} className={css(style)} title={title} {...props}>
			{children}
		</Link>
	)
}

export default StyledLink
