import { useQuery, useMutation, queryCache } from "react-query"
import {
	getCompanyDocuments,
	addCompanyDocument,
	removeCompanyDocument,
	AddCompanyDocumentsVariables,
	RemoveCompanyDocumentsVariables,
} from "services/companyDocuments"
import { IDocument } from "types/IDocument"

const defaultCallbacks = {
	onSuccess: ({ companyId }: any) => {
		queryCache.invalidateQueries(["companyDocuments", { companyId }])
	},
}

export default function useCompanyDocuments(companyId: string) {
	const { data: documents, isLoading, isFetching, error } = useQuery<IDocument[], Error>(
		["companyDocuments", { companyId }],
		getCompanyDocuments,
		{ enabled: companyId },
	)

	const [_addCompanyDocuments, addCompanyDocumentsInfo] = useMutation<any, Error, AddCompanyDocumentsVariables>(
		addCompanyDocument,
		defaultCallbacks,
	)

	const [_removeCompanyDocument, removeCompanyDocumentsInfo] = useMutation<any, Error, RemoveCompanyDocumentsVariables>(
		removeCompanyDocument,
		defaultCallbacks,
	)

	return {
		documents,
		isLoading,
		isFetching,
		error,

		addCompanyDocuments: _addCompanyDocuments,
		addCompanyDocumentsInfo,

		removeCompanyDocument: _removeCompanyDocument,
		removeCompanyDocumentsInfo,
	}
}
