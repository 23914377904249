import { useMemo } from "react"
import { useStyletron } from "baseui"
import { StatefulPopover } from "baseui/popover"
import { Button, KIND as BUTTONKIND, SIZE, SHAPE } from "baseui/button"
import { ColumnInstance, FilterProps } from "react-table"

import { ArrayOption } from "types/shared"
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox"
import StyledLink from "components/StyledLink"
import { ParagraphSmall } from "baseui/typography"
import DropdownContent from "components/DropdownContent"

export interface Props<T extends object> extends FilterProps<T> {
	column: ColumnInstance<T>
}

const CheckboxFilter = <T extends object>({ column }: Props<T>) => {
	const { filterValue, setFilter, preFilteredRows, id, getFilterLabel, staticFilterOptions } = column
	const [css] = useStyletron()

	// Calculate the options for filtering
	const options = useMemo((): ArrayOption[] => {
		let options: ArrayOption[] = []

		if (staticFilterOptions) {
			options = options.concat(staticFilterOptions)
		} else {
			preFilteredRows.forEach(row => {
				const value = row.values[id]
				const option: ArrayOption = {
					label: getFilterLabel ? getFilterLabel(value) : value,
					value: value,
				}

				const found: boolean = options.some((el: ArrayOption) => el.value === value)

				if (!found) options.push(option)
			})
		}

		return options
	}, [id, preFilteredRows, getFilterLabel, staticFilterOptions])

	// Render a multi-select box
	const onChange = (option: ArrayOption, isAdding: boolean) => {
		let newValue = filterValue || []

		if (isAdding) {
			newValue.push(option.value)
		} else {
			// Remove value from filterValue
			newValue = newValue.filter((v: string) => v !== option.value)
		}

		if (newValue.length) {
			setFilter(newValue)
		} else {
			setFilter(undefined)
		}
	}

	const clearFilter = () => {
		setFilter(undefined)
	}

	return (
		<StatefulPopover
			content={() => (
				<DropdownContent>
					<ul
						className={css({
							padding: "0",
							margin: "0",
						})}>
						{options.map((option: ArrayOption, i: number) => {
							const isActive = (filterValue && filterValue.includes(option.value)) || false
							return (
								<li key={`${i}-${option}`}>
									<Checkbox
										checked={isActive}
										onChange={(e: any) => onChange(option, e.target.checked)}
										labelPlacement={LABEL_PLACEMENT.right}>
										{option.label}
									</Checkbox>
								</li>
							)
						})}
					</ul>
					{filterValue && (
						<StyledLink onClick={clearFilter}>
							<ParagraphSmall marginBottom="0">Rensa filter</ParagraphSmall>
						</StyledLink>
					)}
				</DropdownContent>
			)}
			placement="bottomLeft">
			<Button kind={filterValue ? BUTTONKIND.primary : BUTTONKIND.secondary} size={SIZE.mini} shape={SHAPE.pill}>
				{column.Header} {filterValue && `(${filterValue.length})`}
			</Button>
		</StatefulPopover>
	)
}

export default CheckboxFilter
