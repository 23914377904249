import * as React from "react"
import { Block } from "baseui/block"
import { ParagraphXSmall } from "baseui/typography"
import { StatefulMenu } from "baseui/menu"
import { Button, SIZE, KIND, SHAPE } from "baseui/button"
import { StatefulTooltip } from "baseui/tooltip"
import { AlertCircle, ChevronRight, MoreVertical } from "react-feather"
import { StatefulPopover } from "baseui/popover"
import { Cell, ColumnWithLooseAccessor } from "react-table"

import { DocumentTypes, RegulatoryRequirements } from "utils/enums"
import { dateTimeFormatter } from "utils/format"
import { IDocument } from "types/IDocument"
import { StrippedMember } from "types/shared"

import StyledLink from "components/StyledLink"
import PlanFilter from "components/DataTable/filters/PlanFilter"
import RadioFilter from "components/DataTable/filters/RadioFilter"
import CheckboxFilter from "components/DataTable/filters/CheckboxFilter"
// import SelectFilter from "components/DataTable/filters/SelectFilter"
// import TagFilter from "components/DataTable/filters/TagFilter"

// COLUMNS:
// Myndighetskrav
// Uppladdat av
// Godkänd av
// Filtyp
// Kontrollpunkt

const columns = (viewDocument: any, onDownload: any, projectId: string, members: StrippedMember[] | undefined): ColumnWithLooseAccessor<IDocument>[] => {
	return [
		{
			Header: "Filnamn",
			width: 200,
			// @ts-ignore
			accessor: "name",
			Cell: ({ row }: Cell<IDocument>) => {
				const document: IDocument = row.original
				return (
					<div style={{ display: "flex", alignItems: "flex-start" }}>
						<div style={{ marginLeft: ".4rem" }}>
							<StyledLink onClick={() => viewDocument(document._id)} title={document.name}>
								<span
									style={{
										whiteSpace: "nowrap",
										wordWrap: "break-word",
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "block",
										width: "200px",
									}}>
									{typeof document.control !== "string" && document.control?.regulatoryRequirements && (
										<StatefulTooltip
											content={() => <Block>Kontrollpunkt har myndighetskrav</Block>}
											returnFocus
											autoFocus
											showArrow>
											<AlertCircle size="16" />
										</StatefulTooltip>
									)}
									{document.name}
								</span>
							</StyledLink>
							<ParagraphXSmall margin="0" color="mono700" display="flex" alignItems="center">
								<StyledLink
									to={`../control/${typeof document.control !== "string" && document.control?._id}`}
									title={typeof document.control !== "string" ? document.control?.title : ""}>
									{typeof document.control !== "string" && document.control?.title}
								</StyledLink>
								<ChevronRight size="14" />
								<StyledLink
									to={`../control/${typeof document.control !== "string" && document.control?._id}/check/${
										typeof document.check !== "string" && document.check?._id
									}`}
									title={typeof document.check !== "string" ? document.check?.title : ""}>
									{typeof document.check !== "string" && document.check?.title}
								</StyledLink>
							</ParagraphXSmall>
						</div>
					</div>
				)
			},
		},
		{
			Header: "Kontrollpunkt",
			// @ts-ignore
			accessor: "control._id",
			getFilterLabel: () => "Testing",
			Filter: PlanFilter,
			filter: "includesValue",
			Cell: ({ row }: Cell<IDocument>) => typeof row.original.control !== "string" && row.original.control?.title,
		},
		{
			Header: "Filtyp",
			// @ts-ignore
			accessor: "documentType",
			Filter: RadioFilter,
			filter: "includes",
			staticFilterOptions: Object.keys(DocumentTypes).map(key => ({ value: key, label: DocumentTypes[key] })),
			getFilterLabel: (value: string) => DocumentTypes[value],
			Cell: ({ value }: Cell<IDocument>) => {
				return DocumentTypes[value] || ""
			},
		},
		{
			Header: "Myndighetskrav",
			// @ts-ignore
			accessor: "regulatoryRequirements",
			Filter: RadioFilter,
			filter: "includesValue",
			staticFilterOptions: [
				{ value: "true", label: "Med" },
				{ value: "false", label: "Utan" },
			],
			getFilterLabel: (value: string) => RegulatoryRequirements[value],
			Cell: ({ value }: Cell<IDocument>) => {
				return value ? "Ja" : "Nej"
			},
		},
		{
			Header: "Uppladdat av",
			// @ts-ignore
			accessor: "createdBy.fullName",
			filter: "includesValue",
			Filter: CheckboxFilter,
			staticFilterOptions: members?.map((m: StrippedMember) => ({ value: m._id, label: m.fullName })),
			Cell: ({ row }: Cell<IDocument>) => {
				const document: IDocument = row.original
				if (!row.original.createdBy) return null

				return (
					<Block display="flex" flexDirection="column">
						<StyledLink to={`/p/${projectId}/profile/${document.createdBy._id}`}>
							{document.createdBy.fullName} {document.createdBy.position}
						</StyledLink>
						<ParagraphXSmall color="mono700" margin="0">
							{dateTimeFormatter(document.createdAt)}
						</ParagraphXSmall>
					</Block>
				)
			},
		},
		{
			Header: "Godkänt av",
			// @ts-ignore
			accessor: "approvedBy.fullName",
			// Filter: TagFilter,
			Filter: CheckboxFilter,
			filter: "includesValue",
			staticFilterOptions: members?.map((m: StrippedMember) => ({ value: m._id, label: m.fullName })),
			Cell: ({ row }: Cell<IDocument>) => {
				const document: IDocument = row.original
				if (!row.original.approvedBy) return null
				return (
					<Block display="flex" flexDirection="column">
						<StyledLink to={`/p/${projectId}/profile/${document.approvedBy._id}`}>
							{document.approvedBy.fullName} {document.approvedBy.position}
						</StyledLink>
						<ParagraphXSmall color="mono700" margin="0">
							{dateTimeFormatter(document.approvedAt)}
						</ParagraphXSmall>
					</Block>
				)
			},
		},
		{
			Header: "",
			// @ts-ignore
			accessor: "actionColumn",
			Cell: ({ row }: Cell<IDocument>) => {
				return (
					<StatefulPopover
						content={() => (
							<Block {...{ ignoreclick: "" }}>
								<StatefulMenu
									items={[
										{
											label: <span onClick={e => onDownload(row.original)}>Ladda ner</span>,
										},
									]}
								/>
							</Block>
						)}>
						<Button size={SIZE.mini} kind={KIND.secondary} shape={SHAPE.round} {...{ ignoreclick: "" }}>
							<MoreVertical size="16" />
						</Button>
					</StatefulPopover>
				)
			},
		},
	]
}

export default columns
