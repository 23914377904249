import * as React from "react"
import { ParagraphMedium } from "baseui/typography"

import Tile from "components/Tile"
import { ICompany } from "types/ICompany"

interface Props {
	company: ICompany
}

const CompanyCard: React.FC<Props> = ({ company }) => {
	return (
		<div>
			<Tile
				footer={
					<React.Fragment>
						Företagsnamn: {company.name} <br />
						Org.nr: {company.corporateIdentity} <br />
						{company.website && (
							<a href={"https://" + company.website} target="_blank" rel="noopener noreferrer">
								{company.website}
							</a>
						)}
					</React.Fragment>
				}>
				<ParagraphMedium marginTop="0">Företagsinfo</ParagraphMedium>
			</Tile>
			{company.address && (
				<Tile
					footer={
						<React.Fragment>
							{company.phone}
							<br />
							{company.email}
							<br />
							{company.address}
						</React.Fragment>
					}>
					<ParagraphMedium marginTop="0">Kontakt</ParagraphMedium>
				</Tile>
			)}
		</div>
	)
}

export default CompanyCard
