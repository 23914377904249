import { stringify, parse } from "query-string"
import { ArrayOption } from "types/shared"

export const prepareQuery = (tableFilters: any, oldQuery: any) => {
    let builtQuery: any = {}

    // Add filters
    tableFilters.filters?.forEach((f: any) => {
        // console.log("Current filter", f)
        if (f.id === "control._id") {
            builtQuery["control"] = f.value
        } else if (f.id === "createdBy.fullName") {
            builtQuery["createdBy._id"] = f.value
        } else if (f.id === "approvedBy.fullName") {
            builtQuery["approvedBy._id"] = f.value
        } else {
            builtQuery[f.id] = f.value.map((fi: ArrayOption) => fi.value)
        }
    })


    const changedString = detectQueryChanges(oldQuery, builtQuery)
    if (changedString) {
        console.log("There was a change");
    } else if (tableFilters.pageIndex) {
        console.log(tableFilters, builtQuery)
        builtQuery.page = tableFilters.pageIndex
    } else if (tableFilters.pageIndex) {
        builtQuery.page = 0;
        console.log('WE DIDINOTHING', builtQuery)
    }
    // Add pageIndex
    // builtQuery.page = tableFilters.pageIndex || 0

    // Add sorting
    if (tableFilters.sortBy?.length) {
        builtQuery.sort = tableFilters.sortBy[0]?.id
        builtQuery.order = tableFilters.sortBy[0]?.desc === 'true' ? 'desc' : 'asc';
    }

    console.log("Final query: ", stringify(builtQuery, { arrayFormat: "comma" }), tableFilters)

    builtQuery.perPage = tableFilters.pageSize || 50

    return stringify(builtQuery, { arrayFormat: "comma" })
}



const detectQueryChanges = (oldQuery: any, newQuery: any): boolean => {
    const oldParsedQuery = parse(oldQuery || "");
    delete oldParsedQuery.page
    const oldString = stringify(oldParsedQuery, { arrayFormat: "comma" })
    const newString = stringify(newQuery, { arrayFormat: "comma" })
    const changedString = oldString !== newString

    return changedString
}