import * as React from "react"
// import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { ParagraphXSmall } from "baseui/typography"

import { buildActivity } from "utils/buildActivity"
import { IActivity } from "types/IActivity"
// import { Clock } from "react-feather"
// import { StatefulTooltip } from "baseui/tooltip"
// import { dateTimeFormatter, timeagoFormatter } from "utils/misc"

const ActivityTitle: React.FC<{ activity: IActivity }> = ({ activity }) => {
	const builtActivity = buildActivity(activity, true)
	// const [css, theme] = useStyletron()

	return (
		<Block display="flex" justifyContent="space-between">
			<ParagraphXSmall margin="0">{builtActivity}</ParagraphXSmall>
			{/* <ParagraphXSmall
				className={css({
					margin: "0",
					display: "flex",
					alignItems: "center",
					color: theme.colors.primary400,
				})}>
				{timeagoFormatter(activity.createdAt)}
				<StatefulTooltip
					content={() => <Block>{dateTimeFormatter(activity.createdAt)}</Block>}
					returnFocus
					showArrow
					autoFocus>
					// <Clock size="14" className={css({ marginLeft: theme.sizing.scale200 })} />
				</StatefulTooltip>
			</ParagraphXSmall> */}
		</Block>
	)
}

export default ActivityTitle
