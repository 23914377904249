// 0([-\s]?\d){6,10}
import * as React from "react"
import { FieldErrors } from "react-hook-form"

import { swedishPhoneValidation } from "utils/formHelpers"

import FormInput from "./FormInput"

export interface Props {
	register: any
	setValue: any
	errors?: FieldErrors
}

const FormSwedishPhone: React.FC<Props> = ({ register, setValue, errors }) => (
	<FormInput
		label="Telefonnummer"
		fieldError={errors && errors.phone}
		placeholder="070-XXXXXXX"
		id="phone"
		name="phone"
		inputRef={register({
			pattern: {
				// value: /^\d{10}(?:\d{2})?$/,
				value: swedishPhoneValidation,
				message: "Telefonnumret är inte korrekt formaterat",
			},
		})}
		// onBlur={e => maskCorporateIdentity(e, setValue)}
	/>
)

export default FormSwedishPhone
