import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { customStyled } from "config/theme"

import Header from "components/layout/Header"
import Sidebar from "components/layout/Sidebar"
import Content from "components/layout/Content"
import ViewportContext from "context/ViewportContext"

const MainLayout = () => {
	const { projectId } = useParams()
	const { width } = useContext(ViewportContext)
	const [sidebarIsOpen, setSidebarIsOpen] = useState(true)

	useEffect(() => {
		if (width < 700) {
			setSidebarIsOpen(false)
		} else {
			setSidebarIsOpen(true)
		}
	}, [width])

	/* 
		- Sidebar should get status from localStorage
		- Show sidebar if in project
		- Hide sidebar if set.
		- Should always hide if mobile
	*/

	return (
		<Wrapper>
			<Sidebar sidebarIsOpen={sidebarIsOpen} setSidebarIsOpen={setSidebarIsOpen} projectId={projectId} />
			<Main sidebarIsOpen={sidebarIsOpen} projectId={projectId}>
				<Header sidebarIsOpen={sidebarIsOpen} setSidebarIsOpen={setSidebarIsOpen} />
				<Content />
			</Main>
		</Wrapper>
	)
}

export default MainLayout

const Wrapper = customStyled("div", ({ $theme }) => ({
	display: "flex",
	flex: "1 1 0",
	flexDirection: "column",
	height: "100%",
	minHeight: "1px",
	backgroundColor: $theme.custom.bodyBackgroundColor,
	position: "relative",
}))

const Main = customStyled<"main", { projectId: string; sidebarIsOpen: boolean }>("main", ({ $theme, projectId, sidebarIsOpen }) => ({
	display: "flex",
	flexDirection: "column",
	maxWidth: projectId ? "none" : "1200px",
	margin: projectId ? "0" : "0 auto",
	transition: "padding 0.3s",
	paddingLeft: 0,

	[$theme.mediaQuery.medium]: {
		paddingLeft: sidebarIsOpen ? $theme.custom.sidebarWidth : 0,
	},
}))
