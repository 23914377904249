import * as React from "react"
// import ObjectId from "objectid-random"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

import useControl from "hooks/useControl"
import useProjectPlan from "hooks/useProjectPlan"
import { IProjectPlan, IProjectPlanGroup, IProjectPlanSection } from "types/IProjectPlan"

import DroppableContent from "components/DroppableContent"
import DraggableContent from "components/DraggableContent"

import Control from "../Control"

import NewProjectPlanNodeForm from "../../NewProjectPlanNodeForm"
import GroupHeader from "./GroupHeader"

export interface Props {
	canEdit: boolean
	section: IProjectPlanSection
	sectionIndex: number
	group: IProjectPlanGroup
	groupIndex: number
	projectPlan: IProjectPlan
	isGroupOpen: boolean
	isDragging?: boolean
	dragHandleProps?: DraggableProvidedDragHandleProps
}

const Group: React.FC<Props> = ({
	canEdit,

	group,
	groupIndex,
	section,
	sectionIndex,
	projectPlan,

	isGroupOpen,
	dragHandleProps,
	isDragging,
}) => {
	const [css, theme] = useStyletron()
	const { deleteNode, updateNode } = useProjectPlan()
	const { deleteControl, createControl, updateControl } = useControl()
	const [isOpen, setIsOpen] = React.useState(isGroupOpen)

	React.useEffect(() => {
		setIsOpen(isGroupOpen)
	}, [isGroupOpen])

	const removeGroup = (groupId: string) => {
		deleteNode({ projectId: projectPlan._id, type: "group", nodeId: groupId })
	}

	const updateGroup = (values: any) => {
		updateNode({
			projectId: projectPlan._id,
			type: "group",
			nodeId: group._id,
			updatedData: values,
		})
	}

	const addControl = (values: any, e: any) => {
		const newControl = {
			// _id: ObjectId(),
			title: values.title,
			group: group._id,
			// project: projectPlan._id,
		}

		createControl({
			projectId: projectPlan._id,
			updatedData: newControl,
		})

		e.target.reset()
	}

	return (
		<Block>
			<GroupHeader
				canEdit={canEdit}
				onUpdate={updateGroup}
				onRemove={() => {
					if (window.confirm(`Är du säker på att du vill ta bort gruppen med underliggande punkter?`)) {
						removeGroup(group._id)
					}
				}}
				group={group}
				dragHandleProps={dragHandleProps}
				isDragging={isDragging}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>

			<Block
				className={css({
					display: isOpen ? "flex" : "none",
					flexDirection: "column",
					alignItems: "flex-end",
				})}>
				<DroppableContent id={group._id} type="control" className={css({ width: "96%" })} canEdit={canEdit}>
					{group.controls.map((control, index) => {
						if (typeof control === "string") {
							return null
						}

						return (
							<DraggableContent key={control._id} id={control._id} index={index} canEdit={canEdit} customHandle>
								<Control
									control={control}
									group={group}
									canEdit={canEdit}
									removeControl={deleteControl}
									updateControl={updateControl}
								/>
							</DraggableContent>
						)
					})}
				</DroppableContent>

				{/* {canEdit && <AddItemForm onSubmit={addControl} textLabel="rad" />} */}

				{/* Add control form */}
				{canEdit && (
					<NewProjectPlanNodeForm
						onSubmit={addControl}
						type="control"
						wide={false}
						customStyle={{
							border: "none",
							borderBottom: `1px dashed ${theme.colors.mono500}`,
							width: "100%",
							paddingLeft: theme.sizing.scale600,
							textAlign: "left",
							outline: "none",
							height: "40px",
							fontSize: "14px",
						}}
					/>
				)}
			</Block>
		</Block>
	)
}

export default Group
