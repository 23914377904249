import * as React from "react"
import { useMutation, queryCache, useQuery } from "react-query"

import GlobalContext from "context/GlobalContext"
import { createProjectPlanNode, updateProjectPlanNode, deleteProjectPlanNode, moveProjectPlanNode, CreateProjectPlanNodeVariables, UpdateProjectPlanNodeVariables, DeleteProjectPlanNodeVariables, MoveProjectPlanNodeVariables } from "services/projectPlan"
import { getProjectPlan } from "services/projects"
import { moveNode, updateNode } from "utils/tree"
import { IProjectPlan } from "types/IProjectPlan"

export default function useProjectPlan(projectId?: string | undefined) {
	const { showToast } = React.useContext(GlobalContext)

	const { data: projectPlan, refetch: _getProjectPlan } = useQuery<IProjectPlan, Error>(["projectPlan", { projectId }], getProjectPlan, { enabled: false })

	const [_createNode, createNodeInfo] = useMutation<any, Error, CreateProjectPlanNodeVariables>(createProjectPlanNode, {
		onSuccess: (data, { projectId }) => {
			console.log("onSuccess")
			showToast({ type: "positive", message: "Projektplanen har uppdaterats" })
			queryCache.setQueryData<IProjectPlan>(["projectPlan", { projectId }], () => ({ ...data }))
		},
		onError: () => {
			console.log("onError")
			showToast({ type: "negative", message: "Projektplanen kunde inte uppdateras" })
		},
	})

	const [_updateNode, updateInfo] = useMutation<any, Error, UpdateProjectPlanNodeVariables>(updateProjectPlanNode, {
		onMutate: ({ projectId, type, nodeId, updatedData }) => {
			queryCache.cancelQueries(["projectPlan", { projectId }])
			const previousProjectPlan = queryCache.getQueryData(["projectPlan", { projectId }])
			console.log("Previous projectplan", previousProjectPlan)

			queryCache.setQueryData<IProjectPlan | undefined>(["projectPlan", { projectId }], old => {
				if (!old) return;

				const data = updateNode(old, type, nodeId, updatedData)
				const dataKey = type === "group" ? "groups" : "sections"
				return { ...old, [dataKey]: data }
			})

			return () => queryCache.setQueryData(["projectPlan", { projectId }], previousProjectPlan)
		},
		onSuccess: (data, { projectId }) => {
			// console.log("onSuccess")
			showToast({ type: "positive", message: "Projektplanen har uppdaterats" })
			queryCache.setQueryData(["projectPlan", { projectId }], old => ({ ...data }))
		},
		onError: (err, updatedData, rollback) => {
			console.log("onError")
			showToast({ type: "negative", message: "Projektplanen kunde inte uppdateras" })
		},
	})

	const [_deleteNode, deleteInfo] = useMutation<any, Error, DeleteProjectPlanNodeVariables>(deleteProjectPlanNode, {
		onSuccess: (data, { projectId }) => {
			console.log("onSuccess")
			console.log("YES, VI LYCKADES")
			showToast({ type: "positive", message: "Projektplanen har uppdaterats" })
			queryCache.setQueryData(["projectPlan", { projectId }], old => data)
		},
		onError: (error: any, updatedData, rollback) => {
			let message = "Projektplanen kunde inte uppdateras"
			if (error.response && error.response.data.message === "Has freezed items") {
				message = "Det finns godkända punkter under"
			}
			showToast({ type: "negative", message: message })
		},
	})

	const [_moveNode, moveInfo] = useMutation<any, Error, MoveProjectPlanNodeVariables>(moveProjectPlanNode, {
		onMutate: ({ projectId, type, updatedData }) => {
			// queryCache.cancelQueries(["projectPlan", { projectId }])
			const previousProjectPlan = queryCache.getQueryData(["projectPlan", { projectId }])

			queryCache.setQueryData<IProjectPlan | undefined>(["projectPlan", { projectId }], old => {
				if (!old) return;

				const data = moveNode(old, type, updatedData)
				const dataKey = type === "control" ? "groups" : "sections"
				return { ...old, [dataKey]: data }
			})

			return () => queryCache.setQueryData(["projectPlan", { projectId }], previousProjectPlan)
		},
		onSuccess: (data, { projectId }) => {
			// console.log("onSuccess", data)
			showToast({ type: "positive", message: "Projektplanen har uppdaterats" })
			queryCache.setQueryData(["projectPlan", { projectId }], () => data)
		},
		onError: (err, updatedData, rollback: any) => {
			console.log("onError")
			showToast({ type: "negative", message: "Projektplanen kunde inte uppdateras" })
			rollback()
		},
	})

	return {
		projectPlan: projectPlan,
		getProjectPlan: _getProjectPlan,

		updateNode: _updateNode,
		updateInfo,

		createNode: _createNode,
		createNodeInfo,

		deleteNode: _deleteNode,
		deleteInfo,

		moveNode: _moveNode,
		moveInfo,
	}
}
