import * as React from "react"
import dayjs from "dayjs"
import { CalendarProps, StatefulCalendar, StatefulDatepickerProps } from "baseui/datepicker"
import { StatefulPopover } from "baseui/popover"
import { Button, KIND, SIZE, SHAPE } from "baseui/button"
import { X, Calendar } from "react-feather"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { ParagraphMedium } from "baseui/typography"

interface Props extends StatefulDatepickerProps<CalendarProps> {
	label?: React.ReactNode
	buttonSize?: keyof typeof SIZE
	clearable?: boolean
	dateFormat?: string
	date: Date | null
}

const DateDropdown: React.FC<Props> = ({ label, buttonSize = SIZE.compact, clearable, onChange, date, dateFormat = "DD/MM/YYYY", ...props }) => {
	const [css, theme] = useStyletron()
	const [isHovered, setIsHover] = React.useState(false)

	return (
		<div className={css({ position: "relative", display: "flex" })} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)}>
			<ParagraphMedium margin="0">{label}</ParagraphMedium>
			<Block position="relative">
				<StatefulPopover
					content={({ close }) => (
						<StatefulCalendar
							{...props}
							onChange={e => {
								setIsHover(false)
								onChange && onChange(e)
								close()
							}}
						/>
					)}
					returnFocus
					autoFocus>
					<Button
						kind={KIND.minimal}
						size={SIZE[buttonSize]}
						shape={SHAPE.pill}
						overrides={{
							BaseButton: {
								style: () => ({
									paddingTop: "4px",
									paddingRight: "4px",
									paddingBottom: "4px",
									paddingLeft: "4px",
								}),
							},
						}}>
						{date ? dayjs(date).format(dateFormat) : <Calendar size={14} />}
					</Button>
				</StatefulPopover>

				{clearable && date && (
					<Button
						kind={KIND.minimal}
						size={SIZE.mini}
						shape={SHAPE.round}
						onClick={() => onChange && onChange({ date: [] })}
						overrides={{
							BaseButton: {
								style: () => ({
									height: "14px",
									width: "14px",
									position: "absolute",
									right: "-6px",
									top: "-4px",
									textAlign: "center",
									opacity: isHovered ? "1" : "0",
									transitionDuration: theme.animation.timing300,
									transitionProperty: "opacity",
									paddingRight: "1px",
									paddingLeft: "1px",
								}),
							},
						}}>
						<X size={22} className={css({ marginRight: "0 !important" })} />
					</Button>
				)}
			</Block>
		</div>
	)
}

export default DateDropdown
