import * as React from "react"
import { INotification } from "types/INotification"

enum objectStrings {
	document = "Dokumentet:",
	control = "Kontrollpunkten:",
	check = "Redovisningen:",
	task = "Punkten:",
}

enum verbStrings {
	approve = "godkändes",
	disapprove = "underkändes",
	requestApproval = "inväntar godkännande",
}

export const buildNotification = (notification: INotification) => {
	return (
		<React.Fragment>
			{objectStrings[notification.object.objectType]} <strong>{notification.object.title}</strong> {verbStrings[notification.verb]}
		</React.Fragment>
	)
}
