import * as React from "react"
import { Link } from "react-router-dom"
import { format } from "format-phone-swedish"
import { X } from "react-feather"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { Tag, VARIANT } from "baseui/tag"
import { Label3, ParagraphMedium } from "baseui/typography"

import { Permissions } from "utils/enums"
import { setType } from "config/abilities"

import Can from "components/Can"
import StyledLink from "components/StyledLink"
import ProjectRoles from "components/ProjectRoles"
import { Ability } from "@casl/ability"
import { IProfile } from "types/IProfile"
import { Cell } from "react-table"
import { OnChangeParams } from "baseui/select"

const columns = (onChange: Function, onRemoveMember: Function, openModal: Function, ability: Ability, projectId: string) => {
	const cols = [
		{
			Header: "Namn",
			accessor: "fullName",
			primary: true,
			searchable: true,
			search: true,
			width: 200,
			Cell: ({ row, value }: Cell<IProfile>) => {
				// console.log("The row", row.original)
				if (row.original.isPending) {
					return <ParagraphMedium>-</ParagraphMedium>
				}

				return <StyledLink to={`../profile/${row.original._id}`}>{value}</StyledLink>
			},
		},
		{
			Header: "Kontakt",
			accessor: "email",
			Cell: ({ row, value }: Cell<IProfile>) => {
				const profile = row.original
				return (
					<div>
						{value && <a href={"mailto:" + value}>{value}</a>}{" "}
						{profile.isPending && (
							<Tag closeable={false} variant={VARIANT.light} kind="neutral">
								Skickat inbjudan
							</Tag>
						)}
						{value && profile.phone && <br />}
						{profile.phone && <a href={`tel:${profile.phone}`}>{format(profile.phone, "–")}</a>}
					</div>
				)
			},
		},
		{
			Header: "Företag",
			accessor: "company.name",
			Cell: ({ row, value }: Cell<IProfile>) => {
				const userProfile = row.original

				return userProfile.company ? (
					<Link to={`../company/${userProfile.company._id}`}>{value}</Link>
				) : (
					<Button size={SIZE.mini} shape={SHAPE.pill} onClick={e => openModal(userProfile)}>
						Koppla till företag
					</Button>
				)
			},
		},
		{
			Header: "Behörighet",
			accessor: "permissions",
			Cell: ({ row, value }: Cell<IProfile>) => {
				const profileWithType = setType("Profile", row.original)

				if (value !== "owner" && ability.can("update", profileWithType, "permissions")) {
					return (
						<ProjectRoles
							// label="Behörighet"
							clearable={false}
							searchable={false}
							placeholder="Välj ett alternativ"
							onChange={(params: OnChangeParams) => onChange(params.value, row.original)}
							currentRole={value}
						/>
					)
				}

				return <Label3>{Permissions[value]}</Label3>
			},
		},
	]

	const onProjectWithMembersType = { _id: projectId, __type: "Member" }
	if (ability.can("delete", onProjectWithMembersType)) {
		cols.push({
			Header: "",
			accessor: "actionColumn",
			width: 50,
			Cell: ({ row }: Cell<IProfile>) => {
				const profileWithType = setType("Profile", row.original)

				if (profileWithType.permissions === "owner") return null

				return (
					<Can do="delete" on={profileWithType} type="Profile">
						{() => (
							<Button size={SIZE.compact} shape={SHAPE.round} kind={KIND.tertiary} onClick={e => onRemoveMember(row.original)}>
								<X size={16} />
							</Button>
						)}
					</Can>
				)
			},
		} as any)
	}

	return cols
}

export default columns
