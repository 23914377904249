import { useMutation, queryCache } from "react-query"
import { createTaskAttachment, CreateTaskAttachmentVariables, removeTaskAttachment, RemoveTaskAttachmentVariables } from "services/taskAttachments"
import { ITask } from "types/ITask"
import { updateObjectInArray } from "utils/misc"

const defaultCallbacks = {
	onSettled: (data: any) => {
		if (!data) return

		// Convert to array if multiple files were uploaded.
		const dataArr: any[] = [].concat(data)
		const updatedTask: ITask = dataArr.pop()
		if (!updatedTask) return;

		const currentTasks = queryCache.getQueryData(["tasks", { checkId: updatedTask.check }])
		// const currentTask = currentTasks.filter(t => t._id === data.task)[0]

		// currentTask.documents = updateObjectInArray(currentTask.documents, data)
		const updatedTasks = updateObjectInArray(currentTasks, updatedTask)

		queryCache.setQueryData(["tasks", { checkId: updatedTask.check }], updatedTasks)
		// queryCache.invalidateQueries(["currentTask", { taskId }])
	},
}

export default function useTaskAttachments() {
	const [addAttachment, addAttachmentInfo] = useMutation<any, Error, CreateTaskAttachmentVariables>(createTaskAttachment, defaultCallbacks)
	const [removeAttachment, removeAttachmentInfo] = useMutation<any, Error, RemoveTaskAttachmentVariables>(removeTaskAttachment, defaultCallbacks)

	return {
		addAttachment,
		addAttachmentInfo,

		removeAttachment,
		removeAttachmentInfo,
	}
}
