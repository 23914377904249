import { instance as axios } from "config/axios"

export type MarkAsReadVariables = { notificationId: string }

export async function markAsRead({ notificationId }: MarkAsReadVariables) {
	const res = axios.post(`/notifications/${notificationId}/markAsRead`)
	return res
}

export async function markAllAsRead() {
	const res = axios.post(`/notifications/all/markAsRead`)
	return res
}

export async function getNotifications() {
	const { data } = await axios.get(`/notifications`)
	return data
}
