import * as React from "react"
import { toaster, ToasterContainer, PLACEMENT, ToastProps } from "baseui/toast"

import GlobalContext from "context/GlobalContext"

export type IToast = {
	type: "info" | "positive" | "warning" | "negative"
	message: React.ReactNode
	props?: Readonly<ToastProps>
}

const ToastContainer = () => {
	const { activeToast } = React.useContext(GlobalContext)
	const [, setToastKey] = React.useState<React.ReactText | null>(null)

	React.useEffect(() => {
		if (activeToast) {
			// children: React.ReactNode,
			// props: Readonly<ToastProps>,

			const toast = toaster[activeToast.type](activeToast.message, { onClose: () => console.log("closed toast") })
			setToastKey(toast)
		}
	}, [activeToast])

	return <ToasterContainer placement={PLACEMENT.bottomRight} autoHideDuration={3000}></ToasterContainer>
}

export default ToastContainer
