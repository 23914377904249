import * as React from "react"
import { useAbility } from "@casl/react"

import { CustomRoute } from "types/shared"

import PageHeader from "components/layout/PageHeader"
import SubNavigation from "components/layout/SubNavigation"
import RoutesBuilder from "components/RoutesBuilder"
import PageLayout from "components/layout/PageLayout"
import { AbilityContext } from "components/Can"

import Sectors from "./Sectors"
// import Categories from "./Categories"
import Advanced from "./Advanced"
import General from "./General"
import { IProject } from "types/IProject"

export interface Props {
	currentProject: IProject
	onUpdate: Function
	updateProjectInfo: any
	onDelete: Function
	deleteProjectInfo: any
}

const Settings: React.FC<Props> = ({ currentProject, onUpdate, updateProjectInfo, onDelete, deleteProjectInfo }) => {
	const ability = useAbility(AbilityContext)
	const routes = React.useMemo(
		(): CustomRoute[] => [
			{
				text: "Allmänt",
				url: "",
				path: "",
				element: <General currentProject={currentProject} onUpdate={onUpdate} updateProjectInfo={updateProjectInfo} />,
			},
			{
				text: "Sektorer",
				url: "sectors",
				path: "sectors",
				element: (
					<Sectors sectors={currentProject && currentProject.sectors} updateProject={onUpdate} updateProjectInfo={updateProjectInfo} />
				),
			},
			// {
			// 	text: "Kategorier",
			// 	url: "categories",
			// 	path: "categories",
			// 	element: (
			// 		<Categories
			// 			categories={currentProject && currentProject.categories}
			// 			updateProject={onUpdate}
			// 			updateProjectInfo={updateProjectInfo}
			// 		/>
			// 	),
			// },
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentProject, updateProjectInfo, deleteProjectInfo],
	)

	if (ability.can("view", "AdvancedSettings")) {
		routes.push({
			text: "Avancerat",
			url: "advanced",
			path: "advanced",
			element: <Advanced onDelete={onDelete} deleteProjectInfo={deleteProjectInfo} />,
			conditions: { do: "delete", on: "Project" },
		})
	}

	return (
		<React.Fragment>
			<PageHeader title="Projektinställningar" />
			<SubNavigation links={routes} />
			<PageLayout full>
				<RoutesBuilder routes={routes} />
			</PageLayout>
		</React.Fragment>
	)
}

export default Settings
