import { ReactText, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Tabs, Tab } from "baseui/tabs-motion"
import { useAbility } from "@casl/react"

// import { customStyled } from "config/theme"
import { AbilityContext } from "components/Can"

export interface NavItem {
	text: string
	url: string
	path: string
	element: Element
}

export interface Props {
	links: any[]
}

const SubNavigation: React.FC<Props> = ({ links }) => {
	const params = useParams()
	const navigate = useNavigate()
	const ability = useAbility(AbilityContext)
	const [activeKey, setActiveKey] = useState<ReactText>("0")
	const [currentLinks, setCurrentLinks] = useState<NavItem[]>([])

	const onChange = ({ activeKey }: { activeKey: React.ReactText }) => {
		console.log("ON CHANGE")
		setActiveKey(activeKey)
		navigate(currentLinks[activeKey].url)
	}

	useEffect(() => {
		const filteredLinks: NavItem[] = links.filter((item, index) => {
			if (item.url === params["*"].replaceAll("/", "")) {
				console.log("Matches first", item, index, params)
				setActiveKey(index.toString())
			}

			if (item.conditions) {
				console.log("Matches conditions", item, index, params)
				if (ability.can(item.conditions.do, item.conditions.on)) {
					return item
				} else {
					return false
				}
			}

			console.log("Matches last", item, index, params)
			return item
		})

		setCurrentLinks(filteredLinks)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [links, params])

	return (
		<Tabs
			activeKey={activeKey}
			onChange={onChange}
			overrides={{
				TabBorder: { style: () => ({ height: "1px" }) },
				TabHighlight: {
					style: () => ({
						height: "2px",
						bottom: "4px",
					}),
				},
			}}>
			{currentLinks.map((link, index) => (
				<Tab
					overrides={{
						Tab: {
							style: ({ $theme }) => ({
								paddingTop: $theme.sizing.scale300,
								paddingBottom: $theme.sizing.scale300,
							}),
						},
						TabPanel: {
							style: () => ({
								display: "none",
							}),
						},
					}}
					title={link.text}
					key={index}></Tab>
			))}
		</Tabs>
	)
}

export default SubNavigation
