import * as React from "react"
import { useQuery, queryCache, useMutation } from "react-query"
import {
	getProject,
	deleteProject,
	createProject,
	updateProject,
	DeleteProjectVariables,
	CreateProjectVariables,
	UpdateProjectVariables,
	// getProjectPlan,
} from "services/projects"
import { useAction } from "react-unistore"
import { _setCurrentProject } from "actions/project"
import GlobalContext from "context/GlobalContext"
import { IProject } from "types/IProject"

export default function useProject(projectId?: string | undefined) {
	const { showToast } = React.useContext(GlobalContext)

	const defaultOptions = {
		onSettled: (data: any, error: any) => {
			if (error) {
				return
			}

			setProject(data)
		},
	}

	const { data: currentProject, refetch: _getProject, isLoading, isFetching, status, error } = useQuery<IProject, Error>(
		["currentProject", { projectId }],
		getProject,
		{
			...defaultOptions,
			enabled: false,
		},
	)

	const setProject = useAction(_setCurrentProject)
	// const setProjectPlan = useAction(_setProjectPlan)

	const [_deleteProject, deleteProjectInfo] = useMutation<any, Error, DeleteProjectVariables>(deleteProject, {
		onSuccess: ({ projectId }) => {
			queryCache.removeQueries(["currentProject", { projectId }])
			queryCache.removeQueries(["projectPlan", { projectId }])
			queryCache.removeQueries(["projectMembers", { projectId }])
			// queryCache.invalidateQueries("projects")
			showToast({ type: "positive", message: "Projektet har raderats" })
		},
	})
	const [_createProject, createProjectInfo] = useMutation<any, Error, CreateProjectVariables>(createProject, {
		onSuccess: updatedData => {
			showToast({ type: "positive", message: "Projektet har skapats" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Projektet gick inte att skapa" })
		},
		// onSettled: (data, error) => {
		// 	if (error) {
		// 		return
		// 	}

		// 	showToast({ type: "positive", message: "Projektet har skapats" })
		// },
	})
	const [_updateProject, updateProjectInfo] = useMutation<any, Error, UpdateProjectVariables>(updateProject, {
		// Optimistic update
		onMutate: ({ projectId, updatedData }) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			queryCache.cancelQueries(["currentProject", { projectId: projectId }])

			// Snapshot the previous value
			const previousData = queryCache.getQueryData<IProject>(["currentProject", { projectId: projectId }])

			// Optimistically update to the new value
			queryCache.setQueryData<IProject>(["currentProject", { projectId: projectId }], {
				...previousData,
				...updatedData,
			})

			// Return a rollback function
			return () => queryCache.setQueryData(["currentProject", { projectId: projectId }], previousData)
		},
		onSuccess: updatedData => {
			showToast({ type: "positive", message: "Projektet har uppdaterats" })
			// console.log("UPDATEd", updatedData)
			// queryCache.setQueryData(["currentProject", { projectId: projectId }], oldData => {
			// 	const project = {
			// 		...oldData,
			// 		updatedData,
			// 	}
			// 	setProject(project)
			// })
		},
		onError: (error: any, updatedData, rollback: any) => {
			let message = "Projektet gick inte att uppdatera"
			if (error.response && error.response.data.message === "Sector is in use.") {
				message = "Sektorn används i projektet"
			}
			showToast({ type: "negative", message: message })
			rollback()
		},
		onSettled: () => {
			queryCache.invalidateQueries(["currentProject", { projectId: projectId }])
		},
	})

	return {
		currentProject,
		getProject: _getProject,
		isLoading,
		isFetching,
		status,
		error,

		deleteProject: _deleteProject,
		deleteProjectInfo,

		createProject: _createProject,
		createProjectInfo,

		updateProject: _updateProject,
		updateProjectInfo,
	}
}
