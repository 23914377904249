import { instance as axios } from "config/axios"

export type GetUserActivitiesVariables = { userId: string }
export type CreateUserVariables = { updatedData: any }
export type UpdateUserVariables = { userId: string, updatedData: any }
export type UploadPhotoVariables = { files: File[] }

export async function getUserActivities(key: string, { userId }: GetUserActivitiesVariables) {
	const { data } = await axios.get(`/profile/${userId}/activities`)
	return data
}

export async function getCurrentUserAction(key: string) {
	try {
		return await axios.get(`/users/me`)
	} catch (err) {
		return err.response
	}
	// const { data } = await axios.get(`/users/me`)
	// return data
}

export async function getCurrentUser(key: string) {
	const { data } = await axios.get(`/users/me`)
	return data
}

export async function getUser(key: string, { id }: { id: string }) {
	const { data } = await axios.get(`/users/${id}`)
	return data
}

export async function createUser({ updatedData }: CreateUserVariables) {
	const { data } = await axios.post("/users", updatedData)
	return data
}

export async function updateUser({ userId, updatedData }: UpdateUserVariables) {
	const { data } = await axios.patch(`/users/${userId}`, updatedData)
	return data
}

export async function deleteUser(key: string) {
	const { data } = await axios.delete(`/users/me`)
	// const { data } = await axios.delete(`/users/${id}`)
	return data
}

export async function removePhoto(key: string) {
	const { data } = await axios.delete(`/users/me/photo`)
	return data
}

export async function uploadPhoto({ files }: UploadPhotoVariables) {
	const formData = new FormData()
	formData.append("file", files[0])

	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	}

	const { data } = await axios.post(`/users/me/photo`, formData, config)
	return data
}
