import * as React from "react"
import { Routes, Route, useParams } from "react-router-dom"

import { IProject } from "types/IProject"
import useControl from "hooks/useControl"
import Control from "./Control"

interface Props {
	currentProject: IProject
}

const ControlContainer: React.FC<Props> = ({ currentProject }) => {
	const { controlId } = useParams()
	const { currentControl, updateControl, sendControlForApproval } = useControl(controlId)

	// console.log("%c<ControlContainer/>", "color: blue;", currentControl)

	return (
		<Routes>
			<Route
				path="*"
				element={
					<Control
						currentProject={currentProject}
						currentControl={currentControl}
						updateControl={updateControl}
						sendControlForApproval={sendControlForApproval}
						// deleteControl={deleteControl}
						// approveControl={approveControl}
					/>
				}
			/>
		</Routes>
	)
}

export default ControlContainer
