import * as React from "react"
import { useQuery, useMutation, queryCache } from "react-query"

import { getCompany, removeCompany, createCompany, updateCompany, UpdateCompanyVariables, CreateCompanyVariables, RemoveCompanyVariables } from "services/companies"
import GlobalContext from "context/GlobalContext"
import { ICompany } from "types/ICompany"

const defaultCallbacks = {
	// onSuccess: ({ companyId }) => {
	// 	queryCache.invalidateQueries(["company", { companyId }])
	// },

	onSettled: (company: any, error: any) => {
		if (error) {
			return
		}

		queryCache.invalidateQueries(["companies", { projectId: company.project }])
	},
}

export default function useCompany(companyId?: string | undefined) {
	const { showToast } = React.useContext(GlobalContext)

	const { data: company, error, status, isLoading } = useQuery<ICompany, Error>(
		["company", { companyId }],
		getCompany,
		{ enabled: companyId },
	)

	const [_removeCompany, removeCompanyInfo] = useMutation<any, Error, RemoveCompanyVariables>(removeCompany, {
		...defaultCallbacks,
		onSuccess: ({ companyId }) => {
			queryCache.invalidateQueries(["company", { companyId }])
			showToast({ type: "positive", message: "Företaget har tagits bort" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Företaget gick inte att ta bort" })
		},
	})
	const [_updateCompany, updateCompanyInfo] = useMutation<any, Error, UpdateCompanyVariables>(updateCompany, {
		...defaultCallbacks,
		onMutate: ({ companyId, updatedData }) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			queryCache.cancelQueries(["company", { companyId: companyId }])

			// Snapshot the previous value
			const previousData = queryCache.getQueryData<ICompany>(["company", { companyId: companyId }])

			// Optimistically update to the new value
			queryCache.setQueryData(["company", { companyId: companyId }], {
				...previousData,
				...updatedData,
			})

			// Return a rollback function
			return () =>
				queryCache.setQueryData(["company", { companyId: companyId }], previousData)
		},
		onError: (err, updatedData, rollback: any) => {
			showToast({ type: "negative", message: "Det gick inte att uppdatera företaget" })
			rollback()
		},
		onSuccess: ({ companyId }) => {
			// 	queryCache.invalidateQueries(["company", { companyId }])
			showToast({ type: "positive", message: "Företaget har uppdaterats" })
		},
	})
	const [_createCompany, createCompanyInfo] = useMutation<any, Error, CreateCompanyVariables>(createCompany, {
		...defaultCallbacks,
		onSuccess: ({ companyId }) => {
			// queryCache.invalidateQueries(["company", { companyId }])
			showToast({ type: "positive", message: "Företaget har skapats" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Det gick inte att skapa företaget" })
		},
	})

	return {
		company,
		isLoading,
		status,
		error,

		removeCompany: _removeCompany,
		removeCompanyInfo,

		updateCompany: _updateCompany,
		updateCompanyInfo,

		createCompany: _createCompany,
		createCompanyInfo,
	}
}
