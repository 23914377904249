import { Ability, AbilityClass, detectSubjectType, subject, SubjectRawRule } from "@casl/ability"
import store from "store"

import { IProfile } from "types/IProfile";
import { IProjectPlan, IProjectPlanControl } from "types/IProjectPlan";
import { ICompany } from "types/ICompany";
import { IProject } from "types/IProject";
import { ICheck } from "types/ICheck";
import { ITask } from "types/ITask";

import defineResourceRulesFor from "./resourceRules"
import defineProjectRulesFor from "./projectRules"
import { IControl } from "types/IControl";

export type Actions = "view" | "create" | "read" | "update" | "delete" | "viewSettings" | "sendForApproval" | "approve" | "createAdmin" | "manageMembers";
export type SubjectStrings = "Project" | "ProjectPlan" | "Control" | "Check" | "Task" | "Documents" | "Document"
	| "Company" | "CompanyDocuments" | "CompanyFiles"
	| "User" | "Profile" | "Members" | "Member" | "Activity"
	| "CompleteProjectPlan" | "GlobalDocuments" | "Settings" | "CompanySettings" | "AdvancedSettings";
export type Subjects = SubjectStrings | IProject | IProjectPlan | IProjectPlanControl | ICompany | IProfile | IControl | ICheck | ITask

export type TAppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<TAppAbility>;

export function forceType(type: SubjectStrings, obj: any) {
	// return Object.assign(obj, { __type: type })

	const clone = { ...obj };
	return subject(type, clone);
}

export function setType(type: Subjects, obj: any) {
	return Object.assign(obj, { __type: type })
}

// Defines how to detect object's type
// function subjectName(item: { __type: Subjects, type: Subjects } | string) {
// 	if (!item || typeof item === "string") {
// 		return item
// 	}

// 	return item.__type ? item.__type : item.type || null
// }

// const ability = new Ability([], { subjectName })
// const ability = new Ability<[Actions, Subjects]>([], { subjectName })
const ability = new AppAbility([], {
	detectSubjectType: (subject: any): any => {
		if (!subject) return "";

		if (typeof subject === "string") {
			console.log('THIS IS THE ITEM STRING', subject)
			return subject
		}

		// const type: string | undefined = detectSubjectType(subject)
		// const itemToReturn = subject.__type ? subject.__type : subject.type || null

		// // if (!type) return "";
		// console.log('THIS IS THE ITEM', subject, type, itemToReturn)
		// return itemToReturn

		if (subject && typeof subject === 'object' && subject.__type) {
			// console.log('theitem', subject, subject.__type);
			return subject.__type;
		}

		const thesubject = detectSubjectType(subject);
		console.log('THEITEM', subject, thesubject)
		return thesubject;
	}
})

let profile: IProfile | null

store.subscribe(() => {
	if (!store.getState().profileAbilities) return

	const prevProfile = profile

	profile = store.getState().profileAbilities

	if (profile && JSON.stringify(prevProfile) !== JSON.stringify(profile)) {
		let rules: SubjectRawRule<any, any, any>[] = []
		const profileAbilities = defineProjectRulesFor(profile)

		Array.prototype.push.apply(rules, profileAbilities)
		profile.abilities &&
			profile.abilities.forEach(rule => {
				Array.prototype.push.apply(rules, defineResourceRulesFor(rule))
			})
		ability.update(rules)
	}
})

export default ability
