import { useQuery } from "react-query"
import { getChecks } from "services/checks"
import { ICheck } from "types/ICheck"

export default function useChecks(controlId: string) {
	const { data: checks, isLoading, status, error } = useQuery<ICheck[]>(
		["checks", { controlId }],
		getChecks,
		{ enabled: controlId },
	)

	return {
		checks,
		isLoading,
		status,
		error,
	}
}
