import * as React from "react"
// import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Input, SIZE as INPUTSIZE } from "baseui/input"
// import { Button, SIZE, SHAPE } from "baseui/button"
// import { StatefulPopover } from "baseui/popover"
// import { ParagraphXSmall } from "baseui/typography"

import { useDebounce } from "hooks/helpers/useDebounce"

export interface Props {
	onSearch: Function
	activeFilters: Object
	clearFilters: Function
	FilterComponent: any
}

const PlanFilter: React.FC<Props> = ({ onSearch, activeFilters, clearFilters, FilterComponent }) => {
	// const [css, theme] = useStyletron()
	const [value, setValue] = React.useState<string>("")
	const [search, setSearch] = React.useState<string>("")
	const debouncedSearch = useDebounce(search, 300)

	React.useEffect(() => {
		onSearch(debouncedSearch)
	}, [debouncedSearch, onSearch])

	// const activeFiltersLength: number = Object.keys(activeFilters).length

	return (
		<Block marginBottom="scale800" display="flex" flexDirection="column" alignItems="flex-end">
			<Block maxWidth="400px" width="100%" display="flex" alignItems="center" marginBottom="scale200">
				<Input
					type="text"
					placeholder="Sök..."
					onChange={(e: any) => {
						setValue(e.target.value)
						setSearch(e.target.value)
					}}
					size={INPUTSIZE.compact}
					value={value}
					clearable={true}
					overrides={{
						Root: {
							style: ({ $theme }) => ({ marginRight: $theme.sizing.scale600 }),
						},
					}}
				/>

				{/* <StatefulPopover
					content={() => (
						<Block padding={"20px"} backgroundColor="white">
							<Block display="flex" marginBottom="scale400">
								{FilterComponent}
							</Block>

							{activeFiltersLength > 0 && (
								<span onClick={() => clearFilters()}>
									<ParagraphXSmall
										className={css({
											cursor: "pointer",
											marginLeft: theme.sizing.scale400,
											marginTop: theme.sizing.scale800,
											marginBottom: "0px",
										})}>
										Rensa alla filter
									</ParagraphXSmall>
								</span>
							)}
						</Block>
					)}
					returnFocus
					autoFocus>
					<Button
						size={SIZE.compact}
						shape={SHAPE.pill}
						overrides={{
							BaseButton: {
								style: ({ $theme }) => ({
									backgroundColor: activeFiltersLength > 0 ? $theme.colors.accent400 : $theme.colors.mono1000,
									minWidth: "100px",
								}),
							},
						}}>
						{activeFiltersLength > 0 ? `Filtrera (${activeFiltersLength})` : "Filtrera"}
					</Button>
				</StatefulPopover> */}
			</Block>
		</Block>
	)
}

export default PlanFilter
