import { LabelSmall, ParagraphSmall, ParagraphXSmall } from "baseui/typography"

export const getProfileLabel = ({ option }: any) => {
	console.log("THE OPTJIOON", option)
	// queryCache.getQueryData()
	if (!option) return null

	return (
		<>
			<ParagraphSmall margin="0">{option.fullName}</ParagraphSmall>
			<ParagraphXSmall
				overrides={{
					Block: {
						style: ({ $theme }) => ({
							margin: "0",
							color: $theme.colors.mono700,
						}),
					},
				}}>
				{option.company.displayName || option.company.name}
				{/* {descriptions[option.key]} */}
			</ParagraphXSmall>
		</>
	)
}

export const getPermissionsLabel = ({ option }: any) => {
	if (!option) return null

	const descriptions = {
		limited: "Kan endast läsa under en kontrollpunkt.",
		normal: "Kan hantera redovisningar och skicka redovisning för godkännande.",
		advanced: "Medlem + Kan godkänna redovisning, skicka kontrollpunkt för godkännande",
	}

	return (
		<>
			<LabelSmall>{option.label}</LabelSmall>
			<ParagraphXSmall
				overrides={{
					Block: {
						style: ({ $theme }) => ({
							margin: "0",
							color: $theme.colors.mono700,
						}),
					},
				}}>
				{descriptions[option.key]}
			</ParagraphXSmall>
		</>
	)
}
