import * as React from "react"
import { Draggable } from "react-beautiful-dnd"
import { useStyletron } from "baseui"

import DragHandle from "./DragHandle"

// const grid = 8;
const getDraggableStyle = (isDragging: boolean, draggableStyle: any) => ({
	// background: "#fff",
	backgroundColor: isDragging ? "#fff" : "transparent",
	border: isDragging ? "3px solid black" : "0",
	// border: isDragging ? "3px solid black" : "3px solid transparent",
	...draggableStyle,
})

interface Props {
	id: string
	index: number
	canEdit?: boolean
	customHandle?: boolean
	children: any
}

const DraggableContent: React.FC<Props> = ({ id, index, children, customHandle, canEdit = true, ...props }) => {
	const [css, theme] = useStyletron()

	if (canEdit) {
		return (
			<Draggable key={id} draggableId={id} index={index}>
				{(provided, snapshot) => (
					<div
						// className="dnd-draggable"
						className={css({
							userSelect: "none",
							position: "relative",
							// margin: ".2rem 0",
							// padding: ".4rem",
						})}
						ref={provided.innerRef}
						{...provided.draggableProps}
						style={getDraggableStyle(snapshot.isDragging, provided.draggableProps.style)}>
						{customHandle ? (
							React.cloneElement(children, {
								dragHandleProps: provided.dragHandleProps,
								isDragging: snapshot.isDragging,
							})
						) : (
							<React.Fragment>
								<span
									className={css({
										position: "absolute",
										top: "50%",
										transform: "translateY(-50%)",
										left: "10px",
										display: "inline-block",
										opacity: "1",
										transitionDuration: theme.animation.timing300,
										transitionProperty: "all",
										marginRight: "10px;",
									})}
									{...provided.dragHandleProps}>
									<DragHandle size="20" {...props} />
								</span>
								{React.cloneElement(children, {
									isDragging: snapshot.isDragging,
								})}
							</React.Fragment>
						)}
					</div>
				)}
			</Draggable>
		)
	} else {
		return <React.Fragment>{children}</React.Fragment>
	}
}

export default DraggableContent
