import * as UserService from "services/users"
import { IState } from "store"
import { IUser } from "types/IUser"

export const _getCurrentUser = async (state: IState) => {
	const response = await UserService.getCurrentUserAction('currentuser')

	if (!response) {
		return { ...state, response: null, currentUserState: { message: "Internal Server Error", status: 500 } }
	}

	if (response.status && response.status === 404) {
		return { ...state, response: null, currentUserState: { message: "User not found", status: 404 } }
	}

	return { ...state, currentUser: response.data, currentUserState: { message: "Ok", status: 200 } }
}

export const _getUser = async (state: IState, id: string) => {
	const currentUser = await UserService.getUser('getuser', { id })
	return { ...state.currentUser, currentUser }
}

export const _updateUser = async (state: IState, id: string, data: IUser) => {
	const currentUser = await UserService.updateUser({ userId: id, updatedData: data })
	return { ...state.currentUser, currentUser }
}

export const _createUser = async (state: IState, data: IUser) => {
	const currentUser = await UserService.createUser({ updatedData: data })
	return { ...state.currentUser, currentUser }
	// return { ...state }
}

export const _resetCurrentUser = (state: IState) => {
	return { ...state, currentUser: null }
}

export const _updatePhoto = async (state: IState, files: File[]) => {
	const currentUser = await UserService.uploadPhoto({ files })
	return { ...state, currentUser }
}
