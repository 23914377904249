import * as React from "react"
import { Link } from "react-router-dom"
import { Button, KIND, SHAPE } from "baseui/button"
import { Display4 } from "baseui/typography"
import { ListItem, ListItemLabel } from "baseui/list"
import { useStyletron } from "baseui"
import Skeleton from "react-loading-skeleton"
import { MapPin } from "react-feather"

import useProjects from "hooks/useProjects"
import { dateTimeFormatter } from "utils/format"
import { IProject } from "types/IProject"

import { ContentBlock } from "components/ContentBlock"
import PageHeader from "components/layout/PageHeader"

const Dashboard = () => {
	const [css, theme] = useStyletron()
	const { projects, isLoading }: any = useProjects()

	if (isLoading) {
		return <Skeleton />
	}

	return (
		<React.Fragment>
			<ContentBlock maxWidth="800px">
				<PageHeader title={<Display4 marginBottom="scale500">Projekt</Display4>}>
					<Link to="/createProject">
						<Button kind={KIND.primary} shape={SHAPE.pill}>
							Skapa projekt
						</Button>
					</Link>
				</PageHeader>
				<ul
					id="projectList"
					className={css({
						padding: "0",
					})}>
					{projects &&
						projects.map((project: IProject) => (
							<ListItem
								key={project._id}
								endEnhancer={() => (
									<ListItemLabel>
										<span className={css({ color: theme.colors.mono500, display: "flex", alignItems: "center" })}>
											<span className={css({ marginRight: theme.sizing.scale200 })}>
												<MapPin size="20" />
											</span>
											{project.location}
										</span>
									</ListItemLabel>
								)}>
								<ListItemLabel>
									<Link to={`p/${project._id}`}>{project.title}</Link>
									<span
										className={css({
											color: theme.colors.mono600,
											margin: 0,
										})}>
										{project.startDate ? `Startade den ${dateTimeFormatter(project.startDate)}` : ""}
									</span>
								</ListItemLabel>
							</ListItem>
						))}
				</ul>
			</ContentBlock>
		</React.Fragment>
	)
}

export default Dashboard
