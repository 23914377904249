import * as React from "react"
import { FormControl } from "baseui/form-control"
import { Select, SelectProps } from "baseui/select"
import { FieldError } from "react-hook-form"

export interface Props extends SelectProps {
	label?: string
	fieldError?: FieldError
}

const FormSelect: React.FC<Props> = props => {
	const { fieldError } = props

	return (
		<FormControl label={props.label || ""} error={fieldError && fieldError.message}>
			<Select valueKey="key" labelKey="label" placeholder={props.value ? "" : props.placeholder} {...props} />
		</FormControl>
	)
}

export default FormSelect
