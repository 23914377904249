import * as React from "react"
import { useForm } from "react-hook-form"
import { Button, KIND } from "baseui/button"
import { Input } from "baseui/input"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"

interface Props {
	onAdd: any
}

const Form: React.FC<Props> = ({ onAdd }) => {
	const { handleSubmit, register } = useForm()
	const [, theme] = useStyletron()

	return (
		<form onSubmit={handleSubmit(onAdd)}>
			<Block display="flex" marginTop={theme.sizing.scale800}>
				<Input
					name="title"
					id="title"
					overrides={{
						Root: {
							style: ({ $theme }) => ({
								// width: ["40%", "40%", "100%"],
								marginRight: $theme.sizing.scale400,
							}),
						},
					}}
					placeholder="Lägg till..."
					inputRef={register({
						required: true,
						minLength: 2,
					})}
				/>
				<Button kind={KIND.tertiary}>Lägg till</Button>
			</Block>
		</form>
	)
}

export default Form
