const pathObjCache = new Map()

export function getBy(obj: object, path: any, def?: string) {
	if (!path) {
		return obj
	}
	const cacheKey = typeof path === "function" ? path : JSON.stringify(path)

	const pathObj =
		pathObjCache.get(cacheKey) ||
		(() => {
			const pathObj = makePathArray(path)
			pathObjCache.set(cacheKey, pathObj)
			return pathObj
		})()

	let val

	try {
		val = pathObj.reduce((cursor: any, pathPart: any) => cursor[pathPart], obj)
	} catch (e) {
		// continue regardless of error
	}
	return typeof val !== "undefined" ? val : def
}

const reOpenBracket = /\[/g
const reCloseBracket = /\]/g

function makePathArray(arr: any[]) {
	return (
		flattenDeep(arr)
			// remove all periods in parts
			.map(d => String(d).replace(".", "_"))
			// join parts using period
			.join(".")
			// replace brackets with periods
			.replace(reOpenBracket, ".")
			.replace(reCloseBracket, "")
			// split it back out on periods
			.split(".")
	)
}

function flattenDeep(arr: any[], newArr = []) {
	if (!Array.isArray(arr)) {
		newArr.push(arr)
	} else {
		for (let i = 0; i < arr.length; i += 1) {
			flattenDeep(arr[i], newArr)
		}
	}
	return newArr
}
