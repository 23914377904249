import { useQuery, useMutation } from "react-query"
import {
	getTaskDocuments,
	createTaskDocument,
	removeTaskDocument,
	approveTaskDocument,
	CreateTaskDocumentVariables,
	RemoveTaskDocumentVariables,
	ApproveTaskDocumentVariables,
} from "services/taskDocuments"
import { IDocument } from "types/IDocument"
// import { updateObjectInArray } from "utils/misc"

const onApproval = {
	onSettled: (data: any) => {
		if (!data) return

		const documents: IDocument = [].concat(data)[0]
		if (!documents) return;

		// const currentTasks = queryCache.getQueryData(["tasks", { checkId: document.check }])
		// const currentTask = currentTasks.filter(t => t._id === document.task)[0]

		// currentTask.documents = updateObjectInArray(currentTask.documents, document)
		// const updatedTasks = updateObjectInArray(currentTasks, currentTask)

		// queryCache.setQueryData(["tasks", { checkId: document.check }], updatedTasks)
		// queryCache.invalidateQueries(["tasks", { checkId: document.check }])
	},
}

const onAdd = {
	onSettled: (data: any) => {
		if (!data) return

		// const documents = [].concat(data)
		// const checkId = documents[0].check
		// const taskId = documents[0].task

		// const currentTasks = queryCache.getQueryData(["tasks", { checkId }])
		// const currentTask = currentTasks.filter(t => t._id === taskId)[0]

		// documents.forEach(document => {
		// 	currentTask.documents.push(document)
		// })

		// const updatedTasks = updateObjectInArray(currentTasks, currentTask)
		// queryCache.setQueryData(["tasks", { checkId }], updatedTasks)
		// queryCache.invalidateQueries(["tasks", { checkId: document.check }])
	},
}

const onRemove = {
	onSettled: (data: any) => {
		if (!data) return
		// const document = [].concat(data)[0]

		// const currentTasks = queryCache.getQueryData(["tasks", { checkId: document.check }])
		// const currentTask = currentTasks.filter(t => t._id === document.task)[0]

		// console.log("CURRENT TASKS", currentTasks)
		// console.log("CURREN TASK", currentTask)

		// currentTask.documents = currentTask.documents.filter(doc => doc._id !== document._id)
		// const updatedTasks = updateObjectInArray(currentTasks, currentTask)

		// console.log("UPDATED TASKS", updatedTasks)

		// queryCache.setQueryData(["tasks", { checkId: document.check }], updatedTasks)
		// queryCache.invalidateQueries(["tasks", { checkId: document.check }])
	},
}

export default function useTaskDocuments(taskId?: string | undefined) {
	const { data: documents, isLoading, isFetching, error } = useQuery<IDocument[], Error>(
		["taskDocuments", { taskId }],
		getTaskDocuments,
		{ enabled: taskId },
	)

	const [addDocument, addDocumentInfo] = useMutation<any, Error, CreateTaskDocumentVariables>(createTaskDocument, onAdd)
	const [removeDocument, removeDocumentInfo] = useMutation<any, Error, RemoveTaskDocumentVariables>(removeTaskDocument, onRemove)
	const [approveDocument, approveDocumentInfo] = useMutation<any, Error, ApproveTaskDocumentVariables>(approveTaskDocument, onApproval)

	return {
		documents,
		isLoading,
		isFetching,
		error,

		addDocument,
		addDocumentInfo,

		removeDocument,
		removeDocumentInfo,

		approveDocument,
		approveDocumentInfo,
	}
}
