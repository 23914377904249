import * as React from "react"

import Activities from "components/Activities"
import { ICheck } from "types/ICheck"

interface Props {
	currentCheck: ICheck
}

const CheckActivity: React.FC<Props> = ({ currentCheck }) => {
	return (
		<React.Fragment>
			<Activities entity="check" id={currentCheck._id} />
		</React.Fragment>
	)
}

export default CheckActivity
