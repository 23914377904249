import { useState, useEffect, useCallback } from "react"
import { StatefulTooltip } from "baseui/tooltip"
import { ParagraphXSmall, HeadingXSmall, Label4 } from "baseui/typography"
import { Info, MoreVertical } from "react-feather"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { Button, SHAPE, SIZE, KIND } from "baseui/button"
import { StatefulPopover } from "baseui/popover"
import { StatefulMenu } from "baseui/menu"

import { IProjectPlanSection } from "types/IProjectPlan"
import { isSectionDeleteable } from "utils/tree"

import ProjectplanColumn from "components/ProjectplanColumn"
import DragHandle from "components/DragHandle"
import Editable from "components/Editable"
import DropdownContent from "components/DropdownContent"

export interface Props {
	section: IProjectPlanSection
	canEdit: boolean

	onUpdate: Function
	onRemove: Function

	setIsGroupOpen: Function
	isGroupOpen: boolean

	isDragging?: boolean
	dragHandleProps?: DraggableProvidedDragHandleProps
}

const SectionHeader: React.FC<Props> = ({ section, canEdit, onUpdate, onRemove, dragHandleProps, isDragging, setIsGroupOpen, isGroupOpen }) => {
	const [css, theme] = useStyletron()
	const [isHovered, setIsHover] = useState(false)
	const [isDeleteable, setIsDeleteable] = useState(true)
	const checkDeleteable = useCallback(isSectionDeleteable, [])

	useEffect(() => {
		if (!section) return
		setIsDeleteable(checkDeleteable(section))
	}, [section, checkDeleteable])

	const onContentUpdate = (val: string, contentType: string) => {
		onUpdate({
			value: val,
			key: contentType === "text" ? "title" : "description",
		})
	}

	return (
		<div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
			<Block width="100%" position="relative" display="flex" alignItems="center" marginBottom="scale400">
				{canEdit && (
					<DragHandle
						size="20"
						className={css({
							opacity: isHovered || isDragging ? "1" : "0",
							transitionDuration: theme.animation.timing300,
							transitionProperty: "all",
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							left: "-26px",
						})}
						{...dragHandleProps}
					/>
				)}
				<HeadingXSmall
					overrides={{
						Block: {
							style: ({ $theme }) => ({
								margin: `0px ${$theme.sizing.scale200} 0px 0px`,
							}),
						},
					}}
					// className={css({
					// 	margin: `0px ${theme.sizing.scale200} 0px 0px`,
					// })}
				>
					{canEdit ? (
						<Editable
							onUpdate={(val: string) => onContentUpdate(val, "text")}
							value={section.title}
							type="text"
							{...{
								inputProps: {
									style: {
										backgroundColor: theme.colors.mono400,
										border: "0",
										outline: "none",
										minWidth: "auto",
										padding: "2px 4px",
									},
								},
								editOnViewClick: false,
							}}
							showButton={isHovered}
						/>
					) : (
						section.title
					)}
				</HeadingXSmall>

				{canEdit && (
					<StatefulTooltip
						content={() => (
							<Block padding={"0px"} backgroundColor="white" color="black">
								<Label4 marginTop="scale400">Beskrivning:</Label4>

								{canEdit ? (
									<Editable
										onUpdate={(val: string) => onContentUpdate(val, "textarea")}
										value={section.description}
										type="textarea"
										{...{ style: { color: "black" } }}
									/>
								) : (
									<ParagraphXSmall> section.description</ParagraphXSmall>
								)}
							</Block>
						)}
						overrides={{
							Inner: {
								style: ({ $theme }) => ({
									backgroundColor: $theme.colors.white,
								}),
							},
						}}
						returnFocus
						autoFocus>
						{/* <Button shape={SHAPE.round} size={SIZE.mini} kind={KIND.secondary}> */}
						<Info size="24" />
						{/* </Button> */}
					</StatefulTooltip>
				)}

				{!isDragging && !canEdit && section.description && (
					<StatefulTooltip
						content={() => (
							<Block padding={"0px"} backgroundColor="white">
								<ParagraphXSmall>
									{canEdit ? (
										<Editable
											onUpdate={(val: string) => onContentUpdate(val, "textarea")}
											value={section.description}
											type="textarea"
										/>
									) : (
										section.description
									)}
								</ParagraphXSmall>
							</Block>
						)}
						overrides={{
							Inner: {
								style: ({ $theme }) => ({
									backgroundColor: $theme.colors.white,
								}),
							},
						}}
						returnFocus
						autoFocus>
						{/* <Info size="16" /> */}
						<Info size="16" />
					</StatefulTooltip>
				)}

				<Block
					marginLeft="auto"
					display="flex"
					className={css({
						opacity: isDragging ? "0" : "1",
					})}>
					<ProjectplanColumn border={false} narrow>
						<StatefulPopover
							content={() => {
								let items = [
									{
										label: (
											<span onClick={() => setIsGroupOpen((val: boolean) => !val)}>
												{isGroupOpen ? "Stäng grupper" : "Öppna grupper"}
											</span>
										),
									},
								]

								if (canEdit) {
									items.push({
										label: isDeleteable ? (
											<span onClick={e => onRemove(section._id)}>Ta bort sektion</span>
										) : (
											<span
												className={css({
													color: theme.colors.mono500,
												})}>
												Ta bort sektion
											</span>
										),
									})
								}

								return (
									<DropdownContent padding="0">
										<StatefulMenu items={items} />
									</DropdownContent>
								)
							}}
							overrides={{
								Inner: {
									style: ({ $theme }) => ({
										backgroundColor: $theme.colors.white,
									}),
								},
							}}
							returnFocus
							autoFocus>
							<Button
								shape={SHAPE.round}
								size={SIZE.mini}
								kind={KIND.minimal}
								overrides={{
									BaseButton: {
										style: () => {
											return {
												opacity: isHovered ? "1" : "0",
											}
										},
									},
								}}>
								<MoreVertical size="16" />
							</Button>
						</StatefulPopover>
					</ProjectplanColumn>
				</Block>
			</Block>
		</div>
	)
}

export default SectionHeader
