import * as React from "react"
import { useParams } from "react-router-dom"
// import { saveAs } from "file-saver"

// import { instance } from "config/axios"
// import { buildFilePath } from "utils/misc"
import GlobalContext from "context/GlobalContext"
import { useDownloadDocument } from "hooks/helpers/useDownloadDocument"
import useGlobalDocuments from "hooks/useGlobalDocuments"
import { Column, UseRowSelectRowProps } from "react-table"

import { IDocument } from "types/IDocument"

import PageLayout from "components/layout/PageLayout"
import DataTable from "components/DataTable"

import globalColumns from "./globalColumns"
import { ModalEnum } from "containers/ModalContainer"

const Global = () => {
	const { projectId } = useParams()
	const { openModal } = React.useContext(GlobalContext)
	const { documents, removeDocument, removeDocuments } = useGlobalDocuments(projectId)
	const { isLoading: isDownloadLoading, downloadDocuments } = useDownloadDocument()
	const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)

	const viewDocument = (id: string) => {
		const docs: IDocument[] = documents as any
		const currentDocument = docs.filter((doc: IDocument) => doc._id === id)[0]
		openModal(ModalEnum.ViewDocument, { currentDocument })
	}

	const handleSelected = (selectedRows: UseRowSelectRowProps<IDocument>[]) => {
		if (!selectedRows.length) return null
	}

	const onDelete = React.useCallback(
		async rows => {
			const confirm = window.confirm(`Är du säker på att du vill ta bort ${rows.length} filer?`)
			if (!confirm) return null

			setIsDeleteLoading(true)

			const documentsToDelete = [].concat(rows)
			const documentIds = documentsToDelete.map((doc: any) => (doc.hasOwnProperty("_id") ? doc._id : doc.original._id))

			console.log(documentIds)
			const response = await removeDocuments({ projectId, documentIds })
			console.log(response)
			setIsDeleteLoading(false)
		},
		[projectId, removeDocuments],
	)

	const onDownload = React.useCallback(
		async documents => {
			await downloadDocuments(documents)
		},
		[downloadDocuments],
	)

	// const onDownload = async row => {
	// 	const path = buildFilePath(row)
	// 	console.log(`${path}?download=true`)
	// 	const { data } = await instance({
	// 		url: `${path}?download=true`,
	// 		method: "GET",
	// 		responseType: "blob",
	// 	})

	// 	saveAs(data, row.name)
	// }

	const cols: Column<any>[] = React.useMemo(
		() => globalColumns(viewDocument, removeDocument, projectId, onDelete, onDownload),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[projectId, documents],
	)

	return (
		<PageLayout full>
			{documents && documents.length ? (
				<DataTable
					name="globalDocuments"
					columns={cols}
					data={documents}
					// searchable={["name"]}
					// selectable
					onSelect={handleSelected}
					onDelete={onDelete}
					isDeleteLoading={isDeleteLoading}
					onDownload={onDownload}
					isDownloadLoading={isDownloadLoading}></DataTable>
			) : (
				<div className="empty">
					<p className="empty-title h5">Det finns inga projektdokument</p>
				</div>
			)}
		</PageLayout>
	)
}

export default Global
