import * as React from "react"
import { Link } from "react-router-dom"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { Button, KIND } from "baseui/button"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"
import { Block } from "baseui/block"

import { DevTool } from "@hookform/devtools"

import { IProject } from "types/IProject"
import { getKeysFromArray } from "utils/misc"
import { ContractOptionsArray, SettingsPrefixArray } from "utils/enums"

import { ContentBlockAction, ContentBlockSubmitButton } from "components/ContentBlock"
import FormInput from "components/Form/FormInput"
import FormSelect from "components/Form/FormSelect"
import FormDate from "components/Form/FormDate"

interface Props {
	currentProject: IProject | null
	onCancel?: string | boolean
	onUpdate?: Function
	updateProjectInfo?: any
	createProject?: Function
	createProjectInfo?: any
	isNewProject?: boolean
}

interface FormValues {
	title: string
	cadastralReference: string
	location: string
	developer: string
	client: string
	startDate: Date | null
	endDate: Date | null
	typeOfContract?: string
	settings: {
		prefix: string
	}
}

const SettingsForm: React.FC<Props> = ({
	currentProject,
	onCancel,
	onUpdate,
	updateProjectInfo,
	createProject,
	createProjectInfo,
	isNewProject = false,
}) => {
	const [isSubmitted, setIsSubmitted] = React.useState(false)

	const { handleSubmit, register, errors, setValue, control, getValues } = useForm<FormValues>({})

	React.useEffect(() => {
		if (currentProject && !isSubmitted) {
			setValue("title", currentProject.title)
			setValue("cadastralReference", currentProject.cadastralReference)
			setValue("location", currentProject.location)
			setValue("developer", currentProject.developer)
			setValue("client", currentProject.client)
			setValue("startDate", currentProject.startDate ? new Date(currentProject.startDate) : null)
			setValue("endDate", currentProject.endDate ? new Date(currentProject.endDate) : null)

			const type: any[] = ContractOptionsArray.filter(o => o.key === currentProject.typeOfContract)
			setValue("typeOfContract", [].concat(type[0] || []), { shouldValidate: true })

			const currentPrefix = currentProject.settings && currentProject.settings.prefix ? currentProject.settings.prefix : "numeric"
			const settingsType: any[] = SettingsPrefixArray.filter(o => o.key === currentPrefix)
			// console.log("THE TYPE:", settingsType)
			setValue("settings.prefix", [].concat(settingsType[0]), { shouldValidate: true })
		}
	}, [setValue, isSubmitted, currentProject])

	const onSave: SubmitHandler<FormValues> = (values, e) => {
		values.startDate = values.startDate ? new Date(values.startDate) : null
		values.endDate = values.endDate ? new Date(values.endDate) : null

		console.log("TypeOfContract", values.typeOfContract)

		if (values.typeOfContract !== undefined) {
			console.log("typeOfContract", getKeysFromArray(getValues("typeOfContract"))[0] || null)
			values.typeOfContract = getKeysFromArray(getValues("typeOfContract"))[0] || null
		}

		if (values.settings?.prefix !== undefined) {
			values.settings = {
				prefix: getKeysFromArray(getValues("settings.prefix"))[0],
			}
		}

		setIsSubmitted(true)
		if (isNewProject) {
			console.log("This is a new project. Creating new project")
			createProject && createProject(values)
		} else {
			onUpdate && onUpdate(values)
		}
	}

	// if (!currentProject) return null

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSave)}>
				<FlexGrid flexGridColumnCount={[1, 1, 1, 2]} flexGridColumnGap="scale800" flexGridRowGap={["scale200", "scale200", "scale600"]}>
					<FlexGridItem>
						<FormInput
							label="Projektnamn"
							fieldError={errors.title}
							placeholder="Projektnamn"
							id="title"
							name="title"
							inputRef={register({
								required: "Fältet är obligatoriskt.",
							})}
						/>
					</FlexGridItem>
					<FlexGridItem>
						<FormInput
							label="Fastighetsbeteckningar"
							fieldError={errors.cadastralReference}
							placeholder="..."
							id="cadastralReference"
							name="cadastralReference"
							inputRef={register({
								required: "Fältet är obligatoriskt.",
							})}
						/>
					</FlexGridItem>
					<FlexGridItem>
						<FormInput
							label="Ort/plats"
							fieldError={errors.location}
							placeholder="ex. Stockholm"
							id="location"
							name="location"
							inputRef={register({
								required: "Fältet är obligatoriskt.",
							})}
						/>
					</FlexGridItem>
					<FlexGridItem>
						<FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale800" flexGridRowGap={["scale0", "scale0", "scale600"]}>
							<FlexGridItem>
								<Controller
									render={props => (
										<FormDate
											label="Startdatum"
											value={props.value}
											maxDate={getValues("endDate") || undefined}
											name="startDate"
											onChange={({ date }: { date: Date | Date[] }) => setValue("startDate", date)}
											clearable
										/>
									)}
									control={control}
									defaultValue={null}
									name="startDate"
								/>
							</FlexGridItem>
							<FlexGridItem>
								<Controller
									render={props => (
										<FormDate
											label="Slutdatum"
											value={props.value}
											minDate={getValues("startDate") || undefined}
											name="endDate"
											onChange={({ date }: { date: Date | Date[] }) => setValue("endDate", date)}
											clearable
										/>
									)}
									control={control}
									defaultValue={null}
									name="endDate"
								/>
							</FlexGridItem>
						</FlexGrid>
					</FlexGridItem>

					<FlexGridItem>
						<FormInput
							label="Byggherre"
							fieldError={errors.developer}
							placeholder="Karl Karlsson"
							id="developer"
							name="developer"
							inputRef={register({
								required: "Fältet är obligatoriskt.",
							})}
						/>
					</FlexGridItem>

					<FlexGridItem>
						<FormInput
							label="Beställare"
							fieldError={errors.client}
							placeholder="Beställare"
							id="client"
							name="client"
							inputRef={register({
								required: "Fältet är obligatoriskt.",
							})}
						/>
					</FlexGridItem>

					{!isNewProject && (
						<>
							<FlexGridItem>
								<Controller
									render={props => (
										<FormSelect
											options={ContractOptionsArray || []}
											fieldError={errors.typeOfContract}
											value={props.value}
											label="Entreprenadform"
											clearable={true}
											searchable={false}
											placeholder={
												"Välj"
												// props.value && props.value.length > 0
												// 	? ""
												// 	: "Välj ett alternativ"
											}
											onChange={params =>
												setValue("typeOfContract", params.value, {
													shouldValidate: true,
												})
											}
										/>
									)}
									control={control}
									// rules={{
									// 	required: true,
									// 	validate: val => val.length > 0 || "Du måste välja ett alternativ",
									// }}
									name="typeOfContract"
									defaultValue={[]}
								/>
							</FlexGridItem>

							<FlexGridItem>
								<Controller
									render={props => (
										<FormSelect
											options={SettingsPrefixArray || []}
											fieldError={errors.settings && errors.settings.prefix}
											value={props.value}
											label="Projektplan hierarki"
											clearable={false}
											searchable={false}
											placeholder="Välj"
											onChange={params =>
												setValue("settings.prefix", params.value, {
													shouldValidate: true,
												})
											}
										/>
									)}
									control={control}
									rules={{
										required: true,
										validate: val => val.length > 0 || "Du måste välja ett alternativ",
									}}
									name="settings.prefix"
									defaultValue={[]}
								/>
							</FlexGridItem>
						</>
					)}
				</FlexGrid>

				{isNewProject ? (
					<Block marginTop="scale1200">
						<ContentBlockAction>
							{onCancel && (
								<Link to={onCancel ? onCancel.toString() : "/"}>
									<Button kind={KIND.tertiary}>Avbryt</Button>
								</Link>
							)}
							<Button
								overrides={{
									BaseButton: {
										style: () => ({
											margin: "0 auto",
											display: "block",
										}),
									},
								}}
								disabled={createProjectInfo && createProjectInfo.status === "loading"}
								isLoading={createProjectInfo && createProjectInfo.status === "loading"}>
								Nästa
							</Button>
						</ContentBlockAction>
					</Block>
				) : (
					<ContentBlockAction>
						<ContentBlockSubmitButton
							disabled={updateProjectInfo && updateProjectInfo.status === "loading"}
							isLoading={updateProjectInfo && updateProjectInfo.status === "loading"}>
							Spara
						</ContentBlockSubmitButton>
					</ContentBlockAction>
				)}
			</form>
			<DevTool control={control} />
		</React.Fragment>
	)
}

export default SettingsForm
