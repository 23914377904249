import { instance as axios } from "config/axios"

export type GetCompanyMembersVariables = { companyId: string }
export type GetCompanyMemberVariables = { companyId: string }
export type BindCompanyMemberVariables = { companyId: string, userProfileId: string, refetchProjectMembers: boolean, updatedData: any }
export type UpdateCompanyMemberVariables = { companyId: string, memberId: string, updatedData: any }

export async function getCompanyMembers(key: string, { companyId }: GetCompanyMembersVariables) {
	const { data } = await axios.get(`/companies/${companyId}/members`)
	return data
}

export async function getCompanyMember(key: string, { companyId }: GetCompanyMemberVariables) {
	const { data } = await axios.get(`/companies/${companyId}/members/me`)
	return data
}

export async function bindCompanyMember({ companyId, userProfileId, refetchProjectMembers, updatedData }: BindCompanyMemberVariables) {
	const { data } = await axios.post(`/companies/${companyId}/members`, updatedData)
	// const { data } = await axios.post(`/companies/${companyId}/members/${userProfileId}`, updatedData)
	return data
}

export async function updateCompanyMember({ companyId, memberId, updatedData }: UpdateCompanyMemberVariables) {
	const { data } = await axios.patch(`/companies/${companyId}/members/${memberId}`, updatedData)
	return data
}

// export async function removeCompanyMember({ companyId, memberId }) {
// 		const { data } = await axios.delete(`/companies/${companyId}/members/${memberId}`)
// 		return data
// }
