import * as React from "react"
import { Block } from "baseui/block"
// import { useStyletron } from "baseui"

// import Settings from "./Settings"
import PermissionAvatars from "./PermissionsAvatars"
import { IControl } from "types/IControl"

interface Props {
	controlId: string
	projectId: string
	currentControl: IControl
	controlIsFreezed: boolean
}

const Options: React.FC<Props> = ({
	controlId,
	projectId,
	currentControl,
	controlIsFreezed,
	// onDelete,
}) => {
	// const [css, theme] = useStyletron()
	return (
		<Block display="flex" alignItems="center">
			<PermissionAvatars controlId={controlId} projectId={projectId} currentControl={currentControl} controlIsFreezed={controlIsFreezed} />
			{/* {!controlIsFreezed && (
				<React.Fragment>
					<div
						className={css({
							height: theme.sizing.scale800,
							width: "2px",
							background: theme.colors.mono300,
							margin: "0 10px 0 8px",
						})}></div>
					<Settings
						// onDelete={onDelete}
						controlId={controlId}
					/>
				</React.Fragment>
			)} */}
		</Block>
	)
}

export default Options
