import * as React from "react"
import { Routes, Route, Navigate } from "react-router-dom"

import MainLayout from "layouts/MainLayout"
import ProjectContainer from "containers/ProjectContainer"

import Dashboard from "pages/Dashboard"
import AddProject from "pages/AddProject"
import UserAccount from "pages/Account"
import NotFound from "pages/404"
import NewUser from "pages/NewUser"
import Updates from "pages/Updates"

const AppRoutes: React.FC = () => {
	return (
		<Routes>
			{/* General routes */}
			<Route path="/*" element={<MainLayout />}>
				<Route path="/" element={<Dashboard />} />
				<Route path="account" element={<UserAccount />} />
				<Route path="createProject" element={<AddProject />} />
				<Route path="updates" element={<Updates />} />
				<Route element={<NotFound />} />
			</Route>
			{/* If authenticated and returned from signup - redirect to newUser */}
			<Route path="/signup">
				<Navigate to="/newUser" />
			</Route>
			<Route path="newUser" element={<NewUser />} />
			{/* Project routes */}
			<Route path="/p/:projectId/*" element={<MainLayout />}>
				<Route path="*" element={<ProjectContainer />} />
			</Route>
			{/* <Route path="/p/:projectId" element={<ProjectLayout />}>
				<Route path="/*" element={<ProjectContainer />} />
			</Route> */}r{/* 404 */}
			{/* <LayoutRoute layout={DashboardLayout} element={<NotFound />} /> */}
		</Routes>
	)
}

export default AppRoutes
