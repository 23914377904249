import * as React from "react"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { List, Edit3 } from "react-feather"
import { DragDropContext, DropResult } from "react-beautiful-dnd"

import useTasks from "hooks/useTasks"
import { useTask } from "hooks/useTask"

import DroppableContent from "components/DroppableContent"
import DraggableContent from "components/DraggableContent"
import Can, { AbilityContext } from "components/Can"

import Task from "./Task"
import TaskForm from "./TaskForm"
import { reorder } from "utils/misc"
import { useAbility } from "@casl/react"
import { setType } from "config/abilities"
import { ICheck } from "types/ICheck"
import { ITask } from "types/ITask"
import { EntityStatus, TaskTypesEnum } from "types/enums"

interface Props {
	currentCheck: ICheck
	onUpdate: Function
	checkIsFreezed: boolean
}

const TaskList: React.FC<Props> = ({ currentCheck, onUpdate, checkIsFreezed }) => {
	const ability = useAbility(AbilityContext)
	const [css, theme] = useStyletron()

	const [isAdding, setIsAdding] = React.useState(false)
	const [addingType, setAddingType] = React.useState<keyof typeof TaskTypesEnum | "">("")
	const { tasks } = useTasks(currentCheck._id)
	const checkWithType = setType("Check", currentCheck)

	const {
		createTask,
		createTaskInfo,
		updateTask,
		// updateTaskInfo,
		deleteTask,
		// deleteTaskInfo,
		approveTask,
		// approveTaskInfo,
	} = useTask()

	const addTask = async (newTask: ITask) => {
		await createTask({ checkId: currentCheck._id, updatedData: newTask })

		setAddingType("")
		setIsAdding(false)
	}

	const cancelAdding = () => {
		setAddingType("")
		setIsAdding(false)
	}

	const showForm = (type: keyof typeof TaskTypesEnum) => {
		setIsAdding(true)
		setAddingType(type)
	}

	const _removeTask = (taskId: string) => {
		deleteTask({ taskId })
	}

	const approve = (currentTask: ITask) => {
		if (!window.confirm("Är du säker på att du vill ändra status på punkten?")) {
			return false
		}

		const response = {
			status: currentTask.status !== EntityStatus.approved ? EntityStatus.approved : EntityStatus.disapproved,
		}

		approveTask({ taskId: currentTask._id, updatedData: response })
	}

	const _updateTask = (taskId: string, updatedData: any) => {
		updateTask({ taskId, updatedData })
	}

	const onDragEnd = (result: DropResult) => {
		const { type, source, destination } = result

		if (!destination) {
			return
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}

		if (type === "TASKS") {
			const updatedTasks = reorder(currentCheck.tasks, source.index, destination.index)
			onUpdate({ tasks: updatedTasks })
			return
		}
	}

	return (
		<div>
			<Can do="create" on={currentCheck} type="Task">
				{() => (
					<Block
						display={["block", "flex"]}
						justifyContent="center"
						padding={`${theme.sizing.scale600} ${theme.sizing.scale0}`}
						className={css({ textAlign: "center" })}>
						{!isAdding && !checkIsFreezed && (
							<React.Fragment>
								<Button
									kind={KIND.secondary}
									shape={SHAPE.pill}
									size={SIZE.compact}
									onClick={() => showForm(TaskTypesEnum.notes)}
									endEnhancer={() => <Edit3 size="14" />}
									overrides={{
										BaseButton: {
											style: () => ({
												borderRadius: "4px",
												margin: theme.sizing.scale0,
												width: "100%",
												[theme.mediaQuery.medium]: {
													width: "auto",
													padding: `${theme.sizing.scale600} ${theme.sizing.scale800}`,
													margin: theme.sizing.scale400,
												},
											}),
										},
									}}
									// className={css({
									// 	borderRadius: "4px",
									// 	margin: theme.sizing.scale0,
									// 	width: "100%",
									// 	[theme.mediaQuery.medium]: {
									// 		width: "auto",
									// 		padding: `${theme.sizing.scale600} ${theme.sizing.scale800}`,
									// 		margin: theme.sizing.scale400,
									// 	},
									// })}
								>
									Lägg till anteckning
								</Button>
								<Button
									kind={KIND.secondary}
									shape={SHAPE.pill}
									size={SIZE.compact}
									onClick={e => showForm(TaskTypesEnum.files)}
									endEnhancer={() => <List size="14" />}
									overrides={{
										BaseButton: {
											style: () => ({
												borderRadius: "4px",
												margin: theme.sizing.scale0,
												width: "100%",
												[theme.mediaQuery.medium]: {
													width: "auto",
													padding: `${theme.sizing.scale600} ${theme.sizing.scale800}`,
													margin: theme.sizing.scale400,
												},
											}),
										},
									}}

									// className={css({
									// 	borderRadius: "4px",
									// 	margin: theme.sizing.scale0,
									// 	width: "100%",
									// 	[theme.mediaQuery.medium]: {
									// 		width: "auto",
									// 		padding: `${theme.sizing.scale600} ${theme.sizing.scale800}`,
									// 		margin: theme.sizing.scale400,
									// 	},
									// })}
								>
									Lägg till fillista
								</Button>
							</React.Fragment>
						)}

						{isAdding && (
							<TaskForm
								onCancel={cancelAdding}
								addTask={addTask}
								createTaskInfo={createTaskInfo}
								addingType={addingType}
								currentCheck={currentCheck}
							/>
						)}
					</Block>
				)}
			</Can>

			<DragDropContext onDragEnd={onDragEnd}>
				<DroppableContent id={"droppable-tasks"} type="TASKS" canEdit={ability.can("update", checkWithType)}>
					{currentCheck.tasks &&
						tasks &&
						currentCheck.tasks.map((taskId, index) => {
							const task = tasks.filter((t: ITask) => t._id === taskId)[0]

							if (!task) {
								return null
							}

							return (
								<DraggableContent id={task._id} index={index} key={index} canEdit={ability.can("update", checkWithType)} customHandle>
									<Task
										// key={index}
										task={task}
										check={currentCheck}
										checkWithType={checkWithType}
										checkIsFreezed={checkIsFreezed}
										onUpdate={_updateTask}
										onRemove={_removeTask}
										onApprove={approve}
									/>
								</DraggableContent>
							)
						})}

					{tasks && tasks.length < 1 && <div>Det finns inga punkter.</div>}
				</DroppableContent>
			</DragDropContext>
		</div>
	)
}

export default TaskList
