import * as React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND } from "baseui/button"
import { useForm, Controller } from "react-hook-form"
import { UploadCloud } from "react-feather"

import GlobalContext from "context/GlobalContext"
import useTaskDocuments from "hooks/useTaskDocuments"
import { DocumentTypesArray } from "utils/enums"
import { getKeysFromArray } from "utils/misc"

import Uploader from "components/Uploader"
import FormSelect from "components/Form/FormSelect"
import { ModalProps } from "containers/ModalContainer"
import { DocumentTypes } from "types/enums"

interface FormValues {
	documentType: DocumentTypes
}

const UploadTaskDocumentModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const { handleSubmit, control, errors, setValue } = useForm<FormValues>()
	const { modalPayload } = React.useContext(GlobalContext)
	const { taskId } = modalPayload
	const { addDocument, addDocumentInfo } = useTaskDocuments()
	const [files, setFiles] = React.useState<File[]>([])

	const filteredDocumentTypesArray = DocumentTypesArray.filter(type => type.key !== "selfControl")

	const addFiles = (files: File[]) => {
		setFiles(files)
		console.log(files)
	}

	const onClose = () => {
		closeModal()
	}

	const upload = async ({ documentType }: FormValues) => {
		if (!files) return null
		// console.log(values)
		console.log(files)

		try {
			await addDocument({ taskId: taskId || "", files, documentType: getKeysFromArray(documentType) as any })
			onClose()
		} catch (err) {}
	}

	return (
		<Modal onClose={closeModal} closeable isOpen={isOpen} animate autoFocus unstable_ModalBackdropScroll size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>Ladda upp dokument</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit(upload)} encType="multipart/form-data">
					<Uploader onAddedFiles={addFiles} showFileList={true} />
					<Controller
						render={props => (
							<FormSelect
								label="Filtyp"
								options={filteredDocumentTypesArray}
								fieldError={errors.documentType}
								value={props.value}
								clearable={false}
								placeholder="Välj filtyp"
								onChange={params => setValue("documentType", params.value, { shouldValidate: true })}
							/>
						)}
						control={control}
						rules={{ required: "Du måste välja ett alternativ" }}
						name="documentType"
					/>
				</form>
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Avbryt
				</ModalButton>

				<ModalButton
					type="submit"
					disabled={!files || !files.length || addDocumentInfo.status === "loading"}
					onClick={handleSubmit(upload)}
					isLoading={addDocumentInfo.status === "loading"}
					endEnhancer={() => <UploadCloud size={18} />}>
					Ladda upp
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default UploadTaskDocumentModal
