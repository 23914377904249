import { instance as axios } from "config/axios"
import { stringify } from "query-string"

export type GetProjectMembersVariables = { projectId: string, query: any }
export type GetProjectMemberVariables = { projectId: string, userId: string }
export type AddProjectMemberVariables = { projectId: string, companyId: string, updatedData: any, source?: boolean }
export type UpdateProjectMemberVariables = { projectId: string, memberId: string, updatedData: any }
export type RemoveProjectMemberVariables = { projectId: string, memberId: string, companyId: string }

export async function getProjectMembers(key: string, { projectId, query }: GetProjectMembersVariables) {
	const parsedQuery = stringify(query)
	const { data } = await axios.get(`/projects/${projectId}/members?${parsedQuery}`)
	return data
}

export async function getProjectMember(key: string, { projectId, userId }: GetProjectMemberVariables) {
	const { data } = await axios.get(`/projects/${projectId}/members/${userId}`)
	return data
}

export async function addProjectMember({ projectId, companyId, updatedData, source = false }: AddProjectMemberVariables) {
	console.log(companyId)
	const url = source ? `/projects/${projectId}/members?source=company` : `/projects/${projectId}/members`

	const { data } = await axios.post(url, updatedData)
	return data
}

export async function updateProjectMember({ projectId, memberId, updatedData }: UpdateProjectMemberVariables) {
	const { data } = await axios.patch(`/projects/${projectId}/members/${memberId}`, updatedData)
	return data
}

export async function removeProjectMember({ projectId, memberId, companyId }: RemoveProjectMemberVariables) {
	console.log("companyId", companyId)
	const { data } = await axios.delete(`/projects/${projectId}/members/${memberId}`)
	return data
}
