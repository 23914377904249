import * as React from "react"
import { Display4, ParagraphLarge } from "baseui/typography"
import { useStyletron } from "baseui"

import { ContentBlock } from "components/ContentBlock"
import PageHeader from "components/layout/PageHeader"

const Dashboard = () => {
	const [css] = useStyletron()

	return (
		<React.Fragment>
			<ContentBlock maxWidth="800px">
				<PageHeader title={<Display4 marginBottom="scale500">Uppdateringar</Display4>} />
				<ul
					className={css({
						padding: "0",
					})}>
					<li>
						<ParagraphLarge>6 nov 2020</ParagraphLarge>
						<ul>
							<li>• Nytt: Start av implementering av ny design</li>
							<li>• Fix: Autentisering, skapande av konto</li>
						</ul>
					</li>
				</ul>
			</ContentBlock>
		</React.Fragment>
	)
}

export default Dashboard
