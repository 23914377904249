import * as React from "react"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { StatefulPopover } from "baseui/popover"
import { ParagraphXSmall } from "baseui/typography"
import { MoreVertical } from "react-feather"
import { Block } from "baseui/block"
import { StatefulMenu } from "baseui/menu"

import { dateTimeFormatter } from "utils/format"

import StyledLink from "components/StyledLink"
import { Cell } from "react-table"
import { IDocument } from "types/IDocument"

const columns = (viewDocument: Function, removeDocument: Function, projectId: string, onDelete: Function, onDownload: Function) => {
	// const generateUrl = () => {
	// 	const stringToReplace = "https://alnarpoc.blob.core.windows.net/"
	// }

	return [
		{
			Header: "Filnamn",
			accessor: "name",
			width: 400,
			Cell: ({ row, value }: Cell<IDocument>) => (
				<StyledLink onClick={() => viewDocument(row.original._id)}>
					<span
						style={{
							whiteSpace: "nowrap",
							wordWrap: "break-word",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "block",
							width: "400px",
						}}>
						{value}
					</span>
				</StyledLink>
			),
		},
		{
			Header: "Uppladdat av",
			accessor: "createdBy.fullName",
			Cell: ({ row }: Cell<IDocument>) => {
				return (
					<Block display="flex" flexDirection="column">
						<StyledLink to={`/p/${projectId}/profile/${row.original.createdBy._id}`}>
							{row.original.createdBy.fullName} {row.original.createdBy.position}
						</StyledLink>{" "}
						<ParagraphXSmall color="mono700" margin="0">
							{dateTimeFormatter(row.original.createdAt)}
						</ParagraphXSmall>
					</Block>
				)
			},
		},
		{
			Header: "",
			accessor: "actionColumn",
			width: 50,
			Cell: ({ row }: Cell<IDocument>) => {
				return (
					<StatefulPopover
						content={() => {
							const items = []
							items.push({
								label: <span onClick={e => onDownload(row.original)}>Ladda ner</span>,
							})
							// }

							// if (ability.can("delete", task)) {
							items.push({
								label: (
									<span
										onClick={e =>
											removeDocument({
												projectId,
												documentId: row.original._id,
											})
										}>
										Ta bort
									</span>
								),
							})
							// }

							return (
								<Block {...{ ignoreclick: "" }}>
									<StatefulMenu items={items} />
								</Block>
							)
						}}>
						<Button size={SIZE.mini} kind={KIND.secondary} shape={SHAPE.round} {...{ ignoreclick: "" }}>
							<MoreVertical size="16" />
						</Button>
					</StatefulPopover>
				)
			},
		},
	]
}

export default columns
