import * as React from "react"
import { Column } from "react-table"
// import { ParagraphSmall } from "baseui/typography"

import GlobalContext from "context/GlobalContext"
import { prepareQuery } from "utils/helpers"
import { PaginationProps, useProjectDocuments } from "hooks/useProjectDocuments"
import useProjectMembers from "hooks/useProjectMembers"
import { useDownloadDocument } from "hooks/helpers/useDownloadDocument"

import { IDocument } from "types/IDocument"
import { SortingOption } from "types/shared"

// import Loader from "components/Loader"
import DataTable from "components/DataTable"
import documentColumns from "./documentColumns"
import { ModalEnum } from "containers/ModalContainer"

export interface Props {
	projectId: string
}

const DocumentList: React.FC<Props> = ({ projectId }) => {
	const { openModal } = React.useContext(GlobalContext)
	const [query, setQuery] = React.useState<any | null>(null)
	const { documents: documentsResponse, isLoading } = useProjectDocuments(projectId, query)
	const { downloadDocuments, isLoading: isDownloadLoading } = useDownloadDocument()
	const { members } = useProjectMembers(projectId)
	const [currentData, setCurrentData] = React.useState<IDocument[]>([])
	const [paginationProps, setPaginationProps] = React.useState<PaginationProps<IDocument> | null>(null)
	// const documents: any = []
	// const isLoading = false

	React.useEffect(() => {
		onFetchData({})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		if (isLoading) return

		let d: IDocument[] = []
		const response: any = documentsResponse
		const isPagination = response?.hasOwnProperty("docs") ? true : false
		let paginationOptions: any = null

		if (isPagination && response?.docs) {
			d = response.docs
			paginationOptions = response
		} else if (response?.length) {
			paginationOptions = null
			d = response
		}

		// console.log(response)

		setPaginationProps(paginationOptions)

		setCurrentData(d)
	}, [documentsResponse, isLoading])

	const viewDocument = (id: string) => {
		const currentDocument = currentData.filter((doc: any) => doc._id === id)[0]
		openModal(ModalEnum.ViewDocument, { currentDocument })
	}

	const onFetchData = React.useCallback((filters: object) => {
		if (!filters) return
		// console.log("CHANGED AND FETCHING DATA")
		const q = prepareQuery(filters, query)
		setQuery(q)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onDownload = React.useCallback(
		async (documents: IDocument | IDocument[]) => {
			await downloadDocuments(documents)
		},
		[downloadDocuments],
	)

	const columns: Column<IDocument>[] = React.useMemo(
		() => documentColumns(viewDocument, onDownload, projectId, members),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[projectId, documentsResponse, members],
	)

	// if (status !== "loading" && (!documentsResponse || !documentsResponse.length)) {
	// 	return <ParagraphSmall>Det finns inga filer</ParagraphSmall>
	// }

	// if (isLoading && !documentsResponse) {
	// 	console.log("S LOADINGDASKFDSKFJ")
	// 	return (
	// 		<div>
	// 			<Loader />
	// 		</div>
	// 	)
	// }

	const sortingOptions: SortingOption[] = [
		{ label: "Sortera på filnamn A-Z", value: "name", desc: false },
		{ label: "Sortera på filnamn Z-A", value: "name", desc: true },
		{ label: "Sortera på skapare A-Z", value: "createdBy.fullName", desc: false },
		{ label: "Sortera på skapare Z-A", value: "createdBy.fullName", desc: true },
	]

	if (!paginationProps) return null

	return (
		<DataTable<IDocument>
			name="projectDocuments"
			columns={columns}
			data={currentData}
			searchable={true}
			paginationProps={paginationProps}
			perPage={30}
			onSelect={() => null}
			onDownload={onDownload}
			isDownloadLoading={isDownloadLoading}
			isLoading={isLoading}
			onFetchData={onFetchData}
			sortingOptions={sortingOptions}
		/>
	)
}

export default DocumentList
