import * as React from "react"
import { queryCache } from "react-query"
import { FilterProps } from "react-table"
import { StatefulPopover } from "baseui/popover"
import { Button, KIND as BUTTONKIND, SIZE, SHAPE } from "baseui/button"

import { IProjectPlan, IProjectPlanControl } from "types/IProjectPlan"
import useControls from "hooks/useControls"

import Plan from "./Plan"
import { useParams } from "react-router-dom"

const PlanFilter = ({ column }: FilterProps<IProjectPlanControl>) => {
	const { filterValue } = column
	const { projectId } = useParams()
	const projectPlan: IProjectPlan | undefined = queryCache.getQueryData(["projectPlan", { projectId }])
	const { controls } = useControls(projectId)
	const unknownControls: IProjectPlanControl[] = controls as any

	return (
		<StatefulPopover content={<Plan column={column} projectPlan={projectPlan} controls={unknownControls} />} placement="bottomLeft">
			<Button kind={filterValue ? BUTTONKIND.primary : BUTTONKIND.secondary} size={SIZE.mini} shape={SHAPE.pill}>
				{column.Header} {filterValue && `(${filterValue.length})`}
			</Button>
		</StatefulPopover>
	)
}

export default PlanFilter
