// import * as React from "react"
import dayjs from "dayjs"

/************ HELPERS ************/
/************ HELPERS ************/
/************ HELPERS ************/
/************ HELPERS ************/
/************ HELPERS ************/
export const getChanges = function (oldArray, newArray) {
	if (JSON.stringify(oldArray) === JSON.stringify(newArray)) {
		return null
	}

	let changes = [],
		i,
		item,
		j,
		len

	for (i = j = 0, len = newArray.length; j < len; i = ++j) {
		item = newArray[i]
		if (JSON.stringify(item) !== JSON.stringify(oldArray[i])) {
			changes.push(item)
		}
	}

	return changes
}

/**
 * Get the closest matching element up the DOM tree.
 * @private
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 */
export const getClosest = (elem, selector) => {
	// Element.matches() polyfill
	if (!Element.prototype.matches) {
		Element.prototype.matches =
			Element.prototype.matchesSelector ||
			Element.prototype.mozMatchesSelector ||
			Element.prototype.msMatchesSelector ||
			Element.prototype.oMatchesSelector ||
			Element.prototype.webkitMatchesSelector ||
			function (s) {
				var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					i = matches.length
				while (--i >= 0 && matches.item(i) !== this) {}
				return i > -1
			}
	}

	// Get closest match
	for (; elem && elem !== document; elem = elem.parentNode) {
		if (elem.matches(selector)) return elem
	}

	return null
}

// TODO: Replace with _.reverse(array)
export const reverseArray = arr => {
	//Temp array
	let temp = []

	for (let i = 0; i < arr.length; i++) {
		//Copy all the values in reverse order
		temp[i] = arr[arr.length - i - 1]
	}

	return temp
}

export const buildFilePath = (file, projectId) => {
	const baseUrl = process.env.REACT_APP_API_BASEURL
	return `${baseUrl}/files/${projectId || file.project}/${file.fileId || file._id}/${file.name || file.title}`
}

export const getKeysFromArray = selectValues => {
	const values = [].concat(selectValues)
	const vals = values.map(val => (val.key ? val.key : val))
	return vals
}

export function throttle(fn, limit) {
	let waiting = false
	return (...args) => {
		if (!waiting) {
			fn.apply(this, args)
			waiting = true
			setTimeout(() => {
				waiting = false
			}, limit)
		}
	}
}

/************ DATE AND TIME ************/
/************ DATE AND TIME ************/
/************ DATE AND TIME ************/
/************ DATE AND TIME ************/
/************ DATE AND TIME ************/
export const dateOrNull = date => {
	return date && dayjs(date).isValid() ? new Date(date) : null
}

/************ ARRAYS ************/
/************ ARRAYS ************/
/************ ARRAYS ************/
/************ ARRAYS ************/
/************ ARRAYS ************/

export const reorderChildren = (parent, item, key, startIndex, endIndex) => {
	const parentList = Array.from(parent)
	let listIndex = getIndexByItem(parentList, item)
	let parentItem = typeof item === "string" ? parentList[listIndex] : item

	const updatedList = reorder(parentItem[key], startIndex, endIndex)
	const updatedItem = {
		...parentItem,
		[key]: updatedList,
	}
	parentList[listIndex] = updatedItem
	return parentList
}

export const moveToList = (parent, start, end, childrenKey, startIndex, endIndex) => {
	const parentList = Array.from(parent)

	// Get parent indexes
	const parentStartIndex = getIndexByItem(parentList, start)
	const parentEndIndex = getIndexByItem(parentList, end)
	let oldParent = start
	let newParent = end

	// Get real parents if parents are id
	if (typeof oldParent === "string") {
		oldParent = parentList[parentStartIndex]
	}
	if (typeof newParent === "string") {
		newParent = parentList[parentEndIndex]
	}

	// Get children arrays
	const childrenStartList = Array.from(oldParent[childrenKey])
	const childrenEndList = Array.from(newParent[childrenKey])

	const [moved] = childrenStartList.splice(startIndex, 1)
	childrenEndList.splice(endIndex, 0, moved)

	// Update parent lists with updated children
	parentList[parentStartIndex] = { ...oldParent, [childrenKey]: childrenStartList }
	parentList[parentEndIndex] = { ...newParent, [childrenKey]: childrenEndList }

	return parentList
}

export const reorder = (arr, old_index, new_index) => {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1
		while (k--) {
			arr.push(undefined)
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
	return arr // for testing
}

// TODO: replace with _.cloneDeepWith(value, [customizer])
export const deepCopy = inObject => {
	let outObject, value, key

	if (typeof inObject !== "object" || inObject === null) {
		return inObject // Return the value if inObject is not an object
	}
	// Create an array or object to hold the values
	outObject = Array.isArray(inObject) ? [] : {}

	for (key in inObject) {
		value = inObject[key]
		// Recursively (deep) copy for nested objects, including arrays
		outObject[key] = typeof value === "object" && value !== null ? deepCopy(value) : value
	}
	return outObject
}

export function removeNodeFromCollection(collection, nodeId, childKey) {
	let item
	let filteredCollection = []
	collection.forEach(col => {
		var index = col[childKey].indexOf(nodeId)
		if (index !== -1) {
			col[childKey].splice(index, 1)
			item = col
		}

		filteredCollection.push(col)
	})
	// return filteredCollection;
	return {
		item: item,
		filteredCollection: filteredCollection,
	}
}

/************ FIND ************/
/************ FIND ************/
/************ FIND ************/
/************ FIND ************/
/************ FIND ************/

export const findPath = (primitive, object, path = []) => {
	for (const [key, value] of Object.entries(object)) {
		if (value === primitive) {
			return [...path, key]
		}
		if (typeof value === "object" || typeof value === "function") {
			const newPath = findPath(primitive, value, [...path, key])
			if (newPath) {
				return newPath
			}
		}
	}
	return null
}

// https://codereview.stackexchange.com/questions/73714/find-a-nested-property-in-an-object
export const findById = (o, id) => {
	//Early return
	if (o._id === id) {
		return o
	}
	var result, p
	for (p in o) {
		if (o.hasOwnProperty(p) && typeof o[p] === "object") {
			result = findById(o[p], id)
			if (result) {
				return result
			}
		}
	}
	return result
}

export const findByProp = (o, prop, val, retprop) => {
	if (o == null) return false
	if (o[prop] === val) {
		return retprop ? o[retprop] : o
	}
	var result, p
	for (p in o) {
		if (o.hasOwnProperty(p) && typeof o[p] === "object") {
			result = findByProp(o[p], prop, val)
			if (result) {
				return retprop ? result[retprop] : result
			}
		}
	}
	return retprop ? result[retprop] : result
}

export const getIndexByItem = (arr, item) => {
	if (typeof item === "string") {
		return arr.findIndex(i => i._id === item)
	}

	let index
	arr.map((o, i) => {
		if (o === item) {
			index = i
		}
		return null
	})
	return index
	// .indexOf(item[key]);
}

export const updateObjectInArray = (arr, obj) => {
	return arr.map(i => (i._id === obj._id ? obj : i))
	// const index = getIndex(arr, obj, "_id")
	// return replaceAt(arr, index, obj)
}

export const replaceAt = (array, index, value) => {
	const ret = array.slice(0)
	ret[index] = value
	return ret
}
