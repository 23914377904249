import { useEffect, useState } from "react"
import { queryCache } from "react-query"
import { IProjectPlan } from "types/IProjectPlan"

interface PropsToReturn {
	// getControlIndex: (controlId: string) => string | null
	controlIndex: string
}

export default function useControlHelpers(controlId: string, projectId: string): PropsToReturn {
	const projectPlan: IProjectPlan | undefined = queryCache.getQueryData(["projectPlan", { projectId }])
	const [controlIndex, setControlIndex] = useState<string>("");

	useEffect(() => {
		if (projectPlan && controlId) {
			const index: string | null = getControlIndex(controlId)
			setControlIndex(index || "");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [controlId, projectPlan])

	const getControlIndex = (controlId: string): string | null => {
		if (!projectPlan || !controlId) {
			return null
		}

		let groupId: string | null = null,
			controlIndex: number = 0,
			groupIndex: number = 0

		projectPlan.groups.map(group => {
			const foundControl = group.controls.indexOf(controlId)
			if (foundControl > -1) {
				controlIndex = foundControl + 1
				groupId = group._id
			}
			return null
		})

		if (!groupId) return null

		projectPlan.sections.map(section => {
			if (!groupId) return null

			const foundGroup = section.groups.indexOf(groupId)
			if (foundGroup > -1) {
				groupIndex = foundGroup + 1
			}
			return null
		})

		return `${groupIndex}.${controlIndex}`
	}

	return {
		// getControlIndex,
		controlIndex
	}
}
