// import Members from "pages/Members"
import { useQuery, useMutation, queryCache } from "react-query"
import {
	getCompanyMembers,
	// removeCompanyMember,
	updateCompanyMember,
	bindCompanyMember,
	UpdateCompanyMemberVariables,
	BindCompanyMemberVariables,
} from "services/companyMembers"
import { IProfile } from "types/IProfile"

// const defaultCallbacks = {
// 	onSettled: ({ companyId }) => {
// 		queryCache.invalidateQueries(["companyMembers", { companyId }])
// 	},
// }

export default function useCompanyMembers(companyId?: string | undefined) {
	const { data: companyMembers, isLoading, isFetching, status, error } = useQuery<IProfile[], Error>(["companyMembers", { companyId }], getCompanyMembers, {
		enabled: companyId,
	})

	// const [_removeCompanyMember, removeCompanyMemberInfo] = useMutation(
	// 	removeCompanyMember,
	// 	defaultCallbacks,
	// )

	const [_updateCompanyMember, updateCompanyMemberInfo] = useMutation<any, Error, UpdateCompanyMemberVariables>(
		updateCompanyMember,
		// defaultCallbacks,
		{
			onSuccess: (updatedUser, { companyId }) => {
				if (!updatedUser) {
					return
				}

				// console.log("There is an updated user", updatedUser)
				const oldMembers = queryCache.getQueryData<IProfile[] | undefined>(["companyMembers", { companyId }])
				if (!oldMembers) {
					return;
				}

				const newMembers = oldMembers.map(member => {
					if (member._id === updatedUser._id) {
						return updatedUser
					}
					return member
				})

				queryCache.setQueryData<IProfile[]>(["projectMember", { projectId: updatedUser.project, userId: updatedUser._id }], () => updatedUser)
				queryCache.setQueryData<IProfile[]>(["companyMembers", { companyId }], () => newMembers)
				// if (refetchProjectMembers) {
				// 	queryCache.setQueryData(
				// 		["projectMembers", { projectId: updatedUser.project }],
				// 		oldMembers =>
				// 			oldMembers.map(m => (m._id === updatedUser._id ? updatedUser : m)),
				// 	)
				// } else {
				// 	queryCache.invalidateQueries(["companyMembers", { companyId }])
				// }
			},
		},
	)

	const [_bindCompanyMember, bindCompanyMemberInfo] = useMutation<any, Error, BindCompanyMemberVariables>(bindCompanyMember,
		// defaultCallbacks,
		{
			onSuccess: (updatedUser, { companyId, refetchProjectMembers }) => {
				if (!updatedUser) {
					return
				}

				if (refetchProjectMembers) {
					queryCache.setQueryData<IProfile[] | undefined>(["projectMembers", { projectId: updatedUser.project }], oldMembers => {
						if (oldMembers) {
							return oldMembers.map(m => (m._id === updatedUser._id ? updatedUser : m));
						}
					})
				} else {
					queryCache.invalidateQueries(["companyMembers", { companyId }])
				}
			},
		},
	)

	return {
		companyMembers,
		isLoading,
		isFetching,
		status,
		error,

		// removeCompanyMember: _removeCompanyMember,
		// removeCompanyMemberInfo,

		updateCompanyMember: _updateCompanyMember,
		updateCompanyMemberInfo,

		bindCompanyMember: _bindCompanyMember,
		bindCompanyMemberInfo,
	}
}
