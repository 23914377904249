import * as React from "react"
import { useDropzone } from "react-dropzone"
import { Avatar } from "baseui/avatar"
import { Block } from "baseui/block"
import { Button, KIND, SIZE, SHAPE } from "baseui/button"
import { useStyletron } from "baseui"
import { X } from "react-feather"

import { IUser } from "types/IUser"
import { MutationResult } from "react-query"

interface Props {
	onAddedFiles: Function
	onRemove: Function
	currentUser: IUser
	updatePhotoInfo: MutationResult<any>
	removePhotoInfo: MutationResult<any>
}

const AccountPhoto: React.FC<Props> = ({ onAddedFiles, onRemove, currentUser, updatePhotoInfo, removePhotoInfo }) => {
	const [css, theme] = useStyletron()
	const photoUrl = currentUser.photoUrl

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/png, image/jpeg, image/jpg",
		onDrop: (files: File[]) => {
			onAddedFiles(files)
		},
	})

	const isLoading = updatePhotoInfo.status === "loading" || removePhotoInfo.status === "loading" ? true : false

	return (
		<Block
			className={css({
				textAlign: "center",
				margin: `0px auto ${theme.sizing.scale800}`,
				borderRadius: "100%",
				opacity: isLoading ? ".5" : "1",
				transitionTimingFunction: theme.animation.linearCurve,
				transitionDuration: theme.animation.timing300,
			})}>
			<Block
				{...getRootProps()}
				className={css({
					margin: "0 auto",
					borderRadius: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					width: theme.sizing.scale1400,
					height: theme.sizing.scale1400,
				})}>
				<input {...getInputProps()} id="accountPhotoUpload" />
				<Avatar name={currentUser.fullName || "A"} size="scale1400" src={photoUrl} />
			</Block>
			{photoUrl && (
				<Button
					kind={KIND.minimal}
					size={SIZE.mini}
					shape={SHAPE.pill}
					disabled={isLoading ? true : false}
					onClick={() => onRemove()}
					overrides={{
						BaseButton: {
							style: () => ({
								marginTop: theme.sizing.scale400,
							}),
						},
					}}
					endEnhancer={() => <X size={12} />}
					{...{ id: "removeAccountPhoto" }}>
					Ta bort
				</Button>
			)}
		</Block>
	)
}

export default AccountPhoto
