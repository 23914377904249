import * as React from "react"
import { FlexGrid, FlexGridItem, FlexGridItemProps } from "baseui/flex-grid"

export interface Props {
	rightContent?: React.ReactElement
	leftContent?: React.ReactElement
	full?: boolean
}

const PageLayout: React.FC<Props> = ({ children, rightContent, leftContent, full = false }) => {
	const itemProps: FlexGridItemProps = {}

	const wideItemProps: FlexGridItemProps = {
		...itemProps,
		overrides: {
			Block: {
				style: ({ $theme }) => ({
					// width: `calc((200% - ${$theme.sizing.scale800}) / 3)`,
					width: full ? "100%" : `calc((200% - ${$theme.sizing.scale800}) / 3) !important`,
				}),
			},
		},
	}

	return (
		<FlexGrid
			flexGridColumnCount={full ? [1] : [1, 1, 3]}
			flexGridColumnGap="scale800"
			flexGridRowGap="scale800"
			marginTop="scale800"
			marginBottom="scale800">
			{!full && leftContent && (
				<React.Fragment>
					<FlexGridItem key={2} {...itemProps}>
						{leftContent}
					</FlexGridItem>
					<FlexGridItem key={3} display="none"></FlexGridItem>
				</React.Fragment>
			)}

			<FlexGridItem key={1} {...wideItemProps}>
				{children}
			</FlexGridItem>

			{!full && rightContent && (
				<React.Fragment>
					<FlexGridItem key={2} display="none"></FlexGridItem>
					<FlexGridItem key={3} {...itemProps}>
						{rightContent}
					</FlexGridItem>
				</React.Fragment>
			)}
		</FlexGrid>
	)
}

export default PageLayout
