import { instance as axios } from "config/axios"

export type RemoveTaskAttachmentVariables = { taskId: string, attachmentId: string }
export type CreateTaskAttachmentVariables = { taskId: string, files: File[] }

export async function removeTaskAttachment({ taskId, attachmentId }: RemoveTaskAttachmentVariables) {
	const { data } = await axios.delete(`/tasks/${taskId}/attachments/${attachmentId}`)
	return data
}

export async function createTaskAttachment({ taskId, files }: CreateTaskAttachmentVariables) {
	console.log("Started uploading from service", taskId, files)

	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	}

	const res = []

	for (let index = 0; index < files.length; index++) {
		const file = files[index]
		const formData = new FormData()
		formData.append("file", file)
		const { data } = await axios.post(`/tasks/${taskId}/attachments`, formData, config)
		res.push(data)
	}

	console.log("RESSSS", res)

	return res
}
