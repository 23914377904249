import * as React from "react"

// import { useAuth } from "hooks/helpers/useAuth"
import { useMsal } from "auth/hooks/useMsal"
import useUser from "hooks/useUser"

import { ContentBlock } from "components/ContentBlock"
import PageHeader from "components/layout/PageHeader"

import NewUserForm from "./NewUserForm"
import { useSelector } from "react-unistore"
import { Navigate } from "react-router-dom"
import { ContentWidths } from "types/constants"
import { IState } from "store"

const NewUser: React.FC = () => {
	const currentUser = useSelector((state: IState) => state.currentUser)
	const { createUser, createUserInfo } = useUser()
	const { accounts } = useMsal()
	const account = accounts[0]
	const email = account?.username || ""
	const b2c = account?.homeAccountId?.split("-b2c_1_signin")[0]

	// React.useEffect(() => {
	// 	const fetchToken = async () => {
	// 		const token = await getAccessToken()
	// 		console.log(token)
	// 	}
	// 	fetchToken()
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	// Get data from accesstoken or account.
	// Check if isAuthenticated and not loading.
	// If not loading and is not authenticated return;
	// else go to new user form

	if (currentUser) {
		console.log("there is a user")
		return <Navigate to="/" />
	}

	const onCreate = async (updatedData: any) => {
		updatedData.email = email
		updatedData.b2c = b2c

		try {
			const response = await createUser({ updatedData })

			if (response) {
				window.location.href = "/"
			}
		} catch (e) {
			if (e.response.status === 400) {
				// User does already exist. Redirect to home
				window.location.href = "/"
			}
		}
	}

	return (
		<ContentBlock {...{ margin: "2rem auto", maxWidth: ContentWidths.narrow }}>
			<PageHeader title="Välkommen!" center />
			<NewUserForm email={email} onCreate={onCreate} createUserInfo={createUserInfo} />
		</ContentBlock>
	)
}

export default NewUser
