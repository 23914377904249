// import * as React from "react"
import { useQuery } from "react-query"
import { getControls } from "services/controls"
import { IControl } from "types/IControl"


export default function useControls(projectId: string) {
	const { data: controls, isLoading, isFetching, status, error } = useQuery<IControl[] | Error>(["controls", { projectId }], getControls,
		{ enabled: projectId },
	)

	return {
		controls,
		isLoading,
		isFetching,
		status,
		error,
	}
}
