import * as React from "react"
import { Link } from "react-router-dom"
// import { format } from "timeago.js"
import { Clock } from "react-feather"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography"

import { buildNotification } from "utils/buildNotification"
import { timeagoFormatter } from "utils/format"

export interface Props {
	notification: any
	markAsRead: Function
	closePopup: Function
}

const Notification: React.FC<Props> = ({ notification, markAsRead, closePopup }) => {
	const [css, theme] = useStyletron()

	const builtNotification = buildNotification(notification)

	const _markAsRead = () => {
		markAsRead({ notificationId: notification._id })
		closePopup()
	}

	if (!notification) return null
	return (
		<Link to={notification.object.path} onClick={_markAsRead}>
			<Block
				className={css({
					padding: `${theme.sizing.scale600} ${theme.sizing.scale800}`,
					borderBottom: `1px solid ${theme.colors.primary100}`,
					backgroundColor: notification.isRead ? "white" : theme.colors.accent50,
				})}>
				<ParagraphSmall marginTop="0" marginBottom="scale200">
					{builtNotification}
				</ParagraphSmall>
				<ParagraphXSmall margin="0" color="primary500" display="flex" alignItems="center">
					<Clock size="14" className={css({ marginRight: theme.sizing.scale200 })} /> {timeagoFormatter(notification.createdAt)}
				</ParagraphXSmall>
			</Block>
		</Link>
	)
}

export default Notification
