export const HTTP_STATUS_CODES = {
	OK: 200,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	METHOD_NOT_ALLOWED: 405,
	CONFLICT: 409,
	INTERNAL_SERVER_ERROR: 500
};

export const SORTING = { ASC: 'asc', DECS: 'desc' };


export const NotificationTypes = {
	checkApproved: 'checkApproved',
	checkDisapproved: 'checkDisapproved',
	checkSentForApproval: 'checkSentForApproval',
	createdCheck: 'createdCheck',
	updatedCheck: 'updatedCheck',
	removedCheck: 'removedCheck',

	taskApproved: 'taskApproved',
	taskDisapproved: 'taskDisapproved',
	taskSentForApproval: 'taskSentForApproval',
	createdTask: 'createdTask',
	updatedTask: 'updatedTask',
	removedTask: 'removedTask',

	documentApproved: 'documentApproved',
	documentDisapproved: 'documentDisapproved',
	documentSentForApproval: 'documentSentForApproval',
	createdComment: 'createdComment',
	updatedComment: 'updatedComment',
	removedComment: 'removedComment',

	createdTaskDocument: 'createdTaskDocument',
	removedTaskDocument: 'removedTaskDocument',

	createdCheckDocument: 'createdCheckDocument',
	removedCheckDocument: 'removedCheckDocument',

	controlApproved: 'controlApproved',
	controlDisapproved: 'controlDisapproved',
	controlSentForApproval: 'controlSentForApproval',
	createdControl: 'createdControl',
	updatedControl: 'updatedControl',
	removedControl: 'removedControl',

	addedControlMember: 'addedControlMember',
	removedControlMember: 'removedControlMember',

	createdProject: 'createdProject',
	updatedProject: 'updatedProject',
	removedProject: 'removedProject',

	createdProjectDocument: 'createdProjectDocument',
	removedProjectDocument: 'removedProjectDocument',
	addedProjectMember: 'addedProjectMember',
	removedProjectMember: 'removedProjectMember',

	addedCompanyMember: 'addedCompanyMember',
	removedCompanyMember: 'removedCompanyMember',
	updatedCompanyMember: 'updatedCompanyMember',

	createdCompanyDocument: 'createdCompanyDocument',
	removedCompanyDocument: 'removedCompanyDocument',

	updatedFolders: 'updatedFolders',
};


export enum ActivityVerbs {
	approve = 'approve',
	disapprove = 'disapprove',
	requestApproval = 'requestApproval',
	create = 'create',
	update = 'update',
	remove = 'remove',
	invite = 'invite',
};

export enum ObjectTypes {
	// file:'file', 
	// attachment:'attachment',
	// user:'user', 
	document = 'document',
	photo = 'photo',
	comment = 'comment',
	profile = 'profile',
	company = 'company',
	task = 'task',
	check = 'check',
	control = 'control',
	project = 'project',
}

export const ContentWidths = {
	narrow: "600px",
	normal: "1000px",
	wide: "1400px"
}