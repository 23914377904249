import { instance as axios } from "config/axios"
import { DocumentCategories } from "types/enums"

export type GetCompanyDocumentsVariables = { companyId: string }
export type AddCompanyDocumentsVariables = { companyId: string, files: File[], category: typeof DocumentCategories }
export type RemoveCompanyDocumentsVariables = { companyId: string, documentId: string }

export async function getCompanyDocuments(key: string, { companyId }: GetCompanyDocumentsVariables) {
	const { data } = await axios.get(`/companies/${companyId}/documents`)
	return data
}

export async function addCompanyDocument({ companyId, files, category }: AddCompanyDocumentsVariables) {
	console.log(companyId, files, category)

	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	}

	const res = []

	for (let index = 0; index < files.length; index++) {
		const file = files[index]
		const formData = new FormData()
		formData.append("file", file)
		const { data } = await axios.post(
			`/companies/${companyId}/documents?category=${category}`,
			formData,
			config,
		)
		res.push(data)
	}

	return res
}

export async function removeCompanyDocument({ companyId, documentId }: RemoveCompanyDocumentsVariables) {
	console.log("REMOVING DOC", companyId, documentId)
	const { data } = await axios.delete(`/companies/${companyId}/documents/${documentId}`)
	return data
}
