import * as React from "react"
import { Block } from "baseui/block"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"
import { Button, SIZE, SHAPE } from "baseui/button"
import { useStyletron } from "baseui"
import { Plus } from "react-feather"

import GlobalContext from "context/GlobalContext"
import useTaskAttachments from "hooks/useTaskAttachments"

import Editable from "components/Editable"
import TaskImage from "./TaskImage"
import Can from "components/Can"
import { ITask, ITaskAttachment } from "types/ITask"
import { ModalEnum } from "containers/ModalContainer"

interface Props {
	task: ITask
	taskIsFreezed: boolean
	updateTask: Function
}

const TaskNote: React.FC<Props> = ({ task, taskIsFreezed, updateTask }) => {
	const { openModal } = React.useContext(GlobalContext)
	const [css, theme] = useStyletron()
	const { removeAttachment, removeAttachmentInfo } = useTaskAttachments()

	const onRemoveAttachment = (attachmentId: string) => {
		removeAttachment({ taskId: task._id, attachmentId })
	}

	return (
		<React.Fragment>
			<Block marginBottom="scale1000">
				<Can do="update" on={task} type="Task" passThrough>
					{(allowed: boolean) =>
						!allowed || taskIsFreezed ? (
							task.description
						) : (
							<Editable type="textarea" value={task.description} onUpdate={(val: string) => updateTask("description", val)} />
						)
					}
				</Can>
			</Block>

			<Block
				className={css({
					borderTop: `1px solid ${theme.colors.mono200}`,
					paddingTop: theme.sizing.scale800,
				})}>
				<Can do="update" on={task} type="Task">
					{() =>
						!taskIsFreezed && (
							<Button
								overrides={{
									BaseButton: {
										style: () => ({
											marginBottom: theme.sizing.scale600,
										}),
									},
								}}
								size={SIZE.mini}
								// kind={KIND.secondary}
								shape={SHAPE.pill}
								endEnhancer={() => <Plus size="14" />}
								onClick={() => openModal(ModalEnum.UploadTaskAttachment, { taskId: task._id })}>
								Ladda upp bilder
							</Button>
						)
					}
				</Can>

				<Block marginTop="scale800">
					<FlexGrid flexGridColumnCount={[4, 6, 8]} flexGridColumnGap="scale400" flexGridRowGap={["scale400"]}>
						{task.attachments &&
							task.attachments.map((attachment: ITaskAttachment, index: number) => {
								attachment.project = task.project
								return (
									<FlexGridItem key={index}>
										<TaskImage
											attachment={attachment}
											task={task}
											onDelete={onRemoveAttachment}
											removeAttachmentInfo={removeAttachmentInfo}
											taskIsFreezed={taskIsFreezed}
										/>
									</FlexGridItem>
								)
							})}
					</FlexGrid>
				</Block>
			</Block>
		</React.Fragment>
	)
}

export default TaskNote
