const NotFound = () => {
	return (
		<>
			<div>
				<h2 className="mdc-typography--title">404 Sidan kunde inte hittas.</h2>
			</div>
			<div>Looks like the page you are trying to access, doesn't exist.</div>
		</>
	)
}

export default NotFound
