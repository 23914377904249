import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { DragDropContext, DropResult } from "react-beautiful-dnd"
import { Button } from "baseui/button"
import { useAbility } from "@casl/react"
import { ParagraphMedium } from "baseui/typography"

import GlobalContext from "context/GlobalContext"
import { buildTree } from "utils/tree"
import { useFilter } from "hooks/helpers/useFilter"
import useProjectPlan from "hooks/useProjectPlan"
import { IProjectPlan, IProjectPlanControl, IProjectPlanSection } from "types/IProjectPlan"
import { IDragData, NodeEnum, NodeType } from "types/shared"

import { ModalEnum } from "containers/ModalContainer"
import { AbilityContext } from "components/Can"
import Loader from "components/Loader"
import DroppableContent from "components/DroppableContent"
import DraggableContent from "components/DraggableContent"
import NewProjectPlanNodeForm from "./NewProjectPlanNodeForm"

import { StatusFilter, RegulatoryRequirementsFilter } from "../filters"
import PlanFilter from "../PlanFilter"
import Section from "./Section"
import NoSections from "./NoSections"

export interface Props {
	canEdit: boolean
	controls: IProjectPlanControl[]
	projectPlan: IProjectPlan
}

const Sections: FC<Props> = ({ canEdit, controls, projectPlan }) => {
	const ability = useAbility(AbilityContext)
	const { openModal } = useContext(GlobalContext)
	const [builtPlan, setBuiltPlan] = useState<IProjectPlanSection[]>()
	const { createNode, moveNode } = useProjectPlan()
	const mainFilters = useMemo(
		() => [
			{
				title: "Status",
				key: "status",
				filterType: "includesValue",
				element: StatusFilter,
			},
			{
				title: "Myndighetskrav",
				key: "regulatoryRequirements",
				filterType: "includesValue",
				element: RegulatoryRequirementsFilter,
			},
		],
		[],
	)
	const { items, FilterComponent, isActive, activeFilters, onSearch, clearFilters } = useFilter(mainFilters, controls)
	const build = useCallback(buildTree, [projectPlan, items])

	useEffect(() => {
		if (!items) return
		const controls: IProjectPlanControl[] = items

		// console.log('Updated content', projectPlan, items)
		const updatedPlan = Object.assign(projectPlan)
		// console.log("Updated plan", updatedPlan)
		const showAll = !isActive && ability.can("view", "CompleteProjectPlan")
		const data: IProjectPlanSection[] | null = build(updatedPlan, controls, showAll)
		if (data) {
			setBuiltPlan(data)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, projectPlan, isActive])

	const openSectionModal = () => {
		openModal(ModalEnum.AddSection)
	}

	const createSection = async (values: { title: string }) => {
		await createNode({
			projectId: projectPlan._id,
			type: "section",
			updatedData: { title: values.title },
		})
	}

	const onDragEnd = (result: DropResult) => {
		const { source, destination, draggableId } = result
		const type: NodeType = result.type as any

		if (!destination) {
			return
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}

		const updatedData: IDragData = {
			oldIndex: source.index,
			newIndex: destination.index,
		}

		if (type === NodeEnum.group || type === NodeEnum.control) {
			updatedData.oldParent = source.droppableId
			updatedData.newParent = destination.droppableId
		}

		moveNode({
			projectId: projectPlan._id,
			nodeId: draggableId,
			type: type,
			updatedData,
		})

		// console.log("MOVED CONTROLS")

		// if (type === "SECTION" || type === "GROUP") {
		// 	const updatedItems = moveSection(result, sections)
		// 	updateSections(updatedItems)
		// 	return
		// }

		// if (type === "CONTROL") {
		// 	const updatedGroups = moveGroup(result, groups)
		// 	updateGroups(updatedGroups)
		// 	return
		// }
	}

	if (!projectPlan && !controls) {
		return <Loader />
	}

	if (projectPlan.sections && projectPlan.sections.length < 1) {
		return (
			<>
				<NoSections />
				{canEdit && <Button onClick={openSectionModal}>Lägg till sektion</Button>}
			</>
		)
	}

	if (!controls.length && !canEdit) {
		return (
			<div>
				<ParagraphMedium>Du har inte blivit inbjuden till någon kontrollpunkt än.</ParagraphMedium>
			</div>
		)
	}

	if (!builtPlan) return null

	return (
		<div>
			<PlanFilter onSearch={onSearch} FilterComponent={FilterComponent} activeFilters={activeFilters} clearFilters={clearFilters} />
			<DragDropContext onDragEnd={onDragEnd}>
				<DroppableContent id="droppable-sections" type="section" canEdit={canEdit}>
					{builtPlan.map((section: IProjectPlanSection, sectionIndex: number) => (
						<DraggableContent id={section._id} key={sectionIndex} index={sectionIndex} canEdit={canEdit} customHandle>
							<Section canEdit={canEdit} sectionIndex={sectionIndex} section={section} projectPlan={projectPlan} />
						</DraggableContent>
					))}
				</DroppableContent>
			</DragDropContext>

			{/* Add section form */}
			{canEdit && (
				<NewProjectPlanNodeForm
					onSubmit={createSection}
					type="section"
					customStyle={{
						backgroundColor: "transparent",
						border: "none",
						fontSize: "20px",
						fontWeight: "500",
						paddingLeft: "5px",
						height: "40px",
						outline: "none",
					}}
				/>
			)}
		</div>
	)
}

export default Sections
