import {
	useQuery,
	// useMutation, queryCache 
}
	from "react-query"

import {
	getProjectDocuments,
	// createProjectDocument, removeProjectDocument 
} from "services/projectDocuments"
import { IDocument } from "types/IDocument"
// import { Resource } from "./types"
// import { IDocument } from "types/IDocument"

export type Props = {
	projectId: string,
	query: any
}

export interface PaginationProps<T> {
	docs: T[]
	hasNextPage: boolean
	hasPrevPage: boolean
	limit: number
	nextPage: number | null
	page: number
	pagingCounter: number
	prevPage: number | null
	totalDocs: number
	totalPages: number
}

export function useProjectDocuments(projectId: string, query = null) {
	const { data: documents, refetch, isLoading, status, isFetching, error } = useQuery<IDocument[], Error>(
		["projectDocuments", projectId, query], getProjectDocuments,
		{
			enabled: projectId && query !== null,
		},
	)

	return {
		documents,
		getDocuments: refetch,
		status,
		isLoading,
		isFetching,
		error,
	}
}

// const defaultCallbacks = {
// 	onSuccess: () => {
// 		queryCache.invalidateQueries("globalProjectDocuments")
// 	},
// }

// export function useGlobalProjectDocuments(projectId: string) {
// 	const { data: documents, isLoading, isFetching, error } = useQuery<IDocument>(["globalProjectDocuments", projectId, true], getProjectDocuments, {
// 		enabled: projectId,
// 	})
// 	const [addDocument, addDocumentInfo] = useMutation<IDocument[]>(createProjectDocument, defaultCallbacks)
// 	const [removeDocument, removeDocumentInfo] = useMutation(removeProjectDocument, defaultCallbacks)

// 	return {
// 		documents,
// 		isLoading,
// 		isFetching,
// 		error,

// 		addDocument,
// 		addDocumentInfo,

// 		removeDocument,
// 		removeDocumentInfo,
// 	}
// }