import * as React from "react"
import { Link } from "react-router-dom"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { ParagraphMedium } from "baseui/typography"

import GlobalContext from "context/GlobalContext"
import { IActivity } from "types/IActivity"
import { IObject } from "types/shared"
import { ModalEnum } from "containers/ModalContainer"

const Activity: React.FC<{ activity: IActivity }> = ({ activity }) => {
	const [css] = useStyletron()
	const { openModal } = React.useContext(GlobalContext)

	if (!activity) return null

	const isProfile: boolean = activity.object.objectType === "profile"
	const isFile: boolean = activity.object.objectType === "photo" || activity.object.objectType === "document"

	let activityContent = <Link to={activity.object.path}>{activity.object.title}</Link>

	if (activity.target && isProfile) {
		const activityVerb = activity.verb === "invite" ? "till" : "från"
		activityContent = (
			<React.Fragment>
				<Link to={activity.object.path}>{activity.object.title}</Link> {activity.target.title && activityVerb}{" "}
				<Link to={activity.target.path}>{activity.target.title}</Link>
			</React.Fragment>
		)
	} else if (activity.target && isFile && (activity.verb !== "approve" || activity.verb !== "disapprove")) {
		const currentDocument: IObject = Object.assign(activity.object, { project: activity.project })
		activityContent = (
			<React.Fragment>
				{activity.verb === "create" ? (
					<React.Fragment>
						<span
							className={css({
								// whiteSpace: "nowrap",
								wordBreak: "break-word",
								overflow: "hidden",
								// textOverflow: "ellipsis",
								display: "block",
								":hover": {
									cursor: "pointer",
								},
							})}
							onClick={e => openModal(ModalEnum.ViewDocument, { currentDocument })}>
							{activity.object.title}
						</span>{" "}
						till{" "}
					</React.Fragment>
				) : (
					<React.Fragment>
						<Link to={activity.object.path}>{activity.object.title}</Link> från{" "}
					</React.Fragment>
				)}
				<Link to={activity.target.path}>{activity.target.title}</Link>
			</React.Fragment>
		)
	} else if (activity.target && isFile) {
		const currentDocument: IObject = Object.assign(activity.object, { project: activity.project })
		activityContent = (
			<React.Fragment>
				{activity.verb === "create" ? (
					<React.Fragment>
						<span
							className={css({
								// whiteSpace: "nowrap",
								wordWrap: "break-word",
								// overflow: "hidden",
								// textOverflow: "ellipsis",
								display: "block",
								":hover": {
									cursor: "pointer",
								},
							})}
							onClick={e => openModal(ModalEnum.ViewDocument, { currentDocument })}>
							{activity.object.title}
						</span>{" "}
						till{" "}
					</React.Fragment>
				) : (
					<React.Fragment>
						<Link to={activity.object.path}>{activity.object.title}</Link> från{" "}
					</React.Fragment>
				)}
				<Link to={activity.target.path}>{activity.target.title}</Link>
			</React.Fragment>
		)
	}

	return (
		<Block marginTop="scale400" marginBottom="scale700">
			<ParagraphMedium>{activityContent}</ParagraphMedium>
			{/* {activity.target && (
				<ParagraphXSmall marginTop="scale600">
					Kontrollpunkt: <Link to={activity.target.path}>{activity.target.title}</Link>
				</ParagraphXSmall>
			)} */}
		</Block>
	)
}

export default Activity
