import { useMutation, queryCache } from "react-query"
import { removeControlMember, addControlMember, updateControlMember, RemoveControlMembersVariables, UpdateControlMembersVariables, AddControlMembersVariables } from "services/controlMembers"
import { IControl } from "types/IControl"

const defaultCallbacks = {
	onSuccess: (updatedControl: IControl, updatedData: any) => {
		queryCache.setQueryData<IControl[] | undefined>(["controls", { projectId: updatedControl.project }], oldData => {
			if (!oldData) return
			return oldData.map(c => (c._id === updatedControl._id ? updatedControl : c))
		})

		queryCache.setQueryData(
			["currentControl", { controlId: updatedControl._id }],
			oldData => updatedControl,
		)
	},
}

export default function useControlMembers() {
	// console.log("Init controlMembers hook", controlId)
	// const { data: members, isLoading, isFetching, status, error } = useQuery(
	// 	["controlMembers", { controlId }],
	// 	getControlMembers,
	// {
	// 	enabled: controlId
	// }
	// )

	const [_removeControlMember, removeControlMemberInfo] = useMutation<any, Error, RemoveControlMembersVariables>(
		removeControlMember,
		defaultCallbacks,
	)

	const [_updateControlMember, updateControlMemberInfo] = useMutation<any, Error, UpdateControlMembersVariables>(
		updateControlMember,
		defaultCallbacks,
	)

	const [_addControlMember, addControlMemberInfo] = useMutation<any, Error, AddControlMembersVariables>(
		addControlMember,
		defaultCallbacks,
	)

	return {
		// members,
		// isLoading,
		// isFetching,
		// status,
		// error,

		removeControlMember: _removeControlMember,
		removeControlMemberInfo,

		addControlMember: _addControlMember,
		addControlMemberInfo,

		updateControlMember: _updateControlMember,
		updateControlMemberInfo,
	}
}
