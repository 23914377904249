import * as React from "react"
import { createContextualCan } from "@casl/react"
import { TAppAbility, Actions, Subjects } from "config/abilities"
import { detectSubjectType } from "@casl/ability"

export const AbilityContext = React.createContext<TAppAbility>(undefined!)

interface Props {
	on: Subjects | any //Subject
	do: Actions
	type?: Subjects // Subjectstrings
	not?: boolean
	passThrough?: boolean
}

const Can: React.FC<Props> = ({ children, not = false, on, ...props }): any => {
	const Can = createContextualCan(AbilityContext.Consumer)

	// if (typeof props.on === "string") {
	// 	console.log("THIS IS THE ITEM STRING", props.on)
	// 	return props.on
	// }

	// console.log("TUOAROERSF", props.type, on.__type)

	if (on && typeof on !== "string") {
		// props.on.__type = props.type ? props.type : "Project"
		// props.on.__type = props.type ? props.type : detectSubjectType(on)
		on = Object.assign(on, {
			__type: props.type ? props.type : detectSubjectType(on),
		})
	}

	// if (props.on) {
	// 	props.on.__type = props.type ? props.type : "Project"
	// 	console.log("SFDSAFFD", props.on, props.on.__type, props.type)
	// }

	return (
		<Can not={not} on={on} {...props}>
			{children}
		</Can>
	)
}

export default Can
