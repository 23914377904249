import * as React from "react"
import { BrowserRouter } from "react-router-dom"
import { ReactQueryConfigProvider } from "react-query"
import { ReactQueryDevtools } from "react-query-devtools"
import { useSelector, useAction } from "react-unistore"

import abilities from "config/abilities"
import setup from "config/setup"
import { useMsal } from "auth/hooks/useMsal"
import { _getCurrentUser } from "actions/user"

import ModalContainer from "containers/ModalContainer"
import ToastContainer from "containers/ToastContainer"

import NewUser from "pages/NewUser"
import { AbilityContext } from "components/Can"
import AppRoutes from "components/AppRoutes"
import Loader from "components/Loader"
// import { instance } from "config/axios"

const AppAuthenticated: React.FC = () => {
	const { accounts, getAccessToken } = useMsal()
	const currentUserState = useSelector((state: any) => state.currentUserState)
	const currentUser = useSelector((state: any) => state.currentUser)
	const getCurrentUser = useAction(_getCurrentUser)

	// console.log("ISAUTHENTICATED!!!!!", accounts)

	React.useEffect(() => {
		if (!accounts) return

		const fetchToken = async () => {
			const token = await getAccessToken()
			// const token = accessToken
			// console.log('token', token)

			if (token) {
				setup.initialize(token)
				// const res = await instance.get(
				// "https://alnar-test-windows.azurewebsites.net/api/HelloWorld?", // Linux
				// "https://alnar-dev-debug.azurewebsites.net/api/HelloWorld?", // Linux
				// "https://alnartest.azurewebsites.net/api/HttpTrigger1?code=fZtgSngyzHZ31Z2bwCcHFjLA7uFFODolQo0E9msOPoGRNtQd9hxagw==",
				// )
				// console.log("RESPNSE", res)
				// console.log("we have token")
				getCurrentUser()
			}
		}

		fetchToken()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts, getAccessToken])
	// }, [account, accessToken])

	if (!currentUser && !currentUserState) {
		return <Loader />
	}

	if (!currentUser && currentUserState.status === 500) {
		return <div>Internt fel. Vad god och ladda om sidan.</div>
	}

	if (!currentUser && currentUserState.status === 404) {
		return <NewUser />
	}

	return (
		<AbilityContext.Provider value={abilities}>
			<BrowserRouter>
				<ReactQueryConfigProvider config={{ queries: { refetchOnWindowFocus: false } }}>
					<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
					<AppRoutes />
					<ModalContainer />
					<ToastContainer />
				</ReactQueryConfigProvider>
			</BrowserRouter>
		</AbilityContext.Provider>
	)
}

export default AppAuthenticated
