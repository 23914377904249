import { styled } from "stitches.config"

export const Wrapper = styled("div", {
    backgroundColor: "$background",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    overflow: "hidden"
})