import { Outlet } from "react-router-dom"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"

const Content: React.FC = () => {
	const [css, theme] = useStyletron()

	return (
		<Block
			className={css({
				width: "100%",
			})}>
			<Block
				padding={[
					`0 ${theme.sizing.scale200} ${theme.sizing.scale200}`,
					`0 ${theme.sizing.scale400} ${theme.sizing.scale200}`,
					`0 ${theme.sizing.scale600} ${theme.sizing.scale800}`,
					`0 ${theme.sizing.scale1000} ${theme.sizing.scale800}`,
				]}>
				<Outlet />
			</Block>
		</Block>
	)
}

export default Content
