import * as React from "react"
// import { Link } from "react-router-dom"
import { ListItem } from "baseui/list"
import { Button, SHAPE, KIND, SIZE } from "baseui/button"
import { useStyletron } from "baseui"
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography"
import { X } from "react-feather"

import { formatBytes, dateTimeFormatter } from "utils/format"
// import GlobalContext from "context/GlobalContext"
import Can from "components/Can"
import { ICompany } from "types/ICompany"
import { IDocument } from "types/IDocument"

interface Props {
	file: IDocument
	viewDocument: Function
	onRemove: Function
	company: ICompany
}

const DocumentTile: React.FC<Props> = ({ file, viewDocument, onRemove, company }) => {
	// const { openModal } = React.useContext(GlobalContext)
	const [css] = useStyletron()

	return (
		<ListItem
			endEnhancer={() => (
				<Can do="delete" on={file} type="CompanyFiles">
					{/* <Can do="delete" on={company} type="CompanyFiles"> */}
					{() => (
						<Button shape={SHAPE.round} size={SIZE.compact} kind={KIND.tertiary} onClick={e => onRemove(file._id)}>
							<X size={16} />
						</Button>
					)}
				</Can>
			)}
			sublist>
			{/* <ListItemLabel> */}
			<div>
				<ParagraphSmall margin="0" className={css({ cursor: "pointer" })}>
					<span onClick={() => viewDocument(file._id)}>{file.name}</span>
				</ParagraphSmall>
				<ParagraphXSmall color="mono600" margin="0">
					{file.size && formatBytes(file.size)} - Uppladdat av {file.createdBy.fullName} den {dateTimeFormatter(file.createdAt)}
				</ParagraphXSmall>
			</div>
			{/* </ListItemLabel> */}
		</ListItem>

		// <Tile
		// 	className={
		// 		file.status === EntityStatus.approved ? "tile-centered bg-secondary pl-2" : "tile-centered"
		// 	}>
		// 	<Tile.Content>
		// 		<Tile.Title className="tile-title mb-0">
		// 			<Link to="#" onClick={e => viewDocument(file._id)}>
		// 				{file.name}
		// 			</Link>
		// 		</Tile.Title>
		// 		{/* </Button> */}
		// 		<Tile.Subtitle className="text-gray text-small">
		// 			Uppladdat av {file.createdBy.fullName} den {dateTimeFormatter(file.createdAt)}
		// 		</Tile.Subtitle>
		// 		{/* <p className="tile-subtitle text-gray">Uppladdat av {file.createdBy.fullName} den {dayjs(file.createdAt).format("DD/MM/YYYY")}</p> */}
		// 		{/* <small className="tile-subtitle text-gray">Uppladdat av {file.createdBy.fullName} den {dayjs(file.createdAt).format("DD/MM/YYYY")}</small> */}
		// 	</Tile.Content>
		// 	<Tile.Action>
		// 		<span className="text-small text-gray">{file.size && formatBytes(file.size)}</span>

		// 		<div className="menu-item">
		// 			{file.status !== EntityStatus.approved ? (
		// 				<Link to="#" onClick={e => onRemove(file._id)}>
		// 					Ta bort
		// 				</Link>
		// 			) : (
		// 				<span className="text-gray">Ta bort</span>
		// 			)}
		// 		</div>
		// 	</Tile.Action>
		// </Tile>
	)
}

export default DocumentTile
