import * as React from "react"
import { Droppable } from "react-beautiful-dnd"

const getDroppableStyle = (isDraggingOver: boolean) => ({
	background: isDraggingOver ? "lightblue" : "transparent",
})

interface Props {
	id: string
	type: any
	canEdit?: boolean
	children?: any
	className?: any
}

const DroppableContent: React.FC<Props> = ({ id, type, children, canEdit = true, ...props }) => {
	if (canEdit) {
		return (
			<Droppable droppableId={id} type={type}>
				{(provided, snapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef} {...props} style={getDroppableStyle(snapshot.isDraggingOver)}>
						{children}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		)
	}

	return <div {...props}>{children}</div>
}

export default DroppableContent
