import * as React from "react"
import { Clock } from "react-feather"
import { Block } from "baseui/block"
import { Avatar } from "baseui/avatar"
import { useStyletron } from "baseui"
import { ParagraphXSmall } from "baseui/typography"
import { timeagoFormatter, dateTimeFormatter } from "utils/format"
import { StatefulTooltip } from "baseui/tooltip"

import { IActivity } from "types/IActivity"

import Tile from "components/Tile"
import ActivityTitle from "./ActivityTitle"
import Activity from "./Activity"

const ActivityBlock: React.FC<{ activity: IActivity }> = ({ activity }) => {
	const [css, theme] = useStyletron()

	if (!activity) {
		return null
	}

	return (
		<Tile
			author={<Avatar size="scale900" name={activity.actor.displayName} src={activity.actor.photoUrl} />}
			action={
				<ParagraphXSmall
					className={css({
						margin: "0",
						display: "flex",
						alignItems: "center",
						color: theme.colors.primary400,
					})}>
					{timeagoFormatter(activity.createdAt)}
					<StatefulTooltip content={() => <Block>{dateTimeFormatter(activity.createdAt)}</Block>} returnFocus showArrow autoFocus>
						<Clock size="14" className={css({ marginLeft: theme.sizing.scale200 })} />
					</StatefulTooltip>
				</ParagraphXSmall>
			}>
			<ActivityTitle activity={activity} />
			<Activity activity={activity} />
		</Tile>
	)
}

export default ActivityBlock
