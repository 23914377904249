import * as React from "react"
import { Tabs, Tab } from "baseui/tabs"
// import { Tabs, Tab } from "baseui/tabs-motion"
import { Block } from "baseui/block"

import CheckHeader from "./CheckHeader"
import CheckInfo from "./CheckInfo"
import CheckActivity from "./CheckActivity"
// import DocumentList from "./DocumentList"
import TaskList from "./TaskList/index"
import useCheckStatus from "hooks/useCheckStatus"
import { ICheck } from "types/ICheck"
import { IControl } from "types/IControl"
import { IProject } from "types/IProject"
// import Comments from "./Comments"

interface Props {
	currentCheck: ICheck | undefined
	currentControl: IControl
	currentProject: IProject

	// checkIsFreezed,
	// checkIsApprovable,
	controlIsFreezed: boolean

	onDelete: Function
	onUpdate: Function
	sendForApproval: Function
	approve: Function
}

const CheckBody: React.FC<Props> = ({
	currentCheck,
	currentControl,
	// currentProject,

	// checkIsFreezed,
	// checkIsApprovable,
	controlIsFreezed,

	onDelete,
	onUpdate,
	sendForApproval,
	approve,
}) => {
	const [activeKey, setActiveKey] = React.useState("0")
	const { checkIsFreezed, checkIsApprovable } = useCheckStatus(currentCheck)

	if (!currentCheck) return null

	return (
		<React.Fragment>
			<Block padding={["scale700", "scale700", "scale900"]} backgroundColor="primary50">
				<CheckHeader
					currentCheck={currentCheck}
					// currentControl={currentControl}
					checkIsFreezed={checkIsFreezed}
					checkIsApprovable={checkIsApprovable}
					controlIsFreezed={controlIsFreezed}
					onDelete={onDelete}
					onUpdate={onUpdate}
					sendForApproval={sendForApproval}
					approve={approve}
				/>
			</Block>

			<Tabs
				onChange={({ activeKey }) => {
					setActiveKey(activeKey.toString())
				}}
				activeKey={activeKey}>
				<Tab title="Checklistor">
					<TaskList currentCheck={currentCheck} onUpdate={onUpdate} checkIsFreezed={checkIsFreezed} />
				</Tab>
				<Tab title="Info">
					<CheckInfo
						checkIsFreezed={checkIsFreezed}
						onUpdate={onUpdate}
						// currentProject={currentProject}
						currentCheck={currentCheck}
					/>
				</Tab>
				<Tab title="Aktivitet">
					<CheckActivity
						currentCheck={currentCheck}
						// currentProject={currentProject}
					/>
				</Tab>
			</Tabs>
		</React.Fragment>
	)
}

export default CheckBody
