import * as React from "react"
import { Button, KIND, SHAPE } from "baseui/button"

import GlobalContext from "context/GlobalContext"
import { ModalEnum } from "containers/ModalContainer"

interface Props {
	projectId: string
}

const AddMember: React.FC<Props> = ({ projectId }) => {
	const { openModal } = React.useContext(GlobalContext)

	return (
		<div>
			<Button kind={KIND.primary} shape={SHAPE.pill} onClick={e => openModal(ModalEnum.AddProjectMember, { projectId })}>
				Lägg till deltagare
			</Button>
		</div>
	)
}

export default AddMember
