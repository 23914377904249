import * as React from "react"
// import { useDropzone } from "react-dropzone"
import { FileUploader } from "baseui/file-uploader"
import { Block } from "baseui/block"
import { LocaleProvider } from "baseui"

import FileList from "./FileList"
import { AllowedFileTypes, AllowedImageTypes } from "types/enums"
// import { IAttachment } from "types/IAttachment"

// const activeStyle = {
// 	background: "#bab9e0",
// 	borderColor: "#5755d9",
// }

// const acceptStyle = {
// 	background: "#d4d3f1",
// 	borderColor: "#5755d9",
// }

// const rejectStyle = {
// 	borderColor: "#ff1744",
// 	backgroundColor: "#eee",
// }

interface Props {
	onAddedFiles: Function
	showFileList?: boolean
	allowImagesOnly?: boolean
}

const Uploader: React.FC<Props> = ({ onAddedFiles, showFileList = true, allowImagesOnly = false }) => {
	const fileTypeLabel = allowImagesOnly ? "bilder" : "filer"

	const localeOverrideSv = {
		fileuploader: {
			dropFilesToUpload: `Släpp dina ${fileTypeLabel} här för att ladda upp`,
			or: "eller",
			browseFiles: "Bläddra filer",
			retry: "Försök igen",
			cancel: "Avbryt",
		},
	}

	const onDrop = (files: File[]) => {
		let current: File[] = [...currentFiles, ...files]

		if (onAddedFiles) {
			onAddedFiles(files)
		}
		setCurrentFiles(current)
	}

	const [currentFiles, setCurrentFiles] = React.useState<File[]>([])
	// const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
	// 	accept: allowImagesOnly
	// 		? "image/png, image/jpeg, image/jpg"
	// 		: "image/png, image/jpeg, image/jpg, application/pdf",
	// 	onDrop: files => {
	// 		let current = [...currentFiles, ...files]

	// 		if (onAddedFiles) {
	// 			onAddedFiles(files)
	// 		}
	// 		setCurrentFiles(current)
	// 	},
	// })
	// const fileTypeLabel = allowImagesOnly ? "bilder" : "filer"

	// const style = React.useMemo(
	// 	() => ({
	// 		...(isDragActive ? activeStyle : {}),
	// 		...(isDragAccept ? acceptStyle : {}),
	// 		...(isDragReject ? rejectStyle : {}),
	// 	}),
	// 	[isDragActive, isDragReject, isDragAccept],
	// )

	const removeFile = (path: string) => {
		let files = currentFiles.filter((file: any) => file.path !== path)
		if (onAddedFiles) {
			onAddedFiles(files)
		}
		setCurrentFiles(files)
	}

	return (
		<Block>
			<LocaleProvider locale={localeOverrideSv}>
				<FileUploader onDropAccepted={onDrop} accept={allowImagesOnly ? AllowedImageTypes : AllowedFileTypes} />
			</LocaleProvider>
			{/* <div {...getRootProps({ className: "dropzone mb-5", style })}>
				<input {...getInputProps()} />
				<p className="text-gray">
					Släpp dina {fileTypeLabel} här, eller klicka för att ladda upp {fileTypeLabel}.
				</p>
			</div> */}

			{showFileList && currentFiles.length > 0 && <FileList files={currentFiles} removeFile={removeFile} />}
		</Block>
	)
}

export default Uploader
