import { useQuery } from "react-query"

import { getCompanies } from "services/companies"
import { ICompany } from "types/ICompany"

export default function useCompanies(projectId: string | undefined) {
	const { data: companies, error, status, isLoading } = useQuery<ICompany[], Error>(
		["companies", { projectId }],
		getCompanies,
		{ enabled: projectId },
	)

	return {
		companies,
		isLoading,
		status,
		error,
	}
}
