import * as React from "react"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

import useProjectPlan from "hooks/useProjectPlan"
import { IProjectPlan, IProjectPlanSection } from "types/IProjectPlan"

import DroppableContent from "components/DroppableContent"
import DraggableContent from "components/DraggableContent"

import SectionHeader from "./SectionHeader"
import NewProjectPlanNodeForm from "../NewProjectPlanNodeForm"
import Group from "./Group"

export interface Props {
	canEdit: boolean
	sectionIndex: number
	section: IProjectPlanSection
	projectPlan: IProjectPlan

	isDragging?: boolean
	dragHandleProps?: DraggableProvidedDragHandleProps
}

const Section: React.FC<Props> = ({
	canEdit,

	sectionIndex,
	section,
	projectPlan,

	isDragging,
	dragHandleProps,
}) => {
	const [css, theme] = useStyletron()
	const [isGroupOpen, setIsGroupOpen] = React.useState(true)
	const { updateNode, createNode, deleteNode } = useProjectPlan()

	if (!section) {
		return null
	}

	const removeSection = (sectionId: string) => {
		if (window.confirm(`Är du säker på att du vill ta bort sektionen med underliggande grupper och punkter?`)) {
			deleteNode({
				projectId: projectPlan._id,
				type: "section",
				nodeId: sectionId,
			})
		}
	}

	const addGroup = (values: any, e: any) => {
		const updatedData = Object.assign(values, { parent: section._id })

		createNode({
			projectId: projectPlan._id,
			type: "group",
			updatedData,
		})

		e.target.reset()
	}

	const onTitleUpdate = (obj: any) => {
		const updatedData = { [obj.key]: obj.value }

		updateNode({
			projectId: projectPlan._id,
			type: "section",
			nodeId: section._id,
			updatedData,
		})
	}

	return (
		<Block marginBottom="scale1400">
			<SectionHeader
				section={section}
				canEdit={canEdit}
				onUpdate={onTitleUpdate}
				isDragging={isDragging}
				dragHandleProps={dragHandleProps}
				setIsGroupOpen={setIsGroupOpen}
				isGroupOpen={isGroupOpen}
				onRemove={removeSection}
			/>
			<Block
				className={css({
					display: isDragging ? "none" : "block",
					height: isDragging ? "0" : "auto",
				})}>
				<DroppableContent id={section._id} type="group" canEdit={canEdit}>
					{section.groups &&
						section.groups.map((group, groupIndex) => {
							if (!group || typeof group === "string") {
								return null
							}

							return (
								<DraggableContent key={groupIndex + "-" + group._id} id={group._id} index={groupIndex} canEdit={canEdit} customHandle>
									<Group
										group={group}
										groupIndex={groupIndex}
										isGroupOpen={isGroupOpen}
										canEdit={canEdit}
										section={section}
										sectionIndex={sectionIndex}
										projectPlan={projectPlan}
									/>
								</DraggableContent>
							)
						})}
				</DroppableContent>

				{/* Add group form */}
				{canEdit && (
					<NewProjectPlanNodeForm
						onSubmit={addGroup}
						type="group"
						customStyle={{
							width: "100%",
							border: "none",
							backgroundColor: theme.colors.mono300,
							marginTop: theme.sizing.scale400,
							paddingLeft: theme.sizing.scale600,
							textAlign: "left",
							outline: "none",
							height: "40px",
							opacity: ".6",
						}}
					/>
				)}
			</Block>
		</Block>
	)
}

export default Section
