import * as React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Drawer } from "baseui/drawer"
// import { X } from "react-feather"

import { useCheck } from "hooks/useCheck"
// import useCheckStatus from "hooks/useCheckStatus"
import useControlStatus from "hooks/useControlStatus"
import { IProject } from "types/IProject"
import { IControl } from "types/IControl"
import { EntityStatus } from "types/enums"

import Can from "components/Can"

import CheckBody from "./CheckBody"
import CheckForm from "./CheckForm"
import { ICheck } from "types/ICheck"

interface Props {
	currentControl: IControl
	currentProject: IProject
}

const CheckSidebar: React.FC<Props> = ({ currentControl, currentProject }) => {
	const navigate = useNavigate()
	const routeParams = useParams()
	const checkId = routeParams.checkId === "create" ? null : routeParams.checkId
	// const [, setActive] = React.useState(false)
	const {
		currentCheck,
		status,
		// error,
		sendCheckForApproval,
		approveCheck,
		createCheck,
		updateCheck,
		deleteCheck,
	} = useCheck(checkId || undefined)
	// const { checkIsFreezed, checkIsApprovable } = useCheckStatus(currentCheck)
	const { controlIsFreezed } = useControlStatus(currentControl)
	// console.log("Current Check from <CheckSidebar/>", currentCheck)

	// React.useEffect(() => {
	// 	setActive(true)
	// 	return () => {
	// 		setActive(false)
	// 	}
	// }, [])

	const onDelete = async () => {
		if (window.confirm("Är du säker på att du vill ta bort redovisningen")) {
			const response = await deleteCheck({ checkId: currentCheck?._id || "" })
			if (response) {
				navigate(`../..`)
			}
		}
	}

	const onUpdate = (updatedData: ICheck) => {
		// console.log("UPDATING payload", checkId, updatedData)
		updateCheck({ checkId: checkId || "", updatedData })
	}

	const onCreate = async (updatedData: ICheck) => {
		const createdCheck = await createCheck({ controlId: currentControl._id, updatedData })

		if (createdCheck) {
			navigate(`../${createdCheck._id}`)
		} else {
			alert("Det gick inte att skapa redovisningen. Försök igen.")
		}
	}

	const sendForApproval = () => {
		sendCheckForApproval({ checkId: currentCheck?._id || "" })
	}

	const approve = (approvalStatus: keyof typeof EntityStatus) => {
		console.log(approvalStatus)
		if (currentCheck?.status === approvalStatus) {
			return null
		}

		const response = {
			status: approvalStatus,
		}
		console.log("response", response)
		// const response = {
		// 	status: currentCheck.status !== EntityStatus.approved ? EntityStatus.approved : EntityStatus.disapproved,
		// }

		approveCheck({ checkId: currentCheck?._id || "", updatedData: response })
	}

	const onClose = (e: any) => {
		// Only close on escape, not enter
		if (document.activeElement?.tagName === "INPUT" && e.closeSource !== "escape") {
			return
		}

		navigate("../..")
	}

	if (status === "loading") {
		return <div>Laddar</div>
	}

	return (
		<Drawer
			isOpen={true}
			onClose={onClose}
			size="800px"
			autoFocus
			animate={false}
			overrides={{
				DrawerContainer: {
					style: ({ $theme }) => {
						return {
							transform: "none",
						}
					},
				},
				DrawerBody: {
					style: ({ $theme }) => ({
						marginTop: "0px",
						marginRight: "0px",
						marginBottom: "0px",
						marginLeft: "0px",
						paddingTop: "57px",
					}),
				},
				Close: {
					style: ({ $theme }) => ({
						top: "72px",
					}),
				},
			}}>
			{checkId ? (
				<CheckBody
					currentCheck={currentCheck}
					currentControl={currentControl}
					currentProject={currentProject}
					// checkIsApprovable={checkIsApprovable}
					// checkIsFreezed={checkIsFreezed}
					controlIsFreezed={controlIsFreezed}
					onDelete={onDelete}
					onUpdate={onUpdate}
					sendForApproval={sendForApproval}
					approve={approve}
				/>
			) : (
				<Can do="create" on="Check">
					{() => <CheckForm onCreate={onCreate} onClose={onClose} />}
				</Can>
			)}
		</Drawer>
	)
}

export default CheckSidebar
