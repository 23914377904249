import * as React from "react"
import ObjectId from "objectid-random"
import { DragDropContext, DropResult } from "react-beautiful-dnd"

import { getChanges, reorder } from "utils/misc"

import DroppableContent from "components/DroppableContent"

import Item from "./Item"
import Form from "./Form"
import { ListItem } from "./"

interface Props {
	items: ListItem[]
	onUpdate: Function
}

const List: React.FC<Props> = ({ items, onUpdate }) => {
	const [list, setList] = React.useState<ListItem[]>([])
	const [, setChanges] = React.useState<any[]>([])

	React.useEffect(() => {
		if (items.length > 0) {
			setList(items)
		}
	}, [items])

	const onListUpdate = (updatedList: ListItem[], removedId?: string) => {
		setList(updatedList)
		checkUpdates(items, updatedList)
		onUpdate(updatedList, removedId)
	}

	const updateTitle = (val: string, item: ListItem) => {
		item.title = val
		const newList: ListItem[] = list.map(l => {
			if (l._id === item._id) {
				return item
			}
			return l
		})

		// checkUpdates(currentProject.sectors, newList);
		// onUpdate([...newList]);
		// onSave([...newList])
		onListUpdate([...newList])
	}

	const checkUpdates = (oldList: ListItem[], newList: ListItem[]) => {
		const changed = getChanges(oldList, newList)
		setChanges(changed || [])
	}

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result
		if (!destination) {
			return
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}

		const updatedItems = reorder(list, source.index, destination.index)

		// setList(updatedItems)
		onListUpdate(updatedItems)
		return
	}

	const onAdd = (value: ListItem, e: React.BaseSyntheticEvent) => {
		const newItem = { _id: ObjectId(), title: value.title }

		onListUpdate([...list, newItem])
		e.target.reset()
	}

	const onRemove = (item: ListItem) => {
		if (!window.confirm(`Är du säker på att du vill ta bort "${item.title}"?`)) return
		const newList = list.filter(listItem => listItem._id !== item._id)
		onListUpdate(newList, item._id)
	}

	console.log("listiemes", list)

	return (
		<div>
			{list && list.length < 1 ? (
				<div>
					<em>Listan är tom</em>
				</div>
			) : (
				<DragDropContext onDragEnd={onDragEnd}>
					{/* <div className="dnd-container"> */}
					<DroppableContent id={"droppable-sectors"} type="SECTORS">
						{list.map((item, index) => (
							<Item updateTitle={updateTitle} onRemove={onRemove} item={item} key={item._id} index={index} />
						))}
					</DroppableContent>
					{/* </div> */}
				</DragDropContext>
			)}

			<Form onAdd={onAdd} />
		</div>
	)
}

export default List
