import { customStyled } from "config/theme"
import { Link } from "react-router-dom"
import { ExternalLink } from "react-feather"
import { useStyletron } from "baseui"

const BottomLinks = () => {
	const [css, theme] = useStyletron()
	return (
		<BottomList>
			<ListItem>
				<Link to="/updates" className={css({ color: theme.colors.mono600 })}>
					Uppdateringar
				</Link>
			</ListItem>
			<ListItem>
				<a href="https://www.qubitum.se" target="_blank" rel="noopener noreferrer" style={{ color: "inherit" }}>
					FAQ <ExternalLink size="16" />
				</a>
			</ListItem>
		</BottomList>
	)
}

export default BottomLinks

const BottomList = customStyled("ul", ({ $theme }) => ({
	width: "100%",
	marginTop: "auto",
	marginBottom: $theme.sizing.scale400,
	paddingLeft: 0,
}))

const ListItem = customStyled("li", ({ $theme }) => ({
	paddingLeft: $theme.sizing.scale800,
	paddingTop: $theme.sizing.scale500,
	paddingBottom: $theme.sizing.scale500,
	color: $theme.colors.mono600,
}))
