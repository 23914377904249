import { instance as axios } from "config/axios"
import { IProfile } from "types/IProfile"

export type GetControlMembersVariables = { controlId: string }
export type AddControlMembersVariables = { controlId: string, userId: string, updatedData: IProfile }
export type UpdateControlMembersVariables = { controlId: string, userId: string, updatedData: IProfile }
export type RemoveControlMembersVariables = { controlId: string, memberId: string }

export async function getControlMembers(key: string, { controlId }: GetControlMembersVariables) {
	const { data } = await axios.get(`/controls/${controlId}/members`)
	return data
}

export async function addControlMember({ controlId, userId, updatedData }: AddControlMembersVariables) {
	const { data } = await axios.post(`/controls/${controlId}/members/${userId}`, updatedData)
	return data
}

export async function updateControlMember({ controlId, userId, updatedData }: UpdateControlMembersVariables) {
	const { data } = await axios.patch(`/controls/${controlId}/members/${userId}`, updatedData)
	return data
}

export async function removeControlMember({ controlId, memberId }: RemoveControlMembersVariables) {
	const { data } = await axios.delete(`/controls/${controlId}/members/${memberId}`)
	return data
}
