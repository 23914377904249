import * as React from "react"
import {
	Cell,
	// CellProps,
	// Hooks,
	Row,
	TableOptions,
	useFilters,
	useGlobalFilter,
	useFlexLayout,
	useRowSelect,
	useSortBy,
	useTable,
	useAsyncDebounce,
	usePagination,
} from "react-table"
import { StyledHeadCell, StyledTable, StyledBody, StyledCell, StyledRow } from "baseui/table"
import { useStyletron } from "baseui"

import { SortingOption } from "types/shared"
// import { useLocalStorage } from "hooks/helpers/useLocalstorage"
import { useDebounce } from "hooks/helpers/useDebounce"
import { IDocument } from "types/IDocument"
import { PaginationProps } from "hooks/useProjectDocuments"

import { textFilter } from "./filters/TextFilter"
import { selectionHook } from "./hooks"

import TableToolbar from "./TableToolbar"
import TablePagination from "./TablePagination"

const filterTypes = { text: textFilter }

export interface TableProps<T extends object = {}> extends TableOptions<T> {
	name: string
	searchable?: boolean
	hiddenColumns?: Array<string>
	onSelect?: (...args: any[]) => any
	onDelete?: (rows: any) => void
	isDeleteLoading?: boolean
	onClick?: (row: Row<T>) => void
	onDownload?: (...args: any[]) => any
	isDownloadLoading?: boolean
	sortingOptions?: SortingOption[]
	onFetchData?: (...args: any[]) => any | null // Make all calls async
	isLoading?: boolean
	perPage?: number
	// pagination?: any
	paginationProps?: PaginationProps<IDocument> | null
}

const DataTable = <T extends object>(props: React.PropsWithChildren<TableProps<T>>): React.ReactElement | null => {
	const {
		// name,
		columns,
		data,
		perPage = 50,
		// searchable = true,
		hiddenColumns = [],
		onSelect = undefined,
		onFetchData = null,
		isLoading = false,
		paginationProps = null,
	} = props

	// const [initialState, setInitialState] = useLocalStorage(`tableState:${name}`, {})
	const [css, theme] = useStyletron()
	const [initialState, setInitialState] = React.useState<any>({ hiddenColumns, pageIndex: 0, pageSize: perPage })
	const skipPageResetRef = React.useRef()

	const hooks = React.useMemo(() => {
		// Should be added in right order to work properly
		const internalHooks: any = [useGlobalFilter, useFlexLayout, useFilters, useSortBy]
		if (paginationProps) {
			internalHooks.push(usePagination)
		}
		internalHooks.push(useRowSelect)
		if (onSelect) {
			internalHooks.push(selectionHook)
		}

		return internalHooks
	}, [paginationProps, onSelect])

	// Options should be set if data is fetched async
	let options = {}
	if (onFetchData) {
		options = {
			manualFilters: true,
			manualSortBy: true,
			disableMultiSort: true,

			manualPagination: true,
			autoResetPage: false,
			pageCount: paginationProps?.totalPages,
			// autoResetPage: !skipPageResetRef.current,

			autoResetExpanded: !skipPageResetRef.current,
			autoResetGroupBy: !skipPageResetRef.current,
			autoResetSelectedRows: !skipPageResetRef.current,
			autoResetSortBy: !skipPageResetRef.current,
			autoResetFilters: !skipPageResetRef.current,
			// autoResetRowState: !skipPageResetRef.current,
		}
	}

	const instance = useTable<T>({ ...props, columns, data, filterTypes, initialState, ...options }, ...hooks)
	const { getTableProps, headerGroups, rows, state, selectedFlatRows, prepareRow } = instance

	const debouncedState = useDebounce(state, 100)

	// Debounce our onFetchData call for 100ms
	const onFetchDataDebounced = useAsyncDebounce(args => {
		onFetchData && onFetchData(args)
	}, 10)

	React.useEffect(() => {
		const { sortBy, filters, pageSize, pageIndex } = debouncedState

		const val = {
			sortBy,
			filters,
			pageSize,
			pageIndex,
		}

		if (onFetchData) {
			onFetchDataDebounced(val)
		} else {
			setInitialState(val)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedState, onFetchDataDebounced])

	React.useEffect(() => {
		if (onSelect) {
			onSelect(selectedFlatRows)
		}
	}, [selectedFlatRows, onSelect])

	// Render the UI for your table
	return (
		<React.Fragment>
			<TableToolbar instance={instance} tableProps={props} />
			<StyledTable {...getTableProps()} className={css({ borderRadius: "0px !important", border: "none" })}>
				{headerGroups.map(headerGroup => (
					<StyledRow
						{...headerGroup.getHeaderGroupProps()}
						className={css({ backgroundColor: theme.colors.mono300, height: "50px", flex: "0 !important" })}>
						{headerGroup.headers.map(column => (
							<StyledHeadCell
								className={css({
									border: "none",
								})}
								{...column.getHeaderProps()}>
								{/* {...column.getHeaderProps(column.getSortByToggleProps())}> */}
								{column.render("Header")}
							</StyledHeadCell>
						))}
					</StyledRow>
				))}

				<StyledBody
					className={css({
						opacity: isLoading ? 0.2 : 1,
					})}>
					{rows?.length ? (
						rows.map((row: Row<T>, i: number) => {
							prepareRow(row)
							return (
								<StyledRow
									{...row.getRowProps()}
									className={css({ backgroundColor: i % 2 === 0 ? "white" : theme.colors.mono200, minHeight: "50px" })}>
									{row.cells.map((cell: Cell<T>) => (
										<StyledCell {...cell.getCellProps()}>{cell.render("Cell")}</StyledCell>
									))}
								</StyledRow>
							)
						})
					) : (
						<p>Inga dokument hittades</p>
					)}
				</StyledBody>
			</StyledTable>
			{paginationProps && <TablePagination instance={instance} />}
		</React.Fragment>
	)
}

export default DataTable
