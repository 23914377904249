import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND } from "baseui/button"
import { Plus } from "react-feather"

import GlobalContext from "context/GlobalContext"
import useCompanyMembers from "hooks/useCompanyMembers"
import useCompanies from "hooks/useCompanies"
import FormSelect from "components/Form/FormSelect"
import { getKeysFromArray } from "utils/misc"
import { ModalProps } from "containers/ModalContainer"

const BindUserProfileToCompany: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const { handleSubmit, errors, control, setValue } = useForm()
	const { modalPayload } = React.useContext(GlobalContext)
	const { userProfile } = modalPayload
	const { bindCompanyMember } = useCompanyMembers()
	const { companies } = useCompanies(userProfile?.project)

	const onSubmit = async ({ companyId }: { companyId: string }) => {
		if (companyId) {
			companyId = getKeysFromArray(companyId)[0]._id
		}

		await bindCompanyMember({
			companyId,
			userProfileId: userProfile?._id || "",
			updatedData: { companyPermissions: "companyMember" },
			refetchProjectMembers: true,
			// updatedData: { companyPermissions },
		})
		closeModal()
	}

	if (!userProfile) {
		return null
	}

	return (
		<Modal onClose={closeModal} closeable isOpen={isOpen} animate autoFocus unstable_ModalBackdropScroll size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>
				Koppla {userProfile.firstName} {userProfile.lastName} till företag
			</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						render={props => (
							<FormSelect
								label="Företag"
								options={companies}
								fieldError={errors.companyId}
								value={props.value}
								clearable={false}
								placeholder="Välj ett företag"
								onChange={params => setValue("companyId", params.value, { shouldValidate: true })}
								valueKey="_id"
								labelKey="name"
							/>
						)}
						control={control}
						rules={{ required: "Du måste välja ett företag" }}
						name="companyId"
					/>
				</form>
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Avbryt
				</ModalButton>

				<ModalButton type="submit" onClick={handleSubmit(onSubmit)} endEnhancer={() => <Plus size={18} />}>
					Lägg till
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default BindUserProfileToCompany
