import { styled } from "stitches.config"

export const Title = styled("div", {
    fontSize: 40,
    fontWeight: 500,
    color: "$primary",

    bp3: {
        fontSize: 54
    }
})

export const SmallTitle = styled("div", {
    fontSize: 14,
    textTransform: "uppercase",
    letterSpacing: 1,
    fontWeight: 400,
    color: "$gray",

    bp3: {
        fontSize: 15
    }
})