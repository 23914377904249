import * as React from "react"
import { styled } from "baseui"
import { StyledSpinnerNext } from "baseui/spinner"

const LoaderPage: React.FC = () => {
	return (
		<Centered>
			<StyledSpinnerNext />
		</Centered>
	)
}

export default LoaderPage

const Centered = styled("div", {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "80vh",
})
