import { createContext, useEffect, useState } from "react"
import { throttle } from "utils/misc"

interface IViewport {
	width: number
	height: number
}
const initialState: IViewport = {
	width: 0,
	height: 0,
}

const ViewportContext = createContext<IViewport>(initialState)

export const ViewportProvider: React.FC = ({ children }) => {
	// This is the exact same logic that we previously had in our hook

	const [width, setWidth] = useState(window.innerWidth)
	const [height, setHeight] = useState(window.innerHeight)

	const handleWindowResize = () => {
		setWidth(window.innerWidth)
		setHeight(window.innerHeight)
	}

	// TODO: throttle resize
	useEffect(() => {
		window.addEventListener("resize", throttle(handleWindowResize, 200))
		return () => window.removeEventListener("resize", handleWindowResize)
	}, [])

	/* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
	return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>
}

export default ViewportContext
