import * as React from "react"
import { useQuery, useMutation, queryCache } from "react-query"
import { getProjectMembers, removeProjectMember, updateProjectMember, addProjectMember, AddProjectMemberVariables, UpdateProjectMemberVariables, RemoveProjectMemberVariables } from "services/projectMembers"
import GlobalContext from "context/GlobalContext"
import { IProfile } from "types/IProfile"

export default function useProjectMembers(projectId?: string, query = null) {
	const { showToast } = React.useContext(GlobalContext)
	const defaultCallbacks = {
		onSuccess: (data: any, { companyId }: any) => {
			queryCache.invalidateQueries(["companyMembers", { companyId }])
		},
		onError: () => {
			showToast({
				type: "negative",
				message: `Något blev fel.`,
			})
		},
		onSettled: (data: any, error: any) => {
			if (error) {
				return
			}

			const { companyId, projectId } = data
			queryCache.invalidateQueries(["projectMembers", { projectId }])

			if (companyId) {
				queryCache.invalidateQueries(["companyMembers", { companyId }])
			}
		},
	}

	const { data: members, isLoading, isFetching, status, error } = useQuery<IProfile[], Error>(["projectMembers", { projectId, query }], getProjectMembers, {
		enabled: projectId,
	})

	const { data: projectMembersAsync, isLoading: isLoadingAsync, refetch: fetchMembersAsync } = useQuery<IProfile[], Error>(
		projectId && ["projectMembers", { projectId }],
		getProjectMembers,
		{
			enabled: false,
		},
	)

	const [_addProjectMember, addProjectMemberInfo] = useMutation<any, Error, AddProjectMemberVariables>(addProjectMember, {
		...defaultCallbacks,
		onSuccess: (data, { companyId }) => {
			queryCache.invalidateQueries(["companyMembers", { companyId }])

			showToast({
				type: "positive",
				message: `${data.email || data.firstName} har lagts till i projektet.`,
			})
		},
	})
	const [_updateProjectMember, updateProjectMemberInfo] = useMutation<any, Error, UpdateProjectMemberVariables>(updateProjectMember, defaultCallbacks)

	const [_removeProjectMember, removeProjectMemberInfo] = useMutation<any, Error, RemoveProjectMemberVariables>(removeProjectMember, {
		...defaultCallbacks,
		onSuccess: (data, { companyId }) => {
			queryCache.invalidateQueries(["companyMembers", { companyId }])

			showToast({
				type: "positive",
				message: `Användaren har tagits bort.`,
			})
		},
	})

	return {
		members,
		isLoading,
		isFetching,
		status,
		error,

		projectMembersAsync,
		fetchMembersAsync,
		isLoadingAsync,

		removeProjectMember: _removeProjectMember,
		removeProjectMemberInfo,

		addProjectMember: _addProjectMember,
		addProjectMemberInfo,

		updateProjectMember: _updateProjectMember,
		updateProjectMemberInfo,
	}
}
