import * as React from "react"
import { FlexGrid, FlexGridItem, FlexGridItemProps } from "baseui/flex-grid"
import { H4 } from "baseui/typography"
import { useStyletron } from "styletron-react"

const itemProps: FlexGridItemProps = {
	display: "flex",
	alignItems: "center",
}

const wideItemProps: FlexGridItemProps = {
	...itemProps,
	overrides: {
		Block: {
			style: ({ $theme }) => ({
				// width: `calc((200% - ${$theme.sizing.scale800}) / 3)`,
				width: `calc((200% - ${$theme.sizing.scale800}) / 3) !important`,
			}),
		},
	},
}

export interface Props {
	title: string | JSX.Element
	center?: boolean
}

const PageHeader: React.FC<Props> = ({ title, children, center = false }) => {
	const [css] = useStyletron()

	if (center) {
		return (
			<FlexGrid justifyContent="center" flexGridColumnGap="scale800" flexGridRowGap="scale800" marginTop="scale800" marginBottom="scale800">
				<FlexGridItem className={css({ margin: "0 auto", textAlign: "center" })} alignItems="center">
					<H4 marginTop="scale100" marginBottom="scale500">
						{title}
					</H4>
				</FlexGridItem>
			</FlexGrid>
		)
	}
	return (
		<FlexGrid
			flexGridColumnCount={[1, 1, 3]}
			// justifyContent="center"
			flexGridColumnGap="scale800"
			flexGridRowGap="scale800"
			marginTop="scale800"
			marginBottom="scale800">
			<FlexGridItem justifyContent="flex-start" {...wideItemProps}>
				<H4
					margin="0px"
					// marginTop="scale100"
					// marginBottom="scale500"
				>
					{title}
				</H4>
			</FlexGridItem>
			<FlexGridItem display="none"></FlexGridItem>
			<FlexGridItem justifyContent={["flex-start", "flex-start", "flex-end"]} {...itemProps}>
				{children}
			</FlexGridItem>
		</FlexGrid>
	)
}

export default PageHeader
