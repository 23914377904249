import * as React from "react"
import { useParams } from "react-router-dom"
import { useAbility } from "@casl/react"

import GlobalContext from "context/GlobalContext"
import useProjectMembers from "hooks/useProjectMembers"
import { getKeysFromArray } from "utils/misc"

import memberColumns from "./memberColumns"

import DataTable from "components/DataTable"
import { AbilityContext } from "components/Can"
import { ContentBlock } from "components/ContentBlock"
import { IProfile } from "types/IProfile"
import { ProjectPermissions } from "types/enums"
import { ModalEnum } from "containers/ModalContainer"

const Members = () => {
	const { projectId } = useParams()
	const { openModal } = React.useContext(GlobalContext)
	const {
		members,
		error,
		// removeProjectMember,
		removeProjectMemberInfo,
		updateProjectMember,
		updateProjectMemberInfo,
	} = useProjectMembers(projectId)

	const ability = useAbility(AbilityContext)
	// const currentProfile = useSelector((state: IState) => state.profileAbilities)

	const onChange = (newValue: typeof ProjectPermissions, userProfile: IProfile) => {
		const confirm = window.confirm(`Är du säker på att du vill byta behörighet på ${userProfile.firstName} ${userProfile.lastName}?`)
		if (!confirm) return null

		// const updatedData = { email: user.email, permissions: newValue }
		const updatedData = { permissions: getKeysFromArray(newValue)[0] }
		updateProjectMember({ projectId, memberId: userProfile._id, updatedData })
	}

	const onRemoveMember = (member: IProfile) => {
		const confirm = window.confirm(`Är du säker på att du vill ta bort ${member.firstName} ${member.lastName} ?`)
		if (!confirm) return null

		// removeProjectMember({ projectId, memberId: member._id })
	}

	const _openModal = (userProfile: IProfile) => {
		openModal(ModalEnum.BindUserProfileToCompany, { userProfile })
	}

	const columns = React.useMemo(
		() => memberColumns(onChange, onRemoveMember, _openModal, ability, projectId),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ability, projectId],
	)

	if (!members) {
		return null
	}

	if (error) {
		return <div>Det gick inte att hämta projektdeltagarna</div>
	}

	const isLoading = removeProjectMemberInfo.status === "loading" || updateProjectMemberInfo.status === "loading"

	return (
		<ContentBlock isLoading={isLoading}>
			<DataTable
				name="membersTable"
				columns={columns as any}
				data={members}
				// sortable
				// searchable={["name"]}
			/>
		</ContentBlock>
	)
}

export default Members
