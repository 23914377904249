import { AbilityBuilder } from "@casl/ability"

import { IAbility } from "types/IAbility"
import { AppAbility } from "."
// import { TAppAbility } from "./"

enum PermissionScale {
	limited = 0,
	normal = 1,
	advanced = 2,
}

export default function defineResourceRulesFor(rule: IAbility) {
	// console.log("CURRENT RULE", rule)
	const currentPermission: number = PermissionScale[rule.permissions]
	const resourceId = rule.resource
	const profileId = rule.profile._id
	// const { can, cannot, rules } = abilityBuilder
	const { can, cannot, rules } = new AbilityBuilder(AppAbility)

	if (rule.type === "Control") {
		// if (currentPermission >= PermissionScale.limited) {}

		if (currentPermission === PermissionScale.normal) {
			// CONTROLS

			// CHECKS
			can("create", "Check", { _id: resourceId })
			can(["update", "delete"], "Check", { "createdBy._id": profileId })
			cannot(["update", "delete"], "Check", { status: { $in: ["pending", "approved"] } })

			can("sendForApproval", "Check")
			cannot("sendForApproval", "Check", { status: { $in: ["pending", "approved"] } })

			// TASKS
			can(["create", "update"], "Task", { control: resourceId })
			can("delete", "Task", {
				control: resourceId,
				// "createdBy._id": profileId,
			})
			cannot("delete", "Task", { status: { $in: ["pending", "approved"] } })
			can("sendForApproval", "Task", { _id: resourceId })
			cannot("sendForApproval", "Task", { status: { $in: ["pending", "approved"] } })

			// DOCUMENTS
			// can(["delete", "update"], "Document", { "createdBy._id": rule.user._id })
			// cannot(["delete", "update"], "Document", { status: { $in: ["pending", "approved"] } })
		}

		if (currentPermission === PermissionScale.advanced) {
			// CONTROLS
			can("sendForApproval", "Control")
			cannot("sendForApproval", "Control", { status: { $in: ["pending", "approved"] } })

			// CHECKS
			can("create", "Check", { _id: resourceId })
			can(["update", "delete"], "Check", { control: resourceId })
			cannot(["update", "delete"], "Check", { status: { $in: ["pending", "approved"] } })
			can("approve", "Check", { control: resourceId })

			// TASKS
			can(["create", "update", "delete"], "Task", { control: resourceId })
			can("approve", "Task", { control: resourceId })
			// can("sendForApproval", "Task", { _id: resourceId })
			// cannot("sendForApproval", "Task", { status: { $in: ["pending", "approved"] } })

			// MEMBERS
			can(["create", "update", "delete"], "Member", { _id: resourceId })
			cannot(["create", "update", "delete"], "Member", { _id: profileId })
			// can("manageMembers", "Control", { _id: resourceId })
		}
	}

	return rules
}
