import * as React from "react"
import { TableInstance } from "react-table"
import { Block } from "baseui/block"

import SelectColumnOptions from "./actions/SelectColumnOptions"

import GlobalFilter from "./filters/GlobalFilter"
import SortingOptions from "./filters/SortingOptions"
import { TableProps } from "./"

export interface Props<T extends object> {
	instance: TableInstance<T>
	tableProps: React.PropsWithChildren<TableProps<T>>
}

const TableToolbar = <T extends object>({ instance, tableProps }: Props<T>) => {
	const { columns, selectedFlatRows, state, setSortBy } = instance
	const { onSelect, onDelete, isDeleteLoading, onDownload, isDownloadLoading, searchable, sortingOptions } = tableProps

	return (
		<Block marginBottom="scale600" display="flex" alignItems="center" justifyContent="space-between">
			<Block>
				<Block display="flex" alignItems="center">
					{columns.map((column, i) => {
						if (!column.canFilter || !column.Filter) {
							return null
						}

						return (
							<Block marginRight="scale200" key={i}>
								{column.render("Filter")}
							</Block>
						)
					})}
				</Block>

				<SelectColumnOptions
					selectedFlatRows={selectedFlatRows}
					onSelect={onSelect}
					onDelete={onDelete}
					onDownload={onDownload}
					isDownloadLoading={isDownloadLoading}
					isDeleteLoading={isDeleteLoading}
				/>
			</Block>

			<SortingOptions sortingOptions={sortingOptions} setSortBy={setSortBy} current={state.sortBy} />

			{searchable && (
				<div>
					<GlobalFilter {...instance} globalFilter={state.globalFilter} />
				</div>
			)}
		</Block>
	)
}

export default TableToolbar
