import { useInfiniteQuery } from "react-query"

import { getActivities } from "services/activities"
// import { IActivity } from "types/IActivity";
import { ActivityEntities } from "types/shared";

export default function useActivities(entity: ActivityEntities, id: string) {
	const { data: activities, status, error, fetchMore, isFetchingMore, canFetchMore } = useInfiniteQuery<any, Error>(`${entity}-${id}`, getActivities, {
		getFetchMore: (lastGroup) => {
			const lastItem = lastGroup.pop()
			return lastItem ? lastItem._id : false
		},
	})

	return { activities, status, error, fetchMore, isFetchingMore, canFetchMore }
}
