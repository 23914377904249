import { useEffect, useState, useCallback } from "react"
import { StatefulPopover } from "baseui/popover"
import { Block } from "baseui/block"
import { StatefulMenu } from "baseui/menu"
import { Label2 } from "baseui/typography"
import { useStyletron } from "baseui"
import { Button, SHAPE, SIZE, KIND } from "baseui/button"
import { ChevronRight, ChevronDown, MoreVertical } from "react-feather"
import { useSelector } from "react-unistore"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

import { IState } from "store"
import { getClosest } from "utils/misc"
import { isGroupDeleteable } from "utils/tree"
import { IProjectPlanGroup } from "types/IProjectPlan"

import Editable from "components/Editable"
import ProjectplanColumn from "components/ProjectplanColumn"
import DragHandle from "components/DragHandle"
import DropdownContent from "components/DropdownContent"

export interface Props {
	canEdit: boolean
	group: IProjectPlanGroup
	isDragging?: boolean
	dragHandleProps?: DraggableProvidedDragHandleProps
	isOpen: boolean

	setIsOpen: any
	onRemove: any
	onUpdate: any
}

const GroupHeader: React.FC<Props> = ({ canEdit, onUpdate, onRemove, group, dragHandleProps, isDragging, isOpen, setIsOpen }) => {
	const [css, theme] = useStyletron()
	const [isHovered, setIsHover] = useState(false)
	const [isDeleteable, setIsDeleteable] = useState(true)
	const checkDeleteable = useCallback(isGroupDeleteable, [])
	const currentProject = useSelector((state: IState) => state.currentProject)

	useEffect(() => {
		if (!group) return
		setIsDeleteable(checkDeleteable(group))
	}, [group, checkDeleteable])

	const _onUpdate = (val: string) => {
		onUpdate({ title: val })
	}

	return (
		<div
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			onClick={(e: any) => {
				const ignoreClosest = getClosest(e.target, "[ignoreclick]")
				const ignore = e.target.hasAttribute("ignoreclick")

				if (ignore || ignoreClosest) {
					return
				}
				setIsOpen((val: boolean) => !val)
			}}>
			<Block
				width="100%"
				position="relative"
				display="flex"
				alignItems="center"
				height="40px"
				backgroundColor="mono300"
				marginTop="5px"
				// backgroundColor={isHovered || isOpen ? "mono600" : "mono400"}
				className={css({
					paddingLeft: theme.sizing.scale600,
					transitionTimingFunction: theme.animation.linearCurve,
					transitionDuration: theme.animation.timing300,
					transitionProperty: "background-color",
					borderBottom: `1px solid ${theme.colors.mono400}`,
					cursor: "pointer",
				})}>
				{canEdit && (
					<DragHandle
						size="20"
						className={css({
							opacity: isHovered || isDragging ? "1" : "0",
							transitionDuration: theme.animation.timing300,
							transitionProperty: "all",
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							left: "-26px",
						})}
						{...dragHandleProps}
					/>
				)}
				{isOpen ? (
					<ChevronDown
						size="16"
						className={css({
							marginRight: theme.sizing.scale600,
						})}
					/>
				) : (
					<ChevronRight
						size="16"
						className={css({
							marginRight: theme.sizing.scale600,
						})}
					/>
				)}

				<Label2
					display="flex"
					alignItems="center"
					marginRight="auto"
					className={css({
						fontWeight: 400,
					})}>
					{currentProject?.settings && currentProject?.settings.prefix === "numeric" && (
						<Block marginRight="scale400">{group.index}.</Block>
					)}
					{canEdit ? (
						<Editable
							onUpdate={_onUpdate}
							value={group.title}
							type="text"
							{...{
								editOnViewClick: false,
								inputProps: {
									style: {
										backgroundColor: theme.colors.mono400,
										border: "0",
										outline: "none",
										minWidth: "auto",
										padding: "2px 4px",
									},
								},
							}}
							showButton={isHovered}
						/>
					) : (
						group.title
					)}
				</Label2>

				{isOpen && (
					<>
						<ProjectplanColumn border={false} label={<span className={css({ color: theme.colors.mono600 })}>Datum</span>} />
						<ProjectplanColumn border={false} label={<span className={css({ color: theme.colors.mono600 })}>Behöriga</span>} />
						<ProjectplanColumn border={false} label={<span className={css({ color: theme.colors.mono600 })}>Status</span>} />
						<ProjectplanColumn border={false} label={<span className={css({ color: theme.colors.mono600 })}>Redovisningar</span>} />
					</>
				)}

				<ProjectplanColumn border={false} narrow>
					{canEdit && (
						<StatefulPopover
							content={() => (
								<DropdownContent padding="0" {...{ ignoreclick: "" }}>
									<StatefulMenu
										items={[
											{
												label: isDeleteable ? (
													<span onClick={onRemove}>Ta bort</span>
												) : (
													<span
														className={css({
															color: theme.colors.mono500,
														})}>
														Ta bort
													</span>
												),
											},
										]}
									/>
								</DropdownContent>
							)}
							overrides={{
								Inner: {
									style: ({ $theme }) => ({
										backgroundColor: $theme.colors.white,
									}),
								},
							}}
							returnFocus
							autoFocus>
							<Button
								shape={SHAPE.round}
								size={SIZE.mini}
								kind={KIND.tertiary}
								overrides={{
									BaseButton: {
										style: () => ({
											opacity: isHovered ? "1" : "0",
										}),
									},
								}}
								{...{ ignoreclick: "" }}>
								<MoreVertical size="16" />
							</Button>
						</StatefulPopover>
					)}
				</ProjectplanColumn>
			</Block>
		</div>
	)
}

export default GroupHeader
