import * as React from "react"
import { Link } from "react-router-dom"
import { Button, SHAPE, SIZE } from "baseui/button"
import { Block } from "baseui/block"

import PageLayout from "components/layout/PageLayout"
import Can from "components/Can"

import CheckList from "./CheckList"
import Blocks from "./Blocks"
import { IControl } from "types/IControl"

interface Props {
	currentControl: IControl
	onUpdate: Function
	rows: any[]
	controlIsFreezed: boolean
}

const Checks: React.FC<Props> = ({
	currentControl,
	// projectCategories,
	onUpdate,
	rows,
	controlIsFreezed,
}) => {
	if (!rows) {
		return (
			<div className="empty">
				<div className="empty-icon">
					<i className="icon icon-people"></i>
				</div>
				<p className="empty-title h5">Det finns inga redovisningar</p>
				<p className="empty-subtitle">Lägg till en ny redovisning</p>
			</div>
		)
	}

	if (!currentControl) return null

	return (
		<PageLayout rightContent={<Blocks currentControl={currentControl} controlIsFreezed={controlIsFreezed} onUpdate={onUpdate} />}>
			{/* <CheckList control={control} rows={rows} columns={columns}  /> */}
			<Block display="flex" justifyContent="flex-end" marginBottom="scale400">
				<Can do="create" on={currentControl} type="Check">
					{() =>
						!controlIsFreezed && (
							<Link to="check/create">
								<Button
									// onClick={() => alert("click")}
									size={SIZE.compact}
									shape={SHAPE.pill}
									disabled={controlIsFreezed}>
									Ny redovisning
								</Button>
							</Link>
						)
					}
				</Can>
			</Block>

			<CheckList
				// projectCategories={projectCategories}
				// controlIsFreezed={controlIsFreezed}
				rows={rows}
			/>
		</PageLayout>
	)
}

export default Checks
