import { styled, css } from "stitches.config"

export const Polygon = () => {
	return (
		<PolygonLayer>
			<PolygonWrapper position="left">
				<div>
					<svg width="709" height="709" viewBox="0 0 709 709" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							opacity="0.1"
							d="M187.58 187.581L483.435 108.307L700.015 324.887L620.741 620.742L324.887 700.016L108.307 483.435L187.58 187.581Z"
							fill="#420D9A"
						/>
					</svg>
				</div>
			</PolygonWrapper>
			<PolygonWrapper position="topRight">
				<div>
					<svg width="709" height="709" viewBox="0 0 709 709" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							opacity="0.1"
							d="M187.58 187.581L483.435 108.307L700.015 324.887L620.741 620.742L324.887 700.016L108.307 483.435L187.58 187.581Z"
							fill="#420D9A"
						/>
					</svg>
				</div>
			</PolygonWrapper>
			<PolygonWrapper position="bottomRight">
				<div>
					<svg width="709" height="709" viewBox="0 0 709 709" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							opacity="0.1"
							d="M187.58 187.581L483.435 108.307L700.015 324.887L620.741 620.742L324.887 700.016L108.307 483.435L187.58 187.581Z"
							fill="#420D9A"
						/>
					</svg>
				</div>
			</PolygonWrapper>
		</PolygonLayer>
	)
}

const rotateLeft = css.keyframes({
	"0%": { transform: "rotate(0deg)" },
	"100%": { transform: "rotate(-360deg)" },
})
const rotateRight = css.keyframes({
	"0%": { transform: "rotate(0deg)" },
	"100%": { transform: "rotate(360deg)" },
})

const PolygonLayer = styled("div", {
	width: "100%",
	height: "100%",
	background: "$background",
	position: "absolute",
	zIndex: 0,
	overflow: "hidden",
})
const rotationTiming = "300s"

const PolygonWrapper = styled("div", {
	position: "absolute",

	zIndex: 0,
	div: {
		opacity: 0.4,
	},
	svg: {
		width: "100%",
		height: "auto",
	},
	variants: {
		position: {
			left: {
				bottom: 0,
				left: 0,
				transform: "translateX(-50%)",
				width: 400,
				div: {
					animation: `${rotateLeft} ${rotationTiming} infinite`,
				},
				bp3: {
					width: 600,
				},
			},
			topRight: {
				top: 0,
				right: 0,
				width: 250,
				transform: "translate(20%, -30%)",
				div: {
					animation: `${rotateRight} ${rotationTiming} infinite`,
				},
				bp3: {
					width: 420,
				},
			},
			bottomRight: {
				bottom: 0,
				right: 0,
				width: 150,
				transform: "translate(20%, 30%)",
				div: {
					animation: `${rotateLeft} ${rotationTiming} infinite`,
				},
				bp3: {
					width: 300,
				},
			},
		},
	},
})
