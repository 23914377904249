import * as React from "react"
import { Link } from "react-router-dom"
import { format } from "format-phone-swedish"
import { Button, KIND, SHAPE, SIZE } from "baseui/button"
import { X } from "react-feather"
import { Cell } from "react-table"

import Can from "components/Can"
import { ICompany } from "types/ICompany"

const columns = (onRemoveCompany: Function) => {
	return [
		{
			Header: "Företagsnamn",
			accessor: "name",
			primary: true,
			width: 300,
			Cell: ({ row, value }: Cell<ICompany>) => <Link to={`../../company/${row.original._id}`}>{value}</Link>,
		},
		{
			Header: "Kontakt",
			accessor: (row: ICompany) => row.email || row.phone,
			Cell: ({ row }: Cell<ICompany>) => {
				const company = row.original
				return (
					<div>
						{company.email && <a href={"mailto:" + company.email}>{company.email}</a>}
						{company.email && company.phone && <br />}
						{company.phone && <span>{format(company.phone, "–")}</span>}
					</div>
				)
			},
		},
		{
			Header: "Adress",
			accessor: "address",
			Cell: ({ value }: Cell<ICompany>) => <span className="pre-line">{value}</span>,
		},
		{
			Header: "Org. nr",
			accessor: "corporateIdentity",
		},
		{
			Header: "",
			accessor: "actionColumn",
			Cell: ({ row }: Cell<ICompany>) => {
				const company = row.original
				return (
					<Can do="delete" on={company} type="Company">
						<Button size={SIZE.compact} shape={SHAPE.round} kind={KIND.secondary} onClick={e => onRemoveCompany(company)}>
							<X size={16} />
						</Button>
					</Can>
				)
			},
		},
	]
}

export default columns
