import * as React from "react"
import { Button, SHAPE, KIND, SIZE } from "baseui/button"
// import { Avatar } from "baseui/avatar"
// import { StatefulTooltip } from "baseui/tooltip"
import { Block } from "baseui/block"
import { User, UserPlus } from "react-feather"

import GlobalContext from "context/GlobalContext"

import AvatarGroup from "components/AvatarGroup"
import Can from "components/Can"
import { IControl } from "types/IControl"
import { ModalEnum } from "containers/ModalContainer"

export interface Props {
	controlId: string
	projectId: string
	currentControl: IControl
	controlIsFreezed: boolean
}

const PermissionsAvatars: React.FC<Props> = ({ controlId, projectId, currentControl, controlIsFreezed }) => {
	const { openModal } = React.useContext(GlobalContext)

	if (!currentControl.members) return null

	const showModal = () => {
		openModal(ModalEnum.ControlPermissions, {
			controlId,
			projectId,
			control: currentControl,
		})
	}

	return (
		<Can do="update" on={currentControl} type="Member" passThrough>
			{(allowed: boolean) => (
				<Block display="flex" alignItems="center">
					{!allowed || controlIsFreezed ? (
						<Button shape={SHAPE.round} size={SIZE.compact} kind={KIND.secondary} onClick={showModal}>
							<User size={16} />
						</Button>
					) : (
						<Button shape={SHAPE.round} size={SIZE.compact} kind={KIND.secondary} onClick={showModal}>
							<UserPlus size={16} />
						</Button>
					)}
					<AvatarGroup users={currentControl.members} onClick={showModal} />
				</Block>
			)}
		</Can>
	)
}

export default PermissionsAvatars
