import * as React from "react"
import { FormControl } from "baseui/form-control"
import { Input, InputProps, MaskedInput, MaskedInputProps } from "baseui/input"
import { FieldError } from "react-hook-form"

export interface Props extends MaskedInputProps, InputProps {
	fieldError?: FieldError
	label: string
	useMaskedInput?: boolean
}

const FormInput: React.FC<Props> = props => {
	const { fieldError } = props

	return (
		<FormControl label={props.label} error={fieldError && fieldError.message}>
			{props.useMaskedInput ? <MaskedInput type="text" {...props} /> : <Input type="text" {...props} />}
		</FormControl>
	)
}

export default FormInput
