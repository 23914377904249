import * as React from "react"
import shortid from "shortid"

import GlobalContext from "context/GlobalContext"
import useCompanyDocuments from "hooks/useCompanyDocuments"

import PageLayout from "components/layout/PageLayout"
import DocumentCategory from "./DocumentCategory"
import { ICompany } from "types/ICompany"
import { IDocument } from "types/IDocument"
import { DocumentCategories } from "types/enums"
import { ModalEnum } from "containers/ModalContainer"

interface Props {
	company: ICompany
}

const CompanyDocuments: React.FC<Props> = ({ company }) => {
	const { openModal } = React.useContext(GlobalContext)
	// const { companyId } = useParams()
	const companyId = company._id
	const {
		documents,
		removeCompanyDocument,
		addCompanyDocuments,
		// addCompanyDocumentsInfo,
	} = useCompanyDocuments(companyId)

	const viewDocument = (id: string) => {
		const currentDocument = documents?.filter((doc: IDocument) => doc._id === id)[0]
		openModal(ModalEnum.ViewDocument, { currentDocument })
	}

	const onAddedFiles = (files: File[], category: typeof DocumentCategories) => {
		addCompanyDocuments({ companyId, files, category })
	}

	const onRemove = (documentId: string) => {
		removeCompanyDocument({ companyId, documentId })
	}

	// 	Omfattning/ rambeskrivning
	// Kvalitet- och miljö
	// Material- och produktinfo (gäller oftast för entreprenörer/ leverantörer)
	// Egenkontroller och checklistor
	const documentCategories = [
		{ title: "Omfattning/rambeskrivning", category: "scopeDescription" },
		{ title: "Kvalitet- och miljö", category: "qualityEnvironment" },
		{ title: "Material- och produktinfo", category: "materialProductInfo" },
		{ title: "Egenkontroller och checklistor", category: "selfInspection" },
	]

	return (
		<PageLayout full>
			{documentCategories.map((documentCategory, index) => (
				<DocumentCategory
					key={index + shortid.generate()}
					title={documentCategory.title}
					category={documentCategory.category}
					documents={documents}
					onAddedFiles={onAddedFiles}
					onRemove={onRemove}
					viewDocument={viewDocument}
					company={company}
				/>
			))}
		</PageLayout>
	)
}

export default CompanyDocuments
