import * as React from "react"
import Skeleton from "react-loading-skeleton"
import { useAbility } from "@casl/react"

import useCompanies from "hooks/useCompanies"
import useCompany from "hooks/useCompany"
import companyColumns from "./companyColumns"

import DataTable from "components/DataTable"
import { ContentBlock } from "components/ContentBlock"
import { AbilityContext } from "components/Can"
import { ICompany } from "types/ICompany"
import { Column } from "react-table"

interface Props {
	projectId: string
}

const Companies: React.FC<Props> = ({ projectId }) => {
	const { companies, isLoading } = useCompanies(projectId)
	const { removeCompany } = useCompany()
	const ability = useAbility(AbilityContext)

	const onRemoveCompany = (company: ICompany) => {
		if (window.confirm("Är du säker på att du vill ta bort företaget? Data och medlemmar kommer att raderas.")) {
			removeCompany({ companyId: company._id })
		}
	}

	const columns: Column<any>[] = React.useMemo(
		() => companyColumns(onRemoveCompany),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ability],
	)

	if (isLoading) {
		return (
			<div className="block">
				<Skeleton height="50px" />
				<Skeleton height="50px" />
				<Skeleton height="50px" />
				<Skeleton height="50px" />
				<Skeleton height="50px" />
			</div>
		)
	}

	if (!companies) return null

	return (
		<ContentBlock>
			<DataTable
				name="companies"
				columns={columns}
				data={companies}
				// searchable={["name"]}
				// sortable
			/>
		</ContentBlock>
	)
}

export default Companies
