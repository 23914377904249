import * as React from "react"
import { useQuery, queryCache, useMutation } from "react-query"

import { removeNodeFromCollection } from "utils/misc"
import GlobalContext from "context/GlobalContext"
import { getControl, deleteControl, createControl, updateControl, approveControl, sendControlForApproval, DeleteControlVariables } from "services/controls"
import { IControl } from "types/IControl"
import { IProjectPlan } from "types/IProjectPlan"

const defaultCallbacks = {
	onSuccess: ({ controlId }: any) => {
		// console.log(data);
		queryCache.invalidateQueries(["currentControl", { controlId }])
		queryCache.invalidateQueries("controls")
	},
}

const onDeleteOptions = {
	onSuccess: ({ controlId }: DeleteControlVariables) => {
		queryCache.removeQueries(["currentControl", { controlId }])
		queryCache.removeQueries(["controlMembers", { controlId }])
		queryCache.invalidateQueries("controls")
	},
}

export default function useControl(controlId?: string | undefined) {
	const { showToast } = React.useContext(GlobalContext)
	const { data: currentControl, isLoading, isFetching, status, error } = useQuery(["currentControl", { controlId }], getControl, {
		enabled: controlId,
	})

	const [_deleteControl, deleteControlInfo] = useMutation(deleteControl, {
		...onDeleteOptions,
		onSuccess: (data, { projectId, controlId }: DeleteControlVariables) => {

			queryCache.setQueryData<IControl[] | undefined>(["controls", { projectId }], oldControls => {
				if (oldControls) {
					return oldControls.filter(c => c._id !== controlId)
				}
			})


			queryCache.setQueryData<IProjectPlan | undefined>(["projectPlan", { projectId }], oldPlan => {
				if (!oldPlan) return;

				const { filteredCollection } = removeNodeFromCollection(oldPlan.groups, controlId, "controls")

				return { ...oldPlan, groups: filteredCollection }
			})
			showToast({ type: "positive", message: "Kontrollpunkten har tagist bort." })
		},
		onError: (error: any) => {
			let message = "Det gick inte att ta bort kontrollpunkten"
			if (error.response && error.response.data.message === "Has freezed items") {
				message = "Redovisningen har godkända punkter under sig"
			}
			showToast({ type: "negative", message: message })
		},
	})
	const [_createControl, createControlInfo] = useMutation(createControl, {
		...defaultCallbacks,
		// onMutate: ({ projectId }) => {
		// 	const oldControls = queryCache.getQueryData(["controls", { projectId }])
		// },
		onSuccess: (data, { projectId, updatedData }: any) => {
			queryCache.setQueryData<IControl[] | undefined>(["controls", { projectId }], oldControls => {
				if (oldControls) {
					return [...oldControls, data]
				}
			})

			queryCache.setQueryData<IProjectPlan | undefined>(["projectPlan", { projectId }], oldPlan => {
				if (!oldPlan) return;

				const groupId = updatedData.group
				const groups = oldPlan.groups.map(group => {
					if (group._id === groupId) {
						const controls = group.controls.concat(data._id)
						console.log("updatedGroup", { ...group, controls })
						return { ...group, controls }
					}
					return group
				})
				return { ...oldPlan, groups }
			})
			showToast({ type: "positive", message: "Kontrollpunkten har skapats." })
		},
		onError: () => {
			showToast({ type: "negative", message: "Kontrollpunkten kunde inte skapas." })
		},
		// onSettled: data => {
		// 	console.log("projectId", data.project)
		// 	queryCache.invalidateQueries(["projectPlan", { projectId: data.project }])
		// },
	})
	const [_updateControl, updateControlInfo] = useMutation(updateControl, {
		// Optimistic update
		...defaultCallbacks,
		onMutate: ({ controlId, updatedData }) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			queryCache.cancelQueries(["currentControl", { controlId: controlId }])

			// Snapshot the previous value
			const previousData = queryCache.getQueryData<IControl>(["currentControl", { controlId: controlId }])

			// Optimistically update to the new value
			queryCache.setQueryData(["currentControl", { controlId: controlId }], {
				...previousData,
				...updatedData,
			})

			// Return a rollback function
			return () => queryCache.setQueryData(["currentControl", { controlId: controlId }], previousData)

			// queryCache.setQueryData(["currentControl", { controlId: controlId }], oldData => ({
			// 	...oldData,
			// 	...updatedData,
			// }))
		},
		onError: (err, updatedData, rollback: any) => {
			rollback()
			showToast({ type: "negative", message: "Kontrollpunkten kunde inte uppdateras" })
		},
		onSuccess: ({ controlId }) => {
			queryCache.invalidateQueries(["currentControl", { controlId }])
			queryCache.invalidateQueries("controls")
			showToast({ type: "positive", message: "Kontrollpunkten har uppdaterats" })
		},
	})
	const [_approveControl, approveControlInfo] = useMutation(approveControl, {
		...defaultCallbacks,
		onSuccess: ({ controlId }) => {
			queryCache.invalidateQueries(["currentControl", { controlId }])
			queryCache.invalidateQueries("controls")
			showToast({ type: "positive", message: "Kontrollpunktens status har uppdaterats" })
		},
		onError: (err, updatedData, rollback) => {
			showToast({ type: "negative", message: "Kontrollpunktens status kunde inte uppdateras" })
		},
	})
	const [_sendControlForApproval, sendControlForApprovalInfo] = useMutation(sendControlForApproval, defaultCallbacks)

	return {
		currentControl,
		isLoading,
		isFetching,
		status,
		error,

		deleteControl: _deleteControl,
		deleteControlInfo,

		createControl: _createControl,
		createControlInfo,

		updateControl: _updateControl,
		updateControlInfo,

		approveControl: _approveControl,
		approveControlInfo,

		sendControlForApproval: _sendControlForApproval,
		sendControlForApprovalInfo,
	}
}
