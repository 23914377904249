// import { DocumentTypes } from "utils/enums"
import { ObjectTypes, ActivityVerbs } from "./constants"


export const DocumentFolders = <const>["global", "company"]
export const DocumentCategories = <const>[
	"scopeDescription",
	"qualityEnvironment",
	"materialProductInfo",
	"selfInspection",
]
export enum DocumentCategoriesEnum {
	scopeDescription = "scopeDescription",
	qualityEnvironment = "qualityEnvironment",
	materialProductInfo = "materialProductInfo",
	selfInspection = "selfInspection",
}

// Approval status applies to entities: Control, Check, Task, Document
// export const EntityStatus = <const>["", EntityStatus.pending, EntityStatus.disapproved, EntityStatus.approved]
export enum EntityStatus {
	empty = "empty",
	pending = "pending",
	disapproved = "disapproved",
	approved = "approved"
}
export type EntityStatusType = keyof typeof EntityStatus | ""

// export const TaskTypesEnum = <const>["notes", "files"]
// export const TaskTypesEnum = {
// 	notes: "notes",
// 	files: "files"
// }
export enum TaskTypesEnum {
	notes = "notes",
	files = "files"
}

// export const DocumentTypes = <const>["", "drawing", "constructionDocument", "selfControl"]

export enum DocumentTypesEnum {
	drawing = "drawing",
	constructionDocument = "constructionDocument",
	selfControl = "selfControl"
}

export type DocumentTypes = keyof typeof DocumentTypesEnum | "";

// Abilities does not include projectabilities
export const AbilityTypes = <const>["Control", "Check", "Comment", "Document", "Company"]

export const DocumentPermissions = <const>["advanced", "normal"]
export const ControlPermissions = <const>["advanced", "normal", "limited"]
export const CompanyPermissions = <const>["companyManager", "companyMember"]
export const ProjectPermissions = <const>["owner", "admin", "manager", "member", "readOnly"]
export const AbilityPermissions = <const>[
	"owner",
	"admin",
	"manager",
	"member",
	"readOnly",
	"advanced",
	"normal",
	"limited",
]
export const PrefixTypes = <const>["none", "numeric"]

export const UserTypes = <const>["User", "Profile"]
export const UserPositions = <const>[
	"KA",
	"A",
	"E",
	"F",
	"G",
	"I",
	"K",
	"L",
	"M",
	"P",
	"R",
	"S",
	"V",
	"W",
	"BE",
	"EE",
	"GE",
	"HE",
	"KE",
	"ME",
	"RE",
	"SE",
	"TE",
	"VE",
]

export const FileTypes = <const>["image/png", "image/jpeg", "image/jpg", "application/pdf"]
export const ImageTypes = <const>["image/png", "image/jpeg", "image/jpg"]
export const AllowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"]
export const AllowedImageTypes = ["image/png", "image/jpeg", "image/jpg"]

export const NotificationTypes = <const>[
	"taskApproved",
	"taskDisapproved",
	"taskSentForApproval",
	"createdTask",
	"updatedTask",
	"removedTask",

	"checkApproved",
	"checkDisapproved",
	"checkSentForApproval",
	"createdCheck",
	"updatedCheck",
	"removedCheck",

	"documentApproved",
	"documentDisapproved",
	"documentSentForApproval",
	"createdCheckDocument",
	"removedCheckDocument",
	"createdTaskDocument",
	"removedTaskDocument",

	"createdComment",
	"updatedComment",
	"removedComment",

	"controlApproved",
	"controlDisapproved",
	"controlSentForApproval",

	"createdControl",
	"updatedControl",
	"removedControl",

	"addedControlMember",
	"removedControlMember",

	"createdProjectDocument",
	"removedProjectDocument",

	"addedProjectMember",
	"removedProjectMember",

	"createdCompanyDocument",
	"removedCompanyDocument",
	"addedCompanyMember",
	"removedCompanyMember",
	"updatedCompanyMember",

	"createdProject",
	"updatedProject",
	"removedProject",

	"updatedFolders",
]

export const ObjectTypesArray = Object.keys(ObjectTypes).map(key => ObjectTypes[key])
export const ActivityVerbsArray = Object.keys(ActivityVerbs).map(key => ActivityVerbs[key])
export const UserPositionsArray = Object.keys(UserPositions).map(key => UserPositions[key])

export const NotificationEntities = <const>["Control", "Check"]

export const shortMonths = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"Maj",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Okt",
	"Nov",
	"Dec",
]
export const months = [
	"Januari",
	"Februari",
	"Mars",
	"April",
	"Maj",
	"Juni",
	"Juli",
	"Augusti",
	"September",
	"Oktober",
	"November",
	"December",
]

