import { AbilityBuilder } from "@casl/ability"
import { IProfile } from "types/IProfile"
import { AppAbility } from "."
// import { TAppAbility } from "./"

enum CompanyPermission {
	companyMember = 2,
	companyManager = 3,
}

enum PermissionScale {
	readOnly = 4,
	member = 5,
	manager = 6,
	admin = 7,
	owner = 8,
}

export default function defineRulesFor(rule: IProfile) {
	const currentPermission: number = PermissionScale[rule.permissions]
	const currentCompanyPermission = CompanyPermission[rule.companyPermissions]
	const { can, cannot, rules } = new AbilityBuilder(AppAbility)

	console.log("The rule", rule)

	// if (rule.type === "Profile" || rule.type === "UserProfile") {
	console.log("CURRENT PROFILE:", rule)
	const projectId = rule.project
	const companyId = rule.company._id
	const profileId = rule._id

	if (currentPermission === PermissionScale.readOnly) {
		console.log("IS READ ONLY")

		// VIEWS
		can("view", "Documents")
		can("view", "Members")
		can("view", "Control")
		can("view", "CompanyDocuments")
	}

	if (currentPermission === PermissionScale.member) {
		console.log("IS MEMBER")
		// VIEWS
		can("view", "Documents");
		can("view", "CompanyDocuments", { _id: companyId })
		can("view", "Members")

		// PROFILES
		can("update", "Profile", { _id: profileId })
		cannot("update", "Profile", ["permissions", "companyPermissions"])
	}

	if (currentCompanyPermission === CompanyPermission.companyMember) {
		console.log("IS COMPANY MEMBER")
		// can("delete", "CompanyFiles", { "createdBy._id": profileId })
		// COMPANY ACTIVITY
		can("read", "Activity", { _id: companyId })
		can("read", "Activity", { _id: profileId })
		can("read", "Activity", { "company._id": companyId })

		// COMPANY FILES
		can("create", "CompanyFiles", { _id: companyId })
		can("delete", "CompanyFiles", {
			company: companyId,
			"createdBy._id": profileId,
		})

		// PROFILE
		cannot("update", "Profile", ["companyPermissions"])
	}

	if (currentCompanyPermission === CompanyPermission.companyManager) {
		console.log("IS COMPANY MANAGER")
		// VIEWS
		can("view", "CompanySettings")

		// COMPANY ACTIVITY
		can("read", "Activity", { _id: companyId })
		can("read", "Activity", { _id: profileId })
		can("read", "Activity", { "company._id": companyId })

		// COMPANY FILES
		can("create", "CompanyFiles", { _id: companyId })
		can(["update", "delete"], "CompanyFiles", { company: companyId })

		// MEMBERS
		// Can invite company members
		can(["create", "delete"], "Member", { _id: companyId })

		// PROFILES
		// Can delete profile in a specific company
		can(["create", "delete"], "Profile", { _id: companyId })
		can(["update", "delete"], "Profile", { "company._id": companyId })
		can("update", "Profile", ["companyPermissions"], { _id: profileId })

		cannot("update", "Profile", ["permissions"])
		cannot("update", "Profile", ["companyPermissions"], { _id: profileId })
		cannot("delete", "Profile", { _id: profileId })
		cannot("delete", "Profile", { permissions: { $in: ["owner", "admin"] } })
	}

	if (currentPermission === PermissionScale.manager) {
		console.log("IS MANAGER")

		// VIEWS
		can("view", "Documents")
		can("view", "CompanyDocuments", { _id: companyId })
		can("view", "Members")
		can("view", "Control")

		// ACTIVITY
		can("read", "Activity", { project: projectId })

		// PROFILES
		can(["create", "update", "delete"], "Profile", { project: projectId })
		can("update", "Profile", { _id: profileId })
		// can("update", "Profile", ["permissions"], {
		// 	permissions: { $in: ["manager", "member", "readOnly"] },
		// })

		cannot("delete", "Profile", { _id: profileId })
		cannot(["create", "update", "delete"], "Profile", {
			permissions: { $in: ["owner", "admin"] },
		})

		cannot("update", "Profile", ["permissions"], { _id: profileId })
		cannot("update", "Profile", ["permissions"], {
			permissions: { $in: ["owner", "admin"] },
		})

		// COMPANIES
		can(["create", "read", "update"], "Company", { project: projectId })
		can("delete", "Company", { project: projectId, members: { $size: 0 } })

		// MEMBERS
		// Can delete projectMember
		can(["create", "update", "delete"], "Member", { _id: projectId })

		// Can create controlMember
		can(["create", "update", "delete"], "Member", { project: projectId })

		// CONTROLS
		can("update", "Control")
		// can("update", "Control", { project: projectId })
		// can(["create", "update", "delete"], "Control", { project: projectId })
		can("sendForApproval", "Control")
		cannot("sendForApproval", "Control", { status: { $in: ["pending", "approved"] } })
		can("approve", "Control", { status: { $in: ["pending", "approved"] } })
		// can("manageMembers", "Control", { project: projectId })

		// CHECK
		can(["create", "update", "delete"], "Check", { project: projectId })
		can("approve", "Check", { project: projectId })

		// TASKS
		can(["create", "update", "delete", "viewSettings"], "Task", { project: projectId })
		can("approve", "Task", {
			project: projectId,
			// status: { $nin: ["pending", "approved"] },
		})
	}

	if (currentPermission === PermissionScale.admin) {
		console.log("IS ADMIN")
		// VIEWS
		can("view", "CompleteProjectPlan")
		can("view", "Members")
		can("view", "Documents")
		can("view", "GlobalDocuments")
		can("view", "Control")
		can("view", "Settings")
		can("view", "CompanyDocuments")
		can("view", "CompanySettings")

		// PROJECTS
		can("delete", "Project", { _id: projectId })

		// PROJECTPLAN
		can("update", "ProjectPlan", { _id: projectId })

		// PROFILES
		can(["create", "update", "delete"], "Profile")
		cannot("delete", "Profile", { _id: profileId })
		cannot("update", "Profile", ["permissions"], { _id: profileId })

		// COMPANIES
		can(["create", "read", "update"], "Company", { project: projectId })
		can("delete", "Company", { project: projectId, members: { $size: 0 } })

		// MEMBERS
		can(["create", "update", "delete"], "Member", { project: projectId })
		can(["create", "update", "delete"], "Member", { _id: projectId })
		can("createAdmin", "Project", { _id: projectId })

		// COMPANYFILES
		can(["create", "delete"], "CompanyFiles", { project: projectId })

		// CONTROLS
		can(["create", "update", "delete"], "Control", { project: projectId })
		can("approve", "Control", { status: { $in: ["pending", "approved"] } })
		can("sendForApproval", "Control")
		cannot("sendForApproval", "Control", { status: { $in: ["pending", "approved"] } })
		can("manageMembers", "Control", { project: projectId })

		// CHECK
		can(["create", "update", "delete"], "Check", { project: projectId })
		can("approve", "Check", { project: projectId })
		cannot(["update", "delete"], "Check", { status: { $in: ["pending", "approved"] } })

		// TASKS
		can(["create", "update", "delete", "viewSettings"], "Task", { project: projectId })
		can("approve", "Task", {
			project: projectId,
			// status: { $nin: ["pending", "approved"] },
		})

		// can("approve", "Task")
		// can(["update", "delete"], "Task")
		// cannot(["update"], "Task", { status: { $in: ["pending", "approved"] } })
	}

	if (currentPermission === PermissionScale.owner) {
		console.log("IS OWNER", rule, projectId)

		// VIEWS
		can("view", "CompleteProjectPlan")
		can("view", "Members")
		can("view", "Documents")
		can("view", "GlobalDocuments")
		can("view", "Control")
		can("view", "Settings")
		can("view", "AdvancedSettings")
		can("view", "CompanyDocuments")
		can("view", "CompanySettings")

		// PROJECTS
		can("delete", "Project", { _id: projectId })

		// PROJECTPLAN
		can("update", "ProjectPlan", { _id: projectId.toString() })

		// PROFILES
		can(["create", "update", "delete"], "Profile")
		cannot("delete", "Profile", { _id: profileId })

		// MEMBERS
		can(["create", "update", "delete"], "Member", { project: projectId })
		can(["create", "update", "delete"], "Member", { _id: projectId })
		can("createAdmin", "Project", { _id: projectId })

		// COMPANIES
		can(["create", "read", "update"], "Company", { project: projectId })
		can("delete", "Company", { project: projectId, members: { $size: 0 } })

		// COMPANYFILES
		can(["create", "delete"], "CompanyFiles", { project: projectId })

		// CONTROLS
		can(["create", "update", "delete"], "Control", { project: projectId })
		can("manageMembers", "Control", { project: projectId })
		can("approve", "Control", { status: { $in: ["pending", "approved"] } })
		can("sendForApproval", "Control")
		cannot("sendForApproval", "Control", { status: { $in: ["pending", "approved"] } })

		// CHECK
		can(["create", "update", "delete"], "Check", { project: projectId })
		can("approve", "Check", { project: projectId })
		cannot(["update", "delete"], "Check", { status: { $in: ["pending", "approved"] } })

		// TASKS
		can(["create", "update", "delete", "viewSettings"], "Task", { project: projectId })
		can("approve", "Task", {
			project: projectId,
			// status: { $nin: ["pending", "approved"] },
		})

		// can("approve", "Task")
		// can(["update", "delete"], "Task")
		// cannot(["update"], "Task", { status: { $in: ["pending", "approved"] } })
	}

	return rules
}
