import * as React from "react"

import { ContentBlock } from "components/ContentBlock"
import PageLayout from "components/layout/PageLayout"
import CompanyForm from "./CompanyForm"
import { ICompany } from "types/ICompany"
import { MutationResult } from "react-query"

interface Props {
	company: ICompany
	onUpdate: Function
	updateCompanyInfo: MutationResult<ICompany[]>
}

const CompanySettings: React.FC<Props> = ({ company, onUpdate, updateCompanyInfo }) => {
	if (!company) {
		return <div>...</div>
	}

	return (
		<PageLayout full>
			<ContentBlock>
				<CompanyForm company={company} onUpdate={onUpdate} updateCompanyInfo={updateCompanyInfo} />
			</ContentBlock>
		</PageLayout>
	)
}

export default CompanySettings
