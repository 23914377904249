import * as React from "react"
import { EntityStatus, TaskTypesEnum } from "types/enums"
import { ITask, ITaskDocument } from "types/ITask"

export default function useTaskStatus(task: ITask) {
	const [taskIsFreezed, setTaskIsFreezed] = React.useState(true)
	const [taskIsApprovable, setTaskIsApprovable] = React.useState(true)

	React.useEffect(() => {
		if (!task) return

		if (task.status === EntityStatus.approved) {
			setTaskIsFreezed(true)
		} else {
			setTaskIsFreezed(false)
		}

		if (task.type === TaskTypesEnum.files) {
			if (
				task.documents.length &&
				task.documents.filter((document: ITaskDocument) => document.status !== EntityStatus.approved).length === 0
			) {
				setTaskIsApprovable(true)
			} else {
				setTaskIsApprovable(false)
			}
		} else if (task.type === TaskTypesEnum.notes) {
			if (!task.description || task.description === "") {
				setTaskIsApprovable(false)
			} else {
				setTaskIsApprovable(true)
			}
		}
	}, [task])

	return {
		taskIsFreezed,
		taskIsApprovable,
	}
}
