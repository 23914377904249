import { AuthenticationResult, EventMessage, EventType, InteractionType, IPublicClientApplication, Logger } from "@azure/msal-browser"
import { settings, requests } from "config/auth"
import * as React from "react"
import queryString from "query-string"

import { IMsalContext, MsalContext } from "./MsalContext"
import { AccountIdentifiers } from "./types/AccountIdentifiers"
import { Constants, InteractionStatus } from "./utils/Constants"
import { accountArraysAreEqual } from "./utils/utilities"

export type MsalProviderProps = React.PropsWithChildren<{
	instance: IPublicClientApplication
}>

export const MsalProvider: React.FC<MsalProviderProps> = ({ children, instance }) => {
	const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false)
	const [loading, setLoading] = React.useState(false)
	const [loginError, setLoginError] = React.useState(false)

	// Create a logger instance for msal-react with the same options as PublicClientApplication
	const logger: Logger = React.useMemo(() => {
		return instance.getLogger().clone(Constants.SKU, Constants.VERSION)
	}, [instance])

	// State hook to store accounts
	const [accounts, setAccounts] = React.useState<AccountIdentifiers[]>([])

	// State hook to store in progress value
	const [inProgress, setInProgress] = React.useState<InteractionStatus>(InteractionStatus.Startup)

	// Handle callback
	React.useEffect(() => {
		const callbackId = instance.addEventCallback((message: EventMessage) => {
			switch (message.eventType) {
				case EventType.LOGIN_SUCCESS:
				case EventType.SSO_SILENT_SUCCESS:
				case EventType.HANDLE_REDIRECT_END:
				case EventType.LOGIN_FAILURE:
				case EventType.SSO_SILENT_FAILURE:
				case EventType.LOGOUT_FAILURE:
				case EventType.ACQUIRE_TOKEN_SUCCESS:
				case EventType.ACQUIRE_TOKEN_FAILURE:
					const currentAccounts = instance.getAllAccounts()
					if (!accountArraysAreEqual(currentAccounts, accounts)) {
						logger.info("MsalProvider - updating account state")
						setAccounts(currentAccounts)
					} else {
						logger.info("MsalProvider - no account changes")
					}
					break
			}
		})
		logger.verbose(`MsalProvider - Registered event callback with id: ${callbackId}`)

		return () => {
			// Remove callback when component unmounts or accounts change
			if (callbackId) {
				logger.verbose(`MsalProvider - Removing event callback ${callbackId}`)
				instance.removeEventCallback(callbackId)
			}
		}
	}, [instance, accounts, logger])

	// Handle errors from redirects
	React.useEffect(() => {
		// Error handling (gives error on resetPasword and signup)
		const customErrors = instance.addEventCallback((message: EventMessage) => {
			const payload: any = message.payload
			console.log("The message: ", message)

			switch (message.eventType) {
				case EventType.LOGIN_SUCCESS:
					console.log("message", message)
					console.log("message payload", message.payload)
					if (message.interactionType === InteractionType.Redirect && payload.authority.toLowerCase().indexOf(settings.signupPolicy) > -1) {
						window.alert("Ditt konto har skapats. Logga in med ditt nya konto.")
					}

					if (!message.error && payload.authority.toLowerCase().indexOf(settings.resetPolicy) > -1) {
						window.alert("Ditt lösenord har uppdaterats!")
					}
					break
				case EventType.SSO_SILENT_SUCCESS:
					logger.info("Event: SSO Silent success")
					break
				case EventType.HANDLE_REDIRECT_END:
					console.log("message", message)
					logger.info("Event: Handle redirect end")
					break
				case EventType.LOGIN_FAILURE:
					logger.info("Event: Login Failure")
					break
				case EventType.SSO_SILENT_FAILURE:
					logger.info("Event: SSO Silent failure")
					break
				case EventType.LOGOUT_FAILURE:
					logger.info("Event: Logout failure")
					break
				case EventType.ACQUIRE_TOKEN_SUCCESS:
					logger.info("Event: Acquire token success")
					if (message.interactionType === InteractionType.Redirect && payload.authority.toLowerCase().indexOf(settings.signupPolicy) > -1) {
						window.alert("Ditt konto har skapats. Logga in med ditt nya konto.")
					}

					if (!message.error && payload.authority.toLowerCase().indexOf(settings.resetPolicy) > -1) {
						window.alert("Ditt lösenord har uppdaterats!")
					}

					break
				case EventType.ACQUIRE_TOKEN_FAILURE:
					logger.info("Event: Acquire token failure")
					// redirect
					if (message.interactionType === InteractionType.Redirect) {
						if (message.error && message.error.message.indexOf("AADB2C90091") > -1) {
							// ServerError: access_denied: AADB2C90091: Användaren har avbrutit ange självuppgiven information. Correlation ID: 0eb8d442-6104-4614-a55a-b0b822520217
							// window.alert("Ditt lösenord har INTE uppdaterats!")
						} else {
							window.alert("Ditt lösenord har uppdaterats!")
						}
					}

					break
			}
		})
		logger.verbose(`MsalProvider - Registered event callback error handling with id: ${customErrors}`)

		return () => {
			if (customErrors) {
				logger.verbose(`MsalProvider - Removing event callback ${customErrors}`)
				instance.removeEventCallback(customErrors)
			}
		}
	}, [instance, logger])



	// Handle progress and logging
	React.useEffect(() => {
		const callbackId = instance.addEventCallback((message: EventMessage) => {
			switch (message.eventType) {
				case EventType.LOGIN_START:
					logger.info("MsalProvider - Login called, setting inProgress to 'login'")
					setInProgress(InteractionStatus.Login)
					break
				case EventType.SSO_SILENT_START:
					logger.info("MsalProvider - SsoSilent called, setting inProgress to 'ssoSilent'")
					setInProgress(InteractionStatus.SsoSilent)
					break
				case EventType.ACQUIRE_TOKEN_START:
					if (message.interactionType === InteractionType.Redirect || message.interactionType === InteractionType.Popup) {
						logger.info("MsalProvider - Interactive acquireToken called, setting inProgress to 'acquireToken'")
						setInProgress(InteractionStatus.AcquireToken)
					}
					break
				case EventType.HANDLE_REDIRECT_START:
					logger.info("MsalProvider - HandleRedirectPromise called, setting inProgress to 'handleRedirect'")
					setInProgress(InteractionStatus.HandleRedirect)
					break
				case EventType.LOGOUT_START:
					logger.info("MsalProvider - Logout called, setting inProgress to 'logout'")
					setInProgress(InteractionStatus.Logout)
					break
				case EventType.LOGIN_SUCCESS:
				case EventType.SSO_SILENT_SUCCESS:
				case EventType.HANDLE_REDIRECT_END:
				case EventType.LOGIN_FAILURE:
				case EventType.SSO_SILENT_FAILURE:
				case EventType.LOGOUT_FAILURE:
					logger.info("MsalProvider - Interactive request finished, setting inProgress to 'none'")
					setInProgress(InteractionStatus.None)
					break
				case EventType.ACQUIRE_TOKEN_SUCCESS:
				case EventType.ACQUIRE_TOKEN_FAILURE:
					if (message.interactionType === InteractionType.Redirect || message.interactionType === InteractionType.Popup) {
						logger.info("MsalProvider - Interactive acquireToken request finished, setting inProgress to 'none'")
						setInProgress(InteractionStatus.None)
					}
					break
			}
		})
		logger.verbose(`MsalProvider - Registered event callback with id: ${callbackId}`)

		instance.handleRedirectPromise().catch(() => {
			// Errors should be handled by listening to the LOGIN_FAILURE event
			return
		})

		return () => {
			if (callbackId) {
				logger.verbose(`MsalProvider - Removing event callback ${callbackId}`)
				instance.removeEventCallback(callbackId)
			}
		}
	}, [instance, logger])

	React.useEffect(() => {
		console.log("IS AUTHENTICATED", accounts.length > 0)
		setIsAuthenticated(() => accounts.length > 0)
	}, [accounts])

	const login = async () => {
		setLoading(true)
		instance.loginRedirect(requests.login)
	}

	const logout = () => {
		instance.logout()
	}

	// This function can be removed if you do not need to support IE
	const getAccessToken = async () => {
		try {
			console.log("CALLING SILENTREQUEST", accounts[0])
			let account = accounts[0]

			if (accounts.length > 1) {
				account = accounts.filter((account: any) => account.idTokenClaims?.tfp?.toLowerCase() === settings.policy)[0] || null
			}

			console.log("THE ACCOUNT", account)

			const silentRequest: any = {
				account: account,
				scopes: settings.scopes,
				// forceRefresh: true,
			}
			const result: AuthenticationResult = await instance.acquireTokenSilent(silentRequest)
			// setToken(result.accessToken)
			return result.accessToken
		} catch (error) {
			try {
				setLoading(true)

				// instance.logout()
				instance.acquireTokenRedirect(requests.login)
			} catch (error) {
				console.log(error)
				setLoginError(error)
			}
		}
	}

	const resetPassword = (): void => {
		instance.loginRedirect(requests.resetPassword)
	}

	const signup = async (): Promise<void> => {
		const parsedHash: queryString.ParsedQuery<string> = queryString.parse(window.location.hash)

		if (!parsedHash.id_token_hint) return

		const signupRequest = Object.assign(requests.signup, {
			extraQueryParameters: {
				lang: "sv",
				id_token_hint: parsedHash.id_token_hint.toString(),
			},
		})

		console.log("Signup Request:", signupRequest)
		// window.alert("BEFORE SIGNUP")

		instance.loginRedirect(signupRequest)
	}

	const contextValue: IMsalContext = {
		instance,
		inProgress,
		accounts,
		logger,
		isAuthenticated,
		loading,
		loginError,
		login,
		logout,
		signup,
		resetPassword,
		getAccessToken,
	}

	return <MsalContext.Provider value={contextValue}>{children}</MsalContext.Provider>
}
