import { Configuration, LogLevel } from "@azure/msal-browser"

// Setup options
export const settings = {
    authority: process.env.REACT_APP_AUTHORITY || "",
    client_id: process.env.REACT_APP_CLIENT_ID,
    policy: process.env.REACT_APP_POLICY,
    signupPolicy: process.env.REACT_APP_SIGNUP_POLICY || "",
    resetPolicy: process.env.REACT_APP_RESET_POLICY || "",
    // redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: process.env.REACT_APP_RESPONSE_TYPE,
    scopes: [process.env.REACT_APP_SCOPE || ""],
    // post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    redirect_uri: "/callback",
    post_logout_redirect_uri: "/",
}

export const requests = {
    login: {
        scopes: settings.scopes,
        prompt: "login",
        extraQueryParameters: {
            // lang: "sv"
            ui_locales: "sv"
        },
    },
    resetPassword: {
        authority: `${settings.authority}/${settings.resetPolicy}`,
        scopes: [],
        prompt: "login",
        extraQueryParameters: {
            // lang: "sv"
            ui_locales: "sv"
        },
    },
    signup: {
        authority: `${settings.authority}/${settings.signupPolicy}`,
        scopes: [],
        // scopes: settings.scopes,
        prompt: "login",
        redirectUri: `${window.location.origin}/signup`,
        extraQueryParameters: {
            // lang: "sv"
            ui_locales: "sv"
        },
    },
}

export const msalConfig: Configuration = {
    auth: {
        clientId: settings.client_id || "",
        authority: `${settings.authority}/${settings.policy}`,
        knownAuthorities: [
            `${settings.authority}/${settings.policy}`,
            `${settings.authority}/${settings.signupPolicy}`,
            `${settings.authority}/${settings.resetPolicy}`,
        ],
        navigateToLoginRequestUrl: true,
        redirectUri: settings.redirect_uri,
        postLogoutRedirectUri: settings.post_logout_redirect_uri,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        // cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
}