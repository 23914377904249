import * as React from "react"
import { useParams } from "react-router-dom"
import { Button, SHAPE, SIZE, KIND } from "baseui/button"
import { UploadCloud } from "react-feather"

import GlobalContext from "context/GlobalContext"

import Can from "components/Can"
import PageHeader from "components/layout/PageHeader"
import SubNavigation from "components/layout/SubNavigation"
import RoutesBuilder from "components/RoutesBuilder"
import { ContentBlock } from "components/ContentBlock"
import PageLayout from "components/layout/PageLayout"

import Global from "./Global"
import DocumentList from "./DocumentList"
import { CustomRoute } from "types/shared"
import { ModalEnum } from "containers/ModalContainer"

const Documents: React.FC = () => {
	const { projectId } = useParams()
	const { openModal } = React.useContext(GlobalContext)

	const routes = React.useMemo(
		(): CustomRoute[] => [
			{
				text: "Projektfiler",
				url: "",
				path: "",
				element: <DocumentList projectId={projectId} />,
			},
			{
				text: "Hemliga dokument",
				url: "global",
				path: "global",
				element: <Global />,
				conditions: { do: "view", on: "GlobalDocuments" },
			},
		],
		[projectId],
	)

	const modalTrigger = (
		<Button
			kind={KIND.primary}
			shape={SHAPE.pill}
			size={SIZE.compact}
			onClick={() =>
				openModal(ModalEnum.UploadGlobalDocument, {
					projectId,
				})
			}
			startEnhancer={() => <UploadCloud size={18} />}>
			Ladda upp dokument
		</Button>
	)

	return (
		<React.Fragment>
			<PageHeader title="Projekthandlingar">
				<Can do="view" on="GlobalDocuments">
					{() => (
						<RoutesBuilder
							routes={[
								{
									path: "global",
									element: modalTrigger,
								},
							]}
						/>
					)}
				</Can>
			</PageHeader>

			<SubNavigation links={routes} />

			<PageLayout full>
				<ContentBlock>
					<RoutesBuilder routes={routes} />
				</ContentBlock>
			</PageLayout>
		</React.Fragment>
	)
}

export default Documents
