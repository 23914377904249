import * as React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { useForm, Controller } from "react-hook-form"
import { KIND } from "baseui/button"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"
import { Plus } from "react-feather"
import { useSelector } from "react-unistore"

import useCompany from "hooks/useCompany"

import FormInput from "components/Form/FormInput"
import FormTextarea from "components/Form/FormTextarea"
import FormCorporateIdentity from "components/Form/FormCorporateIdentity"
import { emailValidationPattern } from "utils/formHelpers"
import { ModalProps } from "containers/ModalContainer"
import { IState } from "store"
import { ICompany } from "types/ICompany"

const wideItemProps = {
	overrides: {
		Block: {
			style: ({ $theme }: any) => ({
				width: `calc((200% - ${$theme.sizing.scale800}) / 3)`,
				marginRight: "0",
			}),
		},
	},
}

const AddCompany: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
	const { handleSubmit, errors, register, control, setValue } = useForm()
	const currentProject = useSelector((state: IState) => state.currentProject)
	const { createCompany, createCompanyInfo } = useCompany()

	// console.log(createCompanyInfo)

	const onSave = async (updatedData: ICompany) => {
		if (!currentProject) {
			return
		}

		updatedData.project = currentProject._id
		await createCompany({ projectId: currentProject._id, updatedData })
		closeModal()
	}

	return (
		<Modal onClose={closeModal} closeable isOpen={isOpen} animate autoFocus unstable_ModalBackdropScroll size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>Nytt företag</ModalHeader>
			<ModalBody>
				<form onSubmit={handleSubmit(onSave)}>
					<FlexGrid flexGridColumnCount={[1]} flexGridColumnGap="scale800" flexGridRowGap="scale100">
						<FlexGridItem>
							<FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale800" flexGridRowGap="scale100">
								<FlexGridItem>
									<FormInput
										label="Företagsnamn"
										fieldError={errors.name}
										placeholder="Svarking AB"
										id="name"
										name="name"
										inputRef={register({
											required: "Fältet är obligatoriskt.",
										})}
									/>
								</FlexGridItem>
								<FlexGridItem>
									<FormCorporateIdentity errors={errors} register={register} setValue={setValue} />
								</FlexGridItem>
							</FlexGrid>
						</FlexGridItem>
						<FlexGridItem>
							<FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale800" flexGridRowGap="scale100">
								<FlexGridItem>
									<FormInput
										label="E-post"
										fieldError={errors.email}
										placeholder="info@företag.se"
										id="email"
										name="email"
										inputRef={register({
											pattern: emailValidationPattern,
										})}
									/>
								</FlexGridItem>
								<FlexGridItem>
									<FormInput
										label="Telefon"
										fieldError={errors.phone}
										placeholder="070-xxxxxxxx"
										id="phone"
										name="phone"
										inputRef={register}
									/>
								</FlexGridItem>
							</FlexGrid>
						</FlexGridItem>
						<FlexGridItem {...wideItemProps}>
							<Controller
								render={props => (
									<FormTextarea
										{...props}
										fieldError={errors.address}
										label="Adress"
										placeholder="Adress"
										rows={3}
										id="address"
										name="address"
										onChange={(e: React.BaseSyntheticEvent) => setValue("address", e.target.value, { shouldValidate: true })}
									/>
								)}
								control={control}
								name="address"
								// rules={{ required: "Fältet är obligatoriskt" }}
								defaultValue=""
							/>
						</FlexGridItem>
					</FlexGrid>
				</form>
			</ModalBody>
			<ModalFooter>
				<ModalButton kind={KIND.tertiary} onClick={closeModal}>
					Avbryt
				</ModalButton>

				<ModalButton
					type="submit"
					disabled={createCompanyInfo.status === "loading"}
					onClick={handleSubmit(onSave)}
					isLoading={createCompanyInfo.status === "loading"}
					endEnhancer={() => <Plus size={18} />}>
					Lägg till
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}

export default AddCompany
