import { instance as axios } from "config/axios"

export type GetProjectVariables = { projectId: string }
export type CreateProjectVariables = { updatedData: any }
export type UpdateProjectVariables = { projectId: string, updatedData: any }
export type DeleteProjectVariables = { projectId: string }
export type GetProjectPlanVariables = { projectId: string }

export async function getMyProjectProfile(id: string) {
	const { data } = await axios.get(`/projects/${id}/members/me?all=true`)
	// console.log("PROFILES", data)
	return data
}

export async function getProjects() {
	const { data } = await axios.get("/projects")
	return data
}

// export async function getProject(id) {
export async function getProject(action: string, { projectId }: GetProjectVariables) {
	console.log("GET", projectId, action)
	const { data } = await axios.get(`/projects/${projectId}`)
	return data
}

export async function createProject({ updatedData }: CreateProjectVariables) {
	// console.log("UPDATED DATA", updatedData)
	const { data } = await axios.post("/projects", updatedData)
	return data
}

export async function updateProject({ projectId, updatedData }: UpdateProjectVariables) {
	const { data } = await axios.patch(`/projects/${projectId}`, updatedData)
	return data
}

export async function deleteProject({ projectId }: DeleteProjectVariables) {
	// console.log(projectId)
	const { data } = await axios.delete(`/projects/${projectId}`)
	return data
}

// export async function getProject(id) {
export async function getProjectPlan(action: string, { projectId }: GetProjectPlanVariables) {
	// console.log('fetching projectplan', projectId)
	const { data } = await axios.get(`/projects/${projectId}/plan`)
	return data
}
