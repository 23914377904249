import { instance as axios } from "config/axios"
import { ICompany } from "types/ICompany"

export type GetCompaniesVariables = { projectId: string }
export type GetCompanyVariables = { companyId: string }
export type CreateCompanyVariables = { projectId: string, updatedData: ICompany }
export type RemoveCompanyVariables = { companyId: string }
export type UpdateCompanyVariables = { companyId: string, updatedData: ICompany }

export async function getCompanies(key: string, { projectId }: GetCompaniesVariables) {
	const { data } = await axios.get(`/projects/${projectId}/companies`)
	return data
}

export async function getCompany(key: string, { companyId }: GetCompanyVariables) {
	const { data } = await axios.get(`/companies/${companyId}`)
	return data
}

export async function createCompany({ projectId, updatedData }: CreateCompanyVariables) {
	const { data } = await axios.post(`/companies`, updatedData)
	return data
}

export async function removeCompany({ companyId }: RemoveCompanyVariables) {
	const { data } = await axios.delete(`/companies/${companyId}`)
	return data
}

export async function updateCompany({ companyId, updatedData }: UpdateCompanyVariables) {
	const { data } = await axios.patch(`/companies/${companyId}`, updatedData)
	return data
}
