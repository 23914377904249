import * as React from "react"
import { queryCache } from "react-query"
import { EntityStatus } from "types/enums"
import { ICheck } from "types/ICheck"

export default function useCheckStatus(check: ICheck | undefined) {
	const [checkIsApprovable, setCheckIsApprovable] = React.useState<boolean>(true)
	const [checkIsFreezed, setCheckIsFreezed] = React.useState<boolean>(true)
	const [tasks, setTasks] = React.useState<ICheck[]>([])

	React.useEffect(() => {
		// console.log("CHECK EFFECT EFFECT")
		if (!check) return

		if (check.status === EntityStatus.approved || check.status === EntityStatus.pending) {
			setCheckIsFreezed(true)
		} else {
			setCheckIsFreezed(false)
		}

		const cacheTasks = queryCache.getQueryData<ICheck[]>(["tasks", { checkId: check && check._id }])
		if (!cacheTasks) {
			return;
		}

		setTasks(cacheTasks)
	}, [check])

	React.useEffect(() => {
		// console.log("TASK EFFECT")
		if (!tasks) return

		if (tasks.length && tasks.filter(task => task.status !== EntityStatus.approved).length === 0) {
			setCheckIsApprovable(true)
		} else {
			setCheckIsApprovable(false)
		}
	}, [tasks])

	return {
		checkIsFreezed,
		checkIsApprovable,
	}
}
