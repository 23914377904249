import * as React from "react"
import { Select, SelectProps, SIZE } from "baseui/select"

import { Permissions, PermissionsArray } from "utils/enums"
import { useAbility } from "@casl/react"
import { AbilityContext } from "./Can"
import { useSelector } from "react-unistore"
import { setType } from "config/abilities"
import { IState } from "store"

interface Props extends SelectProps {
	currentRole?: keyof typeof Permissions
}

const ProjectRoles: React.FC<Props> = props => {
	const ability = useAbility(AbilityContext)
	const project = useSelector((state: IState) => state.currentProject)
	const projectWithType = setType("Project", project)

	if (props.currentRole === "owner") {
		return <span>Ägare</span>
	}

	const canCreateAdmin = ability.can("createAdmin", projectWithType)
	const permissionsFilter = canCreateAdmin ? ["owner"] : ["owner", "admin"]
	const PermissionsArrayFiltered = PermissionsArray.filter(p => permissionsFilter.indexOf(p.key) === -1)
	const currentRole = PermissionsArrayFiltered.filter((p: any) => p.key === props.currentRole)

	return (
		<Select
			valueKey="key"
			labelKey="label"
			placeholder={currentRole ? "" : props.placeholder}
			options={PermissionsArrayFiltered}
			value={currentRole}
			clearable={false}
			size={props.size ? SIZE[props.size] : SIZE.mini}
			{...props}
		/>
	)
}

export default ProjectRoles
