import * as React from "react"
import { Button, SIZE, SHAPE, KIND } from "baseui/button"
// import { Draggable } from 'react-beautiful-dnd';
import { ListItem, ListItemLabel } from "baseui/list"
import { X } from "react-feather"

import DraggableContent from "components/DraggableContent"
import Editable from "components/Editable"
import { ListItem as IListItem } from "./"

interface Props {
	item: IListItem
	index: number
	onRemove: Function
	updateTitle: Function
}

const Item: React.FC<Props> = ({ item, index, onRemove, updateTitle }) => {
	return (
		<DraggableContent id={item._id} index={index}>
			<ListItem
				artwork={() => <X size="0" />}
				endEnhancer={() => (
					<Button onClick={e => onRemove(item, e)} size={SIZE.compact} shape={SHAPE.round} kind={KIND.tertiary}>
						<X size={16} />
					</Button>
				)}
				overrides={{
					ArtworkContainer: {
						style: ({ $theme }) => {
							return { width: $theme.sizing.scale1000 }
						},
					},
				}}
				sublist>
				<ListItemLabel>
					<span>
						<Editable onUpdate={(val: string) => updateTitle(val, item)} value={item.title} type="text" />
					</span>
				</ListItemLabel>
			</ListItem>
		</DraggableContent>
	)
}

export default Item
