import { register } from "timeago.js"

import { instance } from "config/axios"

import config from "config.json"

class Setup {
	// private token: string | null = null

	// constructor() {
	// 	this.token = null
	// }

	async initialize(token: string) {
		this.setLocaleTime()
		this.setupAxios(token)
	}

	async setupAxios(token: string) {
		try {
			// const token = await useProvideAuth.getAccessToken()
			if (token) {
				instance.defaults.headers.common["Authorization"] = `Bearer ${token}`
			}
		} catch (error) {
			console.log(error)
		}
	}

	setLocaleTime() {
		// Register swedish locale for 'timeago-react'
		const localeFunc: any = (number: number, index: number, total_sec: any) => config.timeago_locale[index]
		register("sv_SE", localeFunc)
	}
}

export default new Setup()
