import { instance as axios } from "config/axios"

export type GetChecksVariables = { controlId: string }
export type GetCheckVariables = { checkId: string }
export type CreateCheckVariables = { controlId: string, updatedData: any }
export type UpdateCheckVariables = { checkId: string, updatedData: any }
export type DeleteCheckVariables = { checkId: string, }
export type SendCheckVariables = { checkId: string, }
export type ApproveCheckVariables = { checkId: string, updatedData: any }

export async function getChecks(key: string, { controlId }: GetChecksVariables) {
	const { data } = await axios.get(`/controls/${controlId}/checks`)
	return data
}

export async function getCheck(key: string, { checkId }: GetCheckVariables) {
	const { data } = await axios.get(`/checks/${checkId}`)
	return data
}

export async function createCheck({ controlId, updatedData }: CreateCheckVariables) {
	const { data } = await axios.post(`/controls/${controlId}/checks/`, updatedData)
	return data
}


export async function updateCheck({ checkId, updatedData }: UpdateCheckVariables) {
	console.log("%cUpdating", "color: green;", checkId, updatedData)
	const { data } = await axios.patch(`/checks/${checkId}`, updatedData)
	return data
}

export async function deleteCheck({ checkId }: DeleteCheckVariables) {
	const { data } = await axios.delete(`/checks/${checkId}`)
	return data
}

export async function sendCheckForApproval({ checkId }: SendCheckVariables) {
	const { data } = await axios.post(`/checks/${checkId}/sendForApproval/`)
	return data
}


export async function approveCheck({ checkId, updatedData }: ApproveCheckVariables) {
	const { data } = await axios.post(`/checks/${checkId}/approval/`, updatedData)
	return data
}
