import { IDocument } from "types/IDocument"
import { IProfile } from "types/IProfile"
import { IProject } from "types/IProject"
import { IUser } from "types/IUser"
import createStore, { Store } from "unistore"
import devtools from "unistore/devtools"

export type ResponseStatus = {
	message: string,
	status: number
}

export type IState = {
	profileAbilities: IProfile | null
	currentProject: IProject | null,
	currentUserState: ResponseStatus | null,
	currentUser: IUser | null,
	profile: IProfile | null,
	currentDocument: IDocument | null,
}

const initialState: IState = {
	profileAbilities: null,
	currentProject: null,
	currentUser: null,
	currentUserState: null,
	profile: null,
	currentDocument: null,
}

const store: Store<IState> =
	process.env.NODE_ENV === "production"
		? createStore(initialState)
		: devtools(createStore(initialState))
// store.subscribe(state => console.log("Store: ", state))

export default store
