import * as ProjectService from "services/projects"
import { IState } from "store"
import { IProject } from "types/IProject"
import { IProjectPlan } from "types/IProjectPlan"

export const _getMyProjectProfile = async (state: IState, id: string) => {
	const profileAbilities = await ProjectService.getMyProjectProfile(id)
	return { ...state.profileAbilities, profileAbilities }
}

export const _resetMyProjectProfile = async (state: IState) => {
	return { ...state, profileAbilities: null }
}

export const _getProject = async (state: IState, projectId: string) => {
	const currentProject = await ProjectService.getProject('getProject', { projectId })
	return { ...state, currentProject }
}

export const _createProject = async (state: IState, data: IProject) => {
	const currentProject = await ProjectService.createProject({ updatedData: data })
	return { ...state, currentProject }
}

export const _updateProject = async (state: IState, id: string, data: IProject) => {
	const currentProject = await ProjectService.updateProject({ projectId: id, updatedData: data })
	return { ...state, currentProject }
}

export const _deleteProject = async (id: string) => {
	// export const _deleteProject = async (state: IState, id) => {
	await ProjectService.deleteProject({ projectId: id })
	return {}
}

export const _setCurrentProjects = (state: IState, projects: IProject[]) => {
	return { ...state, projects }
}

export const _setCurrentProject = (state: IState, currentProject: IProject) => {
	return { ...state, currentProject }
}

export const _setProjectPlan = (state: IState, projectPlan: IProjectPlan) => {
	return { ...state, projectPlan }
}

export const _resetCurrentProject = (state: IState) => {
	return { ...state, currentProject: null }
}
