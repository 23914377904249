import * as React from "react"
import { MutationResult } from "react-query"
// import { Button } from "baseui/button"

import { deepCopy } from "utils/misc"

import { ContentBlock } from "components/ContentBlock"
import DndList from "./DndList"
import { IProject, IProjectSector } from "types/IProject"

interface Props {
	sectors: IProjectSector[]
	updateProject: Function
	updateProjectInfo: MutationResult<any>
}

const Sectors: React.FC<Props> = ({ sectors, updateProject, updateProjectInfo }) => {
	const [items, setItems] = React.useState<IProjectSector[]>([])

	React.useEffect(() => {
		if (sectors) {
			const newItems = deepCopy(sectors)
			setItems(newItems)
		}
	}, [sectors])

	const onSave = async (sectors: IProject["sectors"], removedId: string) => {
		if (removedId) {
			updateProject({ sectors, sectorToRemove: removedId })
		} else {
			updateProject({ sectors })
		}
	}

	if (!items) return null

	return (
		<ContentBlock>
			<DndList updateProjectInfo={updateProjectInfo} onSave={onSave} items={items} />
		</ContentBlock>
	)
}

export default Sectors
