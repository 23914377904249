import * as React from "react"
import { Tag, KIND, VARIANT } from "baseui/tag"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Label3 } from "baseui/typography"
import shortid from "shortid"

import { statusColor } from "utils/enums"
import { EntityStatus } from "types/enums"
import { getBy } from "utils/filterHelpers"

export interface Props {
	filterValue: any[]
	setActiveFilter: Function
	clearFilter: Function
	preFilteredRows: any[]
	id: string
}

export const RegulatoryRequirementsFilter: React.FC<Props> = ({ filterValue, setActiveFilter, clearFilter }) => {
	const [css] = useStyletron()
	// Calculate the options for filtering

	// console.log(options, id)
	// Render a multi-select box
	const onChange = (e: any, option: any, isAdding: boolean) => {
		let newValue: any[] = filterValue || []

		if (isAdding) {
			newValue.push(option.value)
		} else {
			// Remove value from filterValue
			newValue = newValue.filter(v => v !== option.value)
		}

		if (newValue.length) {
			setActiveFilter(newValue)
		} else {
			clearFilter()
		}
	}

	const options = [
		{
			label: "Med myndighetskrav",
			value: true,
		},
		{
			label: "Utan myndighetskrav",
			value: false,
		},
	]

	return (
		<Block marginLeft="scale400" marginRight="scale400">
			<Label3 marginBottom="scale600">Myndighetskrav: </Label3>
			<ul
				className={css({
					padding: "0",
					margin: "0",
				})}>
				{options.map((option, i) => {
					const isActive = (filterValue && filterValue.includes(option.value)) || false
					return (
						<li key={`${i}-${option}`}>
							{isActive ? (
								<Tag kind={KIND.accent} variant={VARIANT.solid} onActionClick={e => onChange(e, option, false)} closeable>
									{option.label}
								</Tag>
							) : (
								<Tag kind={KIND.primary} variant={VARIANT.light} onClick={e => onChange(e, option, true)} closeable={false}>
									{option.label}
								</Tag>
							)}
						</li>
					)
				})}
			</ul>
		</Block>
	)
}

export const StatusFilter: React.FC<Props> = ({ filterValue, setActiveFilter, clearFilter, preFilteredRows, id }) => {
	const [css, theme] = useStyletron()
	// Calculate the options for filtering

	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set()
		preFilteredRows.forEach(row => {
			if (!row[id]) {
				options.add("")
			} else {
				options.add(row[id])
			}
		})
		return [...options.values()]
	}, [id, preFilteredRows])

	// console.log(options, id)
	// Render a multi-select box
	const onChange = (e: any, option: string, isAdding: boolean) => {
		let newValue = filterValue || []
		if (isAdding) {
			newValue.push(option)
		} else {
			// Remove value from filterValue
			newValue = newValue.filter(v => v !== option)
		}

		if (newValue.length) {
			setActiveFilter(newValue)
		} else {
			clearFilter()
		}
	}

	return (
		<Block marginLeft="scale400" marginRight="scale400">
			<Label3 marginBottom="scale600">Status: </Label3>
			<ul
				className={css({
					padding: "0",
					margin: "0",
				})}>
				{options.map((option: any, i: number) => {
					const isActive: boolean = (filterValue && filterValue.includes(option)) || false
					const key: keyof typeof EntityStatus = option === "" ? EntityStatus.empty : option
					const currentStatus: any = statusColor[key]
					const backgroundColor: string = theme.colors[currentStatus.color]

					const dot = (
						<span
							className={css({
								backgroundColor: backgroundColor,
								height: "10px",
								width: "10px",
								borderRadius: "50%",
								marginRight: "5px",
								display: "inline-block",
							})}></span>
					)

					return (
						<li key={`${i}-${shortid.generate()}`}>
							{isActive ? (
								<Tag kind={KIND.accent} variant={VARIANT.solid} onActionClick={e => onChange(e, option, false)} closeable>
									{dot} {currentStatus.label}
								</Tag>
							) : (
								<Tag kind={KIND.primary} variant={VARIANT.light} onClick={e => onChange(e, option, true)} closeable={false}>
									{dot} {currentStatus.label}
								</Tag>
							)}
						</li>
					)
				})}
			</ul>
		</Block>
	)
}

export const SelectUserFilter: React.FC<Props> = ({ filterValue, setActiveFilter, clearFilter, preFilteredRows, id }) => {
	// Calculate the options for filtering

	// using the preFilteredRows
	const options: any[] = React.useMemo(() => {
		const options = new Set()
		preFilteredRows.forEach(row => {
			if (typeof id === "string") {
				const accessorPath = id.split(".")
				options.add(getBy(row, accessorPath))
			} else {
				options.add(row[id])
			}
		})
		return [...options.values()]
	}, [id, preFilteredRows])

	// Render a multi-select box
	return (
		<select
			value={filterValue}
			onChange={e => {
				if (e.target.value === "") {
					// console.log("CLEARING FILTER")
					clearFilter()
				} else {
					setActiveFilter(e.target.value || undefined)
				}
			}}>
			<option value="">All</option>
			{options.map((option: string, i: number) => (
				<option key={`${i}-${option}`} value={option}>
					{option}
				</option>
			))}
		</select>
	)
}
