import * as React from "react"
import { Link } from "react-router-dom"
import { MessageCircle, ArrowLeft } from "react-feather"
import { Block } from "baseui/block"
import { Tag, VARIANT } from "baseui/tag"
import { Button, SIZE, KIND, SHAPE } from "baseui/button"
import { useStyletron } from "baseui"
import { ParagraphXSmall, ParagraphMedium } from "baseui/typography"
import { StatefulTooltip } from "baseui/tooltip"

import { dateTimeFormatter } from "utils/format"

import GlobalContext from "context/GlobalContext"
import Can from "components/Can"
import Editable from "components/Editable"
import { IControl } from "types/IControl"
import { ModalEnum } from "containers/ModalContainer"

interface Props {
	controlIndex: string | null
	currentControl: IControl
	controlIsFreezed: boolean
	controlIsApprovable: boolean
	// checksCount: number
	sendForApproval: Function
	onUpdate: Function
}

const Title: React.FC<Props> = ({ controlIndex, currentControl, controlIsFreezed, controlIsApprovable, sendForApproval, onUpdate }) => {
	const [css, theme] = useStyletron()
	const { openModal } = React.useContext(GlobalContext)

	const controlState = {
		// started: <Label primary>Pågår</Label>,
		// not_started: <Label>Ej påbörjad</Label>,
		// approved: <Label color="success">Klar</Label>,
		// disapproved: <Label color="error">Behöver åtgärdas</Label>,
		// pending: <Label color="warning">Inväntar godkännande</Label>,
		approved: (
			<Tag closeable={false} variant={VARIANT.light} kind="positive">
				Klar
			</Tag>
		),
		disapproved: (
			<Tag closeable={false} variant={VARIANT.light} kind="negative">
				Behöver åtgärdas
			</Tag>
		),
		pending: (
			<Tag closeable={false} variant={VARIANT.light} kind="warning">
				Inväntar godkännande
			</Tag>
		),
	}

	return (
		<Block display="flex">
			{/* {currentControl.status === 'needs_approval' && (
				<Link to="#" className="ml-2">
					Godkänn ?
				</Link>
			)} */}
			<Block display="flex" alignItems="center">
				<Link to="../..">
					<Button
						size={SIZE.mini}
						kind={KIND.tertiary}
						shape={SHAPE.round}
						overrides={{
							BaseButton: {
								style: () => ({
									marginRight: theme.sizing.scale200,
								}),
							},
						}}>
						{/* <ChevronLeft className="icon" /> */}
						<ArrowLeft size="16" />
					</Button>
				</Link>
				<span className={css({ marginRight: "10px" })}>{controlIndex} </span>
				<Can do="update" on={currentControl} type="Control" passThrough>
					{(allowed: boolean) =>
						!allowed || controlIsFreezed ? (
							<div>{currentControl.title}</div>
						) : (
							<Editable value={currentControl.title} type="text" onUpdate={(val: string) => onUpdate({ title: val })} />
						)
					}
				</Can>
			</Block>
			<div>
				{controlState[currentControl.status]}

				{currentControl.approvalMessage && (
					<StatefulTooltip
						content={() => (
							<Block padding={"20px"}>
								<ParagraphMedium>{currentControl.approvalMessage}</ParagraphMedium>
								<ParagraphXSmall margin="0">
									{currentControl.approvedBy.fullName} - {dateTimeFormatter(currentControl.approvedAt)}
								</ParagraphXSmall>
							</Block>
						)}
						overrides={{
							Inner: {
								style: ({ $theme }) => ({
									backgroundColor: $theme.colors.white,
								}),
							},
						}}
						returnFocus
						showArrow
						autoFocus>
						<Button shape={SHAPE.round} size={SIZE.mini} kind={KIND.tertiary}>
							<MessageCircle size="16" />
						</Button>
					</StatefulTooltip>
				)}

				<Can do="approve" on={currentControl} type="Control">
					{() => (
						<Button
							shape={SHAPE.pill}
							kind={KIND.secondary}
							size={SIZE.mini}
							onClick={() => openModal(ModalEnum.ControlApproval, { currentControl })}>
							Hantera
						</Button>
					)}
				</Can>
				{controlIsApprovable && (
					<Can do="sendForApproval" on={currentControl} type="Control">
						{() => (
							<Button size={SIZE.mini} shape={SHAPE.pill} kind={KIND.tertiary} onClick={() => sendForApproval}>
								Skicka för godkännande
							</Button>
						)}
					</Can>
				)}
			</div>
		</Block>
	)
}

export default Title
