import { Tag, VARIANT, KIND } from "baseui/tag"
// import { CheckCircle, XCircle, AlertCircle } from "react-feather"

import { EntityStatus } from "types/enums"
import { statusColor } from "utils/enums"

interface Props {
	status: keyof typeof EntityStatus
}

const Status: React.FC<Props> = ({ status }) => {
	// console.log("THESTATUS", status)

	switch (status) {
		case EntityStatus.pending:
			return (
				<Tag closeable={false} variant={VARIANT.solid} kind="warning">
					{statusColor[status].label}
				</Tag>
			)
		case EntityStatus.disapproved:
			return (
				<Tag closeable={false} variant={VARIANT.solid} kind="negative">
					{statusColor[status].label}
				</Tag>
			)
		case EntityStatus.approved:
			return (
				<Tag closeable={false} variant={VARIANT.solid} kind="positive">
					{statusColor[status].label}
				</Tag>
			)
		default:
			return (
				<Tag closeable={false} variant={VARIANT.outlined} kind={KIND.custom} color="#ccc">
					{statusColor["empty"].label}
				</Tag>
			)
	}
}

export default Status
