import { styled } from "stitches.config"

export const Logo = () => {
	return (
		<LogoWrapper>
			<svg width="176" height="39" fill="none" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.242 3.645h18.066l9.033 15.646-9.033 15.645h-.004L13.24 3.648l.002-.003zm-2.106 3.648L8.638 11.62 22.1 34.936h4.995L11.136 7.293zm-4.602 7.971L17.89 34.936h-4.65L4.21 19.291l2.325-4.027zM33.412 0L44.55 19.29 33.412 38.582H11.138L0 19.291 11.137 0h22.275zM68.101 30.303c-.848.213-1.775.326-2.782.326-6.675 0-9.8-4.919-9.8-11.106 0-6.218 3.126-11.107 9.8-11.107 6.644 0 9.8 4.889 9.8 11.107 0 4.056-1.356 7.567-4.162 9.504l1.54 2.668-2.694 1.555-1.702-2.947zm-1.777-3.08l-1.178-2.039 2.694-1.555 1.36 2.354c1.59-1.38 2.278-3.733 2.278-6.46 0-4.362-1.76-7.765-6.159-7.765-4.43 0-6.159 3.403-6.159 7.765s1.76 7.765 6.16 7.765c.35 0 .686-.022 1.004-.064z"
					fill="#420D9A"
				/>
				<path
					d="M88.82 14.24v9.225c0 2.2-.946 3.574-3.298 3.574-2.352 0-3.3-1.375-3.3-3.574v-9.224h-3.481v9.774c0 3.513 2.382 6.231 6.78 6.231 4.43 0 6.781-2.718 6.781-6.231v-9.774H88.82zM104.677 30.277c4.093 0 6.567-3.238 6.567-8.186 0-4.674-2.322-8.125-6.567-8.125-2.291 0-3.696.977-4.49 2.169h-.123V7.399h-3.482v22.603h3.085l.397-1.894h.123c.794 1.191 2.199 2.169 4.49 2.169zm-.856-3.085c-2.779 0-3.879-2.352-3.879-5.101 0-2.719 1.1-5.04 3.879-5.04 2.872 0 3.91 2.321 3.91 5.04 0 2.78-1.069 5.1-3.91 5.1zM116.429 11.828c1.436 0 2.23-.855 2.23-2.2 0-1.343-.794-2.168-2.23-2.168-1.466 0-2.23.825-2.23 2.169s.764 2.199 2.23 2.199zm-1.741 18.174h3.482V14.24h-3.482V30zM128.311 30.277c1.191 0 2.199-.184 2.688-.336v-2.933h-.306a5.081 5.081 0 01-1.527.214c-1.405 0-2.046-.641-2.046-2.138v-7.88h3.818V14.24h-3.818V9.639h-3.33v4.602h-2.749v2.963h2.597v8.49c0 2.72 1.313 4.583 4.673 4.583zM144.51 14.24v9.225c0 2.2-.947 3.574-3.299 3.574s-3.299-1.375-3.299-3.574v-9.224h-3.482v9.774c0 3.513 2.383 6.231 6.781 6.231 4.429 0 6.781-2.718 6.781-6.231v-9.774h-3.482zM170.049 13.966c-2.505 0-4.093 1.13-4.918 2.474h-.092c-.885-1.588-2.535-2.474-4.734-2.474-2.291 0-3.696.977-4.551 2.321h-.122l-.275-2.046h-3.085V30h3.482v-8.704c0-2.627 1.405-4.185 3.513-4.185 2.168 0 3.054 1.313 3.054 3.452v9.438h3.451v-8.705c0-2.627 1.253-4.185 3.36-4.185 2.169 0 3.055 1.313 3.055 3.452v9.438h3.482V19.708c0-3.543-2.138-5.742-5.62-5.742z"
					fill="#420D9A"
				/>
				<path
					opacity=".2"
					d="M147.823 4.769a4.335 4.335 0 014.335-4.335h19.507A4.335 4.335 0 01176 4.769v1.734a4.335 4.335 0 01-4.335 4.335h-19.507a4.335 4.335 0 01-4.335-4.335V4.769z"
					fill="#420D9A"
				/>
				<path
					d="M152.351 8.67h2.043c1.128 0 1.754-.61 1.754-1.664 0-.815-.494-1.21-.939-1.392v-.033c.428-.19.725-.552.725-1.178 0-.947-.626-1.499-1.598-1.499h-1.985V8.67zm.708-3.295V3.547h1.195c.659 0 .947.313.947.856 0 .676-.371.972-1.038.972h-1.104zm0 2.644V6.001h1.252c.733 0 1.096.33 1.096 1.005 0 .659-.412 1.013-1.096 1.013h-1.252zM157.582 8.67h3.534v-.65h-2.817V6.025h2.561v-.659h-2.561V3.563h2.817v-.659h-3.534V8.67zM164.082 8.67h.725V3.563h1.746v-.659h-4.217v.66h1.746V8.67zM170.03 2.904h-.964l-1.705 5.683v.083h.75l.428-1.507h2.01l.428 1.507h.766v-.083l-1.713-5.683zm-1.31 3.616l.808-2.817h.033l.807 2.817h-1.648z"
					fill="#420D9A"
				/>
			</svg>
		</LogoWrapper>
	)
}

const LogoWrapper = styled("div", {
	position: "relative",
	width: "100%",
	textAlign: "left",
	left: 40,
	top: 30,
})
