import * as React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"

import { MsalProvider } from "auth/MsalProvider"
// import { ProvideAuth } from "hooks/helpers/useAuth"

import "style/index.scss"
import App from "./App"
import { PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "config/auth"

const ProviderApp = () => {
	console.log("env :)", process.env.REACT_APP_API_BASEURL)
	const instance = new PublicClientApplication(msalConfig)

	return (
		<React.StrictMode>
			<MsalProvider instance={instance}>
				<App />
			</MsalProvider>
		</React.StrictMode>
	)
}

ReactDOM.render(<ProviderApp />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
