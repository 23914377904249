import * as React from "react"
import { Breadcrumbs } from "baseui/breadcrumbs"
import { useSelector } from "react-unistore"

import { IProject } from "types/IProject"
import { IState } from "store"

import StyledLink from "components/StyledLink"

const MainBreadcrumbs: React.FC = () => {
	const currentProject: IProject | null = useSelector((state: IState) => state.currentProject)

	return (
		<Breadcrumbs
			overrides={{
				Root: {
					style: ({ $theme }) => ({
						marginLeft: `-${$theme.sizing.scale800}`,
					}),
				},
				ListItem: {
					style: ({ $theme }) => ({
						fontWeight: 300,
						color: $theme.colors.mono600,

						":hover": {
							color: $theme.colors.mono1000,
						},
					}),
				},
				Separator: {
					style: ({ $theme }) => ({
						color: $theme.colors.mono600,
						position: "relative",
						top: "-3px",
						margin: "0 2px",
					}),
				},
			}}>
			{/* <CustomStyledLink href="#parent">{currentProject?.title}</CustomStyledLink> */}
			<StyledLink to="#parent">{currentProject?.title}</StyledLink>
			<span>{currentProject?.title}</span>
		</Breadcrumbs>
	)
}

export default MainBreadcrumbs
